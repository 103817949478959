import { Capacitor } from "@capacitor/core";
import { useMutation } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { get } from "api";
import RepeatMDWithText from "assets/images/RepeatMD_withText.svg";
import { Button } from "atoms/button/Button";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as MapPin } from "atoms/icon/icons/map_pin.svg";
import { ReactComponent as QrCode } from "atoms/icon/icons/qr_code.svg";
import { QRScanner } from "organisms/qr-scanner/QRScanner";
import { ThemeContext } from "pages/Root";

const getPlatformIdFromScannedCodeDebounced = debounce(
  (scannedCode: string) =>
    get("/multi-platform/platform/qrcode", { scannedCode }),
  500,
  { leading: true, trailing: false }
);

export const FindMedspaPage = () => {
  const { resetTheme } = useContext(ThemeContext);
  const [showQRScanner, setShowQRScanner] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get("referralCode");
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: getPlatformIdFromScannedCodeDebounced,
    onSuccess: async (data) => {
      if (Capacitor.isNativePlatform()) {
        const platformId = data.platformId;
        try {
          const platformTheme = await get(
            "/multi-platform/platform/{platformId}/theme",
            { platformId }
          );

          const referralCodeParam = referralCode
            ? `&referralCode=${referralCode}`
            : "";
          const redirectUrl = `/signin/signup/complete-profile?referralCode=${referralCode}&platformId=${data.platformId}&platformImageUrl=${platformTheme.logoImageUrl}&nearestLocationId=${data.platformLocationId}${referralCodeParam}`;

          resetTheme();
          navigate(redirectUrl);
        } catch (error) {
          throw new Error("Get platform theme request failed.");
        }

        return;
      }

      const redirectUrl = referralCode
        ? `/signin/signup/complete-profile?referralCode=${referralCode}`
        : "/signin/signup/complete-profile";

      navigate(redirectUrl);
    },
    onError: (err: any) => {
      if (err.response?.status == 400 || err.response?.status == 401) {
        setErrorMessage("Unrecognized QR code - please try again");
      } else {
        setErrorMessage(
          "Something went wrong - please contact support if this issue persists"
        );
      }
      setShowErrorMessage(true);
    },
  });

  useEffect(() => {
    if (showErrorMessage) {
      const timeout = setTimeout(() => {
        setShowErrorMessage(false);
        setErrorMessage("");
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [navigate, showErrorMessage]);

  return (
    <>
      {showQRScanner && (
        <QRScanner
          errorMessage={showErrorMessage ? errorMessage : undefined}
          onClose={() => {
            setShowQRScanner(false);
            setShowErrorMessage(false);
            setErrorMessage("");
          }}
          onResult={mutation.mutate}
        />
      )}

      <div className="flex flex-col items-center p-4 pt-[calc(56px_+_env(safe-area-inset-top))]">
        <img className="mb-8" src={RepeatMDWithText} height={38} width={155} />

        <div className="mb-3 text-h2">Find your medspa</div>

        <div className="mx-4 mb-8 text-center text-body2">
          Find your medspa now to shop your favorite services, and start earning
          rewards with every purchase and visit!
        </div>

        <div className="flex w-full flex-col gap-6">
          <Button size="large" fullWidth onClick={() => setShowQRScanner(true)}>
            <div className="flex items-center gap-1.5">
              <Icon size="xsmall" color="brandColor" svg={QrCode} />
              Scan QR code in location
            </div>
          </Button>

          <Button
            size="large"
            style="secondary"
            fullWidth
            onClick={() =>
              navigate(`search-location?referralCode=${referralCode}`)
            }
          >
            <div className="flex items-center gap-1.5">
              <Icon size="xsmall" color="brandColor" svg={MapPin} />
              Search by location
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};
