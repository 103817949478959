import cx from "classnames";
import { FC, SVGProps } from "react";
import type { KeyboardEvent } from "react";

import { TextButton } from "atoms/text-button/TextButton";

type Props = {
  type?:
    | "text"
    | "tel"
    | "number"
    | "password"
    | "search"
    | "email"
    | "decimal";

  /** This is both the input label and the placeholder text */
  label?: string;

  /** Additional text that will appear below the input field */
  supportingText?: string;
  secondarySupportingText?: string;

  placeholder?: string;

  invalid?: boolean;
  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  name?: string;
  onChange?: (value: string) => void;
  inputMode?: "text" | "numeric" | "tel" | "email" | "url" | "search";
  maxLength?: number;
  autoFocus?: boolean;
  pattern?: string;
  onFocus?: (value: string) => void;
  onBlur?: (value: string) => void;
  onKeyUp?: (value: string) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void | undefined;

  /** SVG icon to render on the left side of the input box */
  leftIcon?: FC<SVGProps<SVGSVGElement>>;

  /** SVG icon to render on the right side of the input box */
  rightIcon?: FC<SVGProps<SVGSVGElement>> | string;

  /** Handler that is called when either the left or right icons are clicked */
  onIconClick?: (side: "left" | "right") => void;

  /** Specify the testid for current component */
  "data-testid"?: string;
};

export const TextField = ({
  type = "text",
  label,
  name,
  supportingText,
  secondarySupportingText,
  value,
  onChange,
  invalid = false,
  disabled = false,
  readOnly = false,
  leftIcon: Left,
  rightIcon: Right,
  inputMode,
  maxLength,
  onIconClick,
  placeholder,
  autoFocus = false,
  pattern,
  onFocus,
  onBlur,
  onKeyUp,
  onKeyDown,
  "data-testid": dataTestId,
}: Props) => {
  const modes = {
    tel: "tel",
    number: "numeric",
    text: "text",
    password: "text",
    search: "search",
    email: "email",
    decimal: "decimal",
  } as const;

  const isTextButton = typeof Right === "string";

  return (
    <label
      className={cx(
        "relative block pt-2 text-body1 text-secondary",
        (disabled || readOnly) && "opacity-60"
      )}
    >
      {Left && (
        <Left
          className="absolute top-5 left-3 h-6 w-6 cursor-pointer"
          onClick={() => onIconClick?.("left")}
        />
      )}
      <input
        className={cx(
          "-ring-offset-1 peer block h-12 w-full appearance-none rounded border-none text-primary  ring-1 ring-light-grey placeholder:!text-transparent focus:outline-none focus:ring-1 focus:ring-brand-color focus:placeholder:!text-secondary aria-invalid:ring-1 aria-invalid:ring-red aria-invalid:placeholder:text-red",
          Left ? "pl-12" : "pl-4",
          Right ? "pr-12" : "pr-4"
        )}
        placeholder={placeholder || " "}
        type={type}
        inputMode={inputMode || modes[type]}
        aria-invalid={invalid}
        disabled={disabled}
        readOnly={readOnly}
        value={value}
        name={name}
        onChange={(e) => onChange?.(e.target.value)}
        maxLength={maxLength}
        data-testid={dataTestId}
        autoFocus={autoFocus}
        pattern={pattern}
        onFocus={(e) => onFocus?.(e.target.value)}
        onBlur={(e) => onBlur?.(e.target.value)}
        onKeyUp={(e) => onKeyUp?.(e.key)}
        onKeyDown={(e) => onKeyDown?.(e)}
      />
      {Right && !isTextButton && (
        <Right
          className="absolute top-5 right-3 h-6 w-6 cursor-pointer peer-aria-invalid:fill-red"
          onClick={() => onIconClick?.("right")}
        />
      )}
      {Right && isTextButton && (
        <div className="absolute top-[0.80rem] right-2 cursor-pointer">
          <TextButton onClick={() => onIconClick?.("right")}>
            {Right}
          </TextButton>
        </div>
      )}
      <span className="absolute top-0 left-3 whitespace-nowrap bg-white px-1 text-xs text-secondary peer-placeholder-shown:invisible peer-focus:!visible peer-focus:text-brand-color peer-aria-invalid:text-red">
        {label}
      </span>
      <span
        className={cx(
          "absolute top-5 left-3 cursor-text whitespace-nowrap bg-white px-1 text-body1 text-secondary peer-focus:!invisible peer-aria-invalid:text-red",
          value && "hidden",
          Left && "ml-8"
        )}
      >
        {label}
      </span>
      {supportingText && (
        <span className="px-4 text-body3 peer-aria-invalid:text-red">
          {supportingText}
        </span>
      )}
      {secondarySupportingText && (
        <div className="px-4 text-body3 peer-aria-invalid:text-red">
          {secondarySupportingText}
        </div>
      )}
    </label>
  );
};
