import React from "react";

interface Props {
  /** Displays a short descriptive keyword in a pill container */
  children: string;

  /** The background color variant */
  color?: "primary" | "secondary" | "alert";

  /** Specify the testid for current component */
  "data-testid"?: string;
}

export const BadgeTag: React.FC<Props> = ({
  children,
  color = "primary",
  "data-testid": dataTestId,
}) => {
  const colors = {
    primary: "bg-brand-color text-white",
    secondary: "bg-brand-color/10 text-brand-color",
    alert: "text-white bg-text-primary",
  };

  return (
    <div
      className={`flex w-fit items-center gap-1.5 rounded-full py-1 px-2.5 text-bold2 ${colors[color]}`}
      data-testid={dataTestId}
    >
      {color === "alert" && (
        <div className="h-2.5 w-2.5 rounded-full bg-red">
          <div className="h-full w-full animate-ping rounded-full bg-inherit" />
        </div>
      )}
      {children}
    </div>
  );
};
