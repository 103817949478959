import cx from "classnames";
import {
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get } from "api";
import { Button } from "atoms/button/Button";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { queryClient } from "pages/Root";
import Services from "services/Services";
import { waitObj } from "toolbox/Promise";

const load = async () => {
  const platform = queryClient.fetchQuery(["/platform/current"], () =>
    get("/platform/current").catch(() => null)
  );

  const theme = queryClient.fetchQuery(["/platform/theme"], () =>
    get("/platform/theme")
  );

  return waitObj({
    platform,
    theme,
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const v1MigrationLandingLoader: LoaderFunction = async () => {
  if (Services.auth.isAuthorized) return redirect("/home");
  return json<LoaderData>(await load());
};

export const V1MigrationLandingPage = () => {
  const navigate = useNavigate();
  const { platform, theme } = useLoaderData() as LoaderData;

  const heroImage = platform?.homeImageUrl
    ? platform?.homeImageUrl
    : theme.resources?.find((resource) => resource.key === "homepage-hero")
        ?.url;

  return (
    <div>
      <div
        className="h-[290px] flex-col bg-cover bg-center"
        style={{ backgroundImage: "url(" + heroImage + ")" }}
      ></div>
      <div className="flex flex-col items-center justify-center px-7 py-4 text-center">
        <img className="w-[40%] pb-4" src={platform?.logoImageUrl || ""} />
        <div className="px-5 pb-3 font-serif text-h2">
          Introducing our new and improved app!
        </div>
        <div className="pb-5 text-body2 text-secondary">
          Log in now to see your existing plans and memberships, continue
          earning rewards and shop treatments easier than ever!
        </div>
        <div className="w-full pb-4">
          <Button fullWidth onClick={() => navigate("/signin")}>
            Log in or sign up
          </Button>
        </div>
        <div className="inset-x-0 bottom-5 mx-auto">
          <RepeatMDFooter showLinks={false} />
        </div>
      </div>
    </div>
  );
};
