import cx from "classnames";

import { ReactComponent as Affirm } from "atoms/icon/icons/affirm.svg";
import { TextButton } from "atoms/text-button/TextButton";

interface AffirmBannerLargeProps {
  platformLogo?: string | null;
  onClick?: () => void;
}

export const AffirmBannerLarge = ({
  platformLogo,
  onClick,
}: AffirmBannerLargeProps) => {
  return (
    <div
      className={cx(
        "border-b-[0.5px] border-light-grey",
        "h-[220px]",
        "bg-light-grey",
        "w-full bg-[url('assets/images/affirmBannerBG.jpeg')] bg-cover bg-center",
        "pt-6"
      )}
    >
      <div className="my-2 flex items-end justify-center gap-x-2.5 pb-3">
        <Affirm width={81.5} className="h-10" />
        <p className="pb-0.5 text-body1 text-primary">with</p>
        <img
          src={platformLogo ?? ""}
          alt="logo"
          className="max-width-[120px] h-8 pb-0.5"
        />
      </div>
      <div className="mb-0 mt-px flex justify-center pt-px font-serif text-h3 text-primary">
        Treat today. Pay later. Earn rewards.
      </div>
      <div className="mt-px flex justify-center py-3 text-body2 text-primary">
        Make monthly payments with no late fees
      </div>
      <div className="flex justify-center text-body2 text-primary">
        <TextButton onClick={onClick}>How does it work?</TextButton>
      </div>
    </div>
  );
};
