export const handleLinkClick = (
  ref: React.MutableRefObject<null | HTMLAnchorElement>
) => {
  const simulateClickWithModifierKey = (
    element: HTMLElement,
    modifierKey: string
  ) => {
    const event = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      metaKey: modifierKey === "metaKey",
      ctrlKey: modifierKey === "ctrlKey",
    });
    element.dispatchEvent(event);
  };

  if (
    ref?.current &&
    navigator.userAgent.toLocaleLowerCase().includes("android")
  ) {
    simulateClickWithModifierKey(ref?.current, "metaKey");
  }
};
