import { Icon } from "atoms/icon/Icon";
import { ReactComponent as CheckSmall } from "atoms/icon/icons/ic_check_small_ol.svg";

export const YouHaveScannedBanner = () => {
  return (
    <div className="flex items-center rounded bg-background-two py-2 pl-2 pr-4">
      <div className="mb-1">
        <Icon svg={CheckSmall} color="secondaryText" size="x2small" />
      </div>
      <div className="ml-2 text-body2 text-secondary">
        You have already checked in today!
      </div>
    </div>
  );
};
