import cx from "classnames";
import { useState } from "react";

import { Models } from "api/types";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Info } from "atoms/icon/icons/info.svg";
import { ListModal } from "molecules/modal-window/list-modal/ListModal";
import { useFeatureFlag } from "services/Flags";
import { getProductTitle } from "services/HelperService";
import { formatCurrency } from "toolbox/Money";

type OrderSummaryProps = {
  cart: Models<"ShopDisplayOrderResult">;
  displayProducts: boolean;
  feePercentage: number;
};

export interface OrderSummaryProductProps {
  name: string;
  quantity: number;
  cost: number;
}

export interface OrderSummaryPriceProps {
  name: string;
  title: string;
  value: number;
  isDiscount: boolean;
  maximumFractionDigits: number;
}

export const OrderSummary = ({
  cart,
  displayProducts,
  feePercentage,
}: OrderSummaryProps) => {
  const products: OrderSummaryProductProps[] = displayProducts
    ? cart.shopDisplayOrderLineItems?.map((item) => {
        return {
          name: getProductTitle(item.productName, item.name),
          quantity: item.quantity,
          cost: item.cost,
        };
      }) ?? []
    : [];

  const numberOfProducts = cart.shopDisplayOrderLineItems?.length ?? 0;

  const getSummaryItem = (name: string) =>
    cart.orderSummaryItems?.find((i) => i.name === name)?.value;

  const subTotal = getSummaryItem("SubTotal");
  const membershipDiscount = getSummaryItem("MembershipDiscount");
  const promotionCode = getSummaryItem("PromotionCodeDiscount");
  const repeatCashBalance = getSummaryItem("RepeatCashBalance");
  const serviceFee =
    getSummaryItem("ServiceFee") || getSummaryItem("CreditCardFee");
  const tax = getSummaryItem("Tax");
  const estimatedTotal = getSummaryItem("EstimatedTotal");

  return (
    <div>
      <div
        className={cx(
          "text-left text-bold1",
          products.length > 0 ? "pb-4" : ""
        )}
      >
        Order summary ({numberOfProducts})
      </div>
      {products.map((product) => (
        <div
          key={product.name}
          className="flex justify-between pb-2 text-body2"
        >
          <div>
            {product.name} x {product.quantity}
          </div>
          <div>{formatCurrency(product.cost)}</div>
        </div>
      ))}
      <hr className="mb-3 mt-2 bg-light-grey" />
      <ul>
        <SubTotal amount={subTotal} />
        <MembershipDiscount amount={membershipDiscount} />
        <PromotionCode amount={promotionCode} />
        <RepeatCashBalance amount={repeatCashBalance} />
        <ServiceFeeAndTax
          serviceFee={serviceFee}
          tax={tax}
          feePercentage={feePercentage}
        />
      </ul>
      <hr className="mt-2 bg-light-grey" />
      <OrderTotal amount={estimatedTotal} />
    </div>
  );
};

const SubTotal = ({ amount }: { amount: number | undefined }) => {
  if (!amount) return null;
  else
    return (
      <li className="flex justify-between py-2 text-body2">
        Subtotal
        <span>{formatCurrency(amount, 2)}</span>
      </li>
    );
};

const MembershipDiscount = ({ amount }: { amount: number | undefined }) => {
  if (!amount) return null;
  else
    return (
      <li className="flex justify-between py-2 text-body2">
        Membership Discount
        <span className="text-success-green">{formatCurrency(-amount, 2)}</span>
      </li>
    );
};

const PromotionCode = ({ amount }: { amount: number | undefined }) => {
  if (!amount) return null;
  else
    return (
      <li className="flex justify-between py-2 text-body2">
        Discount (Promotion)
        <span className="text-success-green">{formatCurrency(-amount, 2)}</span>
      </li>
    );
};

const RepeatCashBalance = ({ amount }: { amount: number | undefined }) => {
  if (!amount) return null;
  else
    return (
      <li className="flex justify-between py-2 text-body2">
        Repeat Cash Balance
        <span className="text-success-green">{formatCurrency(-amount, 2)}</span>
      </li>
    );
};

const ServiceFeeAndTax = ({
  serviceFee,
  tax,
  feePercentage,
}: {
  serviceFee: number | undefined;
  tax: number | undefined;
  feePercentage: number;
}) => {
  const [showServiceFeeAndTaxModal, setShowServiceFeeAndTaxModal] =
    useState(false);
  const serviceFeeAndTax = (serviceFee ?? 0) + (tax ?? 0);
  const isUnitedDerm = useFeatureFlag("unitedDerm");
  const taxAndText = !isUnitedDerm ? "Tax & " : "";
  const serviceFeeAndTaxText = !isUnitedDerm
    ? "convenience fee"
    : "Credit card fee";
  if (!serviceFeeAndTax) return null;
  else
    return (
      <li className="flex justify-between py-2 text-body2">
        <button
          type="button"
          className="inline-flex items-center justify-center gap-1 text-black "
          onClick={(event) => {
            event.stopPropagation();
            setShowServiceFeeAndTaxModal(!showServiceFeeAndTaxModal);
          }}
        >
          {taxAndText}
          {serviceFeeAndTaxText}
          <Icon svg={Info} size="x2small" color="secondaryText" />
        </button>
        <ListModal
          open={showServiceFeeAndTaxModal}
          onClose={() => setShowServiceFeeAndTaxModal(false)}
          size="small"
          title={`${taxAndText}${serviceFeeAndTaxText}`}
          firstButtonLabel="Got it"
          onFirstButtonClick={() => setShowServiceFeeAndTaxModal(false)}
        >
          {tax != 0 && tax && (
            <div key="sales-tax" className="flex justify-between p-6">
              <div className="text-bold1">Tax</div>
              <div>{formatCurrency(tax, 2)}</div>
            </div>
          )}
          {serviceFee && (
            <>
              <div
                key="service-fee"
                className={cx(
                  "mx-6 flex justify-between pt-6",
                  tax != 0 && tax && "border-t border-light-grey"
                )}
              >
                <div className="text-bold1 capitalize">
                  {(feePercentage * 100).toFixed(1)}% {serviceFeeAndTaxText}
                </div>
                <div>{formatCurrency(serviceFee, 2)}</div>
              </div>
              <div className="px-6 pt-2 text-body2 text-secondary">
                This fee is used to offset admin costs related to your treatment
                or service.
              </div>
            </>
          )}
        </ListModal>
        {serviceFeeAndTax && <div>{formatCurrency(serviceFeeAndTax, 2)}</div>}
      </li>
    );
};

const OrderTotal = ({ amount }: { amount: number | undefined }) => {
  if (!amount) return null;
  else
    return (
      <div className="flex justify-between py-4 pb-2 text-bold1">
        Order Total
        <span>{formatCurrency(amount, 2)}</span>
      </div>
    );
};
