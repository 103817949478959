import cx from "classnames";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { GetResult, Models } from "api/types";
import { Button } from "atoms/button/Button";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right.svg";
import { ReactComponent as ChevronRightSmall } from "atoms/icon/icons/chevron_right_small.svg";
import { ReactComponent as CalendarIcon } from "atoms/icon/icons/ic_calendar.svg";
import { ReactComponent as MembershipsIcon } from "atoms/icon/icons/ic_memberships.svg";
import { ReactComponent as OffersIcon } from "atoms/icon/icons/offers_nav.svg";
import { ReactComponent as RewardsIcon } from "atoms/icon/icons/rewards_nav.svg";
import { formatCurrency } from "toolbox/Money";

export interface MembershipsPromoProps
  extends Models<"ShopMembershipFeatureResult"> {
  onClick?: () => void;
  "data-testid"?: string;
  memberships: GetResult<"/memberships">;
}

const IconMap: { [key: string]: React.FunctionComponent } = {
  offers: OffersIcon,
  rewards: RewardsIcon,
  calendar: CalendarIcon,
};

const MembershipCardList = (props: {
  memberships: Models<"MembershipModel">[];
}) => {
  return (
    <div className="w-full">
      <ul
        className={cx(
          "flex px-4 pt-4 pb-6",
          props.memberships.length > 2
            ? "-mx-4 gap-4 overflow-x-scroll"
            : "-mx-1 gap-6"
        )}
      >
        {props.memberships.map((m) => (
          <li key={m.id}>
            <MembershipCard key={m.id} {...m} />
          </li>
        ))}
      </ul>
    </div>
  );
};

const MembershipCardLarge = (props: Models<"MembershipModel">) => {
  const navigate = useNavigate();

  return (
    <div
      className="my-4 flex h-[263px] w-[342px] flex-col overflow-hidden rounded-lg bg-cover"
      onClick={() => navigate(`/pdp/membership/${props.id}`)}
    >
      <img
        className="h-[190px] w-full object-cover"
        src={props.imageUrl ?? ""}
      />
      <div className="h-[73px] bg-one py-3 pl-6 pr-2 text-primary">
        <div className="font-serif text-h2 line-clamp-1">{props.name}</div>
        <div className="bottom-0 flex items-center justify-between rounded-b">
          <div className="text-body2">
            <span className="text-bold2">
              {formatCurrency(props.monthlyCost)}
            </span>
            /month
          </div>
          <Icon svg={ChevronRight} color="brandColor" size="x2small" />
        </div>
      </div>
    </div>
  );
};

const MembershipCard = (props: Models<"MembershipModel">) => {
  const navigate = useNavigate();

  return (
    <div
      className="flex h-[225px] w-[156px] flex-col overflow-hidden rounded bg-cover"
      onClick={() => navigate(`/pdp/membership/${props.id}`)}
    >
      <img
        className="h-[124px] w-full object-cover"
        src={props.imageUrl ?? ""}
      />
      <div className="flex h-[101px] flex-col justify-between bg-one p-3 text-primary">
        <div className="font-serif text-h3 line-clamp-2">{props.name}</div>
        <div className="bottom-0 flex items-center justify-between rounded-b">
          <div className="text-body2">
            <span className="text-bold2">
              {formatCurrency(props.monthlyCost)}
            </span>
            /month
          </div>
          <Icon svg={ChevronRightSmall} color="brandColor" size="x2small" />
        </div>
      </div>
    </div>
  );
};

const MembershipPerksList = (props: {
  perks: Models<"ShopMembershipFeaturePerkResult">[];
}) => {
  return (
    <ul>
      {props.perks.map((p) => (
        <MembershipPerkItem key={p.id} {...p} />
      ))}
    </ul>
  );
};

const MembershipPerkItem = (
  props: Models<"ShopMembershipFeaturePerkResult">
) => {
  const svg = (props.icon && IconMap[props.icon]) || OffersIcon;
  return (
    <li className="flex flex-row items-center pt-3.5">
      <div className="min-w-6">
        <Icon color="white" svg={svg} size="xsmall" />
      </div>
      <div className="pl-4">{props.perk}</div>
    </li>
  );
};

const MembershipPromo = (props: MembershipsPromoProps) => {
  const memberships = props.memberships;
  const membershipsCount = props.memberships.length;

  return (
    <div className="flex flex-col items-center bg-brand-color px-4 py-6 text-white">
      <div className="pb-4">
        <Icon color="white" svg={MembershipsIcon} size="xsmall" />
      </div>
      <div className="text-center font-serif text-h2">{props.title}</div>
      {memberships && membershipsCount == 1 && (
        <MembershipCardLarge {...memberships[0]} />
      )}
      {memberships && membershipsCount > 1 && (
        <MembershipCardList memberships={memberships ?? []} />
      )}
      <div className="pt-1 pb-0.5 text-center text-sub1 uppercase">
        {props.perksTitle}
      </div>
      <div className="flex w-full justify-center px-4 pb-8 text-body1">
        <MembershipPerksList perks={props.perks?.slice(0, 4) ?? []} />
      </div>
      <div className="w-full px-2">
        <NavLink to="/shop/memberships">
          <Button fullWidth size="large" style="outline">
            {membershipsCount == 1
              ? "View all perks and join now!"
              : membershipsCount == 2
              ? "Shop our memberships"
              : `Shop all ${membershipsCount} memberships`}
          </Button>
        </NavLink>
      </div>
    </div>
  );
};

export default MembershipPromo;
