import { Capacitor } from "@capacitor/core";
import cx from "classnames";
import { useRef } from "react";

import { ReactComponent as Logo } from "assets/images/RepeatMD_myRepeat.svg";
import { handleLinkClick } from "utils/androidByPassDeepLinkClick";

type RepeatMDFooterProps = {
  showLinks?: boolean;
  showShopTerms?: boolean;
};

export const RepeatMDFooter = (props: RepeatMDFooterProps) => {
  const logo = <Logo className="fill-dark-grey" />;
  const anchorRef = useRef(null);
  return (
    <div>
      <div className={cx("flex w-full items-center justify-center", "p-4")}>
        <div className={cx("mr-1 text-body3 text-dark-grey")}>Powered by</div>
        {logo}
      </div>
      {props.showLinks && (
        <div className="flex items-center justify-center gap-6 px-5 pt-3 pb-7">
          <a
            className="text-bold3 text-secondary"
            href={"https://myrepeat.com/terms.html"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of service
          </a>
          <a
            className="text-bold3 text-secondary"
            href={"https://myrepeat.com/privacy-policy.html"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy
          </a>
          {!Capacitor.isNativePlatform() && (
            <a
              ref={anchorRef}
              className="text-bold3 text-secondary"
              href={
                location.protocol +
                "//" +
                location.origin.replace(/[^.]*/, "admin") +
                "/admin"
              }
              target="_self"
              rel="noopener noreferrer"
              onClick={() => {
                handleLinkClick(anchorRef);
              }}
            >
              Admin
            </a>
          )}
        </div>
      )}
      {props.showShopTerms && (
        <span
          className={cx(
            "flex w-full justify-center gap-1 pb-1 text-chip text-primary",
            "flex-wrap font-normal"
          )}
        >
          By placing this order you agree to our
          <a
            className="text-brand-color"
            href={"https://myrepeat.com/privacy-policy.html"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy
          </a>
          and
          <a
            className="text-brand-color"
            href={"https://myrepeat.com/terms.html"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>
        </span>
      )}
    </div>
  );
};
