import axios from "axios";

import { queryClient } from "pages/Root";

import Services from "./Services";

export default class AuthService {
  private _keyName = "repeatmd-app-auth-token";

  get isAuthorized() {
    return !!this.loadAuthToken();
  }

  loadAuthToken() {
    // priority to sessionStorage to query for any active temporary session.
    const token =
      window.sessionStorage.getItem(this._keyName) ||
      window.localStorage.getItem(this._keyName);

    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
    return token;
  }

  setAuthToken(token: string | null, useSessionStorage?: boolean) {
    if (token) {
      let storage = window.localStorage;

      // sessionStorage is used for temporary sessions (e.g. impersonating from admin),
      // so we're able to close the session as soon as the tab or window is closed.
      if (useSessionStorage) {
        storage = window.sessionStorage;
      }

      storage.setItem(this._keyName, token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      window.sessionStorage.removeItem(this._keyName);
      window.localStorage.removeItem(this._keyName);

      delete axios.defaults.headers.common["Authorization"];
      queryClient.clear();
    }
  }
}

export function setAuth(token: string) {
  Services.auth.setAuthToken(token);
}
