import cx from "classnames";
import { ReactNode } from "react";

import { Button } from "atoms/button/Button";
import { IconButton } from "atoms/icon-button/IconButton";
import { ReactComponent as Close } from "atoms/icon/icons/close.svg";
import { Modal } from "atoms/modal/Modal";

export type ListModalSize = "small" | "medium" | "fullscreen";

type BaseListModalProps = {
  open?: boolean;
  onClose: () => void;
  title: string;
  size?: ListModalSize;
  children: ReactNode;
  onFirstButtonClick?: () => void;
  firstButtonLabel?: string;
  firstButtonDisabled?: boolean;
  onSecondButtonClick?: () => void | undefined;
  secondButtonLabel?: undefined;
  secondButtonDisabled?: boolean;
};

type WithSecondButtonProps = {
  onSecondButtonClick: () => void;
  secondButtonLabel: string;
} & Omit<BaseListModalProps, "secondButtonLabel" | "onSecondButtonClick">;

type SelectModalProps = BaseListModalProps | WithSecondButtonProps;

export const ListModal = ({
  open,
  onClose,
  title,
  size,
  children,
  onFirstButtonClick,
  firstButtonLabel,
  firstButtonDisabled = false,
  onSecondButtonClick,
  secondButtonLabel,
  secondButtonDisabled = false,
}: SelectModalProps) => {
  const sizes: { [key: string]: string } = {
    small: "min-h-[360px]",
    medium: "min-h-[460px]",
    fullscreen: "h-[calc(100%_-_env(safe-area-inset-top)_-_12px)]",
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size={size && sizes[size]}
      header={
        <div className="flex h-[69px] w-full border-b border-light-grey">
          <div className="pl-3.5 pt-3">
            <IconButton
              svg={Close}
              size="small"
              style="iconOnly"
              onClick={onClose}
            />
          </div>
          <div className="flex w-full items-center justify-center pt-[26px] pb-[24px] pr-[55px] font-sans text-bold2 uppercase tracking-[.04em] text-primary">
            {title}
          </div>
        </div>
      }
      footer={
        (firstButtonLabel || secondButtonLabel) && (
          <div className="flex w-full flex-row items-center gap-2 border-t border-light-grey p-4">
            {secondButtonLabel && (
              <div className="flex w-1/2 justify-center pr-1.5">
                <Button
                  fullWidth
                  style="secondary"
                  onClick={onSecondButtonClick}
                  disabled={secondButtonDisabled}
                >
                  {secondButtonLabel}
                </Button>
              </div>
            )}
            <div className={cx(secondButtonLabel ? "w-1/2" : "w-full")}>
              <Button
                fullWidth
                onClick={onFirstButtonClick}
                disabled={firstButtonDisabled}
              >
                {firstButtonLabel}
              </Button>
            </div>
          </div>
        )
      }
    >
      {children}
    </Modal>
  );
};
