import cx from "classnames";
import React, { ReactNode, useCallback, useState } from "react";

import { IconButton } from "atoms/icon-button/IconButton";

import { ReactComponent as ArrowLeft } from "../icon/icons/arrow_left.svg";
import { ReactComponent as ArrowRight } from "../icon/icons/arrow_right.svg";

interface Props {
  /** Specify the content of the slides and title */
  slides: ReactNode[];

  /** Specify the function to be called when the index of the slide changes */
  onUpdateIndex: (index: number) => void;

  /** Specify the testid for current component */
  "data-testid"?: string;
}

export const SimpleCarousel: React.FC<Props> = ({
  slides,
  onUpdateIndex,
  "data-testid": dataTestId,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const ref = React.useRef<(HTMLDivElement | null)[]>([]);

  const updateIndex = (index: number) => {
    onUpdateIndex(index);
    setCurrentIndex(index);
  };

  const goToSlide = (slideIndex: number) => {
    updateIndex(slideIndex);
    ref.current[slideIndex]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    updateIndex(newIndex);
    ref.current[newIndex]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };
  const goToNext = useCallback(() => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    updateIndex(newIndex);
    ref.current[newIndex]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [currentIndex, slides]);

  return (
    <div
      className={cx("flex flex-col rounded", slides.length === 1 && "pb-8")}
      data-testid={dataTestId}
    >
      <div
        className={cx(
          "flex",
          "aspect-[2/1]",
          "w-full",
          "rounded",
          "overflow-x-hidden",
          "snap-x",
          "snap-mandatory"
        )}
      >
        {slides.map((item, index) => (
          <div
            ref={(e) => (ref.current[index] = e)}
            key={index}
            className="block aspect-[2/1] h-full snap-center object-fill px-4"
          >
            {item}
          </div>
        ))}
      </div>
      <div
        className={cx(
          "w-fill flex items-center justify-between p-1",
          slides.length === 1 && "hidden"
        )}
      >
        <IconButton
          svg={ArrowLeft}
          size="small"
          style="iconOnly"
          onClick={goToPrevious}
        />
        <div className="flex items-center gap-2">
          {slides.map((_, index) => (
            <div
              key={index}
              className={cx(
                "relative",
                "rounded-full",
                "hover:bg-brand-color",
                "h-2.5 first:h-1.5 last:h-1.5",
                "w-2.5 first:w-1.5 last:w-1.5",
                currentIndex === index ? "bg-brand-color" : "bg-dark-grey"
              )}
              onClick={() => goToSlide(index)}
            ></div>
          ))}
        </div>
        <IconButton
          svg={ArrowRight}
          size="small"
          style="iconOnly"
          onClick={goToNext}
        />
      </div>
    </div>
  );
};

export default SimpleCarousel;
