import { PlatformLocation } from "molecules/platform-location/PlatformLocation";

interface PlatformLocationsListProps {
  platforms: {
    id: string;
    imageUrl?: string | null;
    name: string | null | undefined;
    isBlocked: boolean;
  }[];
  onItemClick: (id: string) => void;
}

export const PlatformLocationsList = ({
  platforms,
  onItemClick,
}: PlatformLocationsListProps) => {
  if (!platforms.length) {
    return null;
  }

  return (
    <ul className="flex flex-col gap-2">
      {platforms.map((platform) => (
        <PlatformLocation
          key={platform.id}
          platform={platform}
          onClick={() => {
            onItemClick(platform.id);
          }}
        />
      ))}
    </ul>
  );
};
