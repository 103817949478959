import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { get } from "api";

const useDunningMembership = () => {
  const { data: userMemberships } = useQuery({
    queryKey: ["/platform-user-memberships/status"],
    queryFn: () => get("/platform-user-memberships/status"),
  });

  const results = userMemberships?.find(
    (um) => um.dunningStage > 0 && um.dunningStage < 4 && !um.canceled
  );

  if (results) return { dunningMembership: { status: true, id: results.id } };
  else return { dunningMembership: { status: false, id: "" } };
};

export default useDunningMembership;
