export function formatPhoneNumber(number?: string | null) {
  if (number == null) {
    return "";
  }

  if (number.length == 12) {
    number = number.slice(2, 12);
  }

  if (number.length != 10) {
    return number;
  }

  const areaCode = number.slice(0, 3);
  const telephonePrefix = number.slice(3, 6);
  const lineNumber = number.slice(6, 10);

  return `(${areaCode}) ${telephonePrefix}-${lineNumber}`;
}
