import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { atom, useAtom } from "jotai";
import { useNavigate } from "react-router-dom";

import { get } from "api";
import { GetResult } from "api/types";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { EmptyState } from "molecules/empty-state/EmptyState";
import { HistoryListEntryProps } from "molecules/history-list-entry/HistoryListEntry";
import TopNav from "molecules/navbars/TopNav";
import { HistoryList } from "organisms/history-list/HistoryList";
import { notNull } from "toolbox/Guards";

const countAtom = atom(1);

const formatHistoryData = (
  data: InfiniteData<GetResult<"/user/reward-history">>,
  onClick: (id: string) => void
) => {
  const values =
    data.pages?.flatMap((page) => page.items).filter(notNull) ?? [];
  const items = values.reduce((rewards, item) => {
    const date: string = dayjs(item.redeemedDateTime).format("YYYY-MM");

    if (!(date in rewards)) {
      rewards[date] = [];
    }

    rewards[date].push({
      id: item.id,
      title: item.title,
      date: item.redeemedDateTime,
      repeatCash: item.status === 1 ? item.valueIfBanked : null,
      subtitle: item.status === 0 ? "Redeemed" : null,
      onClick: () => {
        onClick(item.id);
      },
    });
    return rewards;
  }, {} as Record<string, HistoryListEntryProps[]>);

  const groupArrays = Object.keys(items).map((date) => {
    return {
      date,
      items: items[date],
    };
  });

  return groupArrays;
};

export const RewardHistoryPage = () => {
  const navigate = useNavigate();
  const [count, setCount] = useAtom(countAtom);

  const { fetchNextPage, data, hasNextPage } = useInfiniteQuery({
    queryKey: ["/user/reward-history"],
    queryFn: ({ pageParam = 1 }) =>
      get("/user/reward-history", { page: pageParam }),
    getNextPageParam: ({ page, totalPages }) =>
      page < totalPages ? page + 1 : undefined,
    onSuccess: (data) => {
      if (data.pages.length < count) {
        fetchNextPage();
      }
    },
  });

  const isDataAvailable = data && data?.pages[0].count > 0;

  if (!data) return null;

  return (
    <div className="relative h-full bg-one">
      <TopNav
        startIconSVG={ChevronLeft}
        onStartIconClick={() => {
          navigate(-1);
        }}
        showHeaderItems={false}
      >
        <div className="flex max-h-[32px] items-center">
          <div className="flex w-full items-center justify-center pr-6 text-sub2 text-primary">
            REWARD HISTORY
          </div>
        </div>
      </TopNav>

      <div className="flex flex-col text-center">
        {!isDataAvailable && (
          <EmptyState
            title="You haven’t redeemed any rewards yet"
            body="View the rewards page to see how to earn your first reward!"
            buttonText="View rewards"
            onClick={() => navigate("/rewards")}
          />
        )}
        <HistoryList
          historyListItems={formatHistoryData(data, (id) =>
            navigate(`/rewards/${id}`)
          )}
          showLoadMoreResultsButton={hasNextPage}
          onLoadMoreClick={() => {
            fetchNextPage();
            setCount(data.pages.length + 1);
          }}
        />
      </div>
      <div className="grow" />
      <div className="flex w-full scroll-py-4 flex-col items-center">
        <RepeatMDFooter />
      </div>
    </div>
  );
};
