import { useState } from "react";

import { ModalSelectorList } from "organisms/selection-modals/modal-selector-list/ModalSelectorList";

interface TreatmentSelectorModalProps {
  // todo: change to Treatment type
  treatments: {
    id: string;
    name: string;
    quantity?: number;
  }[];
  primaryOnClick: (id: string, quantity: number) => void;
  primaryLabel: string;
  onClose: () => void;
}

export const TreatmentSelectorModal = ({
  treatments,
  primaryOnClick,
  primaryLabel,
  onClose,
}: TreatmentSelectorModalProps) => {
  const [selected, setSelected] = useState("");

  return (
    <ModalSelectorList
      size="fullscreen"
      onClose={onClose}
      open={true}
      title="SELECT A TREATMENT TO REDEEM"
      type="products"
      firstButtonLabel={primaryLabel}
      onFirstButtonClick={() => primaryOnClick(selected, 1)}
      firstButtonDisabled={!selected}
      items={treatments.map((treatment) => ({
        id: treatment.id,
        title: treatment.name,
        subtitle: treatment.quantity
          ? `${treatment.quantity} units available`
          : undefined,
      }))}
      checkedItems={selected}
      onSelect={(itemId) => setSelected(itemId)}
    />
  );
};
