/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";

import { WhatToExpect } from "molecules/containers/what-to-expect/WhatToExpect";

interface Props {
  /** Specify the content for each value option */
  expectations: {
    id: string;
    title: string;
    content: string;
  }[];

  /** Specify the testid for current component */
  "data-testid"?: string;
}

export const WhatToExpectList: React.FC<Props> = ({
  expectations,
  "data-testid": dataTestId,
}) => {
  if (!expectations.length) return null;
  return (
    <div className="bg-two pb-6" data-testid={dataTestId}>
      <div className="pt-6 pb-6 text-center font-bold text-brand-color">
        WHAT TO EXPECT
      </div>
      {expectations.map((item) => (
        <WhatToExpect key={item.id} title={item.title} body={item.content} />
      ))}
    </div>
  );
};
