import cx from "classnames";

import { Icon } from "atoms/icon/Icon";
import { ReactComponent as SinglePromoIcon } from "atoms/icon/icons/ic_offers_ol.svg";
import { ReactComponent as Warning } from "atoms/icon/icons/ic_warning_ol.svg";
import { ReactComponent as Loading } from "atoms/icon/icons/loading_spin.svg";
import { TextButton } from "atoms/text-button/TextButton";

type TextEntryProps = {
  errorMsg?: boolean;
  onChange?: (e: any) => void;
  value?: string;
  loading?: boolean;
  onClick?: () => void;
  buttonText: string;
  disabled?: boolean | false;
};

export const TextEntry = (props: TextEntryProps) => {
  return (
    <label
      className={cx(
        "relative block w-full px-px text-body1 text-secondary",
        props.disabled && "opacity-60"
      )}
    >
      <div className="absolute top-[20px] left-3 h-6 w-6">
        <Icon svg={SinglePromoIcon} color="secondaryText" size="xsmall" />
      </div>
      <input
        className={cx(
          "-ring-offset-1 peer block h-16 w-full appearance-none rounded border-none pl-12 pr-12 text-primary ring-1 ring-light-grey placeholder:!text-secondary focus:outline-none focus:ring-1 focus:ring-brand-color focus:placeholder:!text-secondary aria-invalid:ring-1 aria-invalid:ring-red aria-invalid:placeholder:text-red",
          props.errorMsg && "ring-1 ring-red focus:ring-red"
        )}
        type="text"
        placeholder="Enter Promo Code"
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
      />
      {!props.loading && props.value && !props.errorMsg && (
        <div className="absolute top-[10px] right-3 cursor-pointer">
          <TextButton onClick={props.onClick} disabled={props.disabled} xsText>
            {props.buttonText}
          </TextButton>
        </div>
      )}
      {props.loading && !props.errorMsg && (
        <div className="absolute top-[19px] right-3 cursor-pointer">
          <Icon svg={Loading} size="xsmall" />
        </div>
      )}
      {props.errorMsg && (
        <div className="absolute top-[20px] right-3 cursor-pointer">
          <Icon svg={Warning} size="xsmall" color="red" />
        </div>
      )}
      {props.errorMsg && (
        <div className="pt-1 pl-1 text-body3 text-red">
          Code invalid or expired
        </div>
      )}
    </label>
  );
};
