import { AppIcon } from "@capacitor-community/app-icon";
import { App } from "@capacitor/app";
import { Capacitor, PluginListenerHandle } from "@capacitor/core";
import { useCallback } from "react";

import { Models } from "api/types";
import Services from "services/Services";

let androidIconListener: PluginListenerHandle | undefined;

/**
 * This function's logic is supposed to exactly match the logic in `sanitize_filename`.
 * @see {@link file://./../../scripts/mobile-app-icons/download_platform_icons.py}
 */
const sanitizeIconName = (rawIconName: string) =>
  rawIconName
    .toLowerCase()
    .replace(/\./g, "_")
    .replace(/[^a-z0-9_]/g, "");

const handleChangeIcon = async (
  platformDomainTokens: Models<"GetMultiPlatformUserPlatformsResults">["platformDomainTokens"],
  rawIconName: string,
  platformType: "android" | "ios"
) => {
  const iconName = sanitizeIconName(rawIconName);
  const iconNames =
    platformDomainTokens?.map(
      (platformDomainToken) =>
        `launcher_${sanitizeIconName(
          `${platformDomainToken.platformSubdomain}`
        )}`
    ) || [];

  if (
    Capacitor.isNativePlatform() &&
    Capacitor.getPlatform() === platformType &&
    (await AppIcon.isSupported()).value
  ) {
    const disableIcons = iconNames.filter(
      (name) => name !== `launcher_${iconName}`
    );

    try {
      await AppIcon.change({
        name: `launcher_${iconName}`,
        suppressNotification: true,
        disable: disableIcons,
      });
    } catch (error) {
      console.error(error);
    }
  }
};

const handleResetIcon = async (
  platformDomainTokens: Models<"GetMultiPlatformUserPlatformsResults">["platformDomainTokens"],
  platformType: "android" | "ios"
) => {
  const iconNames =
    platformDomainTokens?.map(
      (platformDomainToken) =>
        `launcher_${sanitizeIconName(
          `${platformDomainToken.platformSubdomain}`
        )}`
    ) || [];

  if (platformType === "android") {
    AppIcon.reset({
      suppressNotification: true,
      disable: iconNames,
    });
  }
};

export const useChangeIcon = (
  platformDomainTokens: Models<"GetMultiPlatformUserPlatformsResults">["platformDomainTokens"]
) => {
  const changeIcon = useCallback(
    (iconName: string) => {
      handleChangeIcon(platformDomainTokens, iconName, "ios");

      if (androidIconListener) {
        androidIconListener.remove();
      }

      androidIconListener = App.addListener("pause", () => {
        if (Services.auth.isAuthorized) {
          handleChangeIcon(platformDomainTokens, iconName, "android");
        } else {
          handleResetIcon(platformDomainTokens, "android");
        }
      });
    },
    [platformDomainTokens]
  );

  return changeIcon;
};
