import cx from "classnames";
import React, { ReactNode, useState } from "react";

import { Models } from "api/types";
import { ReactComponent as DownChevron } from "atoms/icon/icons/chevron_down_small.svg";
import { TextButton } from "atoms/text-button/TextButton";
import { ModalSelectorFilterList } from "organisms/selection-modals/modal-selector-filter-list/ModalSelectorFilterList";
import { useListingFilterAndSort } from "pages/root/shop/useListingFilterAndSort";

interface Props {
  listings: Models<"ListingModel">[];
  types: ("Package" | "Treatment")[];
  concerns: Models<"ConcernModel">[];
  areas: Models<"AreaResult">[];
  children: (listing: Models<"ListingModel">, index: number) => ReactNode;
}

const sortOptions = new Map([
  ["featured", "Featured"],
  ["bestsellers", "Best sellers"],
  ["priceLowToHigh", "Price - Low to high"],
  ["priceHighToLow", "Price - High to low"],
  ["recentlyAdded", "Recently added"],
] as const);

export type ListingSortOptions = Parameters<typeof sortOptions["get"]>[0];

const typeOptions = {
  Package: "Packages",
  Treatment: "Treatments",
};

export type ListingTypeOptions = keyof typeof typeOptions;

export const FilterAndSortListings: React.FC<Props> = ({
  listings,
  types,
  concerns,
  areas,
  children: renderItem,
}) => {
  const [activeModal, setActiveModal] = useState<
    "concern" | "area" | "type" | "sort" | null
  >(null);

  const type = types.length == 1 ? types[0] : null;
  const filterAndSortState = useListingFilterAndSort(listings, type);

  const filterLabel = (value: string, count: number) => {
    if (count <= 1) {
      return value;
    } else {
      return `${value} +${count - 1}`;
    }
  };

  const hasTreatments = listings.some(
    (listing) => listing.type === "Treatment"
  );
  const hasPackages = listings.some((listing) => listing.type === "Package");

  const showTreatmentTypeFilter =
    types.length > 1 && hasTreatments && hasPackages;

  return (
    <div className="flex flex-col px-4 py-2">
      <div
        className={cx(
          "flex items-center overflow-x-auto",
          (showTreatmentTypeFilter || concerns.length > 0) &&
            "justify-between gap-2"
        )}
      >
        <div className="flex flex-row items-center justify-start">
          {(showTreatmentTypeFilter || concerns.length > 0) && (
            <div className="text-body2 text-primary">Filter:</div>
          )}
          {showTreatmentTypeFilter && (
            <TextButton
              endIconSVG={DownChevron}
              onClick={() => {
                setActiveModal("type");
                filterAndSortState.saveCheckpoints();
              }}
            >
              <div className="text-bold2 text-primary">
                {filterAndSortState.type
                  ? typeOptions[filterAndSortState.type]
                  : "Treatment\u00a0Type"}
              </div>
            </TextButton>
          )}
          {concerns.length > 0 && (
            <TextButton
              endIconSVG={DownChevron}
              onClick={() => {
                setActiveModal("concern");
                filterAndSortState.saveCheckpoints();
              }}
            >
              <div className="text-bold2 text-primary">
                {filterLabel(
                  concerns.find(
                    (concern) => concern.id == filterAndSortState.concerns[0]
                  )?.name ?? "Concern",
                  filterAndSortState.concerns.length
                )}
              </div>
            </TextButton>
          )}
          {areas.length > 0 && (
            <TextButton
              endIconSVG={DownChevron}
              onClick={() => {
                setActiveModal("area");
                filterAndSortState.saveCheckpoints();
              }}
            >
              <div className="text-bold2 text-primary">
                {filterLabel(
                  areas.find((area) => area.id == filterAndSortState.areas[0])
                    ?.name ?? "Area",
                  filterAndSortState.areas.length
                )}
              </div>
            </TextButton>
          )}
        </div>
        <div className="flex flex-row items-center">
          <div className="text-body2 text-primary">Sort:</div>
          <TextButton
            onClick={() => {
              setActiveModal("sort");
              filterAndSortState.saveCheckpoints();
            }}
          >
            <div className="whitespace-nowrap text-bold2 text-primary">
              {sortOptions.get(filterAndSortState.sort)}
            </div>
          </TextButton>
        </div>
        {activeModal === "type" && (
          <ModalSelectorFilterList
            open={activeModal === "type"}
            onClose={() => {
              setActiveModal(null);
              filterAndSortState.restore();
            }}
            title="FILTER BY TREATMENT TYPE"
            type="filter"
            items={Object.entries(typeOptions).map(([id, title]) => ({
              id,
              title,
              results: filterAndSortState.typeCount[id] ?? 0,
              disabled: (filterAndSortState.typeCount[id] ?? 0) === 0,
            }))}
            checkedItems={filterAndSortState.type ?? []}
            onChange={(id) => {
              if (id == filterAndSortState.type) {
                filterAndSortState.setType(null);
              } else {
                filterAndSortState.setType(id as keyof typeof typeOptions);
              }
            }}
            firstButtonLabel="Apply"
            onFirstButtonClick={() => {
              setActiveModal(null);
              filterAndSortState.setSearchParams();
            }}
            firstButtonDisabled={filterAndSortState.type == null}
            secondButtonLabel="Reset filter"
            onSecondButtonClick={() => {
              setActiveModal(null);
              filterAndSortState.setType(null);
            }}
          />
        )}
        {activeModal === "concern" && (
          <ModalSelectorFilterList
            open={activeModal === "concern"}
            onClose={() => {
              setActiveModal(null);
              filterAndSortState.restore();
            }}
            title="FILTER BY CONCERN"
            type="filter"
            items={concerns.map((concern) => ({
              id: concern.id,
              title: concern.name ?? "",
              results: filterAndSortState.concernsCount[concern.id] ?? 0,
              disabled:
                (filterAndSortState.concernsCount[concern.id] ?? 0) === 0,
            }))}
            checkedItems={filterAndSortState.concerns}
            onChange={filterAndSortState.setConcerns}
            firstButtonLabel="Apply"
            onFirstButtonClick={() => {
              setActiveModal(null);
              filterAndSortState.setSearchParams();
            }}
            firstButtonDisabled={filterAndSortState.concerns.length == 0}
            secondButtonLabel="Reset filter"
            onSecondButtonClick={() => {
              setActiveModal(null);
              filterAndSortState.clearConcerns();
            }}
          />
        )}

        {activeModal === "area" && (
          <ModalSelectorFilterList
            open={activeModal === "area"}
            onClose={() => {
              setActiveModal(null);
              filterAndSortState.restore();
            }}
            title="FILTER BY AREA"
            type="filter"
            items={areas.map((area) => ({
              id: area.id,
              title: area.name ?? "",
              results: filterAndSortState.areasCount[area.id] ?? 0,
              disabled: (filterAndSortState.areasCount[area.id] ?? 0) === 0,
            }))}
            checkedItems={filterAndSortState.areas}
            onChange={filterAndSortState.setAreas}
            firstButtonLabel="Apply"
            onFirstButtonClick={() => {
              setActiveModal(null);
              filterAndSortState.setSearchParams();
            }}
            firstButtonDisabled={filterAndSortState.areas.length == 0}
            secondButtonLabel="Reset filter"
            onSecondButtonClick={() => {
              setActiveModal(null);
              filterAndSortState.clearAreas();
            }}
          />
        )}

        {activeModal === "sort" && (
          <ModalSelectorFilterList
            open={activeModal === "sort"}
            onClose={() => {
              setActiveModal(null);
              filterAndSortState.restore();
            }}
            title="SORT BY"
            type="sort"
            items={Array.from(sortOptions).map(([id, title]) => {
              return {
                id,
                title,
              };
            })}
            checkedItems={filterAndSortState.sort}
            onChange={(id: string) => {
              filterAndSortState.sortBy(id as ListingSortOptions);
            }}
            firstButtonLabel="Apply"
            onFirstButtonClick={() => {
              setActiveModal(null);
              filterAndSortState.setSearchParams();
            }}
          />
        )}
      </div>
      <div className="flex flex-col gap-4">
        {filterAndSortState.filteredListings.map((item, index) => (
          <React.Fragment key={item.id}>
            {renderItem(item, index)}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
