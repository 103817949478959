import { Capacitor } from "@capacitor/core";
import {
  isChrome,
  isFirefox,
  isMobile,
  isOpera,
  isSafari,
  isSamsungBrowser,
} from "react-device-detect";

import { Button } from "atoms/button/Button";
import { ReactComponent as Browser } from "atoms/icon/icons/asset_browser_icon_ol.svg";
import { ReactComponent as Chrome } from "atoms/icon/icons/asset_chrome_icon_ol.svg";
import { ReactComponent as Firefox } from "atoms/icon/icons/asset_firefox_icon_ol.svg";
import { ReactComponent as Opera } from "atoms/icon/icons/asset_opera_icon_ol.svg";
import { ReactComponent as RepeatMDLogo } from "atoms/icon/icons/asset_Repeat_app_icon_ol.svg";
import { ReactComponent as Safari } from "atoms/icon/icons/asset_safari_icon_ol.svg";
import { ReactComponent as SamsungBrowser } from "atoms/icon/icons/asset_samsung_icon_ol.svg";
import { Modal } from "atoms/modal/Modal";

const getBrowserIcon = () => {
  if (isSafari) {
    return <Safari />;
  } else if (isChrome) {
    return <Chrome />;
  } else if (isFirefox) {
    return <Firefox />;
  } else if (isSamsungBrowser) {
    return <SamsungBrowser />;
  } else if (isOpera) {
    return <Opera />;
  } else {
    return <Browser />;
  }
};

type ViewAppFooterProps = {
  platformName: string;
  open: boolean;
  onClose: () => void;
  onOpenApp?: () => void;
};

export const ViewAppFooter = (props: ViewAppFooterProps) => {
  if (!isMobile || Capacitor.isNativePlatform()) {
    return null;
  }

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <div className="flex flex-col gap-5 px-4 pt-6 pb-4">
        <div className="text-center font-serif text-h3">
          View in app for a better experience!
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between text-center">
            <div className="flex-none">
              <RepeatMDLogo />
            </div>
            <div className="grow overflow-auto px-2 text-left text-body1">
              {props.platformName} on Patient Rewards
            </div>
            <div className="w-[104px] flex-none">
              <Button size="small" fullWidth onClick={props.onOpenApp}>
                Open
              </Button>
            </div>
          </div>
          <div className="flex items-center justify-between text-center">
            <div className="flex-none ">{getBrowserIcon()}</div>
            <div className="grow overflow-auto px-2 text-left text-body1">
              Continue in browser
            </div>
            <div className="w-[104px] flex-none">
              <Button
                style="secondary"
                size="small"
                fullWidth
                onClick={props.onClose}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
