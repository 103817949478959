import AuthService from "./AuthService";
import EventsService from "./EventsService";

class ServiceContainer {
  readonly events = new EventsService();
  readonly auth = new AuthService();

  registerService(id: "events" | "auth", service: any) {
    console.warn("Service being overwritten: " + id);

    // @ts-expect-error intentionally bypass readonly modifier
    this[id] = service;
  }
}

const Services = new ServiceContainer();

export default Services;
