import * as FullStory from "@fullstory/browser";
import mixpanel from "mixpanel-browser";

type SignInCustomVars = FullStory.UserVars;

export default class EventsService {
  private fullStory?: typeof FullStory;

  constructor() {
    if (process.env.REACT_APP_FULLSTORY_ORG_ID) {
      this.fullStory = FullStory;
      this.fullStory.init({
        orgId: process.env.REACT_APP_FULLSTORY_ORG_ID,
        devMode: process.env.REACT_APP_FULLSTORY_MODE !== "prod",
      });
    } else {
      console.warn(
        `REACT_APP_FULLSTORY_ORG_ID variable not provided, fullstory is not recording`
      );
    }
  }

  signIn(userId: string, customVars?: SignInCustomVars) {
    this.fullStory?.identify(userId, customVars);
  }
}
