import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { post } from "api";
import { queryClient } from "pages/Root";

import { PageHeader } from "./components/PageHeader";
import { ConfirmRequestDeleteMyAccountPage } from "./ConfirmRequestDeleteMyAccountPage";
import { DisclaimerDeleteMyAccountPage } from "./DisclaimerDeleteMyAccountPage";

export const DeleteMyAccountPage: React.FC = () => {
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: () => post("/user/request-deletion", undefined),
    onSuccess: () => {
      queryClient.invalidateQueries(["/user"]);
    },
  });

  const handleDeleteAccount = () => {
    mutation.mutate();
  };

  const goBack = () => {
    navigate(-1);
  };
  const goToAccountDetails = () => {
    navigate(-1);
  };

  return (
    <div className="flex h-full flex-col">
      <PageHeader title="Delete my account" />
      {mutation.isSuccess ? (
        <ConfirmRequestDeleteMyAccountPage
          handleContinue={goToAccountDetails}
        />
      ) : (
        <DisclaimerDeleteMyAccountPage
          disabled={mutation.isLoading}
          handleCancel={goBack}
          handleDelete={handleDeleteAccount}
        />
      )}
    </div>
  );
};
