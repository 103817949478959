import { ReactComponent as Affirm } from "../../icon/icons/affirm.svg";

export const ShopNowAffirm = () => {
  return (
    <div className="flex items-end gap-[5px] text-body2 text-secondary">
      Treat now, pay later with
      <div className="pb-[0.5px]">
        <Affirm />
      </div>
    </div>
  );
};
