import cx from "classnames";
import { NavLink } from "react-router-dom";

interface ItemProps {
  /** Where does it redirect to. */
  to: string;

  /** Label */
  label: string;

  /** Whether to display a notification ping */
  notify?: boolean;
}

interface TabsProps {
  items: ItemProps[];
}

/** Tabs */
export const Tabs = ({ items }: TabsProps) => {
  return (
    <div className="flex gap-4 overflow-auto px-6 pt-1 text-secondary">
      {items.map(({ to, label, notify }) => (
        <NavLink
          key={to}
          to={to}
          className={({ isActive }) =>
            cx(
              "relative whitespace-nowrap pb-[10px] text-bold2",
              isActive && "border-b-2 border-brand-color text-brand-color"
            )
          }
        >
          {notify && (
            <div className="absolute top-[2px] -right-1 h-2 w-2 rounded-full bg-red">
              <div className="h-2 w-2 animate-ping rounded-full bg-red" />
            </div>
          )}
          {label}
        </NavLink>
      ))}
    </div>
  );
};
