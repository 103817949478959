import React from "react";

interface Props {
  /** Displays a descriptive title in the center of the component */
  title: string;

  /** Specify the testid for current component */
  "data-testid"?: string;
}

export const OverviewPanel: React.FC<Props> = ({
  title,
  "data-testid": dataTestId,
}) => {
  return (
    <div
      className="break-words text-center font-serif text-h1"
      data-testid={dataTestId}
    >
      {title}
    </div>
  );
};
