import { AppIcon } from "@capacitor-community/app-icon";
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { datadogRum } from "@datadog/browser-rum-slim";
import { useContext, useRef } from "react";
import {
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get } from "api";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Account } from "atoms/icon/icons/ic_account_small_ol.svg";
import { ReactComponent as Admin } from "atoms/icon/icons/ic_admin_small_ol.svg";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/ic_chevron_right_small_ol.svg";
import { ReactComponent as Logout } from "atoms/icon/icons/ic_logout_small_ol.svg";
import { ReactComponent as MapPin } from "atoms/icon/icons/ic_map_pin_ol.svg";
import { ReactComponent as OrderHistory } from "atoms/icon/icons/ic_orderhistory_small_ol.svg";
import { ReactComponent as QRCode } from "atoms/icon/icons/ic_qr_code_small_ol.svg";
import { ReactComponent as Rewards } from "atoms/icon/icons/ic_rewards_ol.svg";
import { useAppState } from "contexts/AppContext";
import { SettingsContainer } from "molecules/containers/settings-container/SettingsContainer";
import { queryClient, ThemeContext } from "pages/Root";
import Services from "services/Services";
import { waitObj } from "toolbox/Promise";
import { handleLinkClick } from "utils/androidByPassDeepLinkClick";
import { setPromotionModalSeen } from "utils/functions";

const load = async () => {
  const platform = await queryClient.fetchQuery(["/platform"], () =>
    get("/platform")
  );
  return waitObj({
    platform,
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const accountSettingsLoader: LoaderFunction = async () => {
  if (!Services.auth.isAuthorized) return redirect("/signin");
  else return json<LoaderData>(await load());
};

const AccountSettingsPage = () => {
  const { platform } = useLoaderData() as LoaderData;
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const appState = useAppState();
  const { resetTheme } = useContext(ThemeContext);
  const subdomain = platform?.subdomain;
  const resetIcon = async () => {
    const disable = subdomain ? [`launcher_${subdomain}`] : [];
    try {
      await AppIcon.reset({ suppressNotification: true, disable });
    } catch (error) {
      console.debug(error);
    }
  };

  return (
    <div className="flex flex-col gap-2 py-6 px-4">
      <Group>
        <SettingsContainer
          to="/account/settings/details"
          startIconSVG={Account}
        >
          Account details
        </SettingsContainer>
        {/* The billing information was cut out of scope. While don't want to show it until  
            we have this feature implemented
        */}
        {/* <SettingsContainer to="/account/settings/payment-methods">
          Billing information
        </SettingsContainer> */}
      </Group>

      <Group>
        <SettingsContainer
          to="/account/settings/orders"
          startIconSVG={OrderHistory}
        >
          Order History
        </SettingsContainer>
        <SettingsContainer
          to="/account/settings/reward-history"
          startIconSVG={Rewards}
        >
          Reward history
        </SettingsContainer>
        <SettingsContainer to="/account/settings/visits" startIconSVG={QRCode}>
          Visit history
        </SettingsContainer>
      </Group>

      {!Capacitor.isNativePlatform() && (
        <Group>
          <a
            ref={anchorRef}
            className="text-bold3 text-secondary"
            href={
              location.protocol +
              "//" +
              location.origin.replace(/[^.]*/, "admin") +
              "/admin"
            }
            target="_self"
            rel="noopener noreferrer"
            onClick={() => {
              handleLinkClick(anchorRef);
            }}
          >
            <div>
              <button className="flex w-full items-center gap-4 bg-white p-4 text-left text-body1 text-primary">
                <Admin className="h-6 w-6 fill-[theme(textColor.secondary)]" />
                <div className="flex-1">Admin</div>
                <div>
                  <Icon svg={ChevronRight} color="brandColor" size="xsmall" />
                </div>
              </button>
            </div>
          </a>
        </Group>
      )}

      <Group>
        {Capacitor.isNativePlatform() && (
          <SettingsContainer
            to="/account/settings/switch-medspa"
            startIconSVG={MapPin}
          >
            Select your location
          </SettingsContainer>
        )}

        <div
          onClick={async () => {
            appState.setApiUrl("");
            appState.setPlatformId("");
            if (Capacitor.getPlatform() !== "android") {
              await resetIcon();
            }
            setPromotionModalSeen(false);
            Services.auth.setAuthToken(null);
            if (Capacitor.isNativePlatform()) {
              Preferences.clear();
            }
            datadogRum.clearUser();

            if (Capacitor.isNativePlatform()) {
              resetTheme();
            }

            navigate("/signin");
          }}
        >
          <SettingsContainer startIconSVG={Logout}>Log out</SettingsContainer>
        </div>
      </Group>
    </div>
  );
};

export default AccountSettingsPage;

const Group = ({ children }: { children: React.ReactNode }) => (
  <div className="divide-y divide-solid divide-background-two overflow-clip rounded">
    {children}
  </div>
);
