import cx from "classnames";
import { FC, SVGProps, useState } from "react";
import {
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get, post } from "api";
import { Models } from "api/types";
import { Button } from "atoms/button/Button";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { ReactComponent as MembershipFilled } from "atoms/icon/icons/ic_memberships_xsmall_filled_ol.svg";
import { ReactComponent as Plus } from "atoms/icon/icons/ic_plus_large_ol.svg";
import { ReactComponent as Rewards } from "atoms/icon/icons/rewards.svg";
import { ReactComponent as Star } from "atoms/icon/icons/star.svg";
import { TermsAndCondition } from "atoms/terms-and-condition/TermsAndCondition";
import useHistory from "hooks/useHistory";
import { ExpandableItems } from "molecules/expandable-items/ExpandableItems";
import { MembershipBonusInfo } from "molecules/info-blocks/MembershipBonusInfo";
import { ListModal } from "molecules/modal-window/list-modal/ListModal";
import TopNav from "molecules/navbars/TopNav";
import { IncludedMonthlyRewards } from "organisms/included-monthly-rewards/IncludedMonthlyRewards";
import { queryClient } from "pages/Root";
import { IfFlag } from "services/Flags";
import {
  getMembershipRewardGroupSubheader,
  getProductTitle,
  getProductTitleQuantity,
} from "services/HelperService";
import { formatCurrency } from "toolbox/Money";
import { waitObj } from "toolbox/Promise";
import aOrAn from "utils/aOrAn";
import { FormattedString } from "utils/FormattedStrings";
import pluralize from "utils/pluralize";

const load = async (membershipId: string, source?: string) => {
  const membershipListing = queryClient.fetchQuery(
    ["/membership", { membershipId }],
    () => get("/memberships/{membershipId}", { membershipId })
  );
  const membershipRewards = queryClient.fetchQuery(
    ["/membership/{membershipId}/rewards", { membershipId }],
    () => get("/memberships/{membershipId}/rewards", { membershipId })
  );

  const userMemberships = queryClient.fetchQuery(
    ["/platform-user-memberships"],
    () => get("/platform-user-memberships")
  );

  return await waitObj({
    source,
    membershipListing,
    membershipRewards,
    userMemberships,
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const membershipPDPLoader: LoaderFunction = async ({ params }) => {
  const membershipId = params.id;
  const source =
    params.source == "Package"
      ? "package"
      : params.source == "Treatment"
      ? "treatment"
      : undefined;

  if (!membershipId) return redirect("..");
  return json<LoaderData>(await load(membershipId, source));
};

export const addMembershipToCart = async (membershipId: string) => {
  try {
    const response = await post("/orders/current/lineitems", {
      membershipId: membershipId,
    });
    queryClient.invalidateQueries({ queryKey: ["/orders/current/summary"] });
    return response;
  } catch (error) {
    return json<{ error: string }>({
      error: "Not able to add order to cart. try again later",
    });
  }
};

const SelectableMonthlyTreatments = ({
  selectableTreatmentsRewardGroup,
  description,
  svg,
  monthsBeforeFirstReward,
}: {
  selectableTreatmentsRewardGroup: Models<"MembershipRewardGroupModel">;
  description?: string;
  svg: FC<SVGProps<SVGSVGElement>>;
  monthsBeforeFirstReward?: number | null;
}) => {
  return (
    <div className="mb-1 bg-white px-4 py-8">
      <div className={cx("mb-4 text-center")}>
        <div className="flex w-full justify-center">
          <Icon color="brandColor" size="xsmall" svg={svg} />
        </div>
        <div className="pt-4 pb-4 text-sub1 uppercase text-brand-color">
          CHOOSE {selectableTreatmentsRewardGroup.quantityPerPeriod} OF THE
          FOLLOWING
          <br />
          EVERY
          {selectableTreatmentsRewardGroup.periodMonths === 12
            ? " YEAR!"
            : selectableTreatmentsRewardGroup.periodMonths === 1
            ? " MONTH!"
            : ` ${selectableTreatmentsRewardGroup.periodMonths} MONTHS!`}
        </div>
        <IfFlag flag="timeDelayedTreatmentGroups">
          <div className="text-secondary">
            Starting on your{" "}
            <span className="text-bold1">
              {getMembershipRewardGroupSubheader(monthsBeforeFirstReward)}
            </span>{" "}
            as a member
          </div>
        </IfFlag>
        {description && (
          <div className="text-body1 text-secondary">{description}</div>
        )}
      </div>
      <div className="flex flex-col items-center rounded-lg border-solid border-light-grey">
        <div className="mb-4 w-full px-2">
          <ExpandableItems
            items={
              selectableTreatmentsRewardGroup?.membershipRewards.map((mr) => ({
                id: mr.id,
                title:
                  getProductTitle(mr.packageListingTitle, mr.packageName) +
                  " " +
                  getProductTitleQuantity(mr.quantity, mr.packageUnitType),
                description: mr.packageDescription,
              })) || []
            }
          />
        </div>
      </div>
    </div>
  );
};

const MembershipPDP = () => {
  const { membershipListing, membershipRewards, source, userMemberships } =
    useLoaderData() as LoaderData;
  const navigate = useNavigate();
  const { routeBackNumber } = useHistory();

  const currentMembership = userMemberships.find(
    (membership) =>
      membership.membership.id == membershipListing.id && !membership.canceled
  );

  const [milestoneModalOpen, setMilestoneModalOpen] = useState<boolean>(false);
  const [milestoneModalContent, setMilestoneModalContent] =
    useState<Models<"MembershipRewardGroupModel">>();
  const [signUpBonusModalOpen, setSignUpBonusModalOpen] =
    useState<boolean>(false);

  const signUpBonus = membershipRewards.find((group) => {
    return group.monthsBeforeFirstReward === 0 && group.periodMonths === 0;
  });

  const milestoneBonuses = membershipRewards
    .filter((group) => {
      return group.monthsBeforeFirstReward > 0 && group.periodMonths === 0;
    })
    .sort((a, b) => a.monthsBeforeFirstReward - b.monthsBeforeFirstReward);

  const buildSignUpBonusModalIntro = (
    signUpBonus: Models<"MembershipRewardGroupModel">,
    membershipListing: Models<"MembershipModel">
  ) => {
    const count = signUpBonus.hasSelectableRewards
      ? signUpBonus.quantityPerPeriod
      : signUpBonus.membershipRewards.length;
    return (
      <>
        <span className="text-bold1">
          {signUpBonus.hasSelectableRewards ? "Select" : "Receive"} {count}
        </span>{" "}
        bonus {pluralize(count, "treatment", "treatments")}{" "}
        {signUpBonus.hasSelectableRewards && "from the below"} when you sign up
        as {aOrAn(membershipListing.name)} member
      </>
    );
  };

  const buildMilestoneBonusModalIntro = (
    milestoneModalContent: Models<"MembershipRewardGroupModel">,
    membershipListing: Models<"MembershipModel">
  ) => {
    const count = milestoneModalContent.hasSelectableRewards
      ? milestoneModalContent.quantityPerPeriod
      : milestoneModalContent.membershipRewards.length;
    return (
      <>
        <span className="text-bold1">
          {milestoneModalContent.hasSelectableRewards ? "Select" : "Receive"}{" "}
          {count}
        </span>{" "}
        bonus {pluralize(count, "treatment", "treatments")} after{" "}
        {milestoneModalContent.monthsBeforeFirstReward}{" "}
        {pluralize(
          milestoneModalContent.monthsBeforeFirstReward,
          "month",
          "months"
        )}{" "}
        as {aOrAn(membershipListing.name)} member
      </>
    );
  };

  return (
    <>
      <TopNav
        startIconSVG={ChevronLeft}
        onStartIconClick={() => navigate(routeBackNumber)}
      >
        <div className="flex max-h-[32px] items-center">
          <h1 className="text-xl">Memberships</h1>
        </div>
      </TopNav>
      <div className="flex flex-col bg-one">
        <div
          className="relative aspect-[16/9] w-full overflow-hidden bg-cover bg-center"
          style={{
            backgroundImage: `url(${membershipListing.imageUrl})`,
          }}
        />
        <div className="flex flex-col items-center">
          <div className="flex flex-col items-center gap-6 px-4 pt-8 pb-4">
            <div className="flex flex-col items-center">
              <div className="text-center font-serif text-h1 text-primary">
                {membershipListing.name}
              </div>
              <div className="flex pt-3">
                <span className="text-sub1 text-primary">
                  {formatCurrency(membershipListing.monthlyCost)}/
                </span>
                <span className="text-body1 text-primary">month</span>
              </div>
              <div className="pt-5 text-center text-body1 text-secondary">
                <FormattedString>
                  {membershipListing.description || ""}
                </FormattedString>
              </div>
              {membershipListing.commitment > 1 && (
                <div className="pt-5 text-center text-bold2 text-secondary">
                  {membershipListing.commitment} Month Commitment Required
                </div>
              )}
            </div>
            {signUpBonus && (
              <MembershipBonusInfo
                icon={MembershipFilled}
                title="Join now for your sign-up bonus!"
                description={`Receive a one-time sign-up bonus when you join as ${aOrAn(
                  membershipListing.name
                )} member`}
                callToAction="Click for more details"
                callToActionClick={() => setSignUpBonusModalOpen(true)}
              />
            )}
          </div>
          {membershipListing.membershipBenefits.length > 0 && (
            <div className={cx("mb-1 rounded-t-2xl bg-white px-7 pt-8 pb-8")}>
              <div className="flex items-center justify-center">
                <Icon color="brandColor" size="xsmall" svg={Star} />
              </div>
              <div className="my-4 text-center text-sub1 text-brand-color">
                ACCESS MEMBER ONLY BENEFITS
              </div>
              <div className="pb-6 text-center text-body1 text-secondary">
                Sign up today and enjoy exclusive member-only benefits
              </div>
              <ul className="list-outside list-disc pl-5 marker:text-[21px] marker:text-brand-color">
                {membershipListing.membershipBenefits.map((benefit) => {
                  if (benefit.description) {
                    return (
                      <li key={benefit.id}>
                        <div className="py-2 text-left text-body1 text-primary">
                          {benefit.description}
                        </div>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          )}
        </div>

        {membershipRewards
          .filter((rg) => !!rg && rg.periodMonths > 0)
          .map((rg, index) => (
            <>
              {!rg?.hasSelectableRewards ? (
                <IncludedMonthlyRewards
                  freeMembershipRewards={rg?.membershipRewards || []}
                  periodMonths={rg.periodMonths}
                  svg={index == 0 ? Rewards : Plus}
                  monthsBeforeFirstReward={rg.monthsBeforeFirstReward}
                />
              ) : (
                <SelectableMonthlyTreatments
                  selectableTreatmentsRewardGroup={rg}
                  svg={index == 0 ? Rewards : Plus}
                  monthsBeforeFirstReward={rg.monthsBeforeFirstReward}
                />
              )}
            </>
          ))}

        {milestoneBonuses.length > 0 && (
          <div className="-mt-1 flex flex-col gap-1 bg-white px-4 pt-4">
            {milestoneBonuses.map((bonus, index) => (
              <MembershipBonusInfo
                key={bonus.id}
                icon={index === 0 ? MembershipFilled : undefined}
                title={`${bonus.monthsBeforeFirstReward}-month member bonus!`}
                description={
                  index === 0
                    ? `Receive a one-time bonus after ${
                        bonus.monthsBeforeFirstReward
                      } ${pluralize(
                        bonus.monthsBeforeFirstReward,
                        "month",
                        "months"
                      )} as ${aOrAn(membershipListing.name)} member`
                    : ""
                }
                callToAction="Click for more details"
                callToActionClick={() => {
                  setMilestoneModalContent(bonus);
                  setMilestoneModalOpen(true);
                }}
              />
            ))}
          </div>
        )}

        <div className="-mt-1 bg-white px-5 pb-2">
          <TermsAndCondition text="" hideText />
        </div>

        <div className="sticky bottom-[calc(60px_+_env(safe-area-inset-bottom))] w-full bg-white p-4">
          {source ? (
            <div className="flex flex-col gap-3">
              <Button
                fullWidth={true}
                onClick={async () => navigate(routeBackNumber)}
              >
                Back to {source}
              </Button>
              <Button
                fullWidth={true}
                style={"secondary"}
                onClick={async () => navigate("/shop/memberships")}
              >
                Shop all memberships
              </Button>
            </div>
          ) : (
            <Button
              disabled={currentMembership != null}
              fullWidth={true}
              onClick={async () => {
                const response = await addMembershipToCart(
                  membershipListing.id
                );
                if (response) {
                  navigate("/cart");
                }
              }}
            >
              {currentMembership == null
                ? "Join Now"
                : "You are already a member"}
            </Button>
          )}
        </div>
      </div>

      {signUpBonus && signUpBonusModalOpen && (
        <ListModal
          open={signUpBonusModalOpen}
          onClose={() => setSignUpBonusModalOpen(false)}
          size="fullscreen"
          title="Sign-up Bonus"
          firstButtonLabel={
            currentMembership == null ? "Join now" : "You are already a member"
          }
          onFirstButtonClick={async () => {
            const response = await addMembershipToCart(membershipListing.id);
            if (response) {
              navigate("/cart");
            }
          }}
          firstButtonDisabled={currentMembership != null}
        >
          <div className="flex flex-col gap-5 p-6">
            <div className="text-center text-body1 text-primary">
              {buildSignUpBonusModalIntro(signUpBonus, membershipListing)}
            </div>
            <div>
              <ExpandableItems
                items={signUpBonus.membershipRewards.map((item) => {
                  return {
                    id: item.id,
                    title: getProductTitle(
                      item.packageListingTitle,
                      item.packageName
                    ),
                    description: item.packageDescription,
                  };
                })}
              />
            </div>
          </div>
        </ListModal>
      )}

      {milestoneModalContent?.id && milestoneModalOpen && (
        <ListModal
          open={true}
          onClose={() => setMilestoneModalOpen(false)}
          size="fullscreen"
          title={`${milestoneModalContent.monthsBeforeFirstReward}-month member bonus`}
          firstButtonLabel={
            currentMembership == null ? "Join now" : "You are already a member"
          }
          onFirstButtonClick={async () => {
            const response = await addMembershipToCart(membershipListing.id);
            if (response) {
              navigate("/cart");
            }
          }}
          firstButtonDisabled={currentMembership != null}
        >
          <div className="flex flex-col gap-5 p-6">
            <div className="text-center text-body1 text-primary">
              {buildMilestoneBonusModalIntro(
                milestoneModalContent,
                membershipListing
              )}
            </div>
            <div>
              <ExpandableItems
                items={milestoneModalContent.membershipRewards.map((item) => {
                  return {
                    id: item.id,
                    title: getProductTitle(
                      item.packageListingTitle,
                      item.packageName
                    ),
                    description: item.packageDescription,
                  };
                })}
              />
            </div>
          </div>
        </ListModal>
      )}
    </>
  );
};

export default MembershipPDP;
