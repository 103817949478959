import { Capacitor } from "@capacitor/core";
import { Share as CapacitorShare } from "@capacitor/share";
import { useMutation, useQuery } from "@tanstack/react-query";
import cx from "classnames";

import { get, post } from "api";
import { Button } from "atoms/button/Button";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Rewards } from "atoms/icon/icons/rewards_nav.svg";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { buildPlatformUrl } from "utils/functions";

export interface ReferAFriendProps {
  withRepeatMDFooter?: boolean;
  buttonOnly?: boolean;
}

export const ReferAFriend = ({
  withRepeatMDFooter,
  buttonOnly,
}: ReferAFriendProps) => {
  const { data: platform } = useQuery({
    queryKey: ["/platform"],
    queryFn: () => get("/platform"),
  });
  const { data: referrableRewards } = useQuery({
    queryKey: ["/rewards/referrable"],
    queryFn: () => get("/rewards/referrable"),
  });
  const referrableRewardTitle = referrableRewards?.[0]?.title;

  const mutation = useMutation({
    mutationFn: () => post("/referrals", {}),
    onSuccess: ({ referralCode }) => {
      if (referralCode) {
        const baseUrl = buildPlatformUrl(
          platform?.scheme,
          platform?.subdomain,
          platform?.host
        );
        const referralUrl = `${baseUrl}/referral/${referralCode}`;

        if (Capacitor.isNativePlatform()) {
          CapacitorShare.share({
            title: `Click to claim your gift: ${referrableRewardTitle}`,
            text: `Here’s a gift from me to you! Check out the ${platform?.name} app to claim.`,
            url: referralUrl,
          })
            .then(() => console.log("Successful share"))
            .catch((error) => console.log("Error sharing", error));

          return;
        }

        if (navigator.share) {
          navigator
            .share({
              title: `Click to claim your gift: ${referrableRewardTitle}`,
              text: `Here’s a gift from me to you! Check out the ${platform?.name} app to claim.`,
              url: referralUrl,
            })
            .then(() => console.log("Successful share"))
            .catch((error) => console.log("Error sharing", error));

          return;
        }

        window.location.href = `mailto:?subject=Hey there!&body=Hey, sign up for ${platform?.name} and get ${referrableRewardTitle}! ${referralUrl}`;
      }
    },
  });

  const handleClick = () => {
    mutation.mutate();
  };

  if (buttonOnly) {
    return (
      <Button fullWidth onClick={handleClick}>
        Refer a friend
      </Button>
    );
  } else {
    return (
      <div className={cx(!referrableRewardTitle && "hidden")}>
        <div className="flex flex-col items-center bg-brand-color px-4 py-6">
          <Icon color="white" svg={Rewards} size="xsmall" />
          <div className="pt-4 pb-4 font-sans text-sub1 text-white">
            REFER A FRIEND
          </div>
          <div className="px-8 pb-6 text-center font-serif text-h2 text-white">
            Send a friend {referrableRewardTitle}!
          </div>
          <Button style="outline" fullWidth onClick={handleClick}>
            Send to a friend
          </Button>
        </div>
        {withRepeatMDFooter && <RepeatMDFooter />}
      </div>
    );
  }
};
