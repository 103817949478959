import cx from "classnames";

import { Models } from "api/types";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Amex } from "atoms/icon/icons/amex.svg";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right.svg";
import { ReactComponent as JCB } from "atoms/icon/icons/jcb.svg";
import { ReactComponent as Mastercard } from "atoms/icon/icons/mastercard.svg";
import { ReactComponent as Visa } from "atoms/icon/icons/visa.svg";
import { TextButton } from "atoms/text-button/TextButton";
import { getCardBrandIcon } from "toolbox/Cards";

interface PaymentMethodButtonProps {
  id: string;
  text?: string;
  cardType?: Models<"CardBrand">;
  onSelect: (value: string) => void;
  onEdit?: (value: string) => void;
  selected: boolean;
}

const PaymentMethodButton = (props: PaymentMethodButtonProps) => {
  const cardIcon = props.cardType ? getCardBrandIcon(props.cardType) : null;
  const style = cx(
    "flex",
    "cursor-pointer",
    "items-center",
    "justify-between",
    "rounded",
    "border-[1px]",
    "border-solid",
    "px-4",
    "py-2",
    props.selected ? "border-brand-color" : "border-dark-grey"
  );
  if (props.cardType) {
    return (
      <div
        className={style}
        onClick={() => {
          if (props.id == "addnewcard" && props.onEdit != null) {
            props.onEdit(props.id);
          } else {
            props.onSelect(props.id);
          }
        }}
      >
        <div className="flex h-full items-center gap-3 text-bold2 text-primary">
          {cardIcon && <Icon svg={cardIcon} />}
          {props.text && props.text}
        </div>

        <div className="flex items-center">
          <div className="ml-2">
            {props.id != "addnewcard" && props.onEdit ? (
              <TextButton
                xsText
                onClick={() => (props.onEdit ? props.onEdit(props.id) : null)}
              >
                EDIT
              </TextButton>
            ) : (
              <div className="py-2">
                <Icon svg={ChevronRight} color="brandColor" size="xsmall" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={style}
        onClick={() => {
          props.onSelect(props.id);
        }}
      >
        <div className="text-bold2 text-primary">{props.text}</div>

        <div className="flex items-center">
          <Icon svg={Visa} />
          <Icon svg={Mastercard} />
          <Icon svg={JCB} />
          <Icon svg={Amex} />
          <div className="ml-2 py-3">
            <Icon svg={ChevronRight} color="brandColor" size="xsmall" />
          </div>
        </div>
      </div>
    );
  }
};

export default PaymentMethodButton;
