import axios from "axios";

import { delay } from "toolbox/Delay";

import type {
  DeletePaths,
  DeleteRequest,
  GetPaths,
  GetRequest,
  GetResult,
  PostPaths,
  PostRequest,
  PostResult,
  PutPaths,
  PutRequest,
  RawGetResult,
  RawPostResult,
  RawPutResult,
} from "./types";

const getApiBaseUrl = () => {
  return (
    localStorage.getItem("baseApiUrl") ||
    process.env.REACT_APP_API_BASE_URL ||
    ""
  );
};

const getBackground = async (
  id: string
): Promise<GetResult<"/background/{id}">> => {
  const baseUrl = getApiBaseUrl();
  const result = await axios.get<RawGetResult<"/background/{id}">>(
    `${baseUrl}/api/v1/background/${id}`
  );

  if (result.status === 200 && result.data.result) {
    return result.data.result as GetResult<"/background/{id}">;
  } else if (result.status === 204) {
    return delay(10_000).then(() => getBackground(id));
  } else {
    return Promise.reject(
      `Background data response unexpected for ${id}: ${result.status}`
    );
  }
};

/**
 * Substitutes all path variables from provided args, and return the resulting
 * path and remaining args
 */
const derefPath = (p: string, args: Record<string, unknown> = {}) =>
  Object.entries(args).reduce<[string, Record<string, unknown>]>(
    ([path, rest], [k, v]) =>
      path.includes(`{${k}}`)
        ? [path.replace(`{${k}}`, `${v}`), rest]
        : [path, { ...rest, [k]: v }],
    [p, {}]
  );

/**
 * Perform a `GET` request against a known patient app resource
 */
export const get = async <Path extends GetPaths>(
  path: Path,
  ...[args]: GetRequest<Path> extends undefined ? [] : [GetRequest<Path>]
) => {
  const baseUrl = getApiBaseUrl();
  const [resolvedPath, params] = derefPath(
    `${baseUrl}/api/v1${path}`,
    args ?? {}
  );

  const { data: res, status } = await axios.get<RawGetResult<Path>>(
    resolvedPath,
    { params }
  );

  const response = status === 204 && res.id ? await getBackground(res.id) : res;

  if (response.result !== undefined) {
    return response.result as GetResult<Path>;
  } else {
    return Promise.reject(`Failed to load api get result for ${path}`);
  }
};

/**
 * Perform a `POST` request against a known patient app resource
 */
export const post = async <Path extends PostPaths>(
  path: Path,
  args: PostRequest<Path>,
  token?: string | null | undefined
) => {
  const baseUrl = getApiBaseUrl();
  const [resolvedPath, data] = derefPath(
    `${baseUrl}/api/v1${path}`,
    args ?? {}
  );

  const { data: res, status } = await axios.post<RawPostResult<Path>>(
    resolvedPath,
    "$data" in data ? data.$data : data,
    {
      headers: {
        "Content-Type": "application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    }
  );

  const response = status === 204 && res.id ? await getBackground(res.id) : res;

  if (response.result !== undefined) {
    return response.result as PostResult<Path>;
  } else {
    return Promise.reject(`Failed to load api post result for ${path}`);
  }
};

/**
 * Perform a `PUT` request against a known patient app resource
 */
export const put = async <Path extends PutPaths>(
  path: Path,
  args: PutRequest<Path>
) => {
  const baseUrl = getApiBaseUrl();
  const [resolvedPath, data] = derefPath(
    `${baseUrl}/api/v1${path}`,
    args ?? {}
  );

  const { data: res, status } = await axios.put<RawPutResult<Path>>(
    resolvedPath,
    Array.isArray(data.$data) ? data.$data : data,
    { headers: { "Content-Type": "application/json" } }
  );

  const response = status === 204 && res.id ? await getBackground(res.id) : res;

  if (response.result !== undefined) {
    return response.result as PutRequest<Path>;
  } else {
    return Promise.reject(`Failed to load api put result for ${path}`);
  }
};

/**
 * Perform a `DELETE` request against a known patient app resource
 */
export const del = async <Path extends DeletePaths>(
  path: Path,
  args: DeleteRequest<Path>
) => {
  const baseUrl = getApiBaseUrl();
  const [resolvedPath, data] = derefPath(
    `${baseUrl}/api/v1${path}`,
    args ?? {}
  );

  await axios.delete(resolvedPath, {
    headers: { "Content-Type": "application/json" },
    data: "$data" in data ? data.$data : data,
  });
};

export type { GetResult, PostResult, PutRequest, DeleteRequest };
