import cx from "classnames";
import { FC, SVGProps } from "react";

import { Icon } from "atoms/icon/Icon";
import { TextButton } from "atoms/text-button/TextButton";

interface BaseMembershipBonusInfoProps {
  icon?: FC<SVGProps<SVGSVGElement>>;
  title?: string;
  description?: string;
  callToAction?: undefined;
  callToActionClick?: undefined;
}

type WithCallToAction = {
  callToAction: string;
  callToActionClick: () => void;
} & Omit<BaseMembershipBonusInfoProps, "callToAction" | "callToActionClick">;

type MembershipBonusInfoProps = BaseMembershipBonusInfoProps | WithCallToAction;

export const MembershipBonusInfo = ({
  icon,
  title,
  description,
  callToAction,
  callToActionClick,
}: MembershipBonusInfoProps) => {
  return (
    <div
      className="flex flex-col justify-center gap-3 rounded bg-brand-tint px-4 pt-4 pb-2 text-center"
      onClick={callToActionClick && callToActionClick}
    >
      {icon && (
        <div className="mx-auto">
          <Icon svg={icon} color="brandColor" size="xsmall" />
        </div>
      )}
      <div className="flex flex-col gap-1 text-body1 text-primary">
        {title && (
          <div className="text-bold1 uppercase tracking-[.04em]">{title}</div>
        )}
        {description && <div>{description}</div>}
        {callToAction && (
          <div className={cx("mx-auto pr-3", !description && "-mt-1")}>
            <TextButton>
              <div className="text-bold1">{callToAction}</div>
            </TextButton>
          </div>
        )}
      </div>
    </div>
  );
};
