import cx from "classnames";
import { FC, MouseEventHandler, ReactNode, SVGProps } from "react";

import { Icon } from "../icon/Icon";

interface TextButtonProps {
  /** Label */
  children: ReactNode;
  /** Optionally disable the button. */
  disabled?: boolean;
  /** Callback to handle button click events */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** Add an icon before the label. */
  startIconSVG?: FC<SVGProps<SVGSVGElement>>;
  /** Add an icon after the label. */
  endIconSVG?: FC<SVGProps<SVGSVGElement>>;
  /** If true, button will submit parent form */
  submit?: boolean;
  /** If true, font size is xs (12px) */
  xsText?: boolean;
  /** If true, button is red */
  danger?: boolean;
}

/**
 * Atomic component - TextButton
 */
export const TextButton = ({
  children,
  disabled,
  onClick,
  endIconSVG,
  startIconSVG,
  submit = false,
  xsText = false,
  danger = false,
}: TextButtonProps) => {
  return (
    <button
      type={submit ? "submit" : "button"}
      className={cx(
        "flex items-center justify-center text-button text-brand-color active:opacity-60 disabled:opacity-20",
        startIconSVG || endIconSVG ? "py-2.5" : "py-[13px]",
        startIconSVG ? "pl-1" : "pl-3",
        endIconSVG ? "pr-1" : null,
        xsText && "text-xs",
        danger && "text-red"
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {startIconSVG && (
        <Icon
          svg={startIconSVG}
          color="currentColor"
          size={xsText ? "x3small" : "xsmall"}
        />
      )}
      {children}
      {endIconSVG && (
        <Icon
          svg={endIconSVG}
          color="currentColor"
          size={xsText ? "x3small" : "xsmall"}
        />
      )}
    </button>
  );
};
