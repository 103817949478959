import cx from "classnames";
import { useEffect, useState } from "react";

import { ReactComponent as Minus } from "atoms/icon/icons/ic_minus_small_ol.svg";
import { ReactComponent as Plus } from "atoms/icon/icons/ic_plus_small_ol.svg";

type Props = {
  defaultValue?: number;
  maxValue?: number;
  showMaxHelpText?: boolean;
  onChange?: (value: number, invalid: boolean) => void;
};

export const QuantitySelector = ({
  defaultValue = 1,
  maxValue,
  showMaxHelpText = true,
  onChange,
}: Props) => {
  const [activeMinus, setActiveMinus] = useState(true);
  const [activePlus, setActivePlus] = useState(true);
  const [value, setValue] = useState(defaultValue.toString());
  const [invalid, setInvalid] = useState(false);
  const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) =>
    e.target.select();
  const handleChange = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const isNumeric =
      parseInt(e.target.value) !== 0 && /^[0-9]+$/.test(e.target.value);
    if (isNumeric) {
      setValue(e.target.value);
    }
  };
  const increment = () => {
    if ((maxValue && parseInt(value) < maxValue) || !maxValue) {
      const newValue = parseInt(value) + 1;
      setValue(newValue.toString());
    }
  };
  const decrement = () => {
    if (parseInt(value) > 0) {
      const newValue = parseInt(value) - 1;
      setValue(newValue.toString());
    }
  };
  const handleClick = (side: "left" | "right") => {
    if (activeMinus && side === "left") {
      decrement();
    } else if (activePlus && side === "right") {
      increment();
    }
  };
  useEffect(() => {
    const newValue = parseInt(value);
    if (maxValue && newValue > maxValue) {
      setInvalid(true);
      onChange && onChange(newValue, true);
    } else {
      setInvalid(false);
      onChange && onChange(newValue, false);
    }
    if (newValue <= 1) {
      setActiveMinus(false);
    } else {
      setActiveMinus(true);
    }
    if (maxValue && newValue >= maxValue) {
      setActivePlus(false);
    } else {
      setActivePlus(true);
    }
  }, [value, maxValue, onChange]);
  return (
    <div className="w-[108px]">
      <div className="flex items-center">
        <div className="w-[32px]">
          <Minus
            className={cx(
              "mx-auto",
              !activeMinus && "fill-dark-grey",
              activeMinus && "cursor-pointer fill-brand-color"
            )}
            onClick={() => handleClick("left")}
          />
        </div>
        <div className="w-[44px]">
          <input
            className="-ring-offset-1 peer block h-12 w-full appearance-none rounded border-none text-center text-body1 ring-1 ring-dark-grey placeholder:!text-transparent focus:outline-none focus:ring-1 focus:ring-brand-color focus:placeholder:!text-secondary aria-invalid:ring-1 aria-invalid:ring-red aria-invalid:placeholder:text-red"
            aria-invalid={invalid}
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            pattern="[0-9]*"
            inputMode="numeric"
          />
        </div>
        <div className="w-[32px]">
          <Plus
            className={cx(
              "mx-auto",
              !activePlus && "fill-dark-grey",
              activePlus && "cursor-pointer fill-brand-color"
            )}
            onClick={() => handleClick("right")}
          />
        </div>
      </div>
      {showMaxHelpText && maxValue !== undefined && maxValue > 0 && (
        <div
          className={cx(
            "mt-1 select-none text-center text-body3 text-secondary",
            invalid && "text-red"
          )}
        >
          Max qty {maxValue}
        </div>
      )}
    </div>
  );
};
