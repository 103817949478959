import { Preferences } from "@capacitor/preferences";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  json,
  LoaderFunction,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get } from "api";
import { Models, RewardStatus } from "api/types";
import { AffirmBanner } from "atoms/affirm/affirmBanner/AffirmBanner";
import { Banner } from "atoms/banner/Banner";
import { ReactComponent as Quiz } from "atoms/icon/icons/ic_quiz_ol.svg";
import { ReactComponent as Discover } from "atoms/icon/icons/offers_nav.svg";
import { ReactComponent as Star } from "atoms/icon/icons/star.svg";
import { useAppState } from "contexts/AppContext";
import { useChangeIcon } from "hooks/useChangeIcon";
import useDunningMembership from "hooks/useDunningMemberships";
import { AffirmBannerLarge } from "molecules/affirm-banner/AffirmBannerLarge";
import { AffirmModal } from "molecules/affirm-modal/AffirmModal";
import { BannersContainer } from "molecules/containers/banners-container/BannersContainer";
import TopNav from "molecules/navbars/TopNav";
import CampaignCard from "organisms/campaign-card/CampaignCard";
import Carousel from "organisms/carousel-with-breadcrumb/Carousel";
import MembershipPromo from "organisms/memberships-promo/MembershipsPromo";
import ProductCarousel from "organisms/product-carousel/ProductCarousel";
import { ReferAFriend } from "organisms/refer-a-friend/ReferAFriend";
import PromotionHero from "organisms/shop/site-wide-promotion-hero/PromotionHero";
import ShowSiteWidePromotion from "organisms/site-wide-promotion-modal/SiteWidePromotionModal";
import { queryClient } from "pages/Root";
import { ScanInStore } from "pages/root/home/ScanInStore";
import { useFeatureFlag } from "services/Flags";
import {
  checkIfListingsHaveConcerns,
  getBookAppointmentAction,
} from "services/HelperService";
import Services from "services/Services";
import { formatAddressNoCountry } from "toolbox/Address";
import { notFalsy } from "toolbox/Guards";
import { formatPhoneNumber } from "toolbox/PhoneNumber";
import { waitObj } from "toolbox/Promise";

const load = async () => {
  const { value: platformDomainTokensStringified } = await Preferences.get({
    key: "platformDomainTokens",
  });
  const platformDomainTokens: Models<"GetMultiPlatformUserPlatformResult">[] =
    JSON.parse(platformDomainTokensStringified || "[]");

  const visits = queryClient.fetchQuery(["/visit/count"], () =>
    get("/visit/count")
  );

  const platform = queryClient.fetchQuery(["/platform"], () =>
    get("/platform")
  );

  const userInfo = queryClient.fetchQuery(["/user"], () => get("/user"), {
    staleTime: 1000 * 60 * 2,
  });

  const rewards = queryClient.fetchQuery(["/rewards"], () =>
    get("/rewards", {})
  );

  const membershipFeatures = queryClient.fetchQuery(["/shop/memberships"], () =>
    get("/shop/memberships")
  );

  const memberships = queryClient.fetchQuery(["/memberships"], () =>
    get("/memberships")
  );

  const listings = queryClient.fetchQuery(
    ["/listings"],
    () => get("/listings"),
    { staleTime: 1000 * 60 }
  );

  const promotions = queryClient.fetchQuery(
    ["/promotions"],
    () => get("/promotions"),
    { staleTime: 1000 * 60 * 2 }
  );

  const repeatmdOffer = queryClient.fetchQuery(["/offers"], () =>
    get("/offers")
  );

  const platformLocation = queryClient.fetchQuery(["/platform-location"], () =>
    get("/platform-location")
  );

  const theme = queryClient.fetchQuery(["/platform/theme"], () =>
    get("/platform/theme")
  );

  const customCategoriesList = queryClient.fetchQuery(
    ["/shop/custom-categories/listings"],
    () =>
      get("/shop/custom-categories/listings").then((listings) =>
        listings.filter(
          (listing) =>
            listing.promotion?.expiresOn == null ||
            new Date(listing.promotion.expiresOn) > new Date()
        )
      )
  );

  return waitObj({
    platformDomainTokens,
    visits: visits.then((res) => res.totalVisits),
    platform,
    userInfo: userInfo.then((user) => {
      Services.events.signIn(user.id, {
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber || "",
        email: user.email || "",
        subdomain: user.platformDomain?.domain || "",
        role: "customer",
        environment: process.env.NODE_ENV,
        version: "v2",
      });
      return user;
    }),
    rewards,
    membershipFeatures,
    memberships,
    listings,
    promotions,
    platformLocation,
    repeatmdOffer,
    theme,
    customCategoriesList,
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const homeLoader: LoaderFunction = async () => {
  return json<LoaderData>(await load());
};

const HomePage = () => {
  const navigate = useNavigate();
  const {
    platform,
    userInfo,
    rewards,
    membershipFeatures,
    memberships,
    listings,
    promotions,
    platformLocation,
    repeatmdOffer,
    theme,
    customCategoriesList,
    platformDomainTokens,
  } = useLoaderData() as LoaderData;
  const flagSiteWidePromotions = useFeatureFlag("site-wide-promotions");
  const changeIcon = useChangeIcon(platformDomainTokens);
  const appState = useAppState();

  const { dunningMembership } = useDunningMembership();
  const [showAffirmModal, setShowAffirmModal] = useState(false);
  const heroImage = platform?.homeImageUrl
    ? platform?.homeImageUrl
    : theme.resources?.find((resource) => resource.key === "homepage-hero")
        ?.url;

  const browseByConcernImage = theme.resources?.find(
    (resource) => resource.key === "browse-by-concern-campaign"
  )?.url;

  const membershipFeature = membershipFeatures?.[0];

  const available = rewards?.filter((r) => r.status === RewardStatus.Available);

  const future = rewards?.filter((r) => r.status === RewardStatus.NotAvailable);

  const nextReward = future?.length
    ? future.reduce(
        (acc, curr) =>
          acc.minimumVisits ?? 0 <= (curr.minimumVisits ?? 0) ? acc : curr,
        future[0]
      )
    : undefined;

  // this useEffect will change the icons of application if app is opened and logged in with dynamic or deep link
  useEffect(() => {
    if (platformDomainTokens && appState.platformId) {
      const subDomain = platformDomainTokens.find(
        (tokens) => tokens.platformId === appState.platformId
      )?.platformSubdomain;
      if (subDomain) {
        changeIcon(subDomain);
      }
    }
  }, [platformDomainTokens]);

  const sortedCustomCategoryPromotions = customCategoriesList
    .filter((listItem) => listItem.promotion !== null)
    .sort((a, b) => {
      const expiresOnA = a.promotion?.expiresOn
        ? new Date(a.promotion?.expiresOn)
        : null;
      const expiresOnB = b.promotion?.expiresOn
        ? new Date(b.promotion?.expiresOn)
        : null;

      if (expiresOnA === null && expiresOnB === null) {
        return 0;
      }

      if (expiresOnA === null) {
        return 1;
      }

      if (expiresOnB === null) {
        return -1;
      }

      return expiresOnA.getTime() - expiresOnB.getTime();
    });

  const topSellingTreatmentCards = listings
    .sort((a, b) => b.sellVolume - a.sellVolume)
    .map((item) => {
      if (!item.imageUrl || !item.title || !item.baseCost) {
        return null;
      }
      return {
        to: `/pdp/package/${item.id}`,
        imageSrc: item.imageUrl,
        tag: item.type,
        title: item.title,
        price: item.baseCost,
        memberPrice: item.membershipCost,
      };
    })
    .filter(notFalsy)
    .slice(0, 5);

  const specialOffersCards = promotions
    .filter((promotion) => !promotion.redeemedOn)
    .map(
      (promotion) =>
        promotion && {
          to: "/discover?promotion=" + promotion?.id,
          tag: "Promotion",
          title: promotion.title,
          imageSrc: promotion.imageUrl ?? "",
          primary: "View Offer",
          alert:
            promotion.expiresOn &&
            `Expires ${dayjs(promotion.expiresOn).fromNow()}`,
        }
    )
    .slice(0, 5);
  const marketplaceEnabled = platform.platformSettings?.marketplaceEnabled;
  const categoryWithPromotion = customCategoriesList.find(
    (listItem) =>
      listItem.promotion &&
      listItem.category &&
      listItem.packageListings.length > 0
  );

  const bannerAffirmShow =
    platform.platformSettings?.marketplaceEnabled &&
    platform.platformSettings?.affirmEnabled;

  const bannerSitewidePromotionShow = !!categoryWithPromotion;

  const bannerContainersShow = dunningMembership.status === false;

  const promotion = categoryWithPromotion?.promotion;
  const category = categoryWithPromotion?.category;

  // We usually remove this object on the "complete-profile" page,
  // but if the user is NOT new they get redirected to "/home", so
  // we should remove it here
  useState(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("fromSignIn")) {
      window.localStorage.removeItem("state");
      queryParams.delete("fromSignIn");
    }
  });

  const bookAppointmentAction = getBookAppointmentAction(
    platformLocation.bookAppointmentUrl,
    platformLocation.phoneNumber,
    platformLocation.email
  );

  return (
    <>
      {bannerContainersShow && (
        <>
          <div className="sticky top-0 z-10 h-[env(safe-area-inset-top)] bg-white"></div>
          <BannersContainer>
            {bannerAffirmShow && (
              <AffirmBanner onClick={() => setShowAffirmModal(true)} />
            )}
            {flagSiteWidePromotions && bannerSitewidePromotionShow && (
              <Banner
                titleColor={promotion?.textColor ?? undefined}
                title={promotion?.title}
                backgroundColor={promotion?.backgroundColor ?? undefined}
                summary={promotion?.description ?? undefined}
                onClick={() =>
                  navigate(`/shop/custom-category/${category?.id}`)
                }
                arrow={true}
              ></Banner>
            )}
          </BannersContainer>
        </>
      )}
      <TopNav hideShadow={true} withAffirmOnTop={bannerContainersShow}>
        <img className="h-8" src={platform?.logoImageUrl || ""} />
      </TopNav>
      {platform.platformSettings?.affirmEnabled && (
        <AffirmModal
          platformLogo={platform?.logoImageUrl || ""}
          open={showAffirmModal}
          onClose={() => setShowAffirmModal(false)}
          onShopNow={() => navigate("/shop")}
        />
      )}
      {flagSiteWidePromotions && categoryWithPromotion && (
        <ShowSiteWidePromotion
          promotion={categoryWithPromotion.promotion!}
          categoryId={categoryWithPromotion.category.id}
        />
      )}
      <div className="relative rounded-b-lg bg-white">
        <ScanInStore
          userName={userInfo?.firstName}
          nextMilestone={nextReward?.minimumVisits || 0}
          rewardsCount={available?.length || 0}
          cashToUnlock={nextReward?.cashToUnlock}
          unlockProgressPercent={
            future.length == 0 ? 100 : nextReward?.unlockProgressPercent || 0
          }
          marketplaceEnabled={platform.platformSettings?.marketplaceEnabled}
          visitCashValue={platform.platformSettings?.visitCashValue}
        />
      </div>
      <div className="sticky top-[calc(50px_+_env(safe-area-inset-top))] z-10 -mt-1 h-1 shadow-[0px_2px_4px_rgba(0,0,0,0.05)]" />

      <Carousel
        showBreadCrumb={
          flagSiteWidePromotions &&
          promotion?.imageUrl != null &&
          sortedCustomCategoryPromotions.length > 0
        }
      >
        {flagSiteWidePromotions &&
          promotion?.imageUrl != null &&
          sortedCustomCategoryPromotions.map((listItem, index) => (
            <div
              className="min-w-full snap-center"
              key={listItem.category.id}
              data-slideindex={index + 1}
            >
              <div>
                {listItem.promotion && (
                  <PromotionHero
                    {...listItem.promotion}
                    id={listItem.category.id}
                    homeStyle={true}
                  />
                )}
              </div>
            </div>
          ))}
        <div
          className="min-w-full snap-center"
          data-slideindex={sortedCustomCategoryPromotions.length + 1}
        >
          <div
            className="-mt-3 flex aspect-[390/434] flex-col bg-cover bg-center p-8 text-white"
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 20.74%, rgba(0, 0, 0, 0) 39.17%, rgba(0, 0, 0, 0.35) 88.48%), url("${heroImage}")`,
            }}
          >
            <div className="mt-auto">
              <div className="pb-4 text-center font-serif text-display text-shadow">
                {platform.homeTitle || `Welcome to ${platform.name}`}
              </div>
              <div className="text-center font-sans text-bold2 text-shadow">
                {platform.homeSubtitle}
              </div>
            </div>
          </div>
        </div>
      </Carousel>
      {marketplaceEnabled && membershipFeature && memberships?.length > 0 && (
        <MembershipPromo {...membershipFeature} memberships={memberships} />
      )}
      {dunningMembership.status === false &&
        platform.platformSettings?.marketplaceEnabled &&
        platform.platformSettings?.affirmEnabled && (
          <AffirmBannerLarge
            platformLogo={platform?.logoImageUrl}
            onClick={() => setShowAffirmModal(true)}
          />
        )}
      {platform.platformSettings?.marketplaceEnabled && (
        <>
          {checkIfListingsHaveConcerns(listings) && (
            <div className="bg-white">
              <CampaignCard
                svg={Quiz}
                subtitle="browse by concern"
                imageSrc={browseByConcernImage}
                title="Which treatments are best for me?"
                description="Browse by concern to find the treatments that you will love!"
                primary="Browse by concern"
                onButtonClick={() => navigate("/shop/concerns")}
              />
            </div>
          )}

          {listings.length > 0 && (
            <div className="bg-one">
              <ProductCarousel
                svg={Star}
                title="Shop Our Top-Selling Services"
                description="All your favorite treatments and curated packages, in one place."
                cards={topSellingTreatmentCards}
                primary="Shop all services"
                onButtonClick={() => navigate("/shop")}
              />
            </div>
          )}
          {specialOffersCards.length > 0 && (
            <div className="bg-two">
              <ProductCarousel
                svg={Discover}
                title="Special Offers"
                cards={specialOffersCards}
                primary="Discover more offers"
                onButtonClick={() => navigate("/discover")}
              />
            </div>
          )}
        </>
      )}
      <div className="bg-white">
        <CampaignCard
          variant="contactUs"
          subtitle="connect with us"
          imageSrc={platformLocation.imageUrl}
          logoSrc={platform.logoImageUrl}
          address={formatAddressNoCountry(
            platformLocation?.address1,
            platformLocation?.address2,
            platformLocation?.city,
            platformLocation?.state,
            platformLocation?.postalCode
          )}
          email={platformLocation?.email ?? ""}
          phone={formatPhoneNumber(platformLocation?.phoneNumber)}
          primary="Book appointment"
          secondary={
            platformLocation?.leaveReviewUrl ? "Leave us a review" : null
          }
          onButtonClick={(button) => {
            if (button == "primary") {
              if (bookAppointmentAction) {
                window.open(bookAppointmentAction);
              }
            } else if (
              button == "secondary" &&
              platformLocation?.leaveReviewUrl != null
            ) {
              window.open(platformLocation.leaveReviewUrl);
            }
          }}
          firstButtonDisabled={!bookAppointmentAction}
        />
      </div>
      {repeatmdOffer && platform.platformSettings?.repeatMDMarketingEnabled && (
        <div className="bg-two">
          <CampaignCard
            subtitle="win a vacation!"
            imageSrc={repeatmdOffer.imageUrl}
            title={repeatmdOffer.title}
            description={repeatmdOffer.shortDescription}
            primary="Learn more"
            onButtonClick={() =>
              navigate("/discover?repeatOffer=" + repeatmdOffer.id)
            }
          />
        </div>
      )}
      <ReferAFriend withRepeatMDFooter />
    </>
  );
};

export default HomePage;
