import cx from "classnames";
import dayjs from "dayjs";

import { Icon } from "atoms/icon/Icon";
import { ReactComponent as MembershipsIcon } from "atoms/icon/icons/ic_memberships_ol.svg";
import { ReactComponent as SinglePromoIcon } from "atoms/icon/icons/ic_offers_ol.svg";
import { ReactComponent as RewardsIcon } from "atoms/icon/icons/ic_rewards_ol.svg";
import { formatCurrency } from "toolbox/Money";

type AvailableProps = {
  onClick: () => void;
  textButton: string;
  title: string | null | undefined;
  totalDiscount?: number;
  expiresOn?: string | null | undefined;
  isMembership?: boolean | false;
  isHighlighted?: boolean | "" | undefined;
  isReward?: boolean | false;
};

export const Available = (props: AvailableProps) => {
  const isSelected = props.isHighlighted;

  const expiresOnText = (expirationDate: string) => {
    const expiration = dayjs(expirationDate);
    const today = dayjs();
    const expirationInDays = expiration.diff(today, "days");

    if (expirationInDays == 1) {
      return "1 day left to claim!";
    }

    return `${dayjs(props.expiresOn).fromNow(true)} left to claim!`;
  };

  return (
    <div
      className={cx(
        "flex w-full cursor-pointer items-center justify-between rounded border p-3",
        isSelected ? "border-brand-color bg-brand-tint" : "border-light-grey",
        !props.textButton && "pointer-events-none"
      )}
      onClick={props.onClick}
    >
      <div className="flex w-full items-center gap-3">
        <Icon
          svg={
            props.isReward
              ? RewardsIcon
              : props.isMembership
              ? MembershipsIcon
              : SinglePromoIcon
          }
          color={isSelected ? "brandColor" : "secondaryText"}
          size="xsmall"
        />
        <div className="flex flex-col gap-1">
          <div className="pr-4 font-sans text-bold2 text-primary">
            {props.title ?? "Reward hidden"}
          </div>
          <div
            className={cx(
              "text-left text-body2",
              isSelected ? "text-primary" : "text-secondary"
            )}
          >
            -{formatCurrency(props.totalDiscount ?? 0, 2)}
          </div>
          {props.expiresOn && (
            <div className="text-body3 text-red">
              {expiresOnText(props.expiresOn)}
            </div>
          )}
        </div>
      </div>
      {props.textButton && (
        <div className="cursor-pointer pr-1 text-bold3 text-brand-color">
          {props.textButton}
        </div>
      )}
    </div>
  );
};
