import cx from "classnames";
import { FC, SVGProps } from "react";
import { NavLink } from "react-router-dom";

import { Icon } from "atoms/icon/Icon";

interface ItemProps {
  /** Icon */
  svg: FC<SVGProps<SVGSVGElement>>;

  /** Where does it redirect to. */
  to: string;

  /** Label */
  label: string;
}

/** Footer item. */
export const Item = ({ svg, to, label }: ItemProps) => {
  return (
    <NavLink
      className={cx("flex flex-1 flex-col items-center active:opacity-60")}
      to={to}
      data-testid={`nav-link-${label.toLowerCase()}`}
    >
      {({ isActive }) => (
        <>
          <Icon color={isActive ? "brandColor" : "darkGrey"} svg={svg} />
          <div
            className={cx(
              "text-[11px] font-semibold capitalize leading-[12px]",
              isActive ? "text-primary" : "text-secondary"
            )}
          >
            {label}
          </div>
        </>
      )}
    </NavLink>
  );
};
