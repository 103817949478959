import cx from "classnames";
import { useEffect, useReducer } from "react";
import {
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get, post } from "api";
import { Button } from "atoms/button/Button";
import Carousel from "atoms/carousel/Carousel";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { OverviewPanel } from "atoms/overview-panel/OverviewPanel";
import { TermsAndCondition } from "atoms/terms-and-condition/TermsAndCondition";
import useHistory from "hooks/useHistory";
import { ExpandableItems } from "molecules/expandable-items/ExpandableItems";
import TopNav from "molecules/navbars/TopNav";
import { AddedToCartModal } from "organisms/added-to-cart-modal/AddedToCartModal";
import { KeyBenefits } from "organisms/key-benefits/KeyBenefits";
import ProductCarousel from "organisms/product-carousel/ProductCarousel";
import { ModalSelectorList } from "organisms/selection-modals/modal-selector-list/ModalSelectorList";
import { WhatToExpectList } from "organisms/what-to-expect-list/WhatToExpectList";
import { queryClient } from "pages/Root";
import { PricingPreview } from "pages/root/shop/PackagePDP/pricing-preview/PricingPreview";
import { SelectTreatmentPlan } from "pages/root/shop/PackagePDP/select-treatment-plan/SelectTreatmentPlan";
import { getProductTitle } from "services/HelperService";
import { notNull } from "toolbox/Guards";
import { waitObj } from "toolbox/Promise";
import { FormattedString } from "utils/FormattedStrings";

import { SavingsInfo } from "./savings-info/SavingsInfo";

type Action =
  | { type: "package"; packageId: string | null; priceId: string | null }
  | { type: "price"; priceId: string }
  | { type: "open"; modal: "package" | "price" | "cart" }
  | { type: "close" };

type State = {
  packageId: string | null | undefined;
  priceId: string | null | undefined;
  modal: "package" | "price" | "cart" | null;
  membershipBoxChecked: boolean;
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "package":
      return { ...state, packageId: action.packageId, priceId: action.priceId };

    case "price":
      return { ...state, priceId: action.priceId };

    case "open":
      return { ...state, modal: action.modal };

    case "close":
      return { ...state, modal: null };
  }
};

const loader = (id: string) => {
  const shopData = queryClient.ensureQueryData({
    queryKey: ["/shop"],
    queryFn: () => get("/shop"),
    staleTime: 1000 * 60 * 2,
  });

  const platform = queryClient.fetchQuery(["/platform"], () =>
    get("/platform")
  );

  const listing = queryClient.fetchQuery(["/listings/{id}", { id }], () =>
    get("/listings/{id}", { id })
  );

  const expectations = queryClient.fetchQuery(
    ["/listings/{id}/expectations", { id }],
    () => get("/listings/{id}/expectations", { id })
  );

  const beforeAfters = queryClient.fetchQuery(
    ["/listings/{id}/before-afters", { id }],
    () => get("/listings/{id}/before-afters", { id })
  );

  const media = queryClient.fetchQuery(["/listings/{id}/media", { id }], () =>
    get("/listings/{id}/media", { id })
  );

  const packages = queryClient.fetchQuery(
    ["/listings/{id}/packages", { id }],
    () => get("/listings/{id}/packages", { id })
  );

  const related = queryClient.fetchQuery(
    ["/listings/{id}/related", { id }],
    () => get("/listings/{id}/related", { id })
  );

  const platformUserMemberships = queryClient.fetchQuery(
    ["/platform-user-memberships"],
    () => get("/platform-user-memberships")
  );

  return waitObj({
    beforeAfters,
    expectations,
    listing,
    media,
    packages,
    platform,
    related,
    platformUserMemberships,
    shopData,
  });
};

export type LoaderData = Awaited<ReturnType<typeof loader>>;
export const packagePDPLoader: LoaderFunction = async ({ params: { id } }) => {
  if (!id) return redirect("..");
  return json(await loader(id));
};

const singlePackage = (data: LoaderData) =>
  data.packages.length == 1 ? data.packages[0].id : null;

const singlePrice = (data: LoaderData, packageId: string | null) => {
  const $package = data.packages.find((p) => p.id == packageId);
  return $package && $package.packagePrices.length === 1
    ? $package.packagePrices[0].id
    : null;
};

const PackagePDP = () => {
  const { routeBackNumber, setRouteBackNumber } = useHistory();
  const data = useLoaderData() as LoaderData;

  const [state, dispatch] = useReducer(reducer, {
    packageId: singlePackage(data),
    priceId: singlePrice(data, singlePackage(data)),
    modal: null,
    membershipBoxChecked: false,
  });

  const navigate = useNavigate();

  const isMember = data.platformUserMemberships.length > 0;
  const $package = data.packages.find((p) => p.id === state.packageId);
  const price = $package?.packagePrices.find((p) => p.id === state.priceId);
  const packageItems = data.packages.flatMap(($package) => $package.items);
  const defaultPrice = data.packages
    .flatMap(($package) => $package.packagePrices)
    .find((packagePrice) => packagePrice.price === data.listing.baseCost);
  const terms = data.listing.terms ?? data.platform.termsAndConditions;
  const cost = price?.price ?? $package?.baseCost ?? data.listing.baseCost;
  const singularUnitType =
    $package?.packageUnitType.singularUnitType ??
    data.listing.packageUnitType.singularUnitType ??
    "unit";

  useEffect(() => {
    dispatch({
      type: "package",
      packageId: singlePackage(data),
      priceId: singlePrice(data, singlePackage(data)),
    });
  }, [data]);

  const relatedCards = data.related
    .map(
      (item) =>
        item &&
        item.imageUrl &&
        item.type &&
        item.title &&
        item.baseCost && {
          to: `/pdp/package/${item.id}`,
          imageSrc: item.imageUrl,
          tag: item.type,
          title: item.title,
          price: item.baseCost,
          memberPrice: item.membershipCost,
        }
    )
    .filter(notNull) as {
    to: string;
    imageSrc: string;
    tag: string;
    title: string;
    price: number;
    memberPrice?: number;
  }[];

  const secondButtonProps =
    data.packages.length > 1
      ? {
          secondButtonLabel: "< Back",
          onSecondButtonClick: () =>
            dispatch({ type: "open", modal: "package" }),
        }
      : {};

  return (
    <>
      <TopNav
        startIconSVG={ChevronLeft}
        onStartIconClick={() => navigate(routeBackNumber)}
      >
        <div className="flex max-h-[32px] items-center">
          <h1 className="text-xl">
            {data.listing.type == "Treatment" ? "Treatments" : "Packages"}
          </h1>
        </div>
      </TopNav>
      <div className="flex flex-col gap-5 bg-one px-4 pb-8 pt-9">
        <div className="flex flex-col gap-6">
          <OverviewPanel title={data.listing.title} />
          <PricingPreview
            affirmEnabled={data.platform.platformSettings?.affirmEnabled}
            defaultPrice={cost}
            memberPrice={
              price?.membershipPrice ?? data.listing.membershipCost ?? undefined
            }
            isPriceSet={state.priceId ? true : false}
            packageUnitType={singularUnitType}
          />
        </div>
        {!!(price?.membershipPrice ?? data.listing.membershipCost) && (
          <SavingsInfo
            baseCost={cost}
            memberCost={
              price?.membershipPrice ?? data.listing.membershipCost ?? 0
            }
            membershipName={
              price?.membership?.name ?? defaultPrice?.membership?.name ?? ""
            }
            isMember={isMember}
            onViewMoreInfo={() => {
              const membership = price?.membership ?? defaultPrice?.membership;
              if (membership?.id) {
                navigate(
                  `/pdp/membership/${membership.id}/${data.listing.type}`
                );
              }
            }}
          />
        )}
        <div className="flex flex-col gap-[6px]">
          {data.media && <Carousel slides={data.media} />}
          <div
            className={cx(
              "flex flex-col gap-5 px-4 text-body1 text-secondary",
              data.media.length === 1 ? "pt-5" : ""
            )}
          >
            <FormattedString>{data.listing.description}</FormattedString>
          </div>
        </div>
      </div>
      <div className="bg-white px-4 pt-8 pb-4">
        <SelectTreatmentPlan
          onClickPlanSelector={() => {
            dispatch({ type: "open", modal: "package" });
          }}
          onClickSessionsSelector={() =>
            dispatch({ type: "open", modal: "price" })
          }
          baseCost={cost}
          isPriceSet={state.priceId ? true : false}
          isMember={isMember}
          memberCost={
            price?.membershipPrice ?? data.listing.membershipCost ?? undefined
          }
          serviceFee={data.platform.platformSettings?.patientFeePercentage}
          productType={data.listing.type}
          package={$package}
          price={price ?? defaultPrice}
          packages={data.packages}
          onMemberInfo={() => {
            state.membershipBoxChecked = !state.membershipBoxChecked;
          }}
          onViewMoreInfo={() => {
            const membership = price?.membership ?? defaultPrice?.membership;
            if (membership?.id) {
              navigate(`/pdp/membership/${membership.id}/${data.listing.type}`);
            }
          }}
        />
      </div>
      <KeyBenefits
        keyBenefits={
          data.shopData.concerns
            ?.filter((concern) => data.listing.concernIds.includes(concern.id))
            .map((concern) => concern.name)
            .filter(notNull) || []
        }
      />
      {packageItems && data.listing.type === "Package" && (
        <div className="bg-one p-6">
          <div className="pb-6 text-center text-sub1 text-brand-color">
            WHAT IS INCLUDED?
          </div>
          <ExpandableItems
            items={packageItems.map((packageItem) => ({
              id: packageItem.name + packageItem.quantity,
              title: `${packageItem.quantity}x ${packageItem.name}`,
              description: packageItem.description,
            }))}
          />
        </div>
      )}
      <WhatToExpectList expectations={data.expectations} />
      {relatedCards.length > 0 && data.listing.type === "Treatment" && (
        <div className="bg-one">
          <ProductCarousel title="RELATED PACKAGES" cards={relatedCards} />
        </div>
      )}
      <div className="px-4">
        <TermsAndCondition
          text={terms}
          hideText={!data.platform.platformSettings?.affirmEnabled}
        />
      </div>
      <div className="sticky bottom-[calc(60px_+_env(safe-area-inset-bottom))] w-full bg-white p-4">
        <Button
          fullWidth={true}
          disabled={!data.listing.active}
          onClick={async () => {
            if (state.packageId && state.priceId) {
              await post("/orders/current/lineitems", {
                packageListingId: data.listing.id,
                packageId: state.packageId,
                packagePriceId: state.priceId,
                membershipId: state.membershipBoxChecked
                  ? price?.membership?.id
                  : null,
              });

              queryClient.invalidateQueries({
                queryKey: ["/orders/current/summary"],
              });

              dispatch({ type: "open", modal: "cart" });
            } else if (state.packageId) {
              dispatch({ type: "open", modal: "price" });
            } else {
              dispatch({ type: "open", modal: "package" });
            }
          }}
        >
          {data.listing.active ? "Add to cart" : "No longer available"}
        </Button>
      </div>

      <ModalSelectorList
        size="fullscreen"
        title="TREATMENT TYPES"
        open={state.modal === "package"}
        type="products"
        items={data.packages.map((p) => ({
          id: p.id,
          title: p.name,
          description: p.description,
          priceOrResults: p.baseCost,
        }))}
        checkedItems={$package ? [$package.id] : []}
        onClose={() => dispatch({ type: "close" })}
        onSelect={(packageId) => {
          /* Incrementing the routeBackNumber here is only necessary because the
            dispatch causes a re-render of AffirmMessageElement in PricePreview, which
            adds to window.history. See CMX-421 & CMX-826 */
          data.platform.platformSettings?.affirmEnabled &&
            setRouteBackNumber(() => routeBackNumber - 1);
          return dispatch({
            type: "package",
            packageId,
            priceId: singlePrice(data, packageId),
          });
        }}
        firstButtonLabel="Next"
        firstButtonDisabled={!state.packageId}
        onFirstButtonClick={() => dispatch({ type: "open", modal: "price" })}
      />

      {$package && data.listing.active && (
        <ModalSelectorList
          size="fullscreen"
          title={
            "NUMBER OF " +
            `${
              $package.packageUnitType.pluralUnitType?.toUpperCase() ?? "UNITS"
            }`
          }
          type="products"
          open={state.modal === "price"}
          items={$package.packagePrices.map((p) => ({
            id: p.id,
            priceOrResults: p.price,
            membershipPrice: p.membershipPrice,
            title:
              p.quantity === 1
                ? `${p.quantity} ${
                    $package.packageUnitType.singularUnitType ?? "unit"
                  }`
                : `${p.quantity} ${
                    $package.packageUnitType.pluralUnitType ?? "units"
                  }`,
          }))}
          checkedItems={price ? [price.id] : []}
          onSelect={(priceId) => {
            /* Incrementing the routeBackNumber here is only necessary because the
            dispatch causes a re-render of AffirmMessageElement in PricePreview, which
            adds to window.history. See CMX-421 & CMX-826 */
            data.platform.platformSettings?.affirmEnabled &&
              setRouteBackNumber(() => routeBackNumber - 1);
            return dispatch({ type: "price", priceId });
          }}
          onClose={() => dispatch({ type: "close" })}
          firstButtonLabel="Add to cart"
          firstButtonDisabled={!$package || !price}
          onFirstButtonClick={async () => {
            if (!$package || !price) return;

            await post("/orders/current/lineitems", {
              packageListingId: data.listing.id,
              packageId: $package.id,
              packagePriceId: price.id,
              membershipId: state.membershipBoxChecked
                ? price?.membership?.id
                : null,
            });

            queryClient.invalidateQueries({
              queryKey: ["/orders/current/summary"],
            });

            dispatch({ type: "open", modal: "cart" });
          }}
          {...secondButtonProps}
        />
      )}

      {$package && price && (
        <AddedToCartModal
          title={getProductTitle(data.listing.title, $package.name)}
          open={state.modal === "cart"}
          subtitle={
            price.quantity === 1
              ? `${price.quantity} ${$package.packageUnitType.singularUnitType}`
              : `${price.quantity} ${$package.packageUnitType.pluralUnitType}`
          }
          cost={
            state.membershipBoxChecked || isMember
              ? Number(price.membershipPrice)
              : price.price
          }
          membership={state.membershipBoxChecked ? price?.membership : null}
          onClose={() => dispatch({ type: "close" })}
        />
      )}
    </>
  );
};

export default PackagePDP;
