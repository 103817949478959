interface AdminImpersonationBannerProps {
  platformUserDisplayName: string;
}

export const AdminImpersonationBanner = (
  props: AdminImpersonationBannerProps
) => {
  return (
    <div className="sticky top-0 z-10 border-b-[0.75px] bg-brand-tint px-3 py-2 text-left">
      <div className="text-body2">{`Logged in as ${props.platformUserDisplayName}`}</div>
    </div>
  );
};
