/**
 * This page is responsible for rendering the stripe payment form that allows
 * users to check out their orders.
 *
 * The basic flow of the page is as follows:
 * 1. On every page load, we request a transaction from the backend for the
 *    current draft order. If there's not already a "Processing" or "Paid"
 *    transaction, a new one is generated for it with a status of "Unpaid".
 * 2. The user fills out their billing information and payment details, and clicks
 *    the "Place order" button, triggering an order submission.
 * 3. We request a lock on the order by requesting that the backend "prepare"
 *    the current transaction. While this lock is held, new transactions cannot
 *    be created for the order.
 * 4. We confirm the payment with stripe, which will either succeed or fail. If
 *    if succeeds, the user is redirected to the success page. If it fails, we
 *    request the backend to release the lock on the transaction.
 *
 * Held locks are released after a certain amount of time, dictated by the backend,
 * so an interruption in the flow will not permanently lock the order.
 */

import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import {
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get, post } from "api";
import {
  AFFIRM_MINIMUM_AMOUNT,
  AffirmUnavailableReason,
  NotAvailableAffirm,
} from "atoms/affirm/NotAvailableAffirm/NotAvailableAffirm";
import { Button } from "atoms/button/Button";
import { Checkbox } from "atoms/checkbox/Checkbox";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { ReactComponent as Warning } from "atoms/icon/icons/ic_warning_ol.svg";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { TextField } from "atoms/text-field/TextField";
import TopNav from "molecules/navbars/TopNav";
import { OrderSummary } from "organisms/order-summary/OrderSummary";
import { queryClient } from "pages/Root";
import { waitObj } from "toolbox/Promise";
import { buildPlatformUrl, removeArchiveItems } from "utils/functions";

import { PromoCodeErrorModal } from "./PromoCodeErrorModal";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY || "");

const load = async (request: Request) => {
  const platform = queryClient.fetchQuery(["platform"], () => get("/platform"));
  const user = queryClient.fetchQuery(["/user"], () => get("/user"), {
    staleTime: 1000 * 60 * 2,
  });
  const cartDetails = queryClient.fetchQuery(["orders/current/details"], () =>
    get("/orders/current/details")
  );

  /* When displaying the page in a mobile webview, we let the app handle displaying its own navbar */
  const showNavbar =
    new URL(request.url).searchParams.get("isMobileApp") !== "true";

  const data = await waitObj({ platform, user, cartDetails, showNavbar });

  const transactionDetails = await post(
    "/orders/{shopDisplayOrderId}/transactions",
    { shopDisplayOrderId: data.cartDetails.shopDisplayOrderId }
  );

  return { ...data, transactionDetails };
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const checkoutElementLoader: LoaderFunction = async ({ request }) => {
  const data = await load(request);

  if (data.transactionDetails.freePurchase) {
    return redirect(
      `/cart/checkout/success?shopDisplayOrderId=${data.cartDetails.shopDisplayOrderId}`
    );
  } else if (
    data.transactionDetails.transactionStatus === "Paid" ||
    data.transactionDetails.transactionStatus === "Processing"
  ) {
    return redirect(
      `/cart/checkout/success?shopDisplayOrderId=${data.cartDetails.shopDisplayOrderId}&orderTransactionId=${data.transactionDetails.orderTransactionId}`
    );
  }

  return json<LoaderData>(data);
};

interface StripeCheckoutFormProps {
  orderTransactionId: string;
}

const StripeCheckoutForm = ({
  orderTransactionId,
}: StripeCheckoutFormProps) => {
  const { cartDetails, platform, user, showNavbar } =
    useLoaderData() as LoaderData;
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState(user.email ?? "");
  const [emailError, setEmailError] = useState("");
  const [defaultEmail, setDefaultEmail] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const containsMembership =
    cartDetails.shopDisplayOrderLineItems?.find(
      (item) => item.shopDisplayMembershipId != null
    ) != null;
  const containsPackageOrTreatment =
    cartDetails.shopDisplayOrderLineItems?.find(
      (item) => item.shopDisplayPackageId != null
    ) != null;

  const isValidEmail = (email: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const { mutate: handleSubmit, status: submitStatus } = useMutation(
    async () => {
      if (!isValidEmail(email) || !stripe || !elements) {
        return;
      }

      const details = await get("/orders/current/details");

      const isCartEmpty = !details.shopDisplayOrderLineItems?.length;
      const archivedRemoved = await removeArchiveItems(details);

      if (isCartEmpty || archivedRemoved !== 0) {
        navigate("/cart");
        return;
      }

      const promoCodeError = details.discountSummary?.promoCodeError;
      if (promoCodeError) {
        setPromoCodeErrorModal(promoCodeError);
        return;
      }

      const updateEmail =
        !defaultEmail && email != user.email
          ? post("/user/update", { email })
          : Promise.resolve();

      const prepare = post(
        "/orders/current/transactions/{transactionId}/prepare",
        { transactionId: orderTransactionId }
      );

      await Promise.all([updateEmail, prepare]);

      const baseUrl = buildPlatformUrl(
        platform?.scheme,
        platform?.subdomain,
        platform?.host,
        "checkout"
      );
      const getAppDynamicLink = () => {
        const isNonProdEnv =
          location.href.includes(".dev.") ||
          location.href.includes(".staging.");
        const link = new URL("https://repeatmd.page.link");
        link.search = new URLSearchParams({
          link: `https://amora.dev.repeatmd.app/login?platformId=${
            platform.id
          }&isProd=${!isNonProdEnv}`,
          apn: "com.repeatmd.mobile",
          isi: "6448703360",
          ibi: "com.repeatmd.mobile",
          efr: "1",
          ofl: "https://amora.repeatmd.app",
        }).toString();

        return link.href;
      };
      const isNativeApp = Capacitor.isNativePlatform();
      const return_path = `/cart/checkout/success?shopDisplayOrderId=${cartDetails?.shopDisplayOrderId}&orderTransactionId=${orderTransactionId}`;

      Preferences.set({ key: "stripeReturnPath", value: return_path });

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: isNativeApp ? getAppDynamicLink() : baseUrl + return_path,
        },
        redirect: "if_required",
      });

      if (!error) return navigate(return_path);

      if (error.message) setErrorMessage(error.message);

      Preferences.remove({ key: "stripeReturnPath" });

      const resetEmail =
        !defaultEmail && email != user.email
          ? post("/user/update", { email: user.email })
          : Promise.resolve();

      const unprepare = post(
        "/orders/current/transactions/{transactionId}/unprepare",
        { transactionId: orderTransactionId }
      );

      await Promise.all([resetEmail, unprepare]);
    }
  );

  const isOrderTotalAboveMinimum =
    cartDetails.estimatedTotal / 100 > AFFIRM_MINIMUM_AMOUNT;

  const [promoCodeError, setPromoCodeErrorModal] = useState("");

  const closePromoCodeErrorModal = () => {
    setPromoCodeErrorModal("");
    window.location.reload();
  };

  return (
    <>
      {showNavbar && (
        <TopNav
          startIconSVG={ChevronLeft}
          onStartIconClick={() => {
            navigate(-1);
          }}
          showHeaderItems={false}
        >
          <div className="flex max-h-[32px] items-center">
            <div className="flex w-full items-center justify-center pr-6 text-sub2 text-primary">
              CHECKOUT
            </div>
          </div>
        </TopNav>
      )}
      <div className="flex flex-col border-b border-b-light-grey pt-6 pb-5">
        <div className="flex flex-col px-5">
          <div className="text-bold1">Contact details</div>
          <div className="pt-2 pb-4 text-body2 text-secondary">
            Confirm your details to receive your sales receipt
          </div>
          <TextField
            type="email"
            label="Email"
            name="email"
            rightIcon={emailError != "" ? Warning : undefined}
            placeholder="Confirm your email address"
            supportingText={emailError}
            value={email}
            onChange={(value) => {
              setEmail(value);
              setEmailError("");
            }}
            invalid={emailError != ""}
            onBlur={(value) => {
              if (!isValidEmail(value)) {
                setEmailError("Please enter a valid email address");
              }
            }}
          />
        </div>
        <div className="pt-3 pl-2.5 pr-5">
          <Checkbox
            label="Save address to your account"
            checked={defaultEmail}
            onChange={(checked: boolean) => setDefaultEmail(checked)}
          />
        </div>
      </div>
      <div className="pt-6 pl-5 text-bold1">Payment method</div>
      {platform.platformSettings?.affirmEnabled &&
        (!isOrderTotalAboveMinimum ||
          (containsMembership && containsPackageOrTreatment)) && (
          <div className="m-3">
            <NotAvailableAffirm
              isLargeBanner={false}
              reason={
                containsMembership && containsPackageOrTreatment
                  ? AffirmUnavailableReason.ContainsMembership
                  : AffirmUnavailableReason.BelowMinimum
              }
            />
          </div>
        )}

      <div className="flex flex-col">
        <div className="px-5 pt-4 pb-5">
          <PaymentElement />
        </div>
        <hr className=" bg-light-grey" />
        <div className="px-5 pt-5">
          {cartDetails && (
            <>
              <OrderSummary
                cart={cartDetails}
                displayProducts={true}
                feePercentage={
                  platform?.platformSettings?.patientFeePercentage || 0
                }
              />
              {promoCodeError && (
                <PromoCodeErrorModal
                  onClose={closePromoCodeErrorModal}
                  error={promoCodeError}
                />
              )}
            </>
          )}
          <RepeatMDFooter showShopTerms />
        </div>
        <div className="sticky bottom-0 w-full bg-white p-5 pb-[calc(20px_+_env(safe-area-inset-bottom))]">
          <Button
            onClick={() => handleSubmit()}
            disabled={!stripe || submitStatus === "loading"}
            fullWidth
          >
            Place order
          </Button>
          {errorMessage && (
            <div className="flex flex-col items-center text-body1 text-primary">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const CheckoutElementPage = () => {
  const { transactionDetails: data } = useLoaderData() as LoaderData;

  if (!data.clientSecret) {
    // Client secret is only null for free purchases
    throw new Error("Client secret is required for checkout");
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: data.clientSecret }}
      key={data.paymentIntentId}
    >
      <StripeCheckoutForm orderTransactionId={data.orderTransactionId} />
    </Elements>
  );
};

export default CheckoutElementPage;
