import cx from "classnames";

import { Icon } from "atoms/icon/Icon";
import { ReactComponent as RepeatMD } from "atoms/icon/icons/RepeatCash.svg";
import { formatCurrency } from "toolbox/Money";

export interface UserAndCashProps {
  firstName?: string | null;
  lastName?: string | null;
  repeatCashBalance: number;
  hideRepeatCash: boolean;
}

export const UserAndCash = ({
  firstName,
  lastName,
  repeatCashBalance,
  hideRepeatCash,
}: UserAndCashProps) => {
  return (
    <div className="flex h-[60px] items-center justify-between px-6">
      <div
        className="font-sans text-sm font-bold uppercase leading-[18px] text-brand-color"
        data-testid="div-username"
      >
        {firstName} {lastName}
      </div>

      <div
        className={cx("flex items-center gap-0.5", hideRepeatCash && "hidden")}
      >
        <Icon svg={RepeatMD} size="xsmall" />
        <div className="font-sans text-base font-bold leading-[22px]">
          {formatCurrency(repeatCashBalance, 2)}
        </div>
        <div className="pl-0.5 font-sans text-base font-normal leading-[22px] text-secondary">
          cash
        </div>
      </div>
    </div>
  );
};
