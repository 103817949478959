import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Preferences } from "@capacitor/preferences";
import { datadogRum } from "@datadog/browser-rum-slim";
import { FirebaseDynamicLinks } from "@pantrist/capacitor-firebase-dynamic-links";
import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import Services from "../src/services/Services";
import { setPromotionModalSeen } from "../src/utils/functions";

import { useAppState } from "./contexts/AppContext";

const AppUrlListener: React.FC<any> = () => {
  const navigate = useNavigate();
  const signOut = () => {
    setPromotionModalSeen(false);
    Services.auth.setAuthToken(null);
    datadogRum.clearUser();
  };
  const appState = useAppState();
  const getPlatformIdWithDomain = async (url: string) => {
    const resp = await axios.get(
      `${url}/api/v1/multi-platform/platform/domain?domain=${
        url.split("repeatmd.app")?.[0].split("https://")?.[1]?.split(".")?.[0] +
        ".repeatmd.app"
      }`
    );
    return resp.data?.result?.platformId;
  };
  const getDomainWithPlatformId = async (url: string, platformId: string) => {
    const resp = await axios.get(
      `${url}/api/v1/multi-platform/platform/domain/id?platformId=${platformId}`
    );
    return resp.data?.result?.domain;
  };

  const handleLink = async (actualLink: string) => {
    const { value: stripeReturnPath } = await Preferences.get({
      key: "stripeReturnPath",
    });
    if (stripeReturnPath) {
      navigate(stripeReturnPath);
      await Preferences.remove({ key: "stripeReturnPath" });
      return;
    }
    // splitting it so that we can discard anything after repeatmd.app
    const domainName = actualLink.split("repeatmd.app")?.[0];
    let url = domainName + "repeatmd.app";
    // getting env staging dev etc
    const env = actualLink.split("repeatmd.app")?.[0]?.split(".")?.[1];

    let platformId = actualLink
      .split("platformId=")?.[1]
      ?.split("&isProd")?.[0];

    if (!platformId) {
      platformId = await getPlatformIdWithDomain(url);
    } else {
      const isProdEnv = actualLink.split("&isProd=")?.[1];
      // we are getting new url as when we click on dynamic link, we get same url for every platform id. therefore this function is called to get the actual new url
      const newUrl = await getDomainWithPlatformId(
        url
          .split(env)
          .join(isProdEnv === "true" ? "" : env)
          .split("..")
          .join("."),
        platformId
      );
      if (newUrl !== undefined) {
        url =
          "https://" +
          newUrl?.split("repeatmd.app")?.[0] +
          (isProdEnv === "true" ? "repeatmd.app" : env + ".repeatmd.app");
      }
    }
    // checking if url or platform id is changed and then saving url and platform id
    if (
      url !== localStorage.getItem("baseApiUrl") ||
      localStorage.getItem("platformId") !== platformId
    ) {
      //if user is already logged in
      if (Services.auth.isAuthorized) {
        const platforms = await Preferences.get({
          key: "platformDomainTokens",
        });

        const requiredPlatform = JSON.parse(platforms.value || "[]")?.find(
          (platform: { platformId: string }) =>
            platform.platformId === platformId
        );
        if (requiredPlatform) {
          Services.auth.setAuthToken(requiredPlatform.token);
        } else {
          signOut();
        }
      } else {
        signOut();
      }
      appState.setPlatformId(platformId);
      appState.setApiUrl(url);
      location.reload();
    }
  };
  useEffect(() => {
    // added this listener to clear api url and platform id if user is not logged in and app is closed
    App.addListener("pause", () => {
      if (!Services.auth.isAuthorized) {
        appState.setApiUrl("");
        appState.setPlatformId("");
      }
    });
    FirebaseDynamicLinks.addListener("deepLinkOpen", async (data) => {
      await handleLink(data.url);
    });
    App.addListener("appUrlOpen", async (event: URLOpenListenerEvent) => {
      // If the link includes repeatmd.page.link we will not do anything here as this will be parsed and managed by FirebaseDynamicLinks above
      if (event.url.includes("repeatmd.page.link")) {
        return;
      }
      await handleLink(event.url);
    });

    if (appState.apiUrl && !appState.platformId) {
      getPlatformIdWithDomain(appState.apiUrl)
        .then((platformId) => appState.setPlatformId(platformId))
        .catch(() => {
          // it will be a wrong url setting url to empty string so that the app uses url from env
          appState.setApiUrl("");
          location.reload();
        });
    }
    return () => {
      App.removeAllListeners();
      FirebaseDynamicLinks.removeAllListeners();
    };
  }, []);

  return null;
};

export default AppUrlListener;
