export default function aOrAn(text: string): string {
  const vowels = ["a", "e", "i", "o", "u"];
  const firstLetter = text.charAt(0).toLowerCase();

  if (vowels.includes(firstLetter)) {
    return `an ${text}`;
  } else {
    return `a ${text}`;
  }
}
