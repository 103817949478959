import React from "react";

interface Props {
  /** Displays a short descriptive keyword in a pill container */
  children: string;
}

export const ProductBenefitTag: React.FC<Props> = ({ children }) => {
  return (
    <div className="text-sans w-fit rounded border border-light-grey bg-white px-2 py-1 text-body3 text-brand-color">
      {children}
    </div>
  );
};
