import cx from "classnames";
import { useLayoutEffect, useState } from "react";

import {
  AvailableReward,
  AvailableRewardProps,
} from "molecules/containers/reward/available-reward/AvailableReward";
import { queueTaskInBackground } from "utils/queueTaskInBackground";

export interface AvailableRewardListProps {
  rewards: AvailableRewardProps[];
  showTitle?: boolean;
  delay?: number;
}

export const AvailableRewardList = ({
  rewards,
  showTitle = true,
  delay,
}: AvailableRewardListProps) => {
  const [show, setShow] = useState(false);

  useLayoutEffect(() => {
    queueTaskInBackground(() => setShow(true));
  }, []);

  if (!rewards.length) {
    return null;
  }

  return (
    <div>
      {showTitle && (
        <div className="pb-6 text-center font-sans text-sub2 text-secondary">
          AVAILABLE REWARDS
        </div>
      )}
      <div
        className={cx(
          "overflow-clip rounded-lg transition",
          (show || !delay) &&
            "divide-y divide-solid divide-background-two shadow-subtle"
        )}
        style={{
          transitionDelay: delay ? `${delay + 100 * rewards.length}ms` : "0s",
        }}
      >
        {rewards.map((reward, index) => (
          <div
            key={reward.id}
            className="transition-[translate] duration-500"
            style={{
              translate: show || !delay ? 0 : "-100%",
              transitionDelay: delay ? `${delay + 100 * index}ms` : "0s",
            }}
          >
            <AvailableReward {...reward} />
          </div>
        ))}
      </div>
    </div>
  );
};
