import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import cx from "classnames";
import { useEffect, useState } from "react";
import {
  ActionFunction,
  Form,
  json,
  LoaderFunction,
  redirect,
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useSubmit,
} from "react-router-dom";

import { del, get, post, put } from "api";
import { Models } from "api/types";
import { Checkbox } from "atoms/checkbox/Checkbox";
import { IconButton } from "atoms/icon-button/IconButton";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right.svg";
import { ReactComponent as Info } from "atoms/icon/icons/info.svg";
import { TextButton } from "atoms/text-button/TextButton";
import { TextField } from "atoms/text-field/TextField";
import { queryClient } from "pages/Root";
import {
  getCardBrandByCardNumber,
  getCardBrandIcon,
  isCreditCardNumberValid,
} from "toolbox/Cards";
import { waitObj } from "toolbox/Promise";
import { readFormData } from "toolbox/ReadFormData";

import { AddressModal } from "../components/AddressModal";

import { AddressSelector } from "./components/AddressSelector";

const load = async (paymentMethodId: string) => {
  const addresses = queryClient
    .fetchQuery(["/user-addresses"], () => get("/user-addresses", {}))
    .then((a) => a.items);
  const memberships = queryClient.fetchQuery(
    ["/platform-user-memberships"],
    () => get("/platform-user-memberships")
  );

  if (paymentMethodId === "new") {
    return waitObj({ addresses, memberships, paymentMethod: undefined });
  }

  const paymentMethod = queryClient.fetchQuery(
    ["/payment-methods", { paymentMethodId }],
    () => get("/payment-methods/{paymentMethodId}", { paymentMethodId })
  );
  return await waitObj({ addresses, memberships, paymentMethod });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const paymentMethodLoader: LoaderFunction = async ({
  params: { paymentMethodId },
}) => {
  if (!paymentMethodId) return redirect("..");

  return json<LoaderData>(await load(paymentMethodId));
};

export const paymentMethodAction: ActionFunction = async ({ request }) => {
  const data = await readFormData<
    | {
        nameOnCard: string;
        creditCardNumber: string;
        creditCardExpMonth: number;
        creditCardExpYear: number;
        creditCardCvc: string;
        userAddressId: string;
        isDefault: string;
        oneTimeUse: string;
        type: "create-payment-method";
      }
    | {
        paymentMethodId: string;
        userAddressId: string;
        isDefault: string;
        oneTimeUse: "false";
        nameOnCard: string;
        creditCardExpMonth: string;
        creditCardExpYear: string;
        type: "update-payment-method";
      }
    | {
        firstName: string;
        lastName: string;
        address1: string;
        address2: string;
        city: string;
        state: string;
        postalCode: string;
        phoneNumber: string;
        isDefault: string;
        oneTimeUse: "false";
        type: "create-address";
      }
    | {
        type: "delete-payment-method";
        paymentMethodId: string;
      }
  >(request);

  if (data.type === "delete-payment-method" && request.method === "DELETE") {
    await del("/payment-methods/{paymentMethodId}", {
      paymentMethodId: data.paymentMethodId,
    });

    return redirect("..");
  }

  if (data.type === "create-payment-method") {
    try {
      const paymentMethod = await post("/payment-methods", {
        paymentMethodType: "CreditCard",
        userAddressId: data.userAddressId,
        creditCard: {
          nameOnCard: data.nameOnCard,
          creditCardNumber: data.creditCardNumber,
          creditCardExpMonth: +data.creditCardExpMonth,
          creditCardExpYear: +data.creditCardExpYear,
          creditCardCvc: data.creditCardCvc,
        },
        isDefault: data.isDefault === "true",
        oneTimeUse: data.oneTimeUse === "true",
      });
      return redirect("..?selectedPaymentMethodId=" + paymentMethod.id);
    } catch (err) {
      return {
        error: {
          message: (err as AxiosError).response?.data,
          timestamp: Date.now(),
        },
      };
    }
  }

  if (data.type === "update-payment-method") {
    const paymentMethod = await put("/payment-methods", {
      paymentMethodId: data.paymentMethodId,
      userAddressId: data.userAddressId,
      nameOnCard: data.nameOnCard,
      creditCardExpMonth: +data.creditCardExpMonth,
      creditCardExpYear: +data.creditCardExpYear,
      isDefault: data.isDefault === "true",
    });

    return redirect(
      "..?seletedPaymentMethodId=" + paymentMethod.paymentMethodId
    );
  }

  if (data.type === "create-address") {
    await post("/user-addresses", {
      ...data,
      isDefault: data.isDefault === "true",
    });

    return null;
  }
};

type ActionResult = {
  error?: {
    message: string;
    timestamp: number;
  };
};

export const PaymentMethodPage = () => {
  const { paymentMethod, addresses, memberships } =
    useLoaderData() as LoaderData;
  const actionData = useActionData() as ActionResult | undefined;
  const navigate = useNavigate();
  const location = useLocation();
  const [hideBackendErrorMessage, setHideBackendErrorMessage] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    expirationDate: "",
    cvc: "",
    isDefault: false,
    oneTimeUse: false,
    // first in the list is default
    addressId: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    number: "",
    expirationDate: "",
    cvc: "",
    addressId: "",
  });
  const submit = useSubmit();

  const [isAddNewAddressModalOpen, setIsAddNewAddressModalOpen] =
    useState(false);

  useEffect(() => {
    if (paymentMethod) {
      const expirationDate = paymentMethod.paymentMethodDetails.expirationDate;

      setFormData({
        name: paymentMethod.nameOnCard || "",
        number:
          paymentMethod.paymentMethodDetails.displayNumber?.split(" ")[3] || "",
        expirationDate: expirationDate
          ? `${expirationDate.split("/")[0]}/${expirationDate
              .split("/")[1]
              .substring(2, 4)}`
          : "",
        cvc: "",
        isDefault: paymentMethod.isDefault,
        oneTimeUse: false,
        addressId: paymentMethod.userAddressId || "",
      });
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (addresses?.length && !paymentMethod) {
      setFormData((prev) => ({
        ...prev,
        addressId: addresses.find((address) => address.isDefault)?.id || "",
      }));
    }
  }, [addresses, paymentMethod]);

  const formatCardNumber = (value: string): string => {
    const prevValue = formData.number;

    if (
      (prevValue.length < 5 && value.length === 4) ||
      (prevValue.length < 9 && value.length === 9) ||
      (prevValue.length < 14 && value.length === 14)
    ) {
      return value + " ";
    }

    return value;
  };

  const handleSaveAddress = (
    address:
      | Models<"CreateUserAddressRequest">
      | Models<"UpdateUserAddressRequest">
  ) => {
    const actualAddress = address as Models<"CreateUserAddressRequest">;
    submit(
      {
        firstName: actualAddress.firstName,
        lastName: actualAddress.lastName,
        address1: actualAddress.address1,
        address2: actualAddress.address2 || "",
        city: actualAddress.city,
        state: actualAddress.state,
        postalCode: actualAddress.postalCode,
        phoneNumber: actualAddress.phoneNumber,
        isDefault: actualAddress.isDefault?.toString() || "false",
        type: "create-address",
      },
      {
        method: "post",
      }
    );
    setIsAddNewAddressModalOpen(false);
  };

  const { isDefault, oneTimeUse, ...actualFormData } = formData;
  const isAllCardDataFilled = !paymentMethod
    ? Object.values(actualFormData).every((val) => val)
    : formData.name && formData.expirationDate;

  const checkCardNumber = () => {
    if (!isCreditCardNumberValid(formData.number)) {
      return "Invalid";
    }

    return false;
  };

  const checkExpirationDate = () => {
    const month = +formData.expirationDate.split("/")[0];
    const year = +`20${formData.expirationDate.split("/")[1]}`;

    if (
      !month ||
      month < 1 ||
      month > 12 ||
      !year ||
      // 50 years from now - that's when BE doesn't throw error for invalid year
      // probably comes from stripe
      year > new Date().getFullYear() + 50
    ) {
      return "Invalid";
    }

    const isExpired = new Date(year, month - 1) < new Date();

    if (isExpired) {
      return "Expired";
    }

    return false;
  };

  const checkCvc = () => {
    if (formData.cvc.length !== 3 && formData.cvc.length !== 4) {
      return "Must have 3 or 4 digits";
    }

    return false;
  };

  const checkAddress = () => {
    if (!formData.addressId) {
      return "Required";
    }

    return false;
  };

  const handleSubmit = () => {
    const addressError = checkAddress();
    const numberError = checkCardNumber();
    const expirationDateError = checkExpirationDate();
    const cvcError = checkCvc();

    setErrors({
      ...errors,
      addressId: addressError || "",
      number: numberError || "",
      cvc: cvcError || "",
      expirationDate: expirationDateError || "",
    });
    if (
      !paymentMethod &&
      !addressError &&
      !numberError &&
      !cvcError &&
      !expirationDateError
    ) {
      submit(
        {
          nameOnCard: formData.name,
          creditCardNumber: formData.number.toString(),
          creditCardExpMonth: formData.expirationDate.split("/")[0],
          creditCardExpYear: `20${formData.expirationDate.split("/")[1]}`,
          creditCardCvc: formData.cvc,
          isDefault: formData.isDefault.toString(),
          oneTimeUse: formData.oneTimeUse.toString(),
          userAddressId: formData.addressId,
          type: "create-payment-method",
        },
        {
          method: "post",
        }
      );
    }

    if (paymentMethod && !expirationDateError) {
      submit(
        {
          paymentMethodId: paymentMethod.paymentMethodId,
          userAddressId: formData.addressId,
          isDefault: formData.isDefault.toString(),
          oneTimeUse: "false",
          nameOnCard: formData.name,
          creditCardExpMonth: formData.expirationDate.split("/")[0],
          creditCardExpYear: `20${formData.expirationDate.split("/")[1]}`,
          type: "update-payment-method",
        },
        {
          method: "put",
        }
      );
    }
  };

  const removePaymentMethod = () => {
    if (!paymentMethod) return;
    submit(
      {
        type: "delete-payment-method",
        paymentMethodId: paymentMethod.paymentMethodId,
      },
      {
        method: "delete",
      }
    );
  };

  useEffect(() => {
    if (actionData?.error) {
      setHideBackendErrorMessage(false);
    }
  }, [actionData?.error]);

  useEffect(() => {
    if (actionData?.error?.timestamp && !hideBackendErrorMessage) {
      const timeout = setTimeout(() => {
        setHideBackendErrorMessage(true);
      }, 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [actionData?.error?.timestamp, hideBackendErrorMessage]);

  const currentMembership = memberships.find(
    (membership) =>
      membership.paymentMethod?.id == paymentMethod?.paymentMethodId
  );

  const { data: cartDetails } = useQuery({
    queryKey: ["/orders/current/details"],
    queryFn: () => get("/orders/current/details"),
  });

  return (
    <div className="relative">
      <div className="sticky top-0 z-10 flex items-center justify-between bg-white py-2 pr-1 shadow">
        <div className="flex-1">
          <IconButton
            size="small"
            style="iconOnly"
            svg={ChevronLeft}
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <div className="flex-1 text-center text-sub2">
          {paymentMethod ? "EDIT CARD" : "ADD NEW CARD"}
        </div>
        <div
          className={cx(
            "flex flex-1 justify-end",
            !isAllCardDataFilled && "invisible"
          )}
        >
          <TextButton onClick={handleSubmit}>Save</TextButton>
        </div>
      </div>

      <div
        className={cx(
          "absolute top-[76px] left-2 right-2 z-10 rounded bg-red py-4 pl-6 text-body2 text-white",
          (!actionData?.error || hideBackendErrorMessage) && "hidden"
        )}
      >
        {actionData?.error?.message}
      </div>

      <div className="my-6 text-center text-sub2 text-primary">
        CARD DETAILS
      </div>

      {paymentMethod && (
        <div className="mx-6 my-2 flex items-center justify-between">
          <div className="flex items-center">
            {getCardBrandIcon(paymentMethod.paymentMethodDetails.cardBrand) && (
              <Icon
                size="large"
                // @ts-expect-error can't be undefined
                svg={getCardBrandIcon(
                  paymentMethod.paymentMethodDetails.cardBrand
                )}
              />
            )}
            <div className="ml-3.5 text-bold1 text-primary">
              {paymentMethod.paymentMethodDetails.cardBrand} *
              {paymentMethod.paymentMethodDetails.displayNumber?.split(" ")[3]}
            </div>
          </div>

          <div className="-ml-3.5">
            <TextButton
              disabled={currentMembership != null}
              onClick={removePaymentMethod}
            >
              <div className="text-red">Remove</div>
            </TextButton>
          </div>
        </div>
      )}

      {paymentMethod && currentMembership != null && (
        <div className="mx-6 my-4 flex flex-row items-center gap-3 bg-background-two px-3 py-3">
          <Icon size="xsmall" svg={Info} color="primaryText" />
          <div className="pt-1 text-body2 text-primary">
            Card is used for a recurring membership
          </div>
        </div>
      )}

      <Form className="mx-6 flex flex-col gap-4">
        <TextField
          inputMode="text"
          label="Name on card"
          value={formData.name}
          onChange={(value) => setFormData({ ...formData, name: value })}
          invalid={!!errors["name"]}
          supportingText={errors["name"]}
        />
        {!paymentMethod && (
          <TextField
            inputMode="numeric"
            maxLength={19}
            label="Card number"
            value={formData.number}
            onChange={(value) =>
              setFormData({ ...formData, number: formatCardNumber(value) })
            }
            rightIcon={getCardBrandByCardNumber(formData.number)}
            invalid={!!errors["number"]}
            supportingText={errors["number"]}
          />
        )}
        <div className="flex gap-6">
          <TextField
            maxLength={5}
            inputMode="numeric"
            label="Expiration date"
            value={formData.expirationDate}
            onChange={(value) =>
              setFormData({
                ...formData,
                expirationDate: value
                  .replace(/[^0-9]/gi, "")
                  .replace(/^(\d\d)(\d)$/g, "$1/$2")
                  .replace(/^(\d\d)(\d\d)$/g, "$1/$2"),
              })
            }
            invalid={!!errors["expirationDate"]}
            placeholder="MM/YY"
            supportingText={errors["expirationDate"]}
          />
          {!paymentMethod && (
            <TextField
              inputMode="numeric"
              label="CVC"
              value={formData.cvc}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  cvc: value.length > 4 ? value.slice(0, 4) : value,
                })
              }
              invalid={!!errors["cvc"]}
              supportingText={errors["cvc"]}
            />
          )}
        </div>
        {location.pathname.includes("/cart/checkout/paymentmethod/new") ? (
          <Checkbox
            label="Save this card for future use"
            disabled={
              cartDetails?.shopDisplayOrderLineItems?.find(
                (item) => item.shopDisplayMembershipId != null
              ) != null
            }
            checked={!formData.oneTimeUse}
            onChange={(checked: boolean) =>
              setFormData({ ...formData, oneTimeUse: !checked })
            }
          />
        ) : (
          <Checkbox
            label="Use as default payment method"
            checked={formData.isDefault}
            onChange={(checked: boolean) =>
              setFormData({ ...formData, isDefault: checked })
            }
          />
        )}
      </Form>

      <div className="mt-4 h-[1px] bg-light-grey"></div>

      <div className="my-6 text-center text-sub2 text-primary">
        BILLING ADDRESS
      </div>

      {addresses && addresses?.length > 0 && (
        <div className="mx-4">
          <AddressSelector
            selected={formData.addressId}
            addresses={addresses}
            onChange={(id: string) =>
              setFormData({ ...formData, addressId: id })
            }
          />
        </div>
      )}

      <div
        className="mx-4 mt-4 mb-6 flex cursor-pointer items-center justify-between rounded border-[1px] border-solid border-light-grey px-4 py-5"
        onClick={() => setIsAddNewAddressModalOpen(true)}
      >
        <div className="text-bold2 text-primary">Add new address</div>

        <Icon svg={ChevronRight} color="brandColor" size="xsmall" />
      </div>

      <AddressModal
        open={isAddNewAddressModalOpen}
        onClose={() => setIsAddNewAddressModalOpen(false)}
        onSave={handleSaveAddress}
      />
    </div>
  );
};
