import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { useMutation } from "@tanstack/react-query";
import cx from "classnames";
import dayjs from "dayjs";
import { useState } from "react";
import {
  Form,
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
  useLocation,
  useNavigate,
  useNavigation,
  useSearchParams,
} from "react-router-dom";

import { get, post } from "api";
import { Models } from "api/types";
import { Button } from "atoms/button/Button";
import { Dropdown } from "atoms/dropdown/Dropdown";
import { IconButton } from "atoms/icon-button/IconButton";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { TextField } from "atoms/text-field/TextField";
import { queryClient } from "pages/Root";
import Services from "services/Services";
import { waitObj } from "toolbox/Promise";

interface FormErrorStates {
  firstName?: boolean;
  lastName?: boolean;
  email?: boolean;
  dob?: boolean;
  location?: boolean;
  referral?: boolean;
}
const load = async (request: Request) => {
  const referralCode = new URL(request.url).searchParams.get("referralCode");
  const platformId = new URL(request.url).searchParams.get("platformId");
  const platformImageUrl = new URL(request.url).searchParams.get(
    "platformImageUrl"
  );
  const locations = !Capacitor.isNativePlatform()
    ? queryClient.fetchQuery(["/platform/locations"], () =>
        get("/platform/locations", {})
      )
    : queryClient.fetchQuery(
        ["multi-platform/platform/{platformId}/locations"],
        () =>
          get("/multi-platform/platform/{platformId}/locations", {
            platformId: platformId!,
          })
      );

  const user = queryClient
    .fetchQuery(["/user"], () => get("/user"))
    .catch(() => null);

  let multiPlatformVerifyToken;
  if (Capacitor.isNativePlatform()) {
    const { value: token } = await Preferences.get({
      key: "multiPlatformVerifyToken",
    });
    multiPlatformVerifyToken = token;
  }

  return waitObj({
    platformImageUrl,
    referralCode,
    locations,
    user,
    multiPlatformVerifyToken,
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const completeProfileLoader: LoaderFunction = async ({ request }) => {
  const data = await load(request);
  if (data.user?.completedProfile) {
    return redirect("/home");
  }
  return json<LoaderData>(data);
};

const isValidEmail = (email: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const CompleteProfilePage = () => {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const {
    platformImageUrl,
    locations,
    referralCode,
    user,
    multiPlatformVerifyToken,
  } = useLoaderData() as LoaderData;
  const [dob, setDob] = useState<string>(
    user?.dateOfBirth ? dayjs(user.dateOfBirth).format("MM/DD/YYYY") : ""
  );

  const locationState = useLocation();

  const [searchParams] = useSearchParams();
  const nearestLocationId = searchParams.get("nearestLocationId");

  const [locationValue, setLocationValue] = useState<string>(
    nearestLocationId || ""
  );
  const [locationValueText, setLocationValueText] = useState<string>(
    (
      (locations as Models<"GetPlatformLocationsResultPagedResults">).items ||
      (locations as Models<"GetMultiPlatformLocationsByPlatformIdResults">)
        .locations
    )?.find((location) => location.id === nearestLocationId)?.name || ""
  );

  const [referralValue, setReferralValue] = useState<string>("");
  const [referralValueText, setReferralValueText] = useState<string>("");
  const referralOptions = [
    { name: "From an Employee", id: "employee" },
    { name: "Email List", id: "email-list" },
    { name: "Facebook", id: "facebook" },
    { name: "Through a Friend", id: "friend" },
    { name: "Other", id: "other" },
  ];

  const userInformation = {
    birthday: user?.dateOfBirth ?? "",
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    email: user?.email ?? "",
    referrerName: user?.referrerName ?? "",
  };

  const [formData, setFormData] = useState(userInformation);
  const [errors, setErrors] = useState<FormErrorStates>({});
  const locationsLength =
    (locations as Models<"GetPlatformLocationsResultPagedResults">).count ||
    (locations as Models<"GetMultiPlatformLocationsByPlatformIdResults">)
      .locations?.length ||
    0;

  const validateForm = () => {
    const formErrors: FormErrorStates = {};
    let isValid = true;

    if (!formData.firstName) {
      formErrors.firstName = true;
      isValid = false;
    }

    if (!formData.lastName) {
      formErrors.lastName = true;
      isValid = false;
    }

    if (!isValidEmail(formData.email)) {
      formErrors.email = true;
      isValid = false;
    }

    if (dob.length < 10) {
      formErrors.dob = true;
      isValid = false;
    }

    if (locationsLength > 1 && !!locationValue === false) {
      formErrors.location = true;
      isValid = false;
    }

    if (!referralValue && !referralCode) {
      formErrors.referral = true;
      isValid = false;
    }

    setErrors(formErrors);

    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      mutation.mutate();
    }
  };

  const mutation = useMutation({
    mutationFn: async () => {
      if (!Capacitor.isNativePlatform()) {
        return post("/user/update", {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          dateOfBirth: formData.birthday,
          signUpSource: referralCode ? "friend" : referralValue,
          platformLocationId:
            locationsLength > 1
              ? locationValue
              : (locations as Models<"GetPlatformLocationsResultPagedResults">)
                  .items?.[0].id,
          referrerName:
            referralValue == "employee" ||
            referralValue == "other" ||
            referralValue == "friend"
              ? formData.referrerName
              : null,
        });
      }

      const { value: mobileUserPhoneNumber } = await Preferences.get({
        key: "phoneNumber",
      });

      return post(
        "/multi-platform/user",
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          dateOfBirth: formData.birthday,
          signUpSource: referralCode ? "friend" : referralValue,
          platformLocationId:
            locationsLength > 1
              ? locationValue
              : (
                  locations as Models<"GetMultiPlatformLocationsByPlatformIdResults">
                ).locations?.[0]?.id || "0",
          referrerName:
            referralValue == "employee" ||
            referralValue == "other" ||
            referralValue == "friend"
              ? formData.referrerName
              : null,
          phoneNumber: mobileUserPhoneNumber,
        },
        multiPlatformVerifyToken
      );
    },
    onSuccess: (data) => {
      if (Capacitor.isNativePlatform()) {
        const token = (data as Models<"CreateMultiPlatformUserResult">).token;
        if (!token) {
          throw new Error("Failed to create user.");
        }

        Services.auth.setAuthToken(token);
        window.localStorage.removeItem("state");
      }

      queryClient.invalidateQueries({
        queryKey: ["/user"],
      });

      if (user?.v1Id) {
        // user is migrated v1, no account created/offer
        navigate("/signin/friend-referral");
      } else {
        navigate("/signin/account-created");
      }
    },
  });

  return (
    <div>
      {platformImageUrl && (
        <div className="sticky top-0 w-full bg-white px-3 pt-[env(safe-area-inset-top)]">
          <span className="flex items-center justify-between">
            <IconButton
              size="small"
              style="iconOnly"
              svg={ChevronLeft}
              onClick={() =>
                locationState?.state
                  ? navigate("/signin/signup/find-medspa/search-location", {
                      state: locationState.state,
                    })
                  : navigate(-1)
              }
            />

            <img src={platformImageUrl} className="h-8" />

            <div className="invisible">
              <IconButton size="small" style="iconOnly" svg={ChevronLeft} />
            </div>
          </span>
        </div>
      )}

      <Form method="post">
        <div
          className={cx(
            "flex flex-col items-center gap-4 px-8 pb-4",
            platformImageUrl
              ? "pt-6"
              : "pt-[calc(24px_+_env(safe-area-inset-top))]"
          )}
        >
          <div className="mt-8 font-serif text-h2 text-primary">
            Complete your profile
          </div>
          <div className="text-center text-body2 text-secondary">
            Tell us a bit more about yourself
          </div>
          <fieldset
            disabled={navigation.state === "submitting"}
            className="flex w-full flex-col pb-5"
          >
            <div className="flex flex-col gap-[0.8rem]">
              <input type="hidden" name="location" value={locationValue} />
              <input type="hidden" name="referral" value={referralValue} />
              <div className="flex gap-3">
                <TextField
                  type="text"
                  label="First Name"
                  name="firstName"
                  invalid={errors.firstName}
                  value={formData.firstName}
                  disabled={user?.firstName != null}
                  onChange={(value) => {
                    setFormData({ ...formData, firstName: value });
                    setErrors({ ...errors, firstName: false });
                  }}
                />
                <TextField
                  type="text"
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  invalid={!!errors.lastName}
                  disabled={user?.lastName != null}
                  onChange={(value) => {
                    setFormData({ ...formData, lastName: value });
                    setErrors({ ...errors, lastName: false });
                  }}
                />
              </div>
              <TextField
                type="text"
                label="Email Address"
                name="email"
                value={formData.email}
                invalid={errors.email}
                onChange={(value) => {
                  setFormData({ ...formData, email: value });
                }}
                onBlur={(value) => {
                  const isValid = !isValidEmail(value);
                  setErrors({ ...errors, email: isValid });
                }}
              />
              <div className="w-[50%]">
                <TextField
                  type="decimal"
                  label="Date of birth"
                  name="dob"
                  value={dob}
                  maxLength={10}
                  placeholder="MM/DD/YYYY"
                  pattern="[0-9]*"
                  invalid={errors.dob}
                  disabled={user?.dateOfBirth != null}
                  onChange={(value: string) => {
                    setDob(
                      value
                        .replace(/[a-z]/gi, "")
                        .replace(/^(\d\d)(\d)$/g, "$1/$2")
                        .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
                    );

                    if (value.length >= 10) {
                      setFormData({
                        ...formData,
                        birthday: new Date(value).toISOString(),
                      });
                      setErrors({ ...errors, dob: false });
                    }
                  }}
                />
              </div>
              {locationsLength > 1 && (
                <div className="pt-2">
                  <Dropdown
                    label="Nearest location"
                    options={
                      (
                        (
                          locations as Models<"GetPlatformLocationsResultPagedResults">
                        ).items ||
                        (
                          locations as Models<"GetMultiPlatformLocationsByPlatformIdResults">
                        ).locations
                      )?.map((location) => ({
                        id: location.id,
                        name: location.name || "",
                      })) || []
                    }
                    value={locationValue}
                    setValue={(value: string) => {
                      setLocationValue(value);
                      setErrors({ ...errors, location: false });
                    }}
                    textValue={locationValueText}
                    setTextValue={setLocationValueText}
                    invalid={errors.location}
                  />
                </div>
              )}
              {!referralCode && (
                <div className="pt-2">
                  <Dropdown
                    label="How did you hear about us?"
                    options={referralOptions}
                    value={referralValue}
                    textValue={referralValueText}
                    setTextValue={setReferralValueText}
                    invalid={!!errors.referral}
                    setValue={(value: string) => {
                      setReferralValue(value);
                      setErrors({ ...errors, referral: false });
                    }}
                  />
                </div>
              )}
              {(referralValue == "employee" ||
                referralValue == "other" ||
                referralValue == "friend") && (
                <div>
                  <TextField
                    type="text"
                    label={
                      referralValue == "employee"
                        ? "Employee Name"
                        : referralValue == "other"
                        ? "Other"
                        : referralValue == "friend"
                        ? "Friend Name"
                        : undefined
                    }
                    name="referral"
                    value={formData.referrerName}
                    onChange={(value) =>
                      setFormData({ ...formData, referrerName: value })
                    }
                  />
                </div>
              )}
              <div className="pt-10">
                <Button fullWidth onClick={handleSubmit}>
                  Complete profile
                </Button>
              </div>
            </div>
          </fieldset>
        </div>
      </Form>
    </div>
  );
};
