import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import { get } from "api";
import { GetResult } from "api/types";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { EmptyState } from "molecules/empty-state/EmptyState";
import { HistoryListEntryProps } from "molecules/history-list-entry/HistoryListEntry";
import TopNav from "molecules/navbars/TopNav";
import { HistoryList } from "organisms/history-list/HistoryList";
import { notNull } from "toolbox/Guards";

const formatVisitHistory = (data: InfiniteData<GetResult<"/user/visits">>) => {
  const visitHistoryDict =
    (data.pages?.flatMap((page) => page.items).filter(notNull) ?? []).reduce(
      (runningVisits, currentVisit) => {
        const date = dayjs(currentVisit.visitDate);
        const key = date.format("YYYY-MM");
        const items = runningVisits[key] ?? [];
        return {
          ...runningVisits,
          [date.format("YYYY-MM")]: [
            ...items,
            {
              id: currentVisit.id,
              title: currentVisit.platformName,
              subtitle: currentVisit.platformLocationName,
              date: currentVisit.visitDate,
            },
          ],
        };
      },
      {} as Record<string, HistoryListEntryProps[]>
    ) ?? {};

  return Object.entries(visitHistoryDict).map(([yearMonthKey, visitItems]) => ({
    date: yearMonthKey,
    items: visitItems,
  }));
};

export const VisitHistoryPage = () => {
  const navigate = useNavigate();
  const { fetchNextPage, data, hasNextPage } = useInfiniteQuery({
    queryKey: ["/user/visits"],
    queryFn: ({ pageParam }) => get("/user/visits", { page: pageParam }),
    getNextPageParam: ({ page, totalPages }) =>
      page < totalPages ? page + 1 : undefined,
  });

  if (!data) return null;

  const isDataAvailable = data.pages[0].count > 0;

  return (
    <div className="relative flex h-full flex-col bg-one">
      <TopNav
        startIconSVG={ChevronLeft}
        onStartIconClick={() => {
          navigate(-1);
        }}
        showHeaderItems={false}
      >
        <div className="flex max-h-[32px] items-center">
          <div className="flex w-full items-center justify-center pr-6 text-sub2 text-primary">
            VISIT HISTORY
          </div>
        </div>
      </TopNav>
      <div className="flex flex-col text-center">
        {!isDataAvailable && (
          <EmptyState
            title="You haven’t checked in yet"
            body="Next time you visit us you can check in and earn rewards by scanning the QR code in store!"
            buttonText="View rewards"
            onClick={() => navigate("/rewards")}
          />
        )}
        <HistoryList
          historyListItems={formatVisitHistory(data)}
          showLoadMoreResultsButton={hasNextPage}
          onLoadMoreClick={() => {
            fetchNextPage();
          }}
        />
      </div>
      <div className="grow" />
      <div className="flex w-full scroll-py-4 flex-col items-center">
        <RepeatMDFooter />
      </div>
    </div>
  );
};
