import cx from "classnames";
import { useEffect, useState } from "react";
import {
  json,
  LoaderFunction,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get } from "api";
import { RewardStatus } from "api/types";
import { Button } from "atoms/button/Button";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as OpenRewardXL } from "atoms/icon/icons/ic_open_reward_XL_ol.svg";
import { ReactComponent as RewardXL } from "atoms/icon/icons/ic_reward_XL_ol.svg";
import ProgressBar from "atoms/progress-bar/ProgressBar";
import { TextButton } from "atoms/text-button/TextButton";
import { AnimateConfetti as animateConfetti } from "organisms/animated-confetti/AnimatedConfetti";
import { AvailableRewardList } from "organisms/available-reward-list/AvailableRewardList";
import { queryClient } from "pages/Root";
import { waitObj } from "toolbox/Promise";
import { queueTaskInBackground } from "utils/queueTaskInBackground";

const load = async ({ request }: LoaderFunctionArgs) => {
  const rewards = queryClient.fetchQuery(["/rewards"], () =>
    get("/rewards", {})
  );
  const platform = queryClient.fetchQuery(["/platform"], () =>
    get("/platform")
  );

  const searchParams = new URL(request.url).searchParams;

  const rewardProgress = searchParams.get("rewardProgress");
  const comingFromScan = searchParams.get("comingFromScan");

  if (!rewardProgress || !Number.isFinite(parseInt(rewardProgress))) {
    throw new Error("Invalid rewardProgress");
  }

  return waitObj({
    rewards,
    platform,
    rewardProgress: parseInt(rewardProgress),
    comingFromScan: comingFromScan === "true",
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const checkoutRewardTrackerLoader: LoaderFunction = async (args) => {
  return json<LoaderData>(await load(args));
};

export const CheckoutRewardTrackerPage = () => {
  const navigate = useNavigate();
  const { rewards, platform, ...data } = useLoaderData() as LoaderData;
  const [rewardProgress, setRewardProgress] = useState(data.rewardProgress);

  const newlyEarnedAvailableRewards = rewards.filter(
    (r) =>
      r.status === RewardStatus.Available && r.earnedByLatestRewardableEvent
  );
  const futureRewards = rewards.filter(
    (r) => r.status === RewardStatus.NotAvailable
  );
  const nextReward = futureRewards.length
    ? futureRewards.reduce(
        (acc, curr) =>
          acc.minimumVisits ?? 0 <= (curr.minimumVisits ?? 0) ? acc : curr,
        futureRewards[0]
      )
    : undefined;

  const redeemableRewardCount = newlyEarnedAvailableRewards.length;
  const progressBarValue =
    redeemableRewardCount > 0 ? 100 : nextReward?.unlockProgressPercent || 0;

  const canShopInApp =
    platform.platformSettings?.marketplaceEnabled ||
    platform.platformSettings?.visitCashValue === 0;

  useEffect(() => {
    setTimeout(() => setRewardProgress(progressBarValue));

    queueTaskInBackground(() => {
      setTimeout(() => progressBarValue >= 100 && animateConfetti(), 1500);
    });
  }, [progressBarValue]);

  return (
    <div className="flex h-full flex-col items-center justify-between gap-7 bg-one px-7 pt-[calc(56px_+_env(safe-area-inset-top))] pb-3">
      <div className="relative flex flex-col gap-7">
        <div className="flex flex-col items-center gap-7">
          <div
            className={cx(
              "flex flex-col items-center gap-7 transition delay-1000",
              rewardProgress == 100 && "opacity-0"
            )}
          >
            <Icon svg={RewardXL} size="xlarge" color="brandColor" />
            <div className="font-serif text-h2">
              {data.comingFromScan ? "Check in successful!" : "Reward tracker"}
            </div>
          </div>
          <div
            className={cx(
              "absolute top-0 flex flex-col items-center gap-7 transition delay-1000",
              rewardProgress != 100 && "opacity-0"
            )}
          >
            <Icon svg={OpenRewardXL} size="xlarge" color="brandColor" />
            <div className="font-serif text-h2">
              {redeemableRewardCount} reward
              {redeemableRewardCount > 1 ? "s" : ""} unlocked!
            </div>
          </div>
        </div>
        <div className="px-3 text-center text-body1 text-secondary">
          {data.comingFromScan
            ? `Check in every visit ${
                canShopInApp ? `or shop in-app` : ""
              } for rewards`
            : "Your purchase has helped you earn towards your next reward!"}
        </div>
        <ProgressBar value={rewardProgress ?? progressBarValue} />
        <AvailableRewardList
          rewards={newlyEarnedAvailableRewards.map((r, index) => ({
            id: index + "",
            title: r.title,
            valueIfBanked: r.valueIfBanked,
            repeatCashEnabled: r.repeatCashEnabled,
            tagDescription: "reward unlocked!",
            disclaimer: r.termsAndConditions,
          }))}
          showTitle={false}
          delay={1000}
        />
      </div>
      <div className="flex w-full flex-col gap-3 py-4">
        {data.comingFromScan && (
          <>
            <Button fullWidth onClick={() => navigate("/rewards")}>
              View my rewards
            </Button>
            <TextButton onClick={() => navigate("/home")}>
              Continue browsing
            </TextButton>
          </>
        )}
        {!data.comingFromScan && (
          <>
            <Button
              fullWidth
              onClick={() => navigate("/account/my-treatments")}
            >
              My treatments
            </Button>
            <TextButton onClick={() => navigate("/rewards")}>
              View my rewards
            </TextButton>
          </>
        )}
      </div>
    </div>
  );
};
