import cx from "classnames";
import { FC, SVGProps } from "react";

import { Icon } from "atoms/icon/Icon";

type TreatmentNotificationType = "monthly" | "signup" | "milestone";
type TreatmentNotificationStatus = "available" | "upcoming" | "received";

interface TreatmentNotificationProps {
  onClick?: () => void;
  text: string;
  description?: string;
  buttonLabel?: string;
  icon?: FC<SVGProps<SVGSVGElement>>;
  status: TreatmentNotificationStatus;
  type: TreatmentNotificationType;
}

export const TreatmentNotification = ({
  onClick,
  text,
  description,
  buttonLabel,
  icon,
  status,
  type,
}: TreatmentNotificationProps) => {
  const colorState =
    type === "monthly" || status === "upcoming"
      ? "basic"
      : ["signup", "milestone"].includes(type) && status === "available"
      ? "reverse"
      : status === "received"
      ? "disabled"
      : "basic";
  return (
    <div
      className={cx(
        "flex cursor-pointer items-center rounded-lg py-4 pl-3 pr-4",
        colorState === "basic" && "bg-brand-tint",
        colorState === "reverse" && "bg-brand-color",
        colorState === "disabled" && "bg-brand-tint opacity-50"
      )}
      onClick={onClick}
    >
      <div className="flex flex-grow items-center">
        <div className="w-6">
          {icon && (
            <Icon
              svg={icon}
              size="xsmall"
              color={
                ["basic", "disabled"].includes(colorState)
                  ? "brandColor"
                  : "white"
              }
            />
          )}
        </div>
        <div
          className={cx(
            "mx-2 flex flex-grow flex-col text-primary",
            ["basic", "disabled"].includes(colorState) && "text-brand-color",
            colorState === "reverse" && "text-white"
          )}
        >
          <div className="text-left text-bold2">{text}</div>
          {description && (
            <div className="text-left text-body2">{description}</div>
          )}
        </div>
      </div>
      {buttonLabel && (
        <div
          className={cx(
            "text-bold2",
            ["basic", "disabled"].includes(colorState) && "text-brand-color",
            colorState === "reverse" && "text-white"
          )}
        >
          {buttonLabel}
        </div>
      )}
    </div>
  );
};
