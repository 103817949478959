import {
  json,
  LoaderFunction,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get } from "api";
import { RewardStatus } from "api/types";
import { RewardProgress } from "molecules/containers/reward-progress/RewardProgress";
import TopNav from "molecules/navbars/TopNav";
import { UserAndCash } from "molecules/subheaders/user-and-cash/UserAndCash";
import { AvailableRewardList } from "organisms/available-reward-list/AvailableRewardList";
import { RedeemedRewardList } from "organisms/redeemed-reward-list/RedeemedRewardList";
import { ReferAFriend } from "organisms/refer-a-friend/ReferAFriend";
import { UpcomingRewardList } from "organisms/upcoming-reward-list/UpcomingRewardList";
import { queryClient } from "pages/Root";
import { waitObj } from "toolbox/Promise";

const load = async () => {
  const visits = queryClient.fetchQuery(["/visit/count"], () =>
    get("/visit/count")
  );
  const userInfo = queryClient.fetchQuery(["/user"], () => get("/user"), {
    staleTime: 1000 * 60 * 2,
  });
  const rewards = queryClient.fetchQuery(["/rewards"], () =>
    get("/rewards", {})
  );
  const platform = queryClient.fetchQuery(["/platform"], () =>
    get("/platform")
  );

  return waitObj({
    visits: visits.then((v) => v.totalVisits),
    userInfo,
    rewards,
    platform,
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const rewardsIndexLoader: LoaderFunction = async () => {
  return json<LoaderData>(await load());
};

const RewardsIndex = () => {
  const { rewards, userInfo, platform } = useLoaderData() as LoaderData;
  const navigate = useNavigate();

  const available = rewards.filter((r) => r.status === RewardStatus.Available);

  const future = rewards.filter((r) => r.status === RewardStatus.NotAvailable);

  const redeemed = rewards.filter(
    (r) =>
      r.status === RewardStatus.Redeemed || r.status === RewardStatus.CashedIn
  );

  const nextReward = future.length
    ? future.reduce(
        (acc, curr) =>
          acc.minimumVisits ?? 0 <= (curr.minimumVisits ?? 0) ? acc : curr,
        future[0]
      )
    : undefined;

  return (
    <div>
      <TopNav>
        <h1 className="text-xl">Rewards</h1>
      </TopNav>
      <UserAndCash
        firstName={userInfo.firstName}
        lastName={userInfo.lastName}
        repeatCashBalance={userInfo.repeatCashBalance}
        hideRepeatCash={!platform.platformSettings?.repeatCashEnabled}
      />
      <RewardProgress
        nextMilestone={nextReward?.minimumVisits || 0}
        rewardsCount={available.length}
        cashToUnlock={nextReward?.cashToUnlock}
        unlockProgressPercent={
          future.length == 0 ? 100 : nextReward?.unlockProgressPercent || 0
        }
        marketplaceEnabled={platform.platformSettings?.marketplaceEnabled}
        visitCashValue={platform.platformSettings?.visitCashValue}
      />

      <div className="flex flex-col gap-8 bg-one py-8 px-4 empty:hidden">
        <AvailableRewardList
          rewards={available.map((r) => ({
            id: r.rewardId,
            title: r.title,
            valueIfBanked: r.valueIfBanked,
            onClick: () => navigate(`/rewards/${r.rewardId}`),
            repeatCashEnabled: r.repeatCashEnabled,
            disclaimer: r.termsAndConditions,
          }))}
        />
        <UpcomingRewardList
          rewards={future.map((r) => ({
            id: r.rewardId,
            title: r.title,
            visitsToUnlock: r.minimumVisits,
            valueIfBanked: r.valueIfBanked,
            repeatCashEnabled: r.repeatCashEnabled,
            disclaimer: r.termsAndConditions,
          }))}
        />
        <RedeemedRewardList
          rewards={redeemed.map((r) => ({
            id: r.rewardId,
            title: r.title,
            earnedRewardStatus: r.status,
            redeemedDateTime: r.redeemedDateTime,
            onClick: () => navigate(`/rewards/${r.rewardId}`),
            disclaimer: r.termsAndConditions,
          }))}
        />
      </div>

      <ReferAFriend withRepeatMDFooter />
    </div>
  );
};

export default RewardsIndex;
