import { ReactComponent as OpenReward } from "atoms/icon/icons/open_reward.svg";
import { ActionModal } from "molecules/modal-window/action-modal/ActionModal";

interface InStoreRedeemedModalProps {
  onClose: () => void;
}

export const InStoreRedeemedModal = ({
  onClose,
}: InStoreRedeemedModalProps) => {
  return (
    <ActionModal
      size="medium"
      open={true}
      headerIconSvg={OpenReward}
      onClose={onClose}
      onFirstButtonClick={onClose}
      firstButtonLabel="View confirmation"
      title="Congratulations! Your reward has been redeemed!"
      description={
        <div className="px-2">
          Click below to see your confirmation and show this to a staff member
          in store
        </div>
      }
    />
  );
};
