import cx from "classnames";

interface CheckboxProps {
  checked?: boolean;
  onChange?: (value: boolean) => void;
  label?: string;
  subtitle?: string;
  disabled?: boolean;
  textStyle?: "text-body2" | "text-bold2";
}

export const Checkbox = ({
  label,
  subtitle,
  checked,
  onChange,
  disabled = false,
  textStyle = "text-body2",
}: CheckboxProps) => {
  return (
    <div className="flex cursor-pointer items-center">
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange?.(e.target.checked)}
        className={cx(
          "m-2.5 h-6 w-6 cursor-pointer rounded border border-solid border-dark-grey bg-white text-brand-color ring-0 ring-transparent focus:ring-0 focus:ring-transparent",
          disabled && "cursor-not-allowed opacity-30"
        )}
      />
      <div className="ml-1.5 flex flex-col gap-0.5 text-left">
        {label && (
          <div
            className={cx(
              `${textStyle} `,
              disabled ? "text-secondary" : "text-primary"
            )}
          >
            {label}
          </div>
        )}
        {subtitle && (
          <div className="text-body2 text-secondary">{subtitle}</div>
        )}
      </div>
    </div>
  );
};
