import { useRef } from "react";
import { QrReader } from "react-qr-reader";

type Props = {
  facingMode: "front" | "back";
  onResult: (code: string) => void;
};

export const QRScannerForWeb = ({ facingMode, onResult }: Props) => {
  return (
    <QrReader
      key={facingMode}
      constraints={{
        facingMode: facingMode === "back" ? "environment" : "user",
      }}
      containerStyle={{ width: "100%", height: "100%", background: "black" }}
      videoStyle={{ width: "100%", height: "100%", objectFit: "cover" }}
      videoContainerStyle={{ width: "100%", height: "100%" }}
      onResult={(result) => {
        if (result) {
          onResult(result.getText());
        }
      }}
    />
  );
};
