import { ReactComponent as OpenReward } from "atoms/icon/icons/open_reward.svg";
import { ActionModal } from "molecules/modal-window/action-modal/ActionModal";

interface UnableToCheckoutModalProps {
  onClose: () => void;
}

export const UnableToCheckoutModal = ({
  onClose,
}: UnableToCheckoutModalProps) => {
  return (
    <ActionModal
      size="small"
      open={true}
      onClose={onClose}
      onFirstButtonClick={onClose}
      firstButtonLabel="Close"
      title="Unable to checkout this cart with RepeatCash"
      description="RepeatCash can't be used to pay for the full value of a membership. Please remove the RepeatCash or membership to continue."
    />
  );
};
