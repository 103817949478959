import { Button } from "atoms/button/Button";

type Props = {
  handleContinue: () => void;
};

export const ConfirmRequestDeleteMyAccountPage = ({
  handleContinue,
}: Props) => {
  return (
    <div className="mx-6 mt-6 flex h-full flex-col">
      <div className="flex-grow px-4 py-2">
        <h1 className="text-title">Delete account request sent</h1>
        <p className="mt-4 text-body1 text-secondary">
          We will review your request and contact you with a confirmation email
          when your account is deleted. If you change your mind you can contact
          us to cancel your request.
          <span className="mt-6 block">
            You can continue to use the app until your account is deleted.{" "}
          </span>
        </p>
      </div>
      <div className="flex w-full flex-col justify-center space-y-3 p-4">
        <Button fullWidth onClick={handleContinue} style="primary">
          Continue
        </Button>
      </div>
    </div>
  );
};
