import cx from "classnames";

import { Button } from "atoms/button/Button";
import { ReactComponent as Close } from "atoms/icon/icons/close.svg";

interface PromotionModalDataProps {
  onClickCTAButton?: () => void;
  CTAButtonText?: string | null;
  title?: string;
  subtitle?: string;
  disclaimer?: string;
}
type PromotionModalProps = {
  open?: boolean;
  onClose?: () => void;
  imageUrl?: string;
} & PromotionModalDataProps;

const PromotionModalData = ({
  title: header,
  subtitle: body,
  disclaimer,
  CTAButtonText,
  onClickCTAButton,
}: PromotionModalDataProps) => (
  <div className="flex w-[326px] flex-col items-center text-white">
    <div className="text-center">
      {header && (
        <div className="mb-2 font-serif text-h1 text-shadow">{header}</div>
      )}
      {body && <div className="text-bold2 text-shadow">{body}</div>}
    </div>
    {CTAButtonText && (
      <div className="my-5 w-40">
        <Button
          style="outline"
          size="medium"
          fullWidth
          onClick={() => {
            if (onClickCTAButton !== undefined) {
              onClickCTAButton();
            }
          }}
        >
          {CTAButtonText}
        </Button>
      </div>
    )}
    {disclaimer && (
      <div className="text-center text-body3 text-[10px] text-white/60 text-shadow">
        {disclaimer}
      </div>
    )}
  </div>
);
export const PromotionModal = (props: PromotionModalProps) => {
  return props.open ? (
    <>
      <div
        className={cx("fixed inset-0 z-40 bg-black/[35%]", {
          "fade-in": props.open,
        })}
        onClick={props.onClose}
        data-testid="div-dismiss-modal"
      />

      <div
        className={cx(
          "fixed left-0 right-0 bottom-0 top-0 z-40 mx-auto my-auto h-[575px] w-[374px]",
          "bg-cover bg-center",
          "rounded-lg",
          {
            "animate-scale-in": props.open,
          }
        )}
        style={{ backgroundImage: `url(${props.imageUrl})` }}
      >
        <div
          className={cx("flex h-full w-full flex-col", "items-center ", {
            "rounded-lg bg-gradient-to-b from-transparent via-[#0000004F] to-[#000000A5]":
              props.CTAButtonText ||
              props.title ||
              props.disclaimer ||
              props.subtitle,
          })}
        >
          <div className="w-[374px] p-2.5">
            <button onClick={props.onClose} className="rounded-3xl bg-white/50">
              <Close className="h-8 w-8 p-[5.5px]" />
            </button>
          </div>
          <div className="absolute bottom-6">
            <PromotionModalData {...props} />
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
