import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { datadogRum } from "@datadog/browser-rum-slim";
import { useMutation, useQuery } from "@tanstack/react-query";
import cx from "classnames";
import { FC, ReactNode, SVGProps, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { get, post } from "api";
import { IconButton } from "atoms/icon-button/IconButton";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Cart } from "atoms/icon/icons/cart.svg";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right_small.svg";
import { ReactComponent as Search } from "atoms/icon/icons/ic_search.svg";
import { ReactComponent as Warning } from "atoms/icon/icons/ic_warning_ol.svg";
import useDunningMembership from "hooks/useDunningMemberships";
import { AdminImpersonationBanner } from "molecules/admin-impersonation-banner/AdminImpersonationBanner";
import Services from "services/Services";
import { buildPlatformUrl, setPromotionModalSeen } from "utils/functions";

type BaseProps = {
  children?: ReactNode;
  footer?: ReactNode;
  startIconSVG?: undefined;
  onStartIconClick?: undefined;
  hideShadow?: boolean;
  showHeaderItems?: boolean;
  /** Used for HomePage.tsx, because of the way we set TopNav on every page.
   * To remove after setting TopNav at a higher level(AppLayout, where Footer also is).
   */
  withAffirmOnTop?: boolean;
};

type WithIconProps = {
  startIconSVG: FC<SVGProps<SVGSVGElement>>;
  onStartIconClick: () => void;
} & Omit<BaseProps, "startIconSVG" | "onStartIconClick">;

type Props = BaseProps | WithIconProps;

const TopNav = ({
  children,
  footer,
  startIconSVG,
  onStartIconClick,
  hideShadow = false,
  showHeaderItems = true,
  withAffirmOnTop,
}: Props) => {
  const navigate = useNavigate();
  const { data: cartDetails } = useQuery({
    queryKey: ["/orders/current/summary"],
    queryFn: () => get("/orders/current/summary"),
  });

  const { dunningMembership } = useDunningMembership();

  const { data: authClaims } = useQuery({
    queryKey: ["/auth/claims"],
    queryFn: () => get("/auth/claims"),
  });

  const { data: platform } = useQuery({
    queryKey: ["/platform"],
    queryFn: () => get("/platform"),
  });
  const isAdminImpersonation = !!authClaims?.claims.adminUserId;

  const { data: user } = useQuery({
    queryKey: ["/user"],
    queryFn: () => get("/user"),
    staleTime: 1000 * 60 * 2,
  });
  useEffect(() => {
    if (user?.isBlocked) {
      setPromotionModalSeen(false);
      Services.auth.setAuthToken(null);
      datadogRum.clearUser();
      navigate("/signin");
    }
  }, [user?.isBlocked]);
  const url = buildPlatformUrl(
    platform?.scheme,
    platform?.subdomain,
    platform?.host
  );

  const isNativePlatform = Capacitor.isNativePlatform();

  const handleUpdatePayment = async (membershipId: string) => {
    if (isNativePlatform) {
      handleUpdatePaymentOnCapacitor(membershipId);
    } else {
      updatePaymentMutation.mutate(membershipId);
    }
  };

  const updatePaymentMutation = useMutation({
    mutationFn: (platUserMembId: string) =>
      post(
        "/platform-user-memberships/{platformUserMembershipId}/create-stripe-update-session",
        {
          platformUserMembershipId: platUserMembId,
          successUrl: url,
          cancelUrl: url,
        }
      ),
    onSuccess: (response) => {
      if (response && response.stripeSession.url) {
        window.location.href = response.stripeSession.url;
      } else {
        console.log("error ocurred");
      }
    },
  });

  const handleUpdatePaymentOnCapacitor = async (membershipId: string) => {
    const response = await post(
      "/platform-user-memberships/{platformUserMembershipId}/create-stripe-update-session",
      {
        platformUserMembershipId: membershipId,
        successUrl: `${url}/stripe-landing/close-browser?successfulPayment=true`,
        cancelUrl: `${url}/stripe-landing/close-browser?successfulPayment=false`,
      }
    );

    Browser.addListener("browserFinished", async () => {
      Browser.removeAllListeners();
      window.scroll(0, 0);
    });

    await Browser.open({ url: response.stripeSession.url });
  };

  return (
    <div
      className={cx(
        "sticky top-0 z-20 bg-white",
        withAffirmOnTop
          ? "top-[env(safe-area-inset-top)]"
          : "pt-[env(safe-area-inset-top)]",
        hideShadow ? "" : "shadow-[0px_2px_4px_rgba(0,0,0,0.05)]"
      )}
    >
      {isAdminImpersonation && !!user && (
        <AdminImpersonationBanner
          platformUserDisplayName={
            user.email || (user.firstName && user.lastName)
              ? user.email || `${user.firstName} ${user.lastName}`
              : user.phoneNumber
          }
        />
      )}
      {dunningMembership.status == true && (
        <div className="sticky top-0 z-10 flex items-center justify-between bg-red py-3 pl-4 pr-3 text-bold2 text-white">
          <div className="flex gap-2">
            <Icon svg={Warning} size="x2small" color="white" />
            Membership payment failed
          </div>
          <div
            className="flex"
            onClick={() => handleUpdatePayment(dunningMembership.id)}
          >
            Update
            <Icon svg={ChevronRight} size="x2small" color="white" />
          </div>
        </div>
      )}

      <div
        className={cx(
          "flex h-[54px] items-center pt-[5px] pb-[5px] font-bold",
          startIconSVG ? "pr-5" : "px-5"
        )}
      >
        {startIconSVG && (
          <IconButton
            svg={startIconSVG}
            size="small"
            style="iconOnly"
            onClick={onStartIconClick}
          />
        )}
        <div className="flex-1">{children}</div>
        {showHeaderItems && platform?.platformSettings?.marketplaceEnabled && (
          <>
            <div className="px-1.5">
              <NavLink to="/search">
                <Icon svg={Search} />
              </NavLink>
            </div>
            <div className="flex gap-3 px-1.5">
              <NavLink to="/cart">
                <div className="relative">
                  <Icon svg={Cart} />
                  {cartDetails &&
                    cartDetails.numShopDisplayOrderLineItems != null &&
                    cartDetails.numShopDisplayOrderLineItems > 0 && (
                      <div className="absolute -top-1 -right-2 inline-flex h-5 w-5 items-center justify-center rounded-full border-2 border-white bg-brand-color text-xs font-bold text-white">
                        {cartDetails.numShopDisplayOrderLineItems}
                      </div>
                    )}
                </div>
              </NavLink>
            </div>
          </>
        )}
      </div>
      <div>{footer}</div>
    </div>
  );
};

export default TopNav;
