import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PromotionModal } from "molecules/promotion-modal/PromotionModal";
import { getPromotionModalSeen, setPromotionModalSeen } from "utils/functions";
interface ShowSiteWidePromotionProps {
  promotion: {
    title: string;
    description: string;
    imageUrl?: string | null;
    callToActionText?: string | null;
    disclaimer?: string | null;
    backgroundColor?: string | null;
    textColor?: string | null;
  };
  categoryId: string;
}
const ShowSiteWidePromotion = ({
  promotion,
  categoryId,
}: ShowSiteWidePromotionProps) => {
  const promotionModalShown = getPromotionModalSeen();
  const [showModal, setShowModal] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();

  const preloadImage = (url: string) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setImageLoaded(true);
    };
    img.onerror = () => {
      setImageLoaded(true);
    };
  };

  useEffect(() => {
    preloadImage(promotion.imageUrl || "");
    if (!promotionModalShown && imageLoaded) {
      const timer = setTimeout(() => {
        setShowModal(true);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [promotionModalShown, imageLoaded]);

  const handleCloseModal = () => {
    setShowModal(false);
    setPromotionModalSeen(true);
  };

  return (
    <PromotionModal
      open={showModal}
      onClose={handleCloseModal}
      CTAButtonText={promotion.callToActionText}
      onClickCTAButton={() => {
        navigate(`/shop/custom-category/${categoryId}`);
        setPromotionModalSeen(true);
      }}
      title={promotion.title || ""}
      subtitle={promotion.description || ""}
      disclaimer={promotion.disclaimer || ""}
      imageUrl={promotion.imageUrl ?? ""}
    />
  );
};

export default ShowSiteWidePromotion;
