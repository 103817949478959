import { Capacitor } from "@capacitor/core";
import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet";

import { get } from "api";

const Favicon = () => {
  const { data: platform } = useQuery({
    queryKey: ["/platform/current"],
    queryFn: () =>
      get("/platform/current").catch(() => {
        if (!Capacitor.isNativePlatform()) {
          window.location.href = "https://www.repeatmd.com";
        }
      }),
    select: (data) => data,
  });

  return (
    <Helmet>
      <title>{platform?.name}</title>
      <link rel="icon" href={platform?.faviconUrl} />
      <link rel="apple-touch-icon" href={platform?.faviconUrl} />
    </Helmet>
  );
};

export default Favicon;
