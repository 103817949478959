import cx from "classnames";
import { type KeyboardEvent, useState } from "react";

import { ReactComponent as Reset } from "atoms/icon/icons/ic_close_small_ol.svg";
import { ReactComponent as Search } from "atoms/icon/icons/ic_search.svg";
import { TextField } from "atoms/text-field/TextField";

type Props = {
  placeholder?: string;
  label?: string;
  value?: string;
  disabled?: boolean;
  name?: string;
  onChange?: (value: string) => void;
  autoFocus?: boolean;
  pattern?: string;
  onBlur?: (value: string) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;

  /** Handler that is called when either the left or right icons are clicked */
  onIconClick?: (side: "left" | "right") => void;

  /** Specify the testid for current component */
  "data-testid"?: string;
};

export const SearchField = ({
  name,
  value,
  onChange,
  disabled = false,
  onIconClick,
  placeholder,
  label,
  autoFocus = false,
  pattern,
  onBlur,
  onKeyDown,
  "data-testid": dataTestId,
}: Props) => {
  return (
    <label className="relative block grow text-body1 text-secondary">
      <Search
        className="absolute top-2 left-3 h-6 w-6 cursor-pointer fill-[theme(textColor.secondary)]"
        onClick={() => onIconClick?.("left")}
      />
      <TextField
        leftIcon={Search}
        type="search"
        label={label}
        onChange={(value: string) => onChange?.(value)}
        value={value}
        name={name}
        pattern={pattern}
        data-testid={dataTestId}
        autoFocus={autoFocus}
        placeholder={placeholder}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        disabled={disabled}
        inputMode="search"
      />
      {value && (
        <Reset
          className="absolute top-4 right-3 mt-1 h-6 w-6 cursor-pointer fill-[theme(textColor.secondary)] peer-aria-invalid:fill-red"
          onClick={() => onIconClick?.("right")}
        />
      )}
    </label>
  );
};
