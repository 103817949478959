import { FC, SVGProps } from "react";
import { Link } from "react-router-dom";

import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Right } from "atoms/icon/icons/chevron_right_small.svg";
import { BadgeTag } from "atoms/tag/BadgeTag";
import { DescriptiveTag } from "atoms/tag/DescriptiveTag";
import { TextButton } from "atoms/text-button/TextButton";
import { formatCurrency } from "toolbox/Money";

type AllOrNone<T> = T | { [K in keyof T]?: undefined };

type BtnProps = AllOrNone<{
  /** Primary button text to display below the carousel */
  primary: string;

  /** Handler that is called when the primary button is clicked */
  onButtonClick: () => void;
}>;

type Card = {
  /** Route that this card will link to */
  to: string;

  /** Source url of the banner image */
  imageSrc: string;

  /** Descriptive tag text that displays below banner image */
  tag: string | null | undefined;

  /** The main text of this card */
  title: string;
};

interface PriceCard extends Card {
  /** The regular price of this card's product */
  price: number;

  /** The member price of this card's product */
  memberPrice?: number | null;
}

interface PromoCard extends Card {
  /** Alert-style tag that shows up on the upper left of the card */
  alert?: string | null;

  /** Navigation text that appears below the title */
  primary: string;
}

type Props = {
  /** SVG icon to render at the very top of the carousel */
  svg?: FC<SVGProps<SVGSVGElement>> | null;

  /** Allcaps text that appears at the top of the carousel */
  title: string;

  /** Descriptive text that appears below the title */
  description?: string | null;

  /** Information to display in the carousel cards */
  cards: (PriceCard | PromoCard)[];
};

const PriceFooter = (props: PriceCard) => (
  <div className="flex">
    <div className="mr-auto flex items-center gap-2">
      <span className="text-body2">{formatCurrency(props.price)}</span>
      {!!props.memberPrice && (
        <span className="border-l border-black pl-2 text-bold2">
          {formatCurrency(props.memberPrice)} Member
        </span>
      )}
    </div>
    <Icon svg={Right} size="xsmall" color="brandColor" />
  </div>
);

const PromoFooter = (props: PromoCard) => (
  <div className="flex items-center justify-between text-bold2 text-brand-color">
    {props.primary}
    <Icon svg={Right} size="xsmall" color="brandColor" />
  </div>
);

const ProductCarousel = (props: Props & BtnProps) => {
  return (
    <div className="flex w-full flex-col items-center gap-6 px-4 pt-6 pb-8">
      <div className="flex flex-col items-center gap-4 px-2 text-center">
        {props.svg && <Icon size="xsmall" svg={props.svg} color="brandColor" />}

        <div className="text-sub1 uppercase text-brand-color">
          {props.title}
        </div>

        {props.description && (
          <div className="text-body1 text-secondary">{props.description}</div>
        )}
      </div>

      <div className="w-full">
        <ul className="-m-4 flex gap-6 overflow-x-scroll p-4">
          {props.cards.map((card) => (
            <li
              key={card.to}
              className="w-56 shrink-0 overflow-clip rounded-lg shadow-md"
            >
              <Link className="relative flex h-full flex-col" to={card.to}>
                {"alert" in card && card.alert && (
                  <div className="absolute left-3 top-3">
                    <BadgeTag color="alert">{card.alert}</BadgeTag>
                  </div>
                )}
                <img
                  src={card.imageSrc}
                  className="aspect-[4/3] w-full rounded-t-lg object-cover"
                />
                <div className="flex flex-grow flex-col gap-4 rounded-b-lg bg-white p-3">
                  {card.tag && (
                    <DescriptiveTag color="secondary" size="small">
                      {card.tag}
                    </DescriptiveTag>
                  )}
                  <h3 className="flex-grow break-words font-serif text-h3 line-clamp-2">
                    {card.title}
                  </h3>
                  {"primary" in card ? (
                    <PromoFooter {...card} />
                  ) : (
                    <PriceFooter {...card} />
                  )}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      {props.primary && (
        <TextButton endIconSVG={Right} onClick={() => props.onButtonClick()}>
          {props.primary}
        </TextButton>
      )}
    </div>
  );
};

export default ProductCarousel;
