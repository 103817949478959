import cx from "classnames";
import {
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get } from "api";
import { Button } from "atoms/button/Button";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { queryClient } from "pages/Root";
import { waitObj } from "toolbox/Promise";

const load = async (referralCode: string) => {
  const platform = queryClient.fetchQuery(["/platform/current"], () =>
    get("/platform/current").catch(() => null)
  );

  const code = queryClient.fetchQuery(["/referral-codes/{referralCode}"], () =>
    get("/referral-codes/{referralCode}", { referralCode })
  );

  return waitObj({
    referralCode: code.then((c) => c.code),
    platform,
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const friendReferralLandingLoader: LoaderFunction = async ({
  params: { referralCode },
}) => {
  if (!referralCode) return redirect("/signin");
  return json<LoaderData>(await load(referralCode));
};

export const FriendReferralLandingPage = () => {
  const navigate = useNavigate();
  const { platform, referralCode } = useLoaderData() as LoaderData;

  return (
    <div>
      <div
        className={cx(
          "aspect-[390/350]",
          "flex-col",
          "bg-[url('assets/images/FriendReferralImage.png')]",
          "bg-cover",
          "bg-center"
        )}
      ></div>
      <div className="flex flex-col items-center justify-center gap-5 p-7 text-center">
        <img className="w-[40%] pb-3" src={platform?.logoImageUrl || ""} />
        <div className="px-5 text-h2 text-primary">
          You have friends in high places!
        </div>
        <div className="text-body2 text-secondary">
          Sign up below to unlock your free referral reward!
        </div>
        <Button
          fullWidth
          onClick={() => navigate(`/signin?referralCode=${referralCode}`)}
        >
          Sign up to claim
        </Button>
      </div>
      <div className="pt-3">
        <RepeatMDFooter showLinks={true} />
      </div>
    </div>
  );
};
