import { GetResult } from "api/types";
import { Models } from "api/types";

export const getProductTitle = (
  productName: string | null | undefined,
  variantName: string | null | undefined
) => {
  if (!productName && !variantName) {
    return "";
  }
  return productName &&
    variantName &&
    productName.toLocaleLowerCase() != variantName.toLocaleLowerCase()
    ? productName + " - " + variantName
    : variantName ?? "";
};

export const checkIfListingsHaveConcerns = (
  listings: GetResult<"/listings"> | undefined
) => {
  if (!listings || listings.length == 0) return false;
  let concernsAvailable = false;
  listings.map((item) => {
    if (item.concernIds.length > 0) {
      concernsAvailable = true;
    }
  });
  return concernsAvailable;
};

export const getMembershipRewardGroupSubheader = (
  monthsBeforeFirstReward: number | null | undefined
) => {
  if (monthsBeforeFirstReward == null || monthsBeforeFirstReward == undefined)
    return "";

  const timePeriod = monthsBeforeFirstReward == 0 ? "day" : "month";
  const dayNumberWithOrdinal = getNumberWithOrdinal(
    monthsBeforeFirstReward == 0 ? 1 : monthsBeforeFirstReward
  );

  return dayNumberWithOrdinal + " " + timePeriod;
};

export const getNumberWithOrdinal = (n: number | null | undefined) => {
  if (n == null || n == undefined) return "";
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getProductTitleQuantity = (
  quantity: number | null | undefined,
  packageUnitType: Models<"PackageUnitTypeModel"> | null | undefined,
  removeParenthesis?: boolean
) => {
  if (!quantity || !packageUnitType) {
    return "";
  }

  if (removeParenthesis && quantity > 1)
    return `${quantity} ${packageUnitType.pluralUnitType}`;

  return quantity > 1 ? `(${quantity} ${packageUnitType.pluralUnitType})` : "";
};

export const getBookAppointmentAction = (
  url?: string | null | undefined,
  phoneNumber?: string | null | undefined,
  email?: string | null | undefined
) => {
  if (!url && !phoneNumber && !email) return "";
  return (
    url || (phoneNumber && `tel:${phoneNumber}`) || (email && `mailto:${email}`)
  );
};
