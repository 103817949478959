import cx from "classnames";
import { useEffect, useRef, useState } from "react";

import { Icon } from "atoms/icon/Icon";
import { ReactComponent as DownArrow } from "atoms/icon/icons/chevron_down_small.svg";
import { ReactComponent as UpArrow } from "atoms/icon/icons/chevron_up_small.svg";

type DropdownProps = {
  /** This is both the input label and the placeholder text */
  label?: string;
  value?: string | number;
  options: { id: string; name: string }[];
  setValue: (value: string) => void;
  textValue?: string;
  setTextValue: (value: string) => void;
  disableClick?: boolean;
  invalid?: boolean;
};

export const Dropdown = ({
  label,
  value,
  options,
  setValue,
  textValue,
  setTextValue,
  disableClick,
  invalid,
}: DropdownProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const closeDropdown = () => {
    setShowDropdown((value) => !value);
  };
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <div className="relative" ref={ref}>
      {value && (
        <span className="absolute -top-2 left-3 z-10 whitespace-nowrap bg-white px-1 text-xs text-secondary">
          {label}
        </span>
      )}
      <div
        className={cx(
          "text-body flex justify-between rounded border p-3",
          invalid
            ? "border-red text-red ring-red"
            : showDropdown
            ? "border-brand-color"
            : "border-light-grey",
          textValue ? "text-primary" : "text-secondary"
        )}
        onClick={closeDropdown}
      >
        {textValue || label}{" "}
        <Icon
          svg={showDropdown ? UpArrow : DownArrow}
          color={invalid ? "red" : "primaryText"}
          size="xsmall"
        />
      </div>
      {showDropdown && (
        <div className="absolute z-40 w-full border bg-white shadow">
          <ul className="max-h-[150px] overflow-scroll">
            {options.map((location) => (
              <li
                key={location.id}
                className="p-3 py-2 text-body1 hover:bg-light-grey"
                onClick={() => {
                  if (disableClick) {
                    return false;
                  }
                  setValue(location.id);
                  setTextValue(location.name);
                  closeDropdown();
                }}
              >
                {location.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
