import { useIsMutating } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigation } from "react-router-dom";

/**
 * Custom hook that tracks whether we're in a pending global loading state
 */
const useGlobalLoading = () => {
  const [loading, setLoading] = useState(false);
  const isMutating = useIsMutating();
  const { state: navState } = useNavigation();

  useEffect(() => {
    if (isMutating || navState !== "idle") {
      const id = setTimeout(() => setLoading(true), 1000);
      return () => clearTimeout(id);
    } else {
      setLoading(false);
    }
  }, [isMutating, navState]);

  return loading;
};

const LoadingLayout = () => {
  const loading = useGlobalLoading();

  return (
    <>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-white/60">
          <div className="h-8 w-8 animate-spin rounded-full border-2 border-brand-color border-b-transparent" />
        </div>
      )}
    </>
  );
};

export default LoadingLayout;
