import { ModalSelector } from "molecules/containers/modal-selector-list/entry/ModalSelector";
import { ListModalSize } from "molecules/modal-window/list-modal/ListModal";
import { ListModal } from "molecules/modal-window/list-modal/ListModal";
import { formatCurrency } from "toolbox/Money";

type BaseSelectModalProps = {
  open?: boolean;
  onClose: () => void;
  title: string;
  subtitle?: React.ReactNode;
  size?: ListModalSize;
  type: "sort" | "filter" | "products" | undefined;
  selectionType?: "single" | "multiple" | undefined;
  items: {
    id: string;
    title: string;
    priceOrResults?: number | undefined;
    membershipPrice?: number | null;
    description?: string | null;
    subtitle?: string;
    disabled?: boolean;
  }[];
  checkedItems: string[] | string;
  onSelect: (value: string) => void;
  onFirstButtonClick: () => void;
  firstButtonLabel: string;
  firstButtonDisabled?: boolean;
  onSecondButtonClick?: undefined;
  secondButtonLabel?: undefined;
  secondButtonDisabled?: boolean;
};

type WithSecondButtonProps = {
  onSecondButtonClick: () => void;
  secondButtonLabel: string;
} & Omit<BaseSelectModalProps, "secondButtonLabel" | "onSecondButtonClick">;

type SelectModalProps = BaseSelectModalProps | WithSecondButtonProps;

export const ModalSelectorList = ({
  open,
  onClose,
  title,
  subtitle,
  type,
  selectionType,
  size,
  items,
  checkedItems,
  onSelect,
  onFirstButtonClick,
  firstButtonLabel,
  firstButtonDisabled,
  onSecondButtonClick,
  secondButtonLabel,
  secondButtonDisabled,
}: SelectModalProps) => {
  const setLabel = (
    description: string | null | undefined,
    membershipPrice: number | null | undefined,
    priceOrResults: number | undefined
  ) => {
    if (type === "products" && priceOrResults) {
      if (!description && membershipPrice) {
        return (
          <>
            <span className="text-body2">
              {formatCurrency(priceOrResults)} |{" "}
            </span>
            <span className="text-body2 text-primary">
              {formatCurrency(membershipPrice)} Member
            </span>
          </>
        );
      }
      return (
        <span className="text-body2">{formatCurrency(priceOrResults)}</span>
      );
    } else if (type === "filter") {
      return (
        <span className="text-body2">
          {priceOrResults ?? 0}{" "}
          {(priceOrResults ?? 0) === 1 ? "result" : "results"}
        </span>
      );
    }
  };

  const modalProps = {
    open,
    onClose,
    size,
    title,
    subtitle,
    firstButtonLabel,
    onFirstButtonClick,
    firstButtonDisabled,
  };

  const content = (
    <>
      {subtitle && <div className="p-4 pb-3 text-center">{subtitle}</div>}
      <div className="px-4">
        {items?.map((item) => {
          return (
            <ModalSelector
              key={item.id}
              title={item.title || ""}
              checked={checkedItems?.includes(item.id)}
              description={item.description}
              onChange={() => {
                onSelect(item.id);
              }}
              label={setLabel(
                item.description,
                item.membershipPrice,
                item.priceOrResults
              )}
              type={
                type === "filter"
                  ? "checkbox"
                  : selectionType === "multiple"
                  ? "checkbox"
                  : "radio"
              }
              subtitle={item.subtitle}
              disabled={item.disabled}
            />
          );
        })}
      </div>
    </>
  );

  if (secondButtonLabel && onSecondButtonClick) {
    return (
      <ListModal
        {...modalProps}
        secondButtonLabel={secondButtonLabel}
        onSecondButtonClick={onSecondButtonClick}
        secondButtonDisabled={secondButtonDisabled}
      >
        {content}
      </ListModal>
    );
  }
  return <ListModal {...modalProps}>{content}</ListModal>;
};
