import cx from "classnames";
import { FC, SVGProps } from "react";

import { Models } from "api/types";
import { Icon } from "atoms/icon/Icon";
import { ExpandableItems } from "molecules/expandable-items/ExpandableItems";
import { IfFlag } from "services/Flags";
import {
  getMembershipRewardGroupSubheader,
  getProductTitle,
  getProductTitleQuantity,
} from "services/HelperService";

export const IncludedMonthlyRewards = ({
  freeMembershipRewards,
  description,
  inactiveMembership,
  periodMonths,
  svg,
  monthsBeforeFirstReward,
}: {
  freeMembershipRewards: Models<"MembershipRewardModel">[];
  description?: string;
  inactiveMembership?: boolean | null;
  periodMonths?: number | null;
  svg: FC<SVGProps<SVGSVGElement>>;
  monthsBeforeFirstReward?: number | null;
}) => {
  return (
    <div className="mb-1 bg-white px-6 py-8">
      <div className="mb-6 text-center">
        <div className="flex w-full justify-center">
          <Icon
            color={inactiveMembership ? "darkGrey" : "brandColor"}
            size="xsmall"
            svg={svg}
          />
        </div>
        <div
          className={cx(
            "py-4 text-sub1",
            inactiveMembership ? "text-dark-grey" : "text-brand-color"
          )}
        >
          ENJOY ALL OF THE FOLLOWING <br />
          EVERY
          {periodMonths === 12
            ? " YEAR!"
            : periodMonths === 1
            ? " MONTH!"
            : ` ${periodMonths} MONTHS!`}
        </div>
        <IfFlag flag="timeDelayedTreatmentGroups">
          <div className="text-secondary">
            Starting on your{" "}
            <span className="text-bold1">
              {getMembershipRewardGroupSubheader(monthsBeforeFirstReward)}
            </span>{" "}
            as a member
          </div>
        </IfFlag>
        {description && (
          <div
            className={cx(
              "text-body1",
              inactiveMembership ? "text-dark-grey" : "text-secondary"
            )}
          >
            {description}
          </div>
        )}
      </div>

      <ExpandableItems
        items={freeMembershipRewards.map((mr) => ({
          id: mr.id,
          title:
            getProductTitle(mr.packageListingTitle, mr.packageName) +
            " " +
            getProductTitleQuantity(mr.quantity, mr.packageUnitType),
          description: mr.packageDescription,
        }))}
        disabled={inactiveMembership || null}
      />
    </div>
  );
};
