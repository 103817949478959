import { useQuery } from "@tanstack/react-query";

import { get } from "api";
import ProgressBar from "atoms/progress-bar/ProgressBar";
import ScanInButton from "atoms/scan-in-button/ScanInButton";
import { YouHaveScannedBanner } from "organisms/you-have-scanned-banner/YouHaveScannedBanner";
import { formatCurrency } from "toolbox/Money";
import pluralize from "utils/pluralize";

export type RewardProgressProps = {
  rewardsCount: number;
  nextMilestone: number;
  unlockProgressPercent: number;
  cashToUnlock?: number;
  marketplaceEnabled?: boolean;
  visitCashValue?: number;
};

export const RewardProgress = ({
  rewardsCount,
  nextMilestone,
  unlockProgressPercent,
  cashToUnlock,
  marketplaceEnabled,
  visitCashValue,
}: RewardProgressProps) => {
  const { data: userCanCheckIn } = useQuery({
    queryKey: ["/user/check-in"],
    queryFn: () => get("/user/check-in"),
  });

  const getTitle = (): string | undefined => {
    if (!marketplaceEnabled || visitCashValue === 0) {
      return `Just ${nextMilestone} ${pluralize(
        nextMilestone,
        "visit",
        "visits"
      )} until your next reward!`;
    }

    if (nextMilestone) {
      return `${nextMilestone} more ${pluralize(
        nextMilestone,
        "visit",
        "visits"
      )} ${
        cashToUnlock && `or spend ${formatCurrency(cashToUnlock)}`
      } for next reward!`;
    }

    if (rewardsCount > 0) {
      return `${rewardsCount} ${
        rewardsCount === 1 ? "reward" : "rewards"
      } available!`;
    }

    return undefined;
  };

  return (
    <div className="flex flex-col items-center gap-6 px-5 pt-4 pb-8 text-center">
      {getTitle() && (
        <>
          <h2 className="font-serif text-h2 text-primary">{getTitle()}</h2>
          <ProgressBar value={unlockProgressPercent} />
        </>
      )}
      <div className="font-sans text-base font-normal leading-[22px] text-secondary">
        {!marketplaceEnabled || visitCashValue === 0
          ? "Check in every visit for rewards"
          : "Check in every visit or shop in-app for rewards"}
      </div>
      {userCanCheckIn ? <ScanInButton /> : <YouHaveScannedBanner />}
    </div>
  );
};
