import {
  Treatment,
  TreatmentProps,
} from "molecules/containers/treatment/Treatment";

export interface TreatmentListProps {
  treatments: TreatmentProps[];
}

export const TreatmentOrderedList = ({ treatments }: TreatmentListProps) => {
  if (!treatments.length) return null;

  return (
    <div>
      <div className="divide-y divide-solid divide-background-two overflow-clip rounded-lg shadow-subtle">
        {treatments.map((treatment) => (
          <Treatment key={treatment.id} {...treatment} />
        ))}
      </div>
    </div>
  );
};
