import cx from "classnames";
import {
  json,
  LoaderFunction,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get } from "api";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { ShopMembershipListItem } from "molecules/containers/shop-membership-list-item/ShopMembershipListItem";
import { queryClient } from "pages/Root";
import { waitObj } from "toolbox/Promise";

import { ShopListHeader } from "./ShopListHeader/ShopListHeader";

const load = async () => {
  const memberships = queryClient.fetchQuery(["/memberships"], () =>
    get("/memberships")
  );
  const categories = queryClient.fetchQuery(["shop/categories"], () =>
    get("/shop/categories")
  );

  return waitObj({ memberships, categories });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const shopMembershipsLoader: LoaderFunction = async () => {
  return json<LoaderData>(await load());
};

const ShopMembershipsPage = () => {
  const { memberships, categories } = useLoaderData() as LoaderData;
  const navigate = useNavigate();

  const membershipsCategory = categories.find(
    (c) => c.defaultCategoryId === "memberships"
  );

  return (
    <div className="flex flex-col bg-two">
      {membershipsCategory && (
        <ShopListHeader
          title={membershipsCategory.title}
          subtitle={membershipsCategory.description}
          bgGradient
          // TODO(CAA-158) implement a backgroundImageUrl
          backgroundColor="rgb(var(--repeatmd-brand-color))"
        />
      )}
      <div className="flex flex-col px-4 py-2">
        <div className="flex flex-col gap-4">
          {memberships?.map((membership) => {
            return (
              <ShopMembershipListItem
                key={membership.id}
                title={membership.name}
                includedList={membership.membershipBenefits.map(
                  (benefit) => benefit.description
                )}
                imageUrl={membership.imageUrl}
                price={membership.monthlyCost}
                onClick={() => navigate(`/pdp/membership/${membership.id}`)}
              />
            );
          })}
        </div>
      </div>
      <RepeatMDFooter />
    </div>
  );
};

export default ShopMembershipsPage;
