import cx from "classnames";
import { ReactNode, useEffect, useMemo, useState } from "react";

import { ReactComponent as Close } from "atoms/icon/icons/close.svg";

type MessageState = {
  content: ReactNode;
  type: "success" | "warning" | "info" | "error";
  dismissable?: boolean;
  timeout?: number | null;
};

type MessageOpts = {
  /**
   * Whether the notification can be dismissed by the user. Defaults to `true`
   */
  dismissable?: boolean;
  /**
   * How long the notification should be displayed before automatically
   * dismissing. Defaults to `5000` (5 seconds)
   */
  timeout?: number | null;
};

const defaultOpts: MessageOpts = {
  dismissable: true,
};

/**
 * A hook for displaying notifications to the user, with optional auto-dismissal
 *
 * Designed to be used with the `Notification` component
 */
export const useNotify = () => {
  const [state, setState] = useState<MessageState>();

  useEffect(() => {
    //-- Put a short delay in place before we show the message
    setTimeout(() => {
      if (!state?.timeout) return;

      const id = setTimeout(() => setState(undefined), state.timeout);
      return () => clearTimeout(id);
    }, 500);
  }, [state]);

  const notify = useMemo(() => {
    const buildMessage =
      (type: MessageState["type"]) =>
      (content: ReactNode, opts?: MessageOpts) =>
        setState({ content, type, ...defaultOpts, ...opts });

    return {
      success: buildMessage("success"),
      warning: buildMessage("warning"),
      info: buildMessage("info"),
      error: buildMessage("error"),
      clear: () => setState(undefined),
    };
  }, []);

  return [{ state, clear: notify.clear }, notify] as const;
};

type NotificationProps = ReturnType<typeof useNotify>[0];

/**
 * A component for displaying notifications to the user
 *
 * Designed to be used with the `useNotify` hook, which provides its props
 */
export const Notification = (props: NotificationProps) => {
  if (!props.state) return null;

  const color = {
    success: "bg-validation-green text-white",
    warning: "bg-warning-yellow text-white",
    info: "bg-nearly-black text-white",
    error: "bg-red text-white",
  }[props.state.type];

  return (
    <div
      data-status={props.state.type}
      className={cx(
        "absolute top-[95px] left-2 right-2 z-50 rounded py-5 pl-6 text-body2",
        color
      )}
      data-testid="notification"
    >
      {props.state.content}
      {props.state.dismissable && (
        <button data-testid="close-button" type="button" onClick={props.clear}>
          <Close className="h-6 w-6 fill-current" />
        </button>
      )}
    </div>
  );
};
