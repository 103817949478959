import { useNavigate } from "react-router-dom";

import type { Models } from "api/types";
import { ShopNowAffirm } from "atoms/affirm/shopNowAffirm/ShopNowAffirm";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Shop } from "atoms/icon/icons/shop_nav.svg";
import { ProductCategory } from "molecules/containers/product-category/ProductCategory";
import { IfFlag } from "services/Flags";

export interface CategoriesSectionProps {
  categories: Models<"CategoryResult">[];
  customCategory: Models<"CategoryListingsResult"> | undefined;
  hasMemberships: boolean;
  hasTreatments: boolean;
  hasPackages: boolean;
  affirmEnabled: boolean | undefined;
}

const CategoriesSection = ({
  categories,
  hasMemberships,
  customCategory,
  hasPackages,
  hasTreatments,
  affirmEnabled,
}: CategoriesSectionProps) => {
  const defaultMemberships = categories.find(
    (c) => c.defaultCategoryId === "memberships"
  );
  const defaultTreatments = categories.find(
    (c) => c.defaultCategoryId === "treatments"
  );
  const defaultPackages = categories.find(
    (c) => c.defaultCategoryId === "packages"
  );
  // NOTE: to be added back once client team implements custom shop categories work in https://repeatmd.atlassian.net/browse/CMX-486
  // const customCategories = categories.filter(
  //   (c) => c.defaultCategoryId === null
  // );

  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center bg-two p-6">
      <div className="flex flex-col items-center">
        <Icon size="xsmall" svg={Shop} color="brandColor" />
        <div className="py-4 font-serif text-h2">Our Services</div>
        {affirmEnabled && (hasPackages || hasTreatments) && (
          <div className="pb-6">
            <ShopNowAffirm />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <IfFlag flag="site-wide-promotions">
          {customCategory && (
            <ProductCategory
              key={customCategory.category.id}
              text={customCategory.category.title ?? ""}
              secondaryText={customCategory.category.description ?? ""}
              imageUrl={customCategory.category.imageUrl || ""}
              backgroundColor={customCategory.promotion?.backgroundColor ?? ""}
              color={customCategory.promotion?.textColor ?? ""}
              onClick={() => {
                const navigateTo =
                  customCategory.packageListings.length === 1
                    ? `/pdp/package/${customCategory.packageListings[0].id}`
                    : `/shop/custom-category/${customCategory.category.id}`;
                navigate(navigateTo);
              }}
            />
          )}
        </IfFlag>
        {hasMemberships && defaultMemberships && (
          <ProductCategory
            key={defaultMemberships.defaultCategoryId}
            text={defaultMemberships.title}
            secondaryText={defaultMemberships.description}
            imageUrl={defaultMemberships.imageUrl || ""}
            onClick={() => navigate("/shop/memberships")}
          />
        )}
        {hasTreatments && defaultTreatments && (
          <ProductCategory
            key={defaultTreatments.defaultCategoryId}
            text={defaultTreatments.title}
            secondaryText={defaultTreatments.description}
            imageUrl={defaultTreatments.imageUrl || ""}
            onClick={() => navigate("/shop/treatments")}
          />
        )}
        {hasPackages && defaultPackages && (
          <ProductCategory
            key={defaultPackages.defaultCategoryId}
            text={defaultPackages.title}
            secondaryText={defaultPackages.description}
            imageUrl={defaultPackages.imageUrl || ""}
            onClick={() => navigate("/shop/packages")}
          />
        )}
        {/* NOTE: to be added back once client team implements custom shop categories work in https://repeatmd.atlassian.net/browse/CMX-486 */}
        {/* {customCategories.map((category) => (
          <ProductCategory
            key={category.id}
            text={category.title}
            secondaryText={category.description}
            imageUrl={category.imageUrl}
          />
        ))} */}
      </div>
    </div>
  );
};

export default CategoriesSection;
