// eslint-disable-next-line no-restricted-imports
import {
  LDClient,
  LDContextCommon,
  LDProvider,
  useFlags,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import React, { useEffect } from "react";
import { LoaderFunction, redirect } from "react-router-dom";

/**
 * Feature flags and their "default" values that are currently available/tracked
 * in the admin web app
 */
const featureFlags = {
  /**
   * Assume that this flag is always "on" in dev environments, and always "off"
   * in production
   */
  isDev: true,
  suspiciousClients: true,
  "site-wide-promotions": true,
  timeDelayedTreatmentGroups: true,
  "incomplete-profile-redirect-v-2": false,
  unitedDerm: false,
  dropDownWithMembership: false,
};

// Mapped type makes the IDE annotations a bit nicer
type Flags = { [K in keyof typeof featureFlags]: typeof featureFlags[K] };

let ldClient: LDClient | undefined;
const clientSideID =
  process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID ??
  console.warn(
    "REACT_APP_LAUNCHDARKLY_CLIENT_ID variable not provided, feature flags will revert to default values"
  );

const LDWrapper = ({ children }: { children: React.ReactNode }) => {
  const client = useLDClient();

  useEffect(() => {
    ldClient = client;
  }, [client]);

  return <>{children}</>;
};

function waitLDClient(): Promise<LDClient | null> {
  return new Promise(function recur(res) {
    if (!clientSideID) res(null);
    if (!ldClient) setTimeout(() => recur(res));
    else res(ldClient);
  });
}

export const FFlagProvider = ({ children }: { children: React.ReactNode }) => {
  if (!clientSideID) return <LDWrapper>{children}</LDWrapper>;

  return (
    <LDProvider
      clientSideID={clientSideID}
      reactOptions={{ useCamelCaseFlagKeys: false }}
    >
      <LDWrapper>{children}</LDWrapper>
    </LDProvider>
  );
};

/**
 * Lower-level hooks API to subscribe to the value of a particular feature flag.
 */
export const useFeatureFlag = <Flag extends keyof Flags>(
  key: Flag
): Flags[Flag] => {
  const ldFlags = useFlags();
  return ldFlags[key] ?? featureFlags[key];
};

/**
 * Lower-level API to get the current value of a particular feature flag.
 */
export const getFeatureFlag = async <Flag extends keyof Flags>(
  key: Flag
): Promise<Flags[Flag]> => {
  const ldClient = await waitLDClient();

  return ldClient?.variation(key, featureFlags[key]) ?? featureFlags[key];
  // return ldClient?.variation(key, featureFlags[key]);
};

/**
 * Wrapper component that will render its children only if a feature flag
 * matches the specified value
 *
 * If the `equals` prop is not specified, the children will be rendered if the
 * flag is truthy. Otherwise, they will be rendered only if the flag matches the
 * specified value.
 *
 * @example
 *
 * const MyComponent = () => {
 *   return (
 *     <>
 *       <IfFlag flag="isDev">
 *         <div>This will only be rendered in dev environments</div>
 *       </IfFlag>
 *
 *       <IfFlag flag="isDev" equals={false}>
 *         <div>This will only be rendered in production environments</div>
 *       </IfFlag>
 *    </>
 *   );
 * };
 */
export const IfFlag = <Flag extends keyof Flags>(props: {
  flag: Flag;
  equals?: Flags[Flag];
  children: React.ReactNode;
}) => {
  const flagValue = useFeatureFlag(props.flag);
  if (props.equals === undefined ? flagValue : flagValue === props.equals)
    return <>{props.children}</>;
  else return null;
};

/**
 * Wraps a route loader function to prevent loading the page based on the value
 * of a feature flag.
 *
 * If `equals` argument is not specified, the page will load if the flag is
 * truthy. Otherwise, it will load only if the flag matches the specified value.
 *
 * @example
 *
 * export const loader: LoaderFunction = loadIfFlag("isDev")(() => {
 *  return json({ message: "This page will only be reachable in dev environments" })
 * });
 */
export const loadIfFlag =
  <Flag extends keyof typeof featureFlags>(
    flag: Flag,
    equals?: typeof featureFlags[Flag]
  ) =>
  (fn: LoaderFunction): LoaderFunction => {
    return async (args) => {
      const flagValue = await getFeatureFlag(flag);
      if (equals === undefined ? flagValue : flagValue === equals)
        return await fn(args);
      else return redirect("/");
    };
  };

/**
 * Returns the passed object conditionally based on the value of a feature flag.
 *
 * If `equals` argument is not specified, the object will be returned if the
 * flag is truthy. Otherwise, it will be returned only if it matches the
 * specified value.
 *
 * @example
 *
 * const MyComponent = () => {
 *   return (
 *     <>
 *       {ifFlag("isDev")(
 *         <div>This will only be rendered in dev environments</div>
 *       )}
 *
 *       {ifFlag("isDev", false)(
 *         <div>This will only be rendered in production environments</div>
 *       )}
 *     </>
 *   );
 * };
 */
export const ifFlag =
  <Flag extends keyof typeof featureFlags>(
    flag: Flag,
    equals?: typeof featureFlags[Flag]
  ) =>
  async <T,>(obj: T): Promise<T | null> => {
    const flagValue = await getFeatureFlag(flag);
    if (equals === undefined ? flagValue : flagValue === equals) return obj;
    else return null;
  };

type UserContext = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};

type PlatformContext = {
  id: string;
  domain?: string;
};

/**
 * Identify the current user context to LaunchDarkly
 */
export const identifyUser = (user: UserContext, platform?: PlatformContext) => {
  const context: Record<string, LDContextCommon> = {
    user: {
      key: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    },
  };

  if (platform) {
    context.platform = {
      key: platform.id,
      domain: platform.domain,
    };
  }

  ldClient?.identify({
    kind: "multi",
    ...context,
  });
};
