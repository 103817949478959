import { Capacitor } from "@capacitor/core";
import { datadogRum } from "@datadog/browser-rum-slim";
import { useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { useEffect, useState } from "react";
import {
  json,
  LoaderFunction,
  Navigate,
  Outlet,
  redirect,
  useNavigate,
  useRouteError,
} from "react-router-dom";

import { get } from "api";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Left } from "atoms/icon/icons/chevron_left.svg";
import { ReactComponent as PageError } from "atoms/icon/icons/page_error.svg";
import { TextButton } from "atoms/text-button/TextButton";
import useHistory from "hooks/useHistory";
import TopNav from "molecules/navbars/TopNav";
import { Footer } from "molecules/navigation/footer/Footer";
import { OpenMobileAppBanner } from "molecules/open-mobile-app-banner/OpenMobileAppBanner";
import { ViewAppFooter } from "molecules/view-app-footer/ViewAppFooter";
import { queryClient } from "pages/Root";
import {
  linkToApp,
  setIdleTimeInTimestamp,
  showViewAppFooter,
} from "services/AppBannerService";
import { getFeatureFlag, identifyUser } from "services/Flags";
import Services from "services/Services";
import { setPromotionModalSeen } from "utils/functions";

import LoadingLayout from "./LoadingLayout";

/**
 * Layout route for authenticated users (post-login)
 */

const load = async () => {
  const user = await queryClient.ensureQueryData({
    queryKey: ["/user"],
    queryFn: () => get("/user"),
    staleTime: 1000 * 60 * 2,
  });

  return user;
};
type LoaderData = Awaited<ReturnType<typeof load>>;

export const appLayoutLoader: LoaderFunction = async () => {
  const completeProfileFlag = await getFeatureFlag(
    "incomplete-profile-redirect-v-2"
  );

  const data = await load();

  if (completeProfileFlag) {
    const authClaims = await queryClient.ensureQueryData({
      queryKey: ["/auth/claims"],
      queryFn: () => get("/auth/claims"),
    });

    const isAdminImpersonation = !!authClaims?.claims.adminUserId;

    if (!data!.completedProfile && !isAdminImpersonation) {
      return redirect(
        // can there be such a case when platformId is not present? if no, make platformDomain mandatory, if yes, how to handle?
        Capacitor.isNativePlatform()
          ? `/signin/signup/complete-profile?platformId=${data.platformDomain?.platformId}`
          : "/signin/signup/complete-profile"
      );
    }
  }

  return json<LoaderData>(data);
};
const AppLayout = () => {
  const { data: user } = useQuery({
    queryKey: ["/user"],
    queryFn: () => get("/user"),
    staleTime: 1000 * 60 * 2,
    onSuccess: (data) => {
      datadogRum.setUser({ id: data.id, name: data.phoneNumber });

      let platformContext = undefined;
      if (data.platformDomain) {
        platformContext = {
          id: data.platformDomain.platformId,
          domain: data.platformDomain.domain || undefined,
        };
      }

      identifyUser(
        {
          id: data.id,
          firstName: data.firstName ?? undefined,
          lastName: data.lastName ?? undefined,
          email: data.email ?? undefined,
        },
        platformContext
      );
    },
  });
  const { data: platform } = useQuery({
    queryKey: ["/platform"],
    queryFn: () => get("/platform"),
  });

  const showAppBannerEnabled = platform?.platformSettings?.mobileAppDiscovery;

  const [displayViewAppFooter, setDisplayViewAppFooter] = useState(
    showAppBannerEnabled && showViewAppFooter(user?.id)
  );

  useEffect(() => {
    if (displayViewAppFooter) {
      setDisplayViewAppFooter(showViewAppFooter(user?.id));
    }
  }, [displayViewAppFooter, user?.id]);

  return (
    <>
      {showAppBannerEnabled && (
        <OpenMobileAppBanner onClick={() => linkToApp(platform?.id)} />
      )}
      {displayViewAppFooter && (
        <ViewAppFooter
          platformName={platform?.name || ""}
          open={displayViewAppFooter}
          onClose={() => {
            setDisplayViewAppFooter(false);
            setIdleTimeInTimestamp(user?.id);
          }}
          onOpenApp={() => linkToApp(platform?.id)}
        />
      )}
      <LoadingLayout />
      <div className="flex flex-auto">
        <div className="w-full">
          <Outlet />
        </div>
      </div>
      <div className="sticky bottom-0">
        <Footer />
      </div>
    </>
  );
};

export const AppLayoutError = () => {
  const { routeBackNumber } = useHistory();
  const navigate = useNavigate();
  const error = useRouteError();

  const { data: logo } = useQuery({
    queryKey: ["/platform"],
    queryFn: () => get("/platform"),
    select: (data) => data.logoImageUrl,
  });

  if (isAxiosError(error) && error.response?.status === 401) {
    setPromotionModalSeen(false);
    return <Navigate to="/signin" />;
  }

  return (
    <>
      <TopNav>{logo && <img src={logo} className="h-8" />}</TopNav>

      <div className="flex flex-auto flex-col items-center justify-center gap-10">
        <div className="flex flex-col items-center gap-5">
          <Icon svg={PageError} color="darkGrey" size="medium" />
          <div className="text-body1 text-primary">
            Sorry, something went wrong!
          </div>
        </div>
        <TextButton
          startIconSVG={Left}
          onClick={() => navigate(routeBackNumber)}
        >
          Go Back
        </TextButton>
        <TextButton
          onClick={() => {
            setPromotionModalSeen(false);
            Services.auth.setAuthToken(null);
            datadogRum.clearUser();
            navigate("/signin");
          }}
        >
          Logout
        </TextButton>
      </div>

      <div className="sticky bottom-0">
        <Footer />
      </div>
    </>
  );
};

export default AppLayout;
