import { Capacitor } from "@capacitor/core";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useQuery } from "@tanstack/react-query";
import { RateApp } from "capacitor-rate-app";
import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import {
  json,
  LoaderFunction,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { get, post } from "api";
import { RewardStatus } from "api/types";
import { Button } from "atoms/button/Button";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as CartCheckIcon } from "atoms/icon/icons/ic_added_to_cart_check_ol.svg";
import { ReactComponent as WarningIcon } from "atoms/icon/icons/warning_circle_info.svg";
import { TreatmentOrderedList } from "organisms/treatment-ordered-list/TreatmentOrderedList";
import { queryClient } from "pages/Root";
import { getProductTitle } from "services/HelperService";
import { notNull } from "toolbox/Guards";
import { waitObj } from "toolbox/Promise";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY || "");

const load = async () => {
  const rewards = queryClient.fetchQuery(["/rewards"], () =>
    get("/rewards", {})
  );
  return waitObj({ rewards });
};

type LoaderData = Awaited<ReturnType<typeof load>>;

export const CheckoutSuccessPageLoader: LoaderFunction = async () => {
  return json<LoaderData>(await load());
};

const CheckoutSuccessPageInternal = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const stripe = useStripe();
  const shopDisplayOrderId = searchParams.get("shopDisplayOrderId");
  const shopDisplayOrderTransactionId = searchParams.get("orderTransactionId");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorLoadingTransaction, setErrorLoadingTransaction] = useState(false);
  const { rewards } = useLoaderData() as LoaderData;

  enum orderStatus {
    paid = "Paid",
    processing = "Processing",
    failed = "Failed",
    requiresAction = "RequiresAction",
  }

  // If the user checked out with an email they did not want saved to their account,
  // we need to reset the email value back to the way it was
  const email = searchParams.get("email");
  useEffect(() => {
    if (email != null) {
      post("/user/update", { email });
    }
  }, [email]);

  const { data: shopDisplayOrderTransaction } = useQuery({
    enabled: !!shopDisplayOrderTransactionId,
    queryKey: [
      "/orders/transaction/{shopDisplayOrderTransactionId}",
      { shopDisplayOrderTransactionId: shopDisplayOrderTransactionId },
    ],
    queryFn: () =>
      get("/orders/transaction/{shopDisplayOrderTransactionId}", {
        shopDisplayOrderTransactionId: shopDisplayOrderTransactionId!,
      }),
    onError: () => {
      console.log("Error loading transaction");
      setErrorLoadingTransaction(true);
    },
    refetchInterval: (shopDisplayOrderTransaction) =>
      shopDisplayOrderTransaction &&
      (shopDisplayOrderTransaction?.status == "Failed" ||
        shopDisplayOrderTransaction?.status == "Paid")
        ? false
        : 1000,
    refetchOnMount: true,
  });

  const { data: shopDisplayOrder } = useQuery({
    queryKey: [
      "/orders/{shopDisplayOrderId}",
      { shopDisplayOrderId: shopDisplayOrderId },
    ],
    queryFn: () =>
      get("/orders/{shopDisplayOrderId}", {
        shopDisplayOrderId: shopDisplayOrderId || "",
      }),
    refetchInterval: (shopDisplayOrder) =>
      shopDisplayOrder &&
      (shopDisplayOrder?.status == orderStatus.failed ||
        shopDisplayOrder?.status == orderStatus.paid)
        ? false
        : 1000,
    refetchOnMount: true,
  });

  useEffect(() => {
    async function stripeRequiresAction() {
      if (
        shopDisplayOrderTransaction?.status == "RequiresAction" &&
        stripe &&
        shopDisplayOrderTransaction &&
        shopDisplayOrderTransaction.paymentIntentClientSecret
      ) {
        const clientSecret =
          shopDisplayOrderTransaction.paymentIntentClientSecret || "";

        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error && error.message) {
          setErrorMessage(error.message);
          // Force status to failure to stop polling
          shopDisplayOrderTransaction.status = "Failed";
        }
      }
    }
    stripeRequiresAction();
  }, [stripe, shopDisplayOrderTransaction]);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    if (
      (shopDisplayOrder?.status == orderStatus.paid &&
        shopDisplayOrderTransaction?.status == "Paid") ||
      !shopDisplayOrderTransactionId
    ) {
      RateApp.requestReview();
    }
  }, [
    orderStatus.paid,
    shopDisplayOrder?.status,
    shopDisplayOrderTransaction?.status,
    shopDisplayOrderTransactionId,
  ]);

  const orderedTreatments = shopDisplayOrder?.shopDisplayOrderLineItems
    ?.filter(notNull)
    .map((item) => {
      if (item.name && item.imageUrl && item.shopDisplayMembershipId == null) {
        return {
          id: item.id,
          imgUrl: item.imageUrl,
          type: item.displayType === 0 ? "Treatment" : "Package",
          title: getProductTitle(item.productName, item.name),
          session: `${item.quantity} ${
            item.quantity > 1
              ? item.package?.packageUnitType.pluralUnitType ?? "units"
              : item.package?.packageUnitType.singularUnitType ?? "unit"
          }`,
        };
      }
    })
    .filter(notNull);

  const orderedMemberships = shopDisplayOrder?.shopDisplayOrderLineItems
    ?.filter(notNull)
    .map((item) => {
      if (item.name && item.imageUrl && item.shopDisplayMembershipId != null) {
        return {
          id: item.id,
          imgUrl: item.imageUrl,
          type: "Membership",
          title: item.name,
          session: "Billed Monthly",
        };
      }
    })
    .filter(notNull);
  if (
    errorLoadingTransaction ||
    shopDisplayOrderTransaction?.status == "Failed"
  ) {
    queryClient.invalidateQueries([
      "/orders/{shopDisplayOrderId}",
      { shopDisplayOrderId: shopDisplayOrderId },
    ]);
    return (
      <div className="content-center items-center justify-center p-4 text-center">
        <div className="flex items-center justify-center gap-2 py-12">
          <Icon svg={WarningIcon} size="medium" color="primaryText" />
        </div>
        <div className="pb-12 font-serif text-h2">Sorry!</div>
        <br />
        <div className="font serif text-h3">
          {errorMessage
            ? errorMessage
            : "Something went wrong. Please try your payment again."}
        </div>

        <div className="py-32">
          <Button fullWidth onClick={() => navigate("/cart")}>
            Back to cart
          </Button>
        </div>
      </div>
    );
  } else if (
    (shopDisplayOrder?.status == orderStatus.paid &&
      shopDisplayOrderTransaction?.status == "Paid") ||
    !shopDisplayOrderTransactionId
  ) {
    queryClient.invalidateQueries([
      "/orders/{shopDisplayOrderId}",
      { shopDisplayOrderId: shopDisplayOrderId },
    ]);
    const futureRewards = rewards.filter(
      (r) => r.status === RewardStatus.NotAvailable
    );
    const nextReward = futureRewards.length
      ? futureRewards.reduce(
          (acc, curr) =>
            acc.minimumVisits ?? 0 <= (curr.minimumVisits ?? 0) ? acc : curr,
          futureRewards[0]
        )
      : undefined;
    const rewardProgress = nextReward?.unlockProgressPercent || 0;

    return (
      <div className="mt-[env(safe-area-inset-top)] h-screen flex-col items-center justify-center bg-one text-center">
        <div className="flex items-center justify-center py-12">
          <Icon svg={CartCheckIcon} size="xlarge" color="primaryText" />
        </div>
        <div className="pb-8 font-serif text-h2">
          <div>Get excited,</div>
          <div>your order is confirmed!</div>
        </div>
        <div className="flex h-full flex-col gap-4">
          {orderedMemberships && orderedMemberships.length > 0 && (
            <div className="px-4">
              <div className="pb-6 text-body1 text-primary">{`${orderedMemberships.length} item added to ‘Membership’`}</div>
              <TreatmentOrderedList
                treatments={orderedMemberships}
              ></TreatmentOrderedList>
            </div>
          )}
          {orderedTreatments && orderedTreatments.length > 0 && (
            <div className="px-4">
              <div className="pb-6 text-body1 text-primary">{`${orderedTreatments.length} item added to ‘My Treatments’`}</div>
              <TreatmentOrderedList
                treatments={orderedTreatments}
              ></TreatmentOrderedList>
            </div>
          )}
          <div className="grow" />
          <div className="sticky bottom-[env(safe-area-inset-bottom)] w-full bg-one p-4 pb-5 empty:hidden">
            <Button
              fullWidth
              onClick={() => {
                navigate(
                  `/cart/checkout/reward?rewardProgress=${rewardProgress}`
                );
              }}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    // Processing
    return (
      <div className="flex flex-col items-center gap-8 px-8 pb-8 pt-[calc(20px_+_env(safe-area-inset-top))]">
        <TailSpin
          height="80"
          width="80"
          radius="1"
          color="rgb(var(--repeatmd-brand-color))"
        />
        <div className="pb-12 text-center font-serif text-h2">
          <div>Just a moment...</div>
          <div>Confirming your order.</div>
        </div>
      </div>
    );
  }
};

const CheckoutSuccessPage = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutSuccessPageInternal />
    </Elements>
  );
};

export default CheckoutSuccessPage;
