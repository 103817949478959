import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

const RootError = () => {
  const error = useRouteError();

  useEffect(() => console.error(error), [error]);

  return (
    <div className="absolute m-auto flex h-full w-full flex-col items-center justify-center">
      <h1 className="text-2xl">Something went wrong!</h1>
    </div>
  );
};

export default RootError;
