import React from "react";

import { ListModalSize } from "molecules/modal-window/list-modal/ListModal";

import { ModalSelectorList } from "../modal-selector-list/ModalSelectorList";

type BaseModalSelectorFilterListProps = {
  /** Specify the modal state as open or closed */
  open?: boolean;
  /** Specify the on Close event */
  onClose: () => void;
  /** Specify the modal Title */
  title: string;
  /** Specify the modal type as filter or sort */
  type: "filter" | "sort";
  /** Specify the listed items */
  items: { id: string; title: string; results?: number; disabled?: boolean }[];
  /** Specify the checked items by id*/
  checkedItems: string[] | string;
  /** Specify the on Change event */
  onChange: (value: string) => void;
  /** Specify the handler for the first button */
  onFirstButtonClick: () => void;
  /** Specify the text for the first button */
  firstButtonLabel: string;
  /** Specify the disabled state for the first button */
  firstButtonDisabled?: boolean;
  /** Specify the handler for the second button */
  onSecondButtonClick?: undefined;
  /** Specify the text for the second button */
  secondButtonLabel?: undefined;
  /** Specify the disabled state for the second button */
  secondButtonDisabled?: boolean;
  /** Specify the testid for current component */
  "data-testid"?: string;
};

type WithSecondButtonProps = {
  onSecondButtonClick: () => void;
  secondButtonLabel: string;
} & Omit<
  BaseModalSelectorFilterListProps,
  "secondButtonLabel" | "onSecondButtonClick"
>;

type ModalSelectorFilterListProps =
  | BaseModalSelectorFilterListProps
  | WithSecondButtonProps;

export const ModalSelectorFilterList: React.FC<
  ModalSelectorFilterListProps
> = ({
  open = false,
  onClose,
  title,
  type,
  items,
  checkedItems,
  onChange,
  onFirstButtonClick,
  firstButtonLabel,
  firstButtonDisabled,
  onSecondButtonClick,
  secondButtonLabel,
  secondButtonDisabled,
  "data-testid": dataTestId,
}) => {
  const modalProps = {
    size: "fullscreen" as ListModalSize,
    open: open,
    onClose,
    title: title,
    type: type,
    "data-testid": dataTestId,
    items: items?.map((i) => ({
      id: i.id,
      title: i.title,
      type: type === "sort" ? "radio" : "checkbox",
      disabled: i.disabled,
      priceOrResults: i.results,
    })),
    checkedItems: checkedItems,
    onSelect: onChange,
    onFirstButtonClick: onFirstButtonClick,
    firstButtonLabel: firstButtonLabel,
    firstButtonDisabled: firstButtonDisabled,
  };
  if (onSecondButtonClick && secondButtonLabel) {
    return (
      <ModalSelectorList
        {...modalProps}
        onSecondButtonClick={onSecondButtonClick}
        secondButtonLabel={secondButtonLabel}
        secondButtonDisabled={secondButtonDisabled}
      />
    );
  }
  return <ModalSelectorList {...modalProps} />;
};
