import { Capacitor } from "@capacitor/core";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import {
  json,
  LoaderFunction,
  useLoaderData,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";

import { get, post } from "api";
import MyRepeatLogo from "assets/images/RepeatMD_myRepeat.svg";
import { Button } from "atoms/button/Button";
import { Checkbox } from "atoms/checkbox/Checkbox";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right_small.svg";
import { ReactComponent as Cake } from "atoms/icon/icons/ic_birthday_large_ol.svg";
import { ReactComponent as Offers } from "atoms/icon/icons/ic_offers_large_ol.svg";
import { ReactComponent as Reward } from "atoms/icon/icons/ic_rewards_large_ol.svg";
import { ReactComponent as Star } from "atoms/icon/icons/ic_star_large_ol.svg";
import { ReactComponent as WarningCircleInfo } from "atoms/icon/icons/warning_circle_info.svg";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { TextButton } from "atoms/text-button/TextButton";
import { TextField } from "atoms/text-field/TextField";
import { queryClient } from "pages/Root";
import { IfFlag } from "services/Flags";
import { waitObj } from "toolbox/Promise";

const load = async (request: Request) => {
  const referralCode = new URL(request.url).searchParams.get("referralCode");

  const localStoragePlatformId = localStorage.getItem("platformId");

  const theme = queryClient.fetchQuery(["theme"], () =>
    !localStoragePlatformId ? get("/platform/theme") : null
  );

  const multiPlatformTheme = queryClient.fetchQuery(
    [
      "/multi-platform/platform/{platformId}/theme",
      { platformId: localStoragePlatformId },
    ],
    () =>
      localStoragePlatformId
        ? get("/multi-platform/platform/{platformId}/theme", {
            platformId: localStorage.getItem("platformId") || "",
          })
        : null
  );

  return waitObj({
    referralCode,
    theme,
    multiPlatformTheme,
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const signInDisplayLoader: LoaderFunction = async ({ request }) => {
  return json<LoaderData>(await load(request));
};

const SignUpPerks = () => {
  return (
    <div className="mx-auto flex flex-col gap-6 pt-5 pb-7">
      <div className="flex items-center gap-3 text-body1 text-primary">
        <Icon svg={Star} size={"medium"} color={"brandColor"} />
        Free reward for new members!
      </div>
      <div className="flex items-center gap-3 text-body1 text-primary">
        <Icon svg={Reward} size="medium" color="brandColor" />
        Earn rewards for visits & purchases
      </div>
      <div className="flex items-center gap-3 text-body1 text-primary">
        <Icon svg={Cake} size={"medium"} color={"brandColor"} />
        Free birthday gifts
      </div>
      <div className="flex items-center gap-3 text-body1 text-primary">
        <Icon svg={Offers} size={"medium"} color={"brandColor"} />
        Member only offers & events
      </div>
    </div>
  );
};

const formatPhoneNumber = (value: string) => {
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

const SignInPage = () => {
  const navigate = useNavigate();
  const { referralCode, theme, multiPlatformTheme } =
    useLoaderData() as LoaderData;
  const navigation = useNavigation();
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const [smsAgreement, setSmsAgreement] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [userBlocked, setUserBlocked] = useState<boolean>(
    Boolean(location.state?.userBlocked)
  );
  const [blockedOnPlatform, setBlockedOnPlatform] = useState<boolean>(false);
  const [locationPhoneNumber, setLocationPhoneNumber] = useState<
    string | null | undefined
  >(location.state?.contact);
  const [userExists, setUserExists] = useState<boolean>(false);
  const [invalidPhoneNumberError, setInvalidPhoneNumberError] =
    useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [secondaryErrorMsg, setSecondaryErrorMsg] = useState<string>("");

  const handlePhoneNumberInput = (phone: string) => {
    const formattedPhoneNumber = formatPhoneNumber(phone);
    setUserExists(false);
    setInvalidPhoneNumberError(false);
    setErrorMsg("");
    setDisabledButton(formattedPhoneNumber.length < 14);
    setUserBlocked(false);
    setInputValue(formattedPhoneNumber);
  };

  const mutation = useMutation({
    mutationFn: (phoneNumber: string) => {
      const apiPath = !Capacitor.isNativePlatform()
        ? "/auth/exists"
        : "/multi-platform/auth/exists";
      return get(apiPath, { phoneNumber });
    },
    onSuccess: ({ exists }) => {
      if (exists && referralCode) {
        setUserExists(true);
        setDisabledButton(true);
        setInvalidPhoneNumberError(true);
        setErrorMsg("There is already an account with this number.");
        setSecondaryErrorMsg("Referral rewards are for new users only.");

        return "error";
      } else if (!referralCode && exists) {
        submitLoginMutation(inputValue);
      } else {
        setNewUser(true);
      }
    },
  });

  const loginMutation = useMutation({
    mutationFn: (phoneNumber: string) => post("/auth/login", { phoneNumber }),
    onSuccess: (res) => {
      if (res.success) {
        navigate(`/signin/verification`, {
          state: {
            phone: "+1" + inputValue.replace(/[^\d]/g, ""),
            newUser: newUser,
            signupReferralCode: referralCode,
          },
        });
      } else if (
        res.success === false &&
        res.message === "User access is blocked for this platform."
      ) {
        setUserBlocked(true);
        setBlockedOnPlatform(true);
        setInvalidPhoneNumberError(true);
        setLocationPhoneNumber(res.locationPhoneNumber);
      } else {
        setInvalidPhoneNumberError(true);
        setErrorMsg("Number not recognized.");
      }
    },
  });

  const submitMutation = (phone: string) => {
    mutation.mutate("+1" + phone.replace(/[^\d]/g, ""));
  };

  const submitLoginMutation = (phone: string) => {
    loginMutation.mutate("+1" + phone.replace(/[^\d]/g, ""));
  };

  const comingFromDeepLink = localStorage.getItem("platformId");

  return (
    <div>
      <IfFlag flag="suspiciousClients">
        {userBlocked && (
          <div className="sticky top-[env(safe-area-inset-top)] z-10 flex h-[54px] items-center justify-between bg-red py-[9px] pl-4 pr-3 text-white">
            <div className="flex w-[250px] gap-2 text-body2">
              This account is disabled due to suspicious activity
            </div>
            <a
              href={
                blockedOnPlatform
                  ? locationPhoneNumber
                    ? `tel:${locationPhoneNumber}`
                    : "mailto:support@repeatmd.com"
                  : "mailto:support@repeatmd.com"
              }
              className="flex cursor-pointer items-center text-bold2"
            >
              Contact us
              <Icon svg={ChevronRight} size="xsmall" color="white" />
            </a>
          </div>
        )}
      </IfFlag>
      <div className="flex flex-col gap-4 px-8 pb-4 pt-[calc(48px_+_env(safe-area-inset-top))]">
        <div className="flex flex-col items-center text-center">
          <img
            className="mb-8 w-[40%]"
            src={
              Capacitor.isNativePlatform() && !comingFromDeepLink
                ? MyRepeatLogo
                : Capacitor.isNativePlatform() && comingFromDeepLink
                ? multiPlatformTheme?.logoImageUrl || ""
                : theme?.platformLogoUrl || ""
            }
          />
          <div className="font-serif text-h2 text-primary">
            Can we get your number?
          </div>
          <div className="mt-4 text-body2 text-secondary">
            Sign up or login using your phone number
          </div>
        </div>
        <fieldset
          disabled={navigation.state === "submitting"}
          className="flex w-full flex-col gap-6 pb-5"
        >
          <TextField
            type="tel"
            label="Phone number"
            name="phoneNumber"
            onChange={(e) => handlePhoneNumberInput(e)}
            value={inputValue}
            pattern="[0-9]*"
            placeholder="(123) 456-7890"
            invalid={invalidPhoneNumberError || userBlocked}
            supportingText={errorMsg}
            secondarySupportingText={secondaryErrorMsg}
            rightIcon={referralCode && userExists ? WarningCircleInfo : ""}
            onKeyUp={(e) => {
              if (e === "Enter") {
                submitMutation(inputValue);
              }
            }}
          />
          {newUser && (
            <Checkbox
              label="I agree to receive special rewards, gifts & promotional material via sms messages"
              checked={smsAgreement}
              onChange={(checked: boolean) => setSmsAgreement(checked)}
            />
          )}

          {!newUser && (
            <Button
              fullWidth
              disabled={
                mutation.isLoading ||
                loginMutation.isLoading ||
                disabledButton ||
                userBlocked
              }
              onClick={() => {
                submitMutation(inputValue);
              }}
            >
              Login or sign up
            </Button>
          )}

          {newUser && (
            <div>
              <Button
                fullWidth
                disabled={loginMutation.isLoading || !smsAgreement}
                onClick={() => {
                  submitLoginMutation(inputValue);
                }}
              >
                Send verification code
              </Button>
              <div className="flex flex-col items-center pt-3 text-center">
                <div className="text-body3 text-secondary">
                  By selecting you agree you have read and accepted our{" "}
                </div>
                <a
                  className="text-bold3 text-brand-color"
                  href="https://myrepeat.com/terms.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of service
                </a>
              </div>
            </div>
          )}
          {referralCode && userExists && (
            <div className="flex items-center justify-center">
              <TextButton
                disabled={loginMutation.isLoading}
                onClick={() => {
                  submitLoginMutation(inputValue);
                }}
              >
                Login instead
              </TextButton>
            </div>
          )}
        </fieldset>
        <SignUpPerks />
      </div>
      <RepeatMDFooter showLinks={true} />
    </div>
  );
};

export default SignInPage;
