import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right_small.svg";
import { BadgeTag } from "atoms/tag/BadgeTag";
import { DescriptiveTag } from "atoms/tag/DescriptiveTag";
import { formatCurrency } from "toolbox/Money";

interface ShopMembershipListItemProps {
  /** Title of item */
  title: string;

  /** List of included items in this shop item */
  includedList?: Array<string> | null;

  /** Item Image */
  imageUrl?: string | null;

  /** Tag for item (ie: Most Popular, New, etc.) */
  tag?: string | null;

  /** List of concerns for item */
  concerns?: Array<string> | null;

  /** Standard item cost (in smallest monetary unit) */
  price: number;

  /** Called when clicking this item */
  onClick?: () => void;
}

export const ShopMembershipListItem = ({
  title,
  includedList,
  imageUrl,
  tag,
  concerns,
  price,
  onClick,
}: ShopMembershipListItemProps) => {
  return (
    <div
      className="flex cursor-pointer flex-col rounded-lg bg-white shadow"
      onClick={onClick}
    >
      <div
        className="relative h-[190px] w-full overflow-hidden rounded bg-cover bg-top"
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      >
        {tag && (
          <div className="flex-wrap px-5 py-5">
            <BadgeTag>{tag}</BadgeTag>
          </div>
        )}
      </div>
      <div className="py-3 px-4 text-left font-serif text-h1 text-primary">
        {title}
      </div>
      {concerns && concerns.length > 0 && (
        <div className="flex flex-wrap justify-start gap-3 px-4 pb-3">
          {concerns?.slice(0, 3).map((concern) => (
            <div key={concern}>
              <DescriptiveTag color="secondary" size="medium">
                {concern}
              </DescriptiveTag>
            </div>
          ))}
        </div>
      )}
      {includedList && includedList.length > 0 && (
        <div className="flex flex-col justify-center gap-3 px-4 pb-3 text-body2 text-primary">
          <ul className="list-outside list-disc pl-5">
            {includedList?.slice(0, 3).map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      )}
      <div className="flex h-[50px] w-full items-center justify-between bg-one px-4">
        <div className="flex w-full items-center">
          <div className="flex w-full flex-row justify-between">
            <div className="text-bold2 text-primary">
              {formatCurrency(price)}/month
            </div>
            <div className="text-body2 text-brand-color">See all benefits</div>
          </div>
        </div>
        <Icon svg={ChevronRight} size="medium" color="brandColor" />
      </div>
    </div>
  );
};
