import cx from "classnames";
import { FC, MouseEventHandler, SVGProps } from "react";

import { Icon } from "../icon/Icon";

const StyleToIconColor = {
  camera: "white",
  solid: "white",
  iconOnly: "primaryText",
  brandIcon: "brandColor",
  inactive: "lightGrey",
} as const;

const SizeToIconSize = {
  camera: "medium",
  large: "medium",
  small: "xsmall",
  xsmall: "x2small",
} as const;

interface IconButtonProps {
  /** The actual svg component to render (imports from component/icon/icons) */
  svg: FC<SVGProps<SVGSVGElement>>;

  /** Optionally disable the button */
  disabled?: boolean;

  /** Callback to handle button click events */
  onClick?: MouseEventHandler<HTMLButtonElement>;

  /** Specify the button size */
  size?: "xsmall" | "small" | "large" | "camera";

  /** Specify the button variant */
  style?: "solid" | "iconOnly" | "camera" | "brandIcon" | "inactive";

  /** Specify the testid for current component */
  "data-testid"?: string;
}

/**
 * Similar to `Icon`, but nested inside a button element
 */
export const IconButton = ({
  svg,
  disabled,
  onClick,
  size = "large",
  style = "solid",
  "data-testid": dataTestId,
}: IconButtonProps) => {
  const sizes = {
    camera: "p-[10px]",
    small: "p-[10px]",
    large: "p-3",
    xsmall: "py-[10px] px-0",
  };

  const styles = {
    solid:
      "bg-brand-color enabled:active:brightness-125 disabled:bg-light-grey",
    camera:
      "bg-light-grey/30 enabled:active:bg-light-grey/20 enabled:hover:bg-light-grey/40 disabled:bg-light-grey/10",
    iconOnly: "enabled:active:bg-two disabled:opacity-20",
    brandIcon: "enabled:active:bg-two disabled:opacity-20",
    inactive: "enabled:active:bg-two disabled:opacity-20",
  };

  return (
    <button
      className={cx("rounded-full", sizes[size], styles[style])}
      disabled={disabled}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <Icon
        svg={svg}
        size={SizeToIconSize[size]}
        color={StyleToIconColor[style]}
      />
    </button>
  );
};
