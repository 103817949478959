import { AffirmMessageElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { formatCurrency } from "toolbox/Money";

type PricingPreviewProps = {
  defaultPrice: number;
  memberPrice?: number;
  isPriceSet: boolean;
  packageUnitType: string | null | undefined;
  affirmEnabled?: boolean;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY || "");

export const PricingPreview = (props: PricingPreviewProps) => {
  const defaultPricing = (
    <div className="text-body1">
      {props.isPriceSet ? "" : "From "}
      <span className="text-bold1">{formatCurrency(props.defaultPrice)}</span>
      {props.isPriceSet ? "" : ` per ${props.packageUnitType}`}
    </div>
  );

  const memberPricing = (
    <div className="text-body1">
      <span className="text-body1">{props.isPriceSet ? "" : "From "}</span>
      <span className="pr-[6px] text-body1">
        {formatCurrency(props.defaultPrice)}
      </span>
      <span className="border-l border-black pl-[6px] text-bold1 text-primary">
        {props.memberPrice ? formatCurrency(props.memberPrice) : ""} Member
      </span>
    </div>
  );

  return (
    <div>
      <div className="flex flex-col items-center pb-2">
        {props.memberPrice ? memberPricing : defaultPricing}
      </div>
      {props.affirmEnabled && (
        <Elements stripe={stripePromise}>
          <AffirmMessageElement
            options={{
              amount: props.memberPrice ?? props.defaultPrice,
              currency: "USD",
              fontColor: "#6B7070",
              fontSize: "15px",
              textAlign: "center",
            }}
          />
        </Elements>
      )}
    </div>
  );
};
