import { useRef } from "react";

import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right_small.svg";
import { DescriptiveTag } from "atoms/tag/DescriptiveTag";
import { ProductBenefitTag } from "atoms/tag/ProductBenefitTag";
import { formatCurrency } from "toolbox/Money";

interface ProductCardLargeProps {
  /** Title of Product */
  title: string;

  /** Description of Product */
  description?: string | null;

  /** Product Image */
  imageUrl?: string | null;

  /** Product Type (ie: Treatment or Package) */
  type: "Treatment" | "Package";

  /** Tag for Product (ie: Best Seller, New, etc.) */
  tag?: string | null;

  /** List of concerns for Product */
  concerns?: Array<string> | null;

  /** Standard Product cost (in smallest monetary unit) */
  price: number;

  /** Member Product cost (in smallest monetary unit) */
  memberPrice?: number | null;

  /** Called when clicking this item */
  onClick?: () => void;
}

export const ProductCardLarge = ({
  title,
  description,
  imageUrl,
  type,
  tag,
  concerns,
  price,
  memberPrice,
  onClick,
}: ProductCardLargeProps) => {
  const tags: React.MutableRefObject<HTMLDivElement[]> = useRef([]);
  return (
    <div
      className="cursor-pointer flex-col rounded-lg bg-white pt-2 shadow-subtle"
      onClick={onClick}
    >
      <div className="flex justify-between pl-3 pr-2 pb-3">
        <div className="flex min-w-0 flex-col pt-1">
          <div className="flex gap-1 pb-3">
            <DescriptiveTag color="secondary" size="small">
              {type}
            </DescriptiveTag>
            {tag && (
              <DescriptiveTag color="primary" size="small">
                {tag}
              </DescriptiveTag>
            )}
          </div>
          <div className="break-words pr-2 text-left font-serif text-h3 text-primary line-clamp-2">
            {title}
          </div>
        </div>
        {imageUrl && (
          <div className="h-[81px] w-[108px]">
            <img
              className="h-[81px] w-[108px] max-w-none rounded object-cover"
              src={imageUrl}
              height={100}
            />
          </div>
        )}
      </div>
      {description && (
        <div className="mb-3 pl-3 pr-2 text-left text-body2 text-secondary line-clamp-3">
          {description}
        </div>
      )}
      {concerns && concerns.length > 0 && (
        <div className="flex flex-wrap justify-start gap-1 overflow-hidden pl-3 pr-2 pb-3">
          {concerns?.map((concern) => (
            <div
              className="flex flex-col gap-2"
              key={concern}
              ref={(tag) => {
                if (tag != null) {
                  tags.current.push(tag);
                }
              }}
            >
              <ProductBenefitTag>{concern}</ProductBenefitTag>
            </div>
          ))}
        </div>
      )}
      <div className="mt-1 flex w-full items-center justify-between rounded-b-lg bg-one py-3 pl-3 pr-2">
        <div className="flex">
          <span className="flex text-body2 text-secondary">
            From {formatCurrency(price)}
          </span>
          {!!memberPrice && (
            <>
              <span className="-mt-[1px] pl-1.5 text-[17px] text-body2 text-primary">
                {" "}
                |{" "}
              </span>
              <span className="pl-1.5 text-body2 text-primary">
                {formatCurrency(memberPrice)} Member
              </span>
            </>
          )}
        </div>
        <Icon svg={ChevronRight} size="xsmall" color="brandColor" />
      </div>
    </div>
  );
};
