import { useQuery } from "@tanstack/react-query";
import cx from "classnames";
import { useEffect, useRef } from "react";
import { NavLink, Outlet, useMatch } from "react-router-dom";

import { get } from "api";
import TopNav from "molecules/navbars/TopNav";
import { checkIfListingsHaveConcerns } from "services/HelperService";

type NavButtonProps = { exact?: boolean; to: string; children: string };
const NavButton = ({ exact, to, children }: NavButtonProps) => {
  const matches = useMatch(to);
  const itemRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (itemRef.current && matches) {
      itemRef.current?.scrollIntoView({ behavior: "smooth", inline: "start" });
    }
  }, [matches]);
  return (
    <NavLink
      to={to}
      end={exact}
      className={(p) =>
        cx(
          "scroll-ml-[20px] pb-[10px]",
          p.isActive && "border-b-2 border-brand-color text-brand-color"
        )
      }
      ref={itemRef}
    >
      {children}
    </NavLink>
  );
};

const ShopNav = () => {
  const { data: listings, isFetched: isListingsFetched } = useQuery({
    queryKey: ["/listings"],
    queryFn: () => get("/listings"),
    staleTime: 1000 * 60,
  });
  const { data: memberships, isFetched: isMembershipsFetched } = useQuery({
    queryKey: ["/memberships"],
    queryFn: () => get("/memberships"),
  });
  const { data: shopData, isFetched: isShopDataFetched } = useQuery({
    queryKey: ["/shop"],
    queryFn: () => get("/shop"),
  });
  const { data: customShopCategories, isFetched: isShopCategoriesFetched } =
    useQuery({
      queryKey: ["/shop/custom-categories/listings"],
      queryFn: () => get("/shop/custom-categories/listings"),
    });
  const customShopCategory = customShopCategories?.[0] ?? null;
  const customShopCategoryTo =
    customShopCategory?.packageListings.length === 1
      ? `/pdp/package/${customShopCategory.packageListings[0].id}`
      : `/shop/custom-category/${customShopCategory?.category.id}`;
  const treatments = listings?.filter((listing) => listing.type == "Treatment");
  const packages = listings?.filter((listing) => listing.type == "Package");
  const isAllFetched =
    // it's necenssry to check that these are all fetched before rendering the nav
    // to support scrolling the nav to show the active item
    isListingsFetched &&
    isMembershipsFetched &&
    isShopDataFetched &&
    isShopCategoriesFetched;

  return (
    <div className="flex flex-row gap-4 overflow-x-auto whitespace-nowrap px-6 pt-[4px] text-bold2 text-secondary">
      <NavButton exact to="/shop">
        Browse
      </NavButton>
      {isAllFetched && (
        <>
          {customShopCategory &&
            customShopCategory.packageListings.length > 0 && (
              <NavButton to={customShopCategoryTo}>
                {`${customShopCategory.category.subTitle}`}
              </NavButton>
            )}
          {memberships && memberships.length > 0 && (
            <NavButton to="/shop/memberships">Memberships</NavButton>
          )}
          {treatments && treatments.length > 0 && (
            <NavButton to="/shop/treatments">Treatments</NavButton>
          )}
          {packages && packages.length > 0 && (
            <NavButton to="/shop/packages">Packages</NavButton>
          )}
          {shopData?.concerns &&
            shopData.concerns.length > 0 &&
            checkIfListingsHaveConcerns(listings) && (
              <NavButton to="/shop/concerns">Browse by concern</NavButton>
            )}
        </>
      )}
    </div>
  );
};

const ShopPage = () => {
  return (
    <>
      <TopNav footer={<ShopNav />}>
        <h1 className="text-xl">Shop</h1>
      </TopNav>
      <Outlet />
    </>
  );
};

export default ShopPage;
