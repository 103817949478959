import { IconButton } from "atoms/icon-button/IconButton";
import { ReactComponent as Chevron } from "atoms/icon/icons/chevron_down_small.svg";

type DropdownSelectorState = "unselected" | "selected" | "unavailable";

interface DropdownSelectorProps {
  /** Selector text */
  text: string;

  /**  Optional secondary text */
  secondaryText?: string;

  /** Optional text displayed to the right of the main text */
  tagText?: string;

  /** The display state of the selector */
  state: DropdownSelectorState;

  /** Callback when selector is clicked */
  onClick?: () => void;
}

export const DropdownSelector = ({
  text,
  secondaryText,
  tagText,
  state = "unselected",
  onClick,
}: DropdownSelectorProps) => {
  const primaryTextStyling = {
    unselected: "text-bold2 text-secondary",
    selected: "text-bold2 text-primary",
    unavailable: "text-bold2 text-dark-grey",
  };

  const tagTextStyling = {
    selected: "px-1 text-body3 text-secondary",
    unavailable: "px-1 text-body3 text-dark-grey",
  };

  const secondaryTextStyling = {
    selected: "text-body3 text-secondary line-clamp-3",
    unavailable: "text-body3 text-dark-grey line-clamp-3",
  };

  return (
    <div
      className="flex w-full items-center justify-between rounded border border-light-grey px-2 py-[5px]"
      onClick={onClick}
    >
      <div className="pl-3">
        <div className="flex items-center"></div>
        <span className={primaryTextStyling[state]}>{text}</span>
        {state != "unselected" && tagText && (
          <span className={tagTextStyling[state]}>{tagText}</span>
        )}
        {state != "unselected" && secondaryText && (
          <div className="py-1">
            <div className={secondaryTextStyling[state]}>{secondaryText}</div>
          </div>
        )}
      </div>
      <IconButton
        svg={Chevron}
        size="small"
        style="iconOnly"
        onClick={onClick}
      ></IconButton>
    </div>
  );
};
