import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { get } from "api";
import { AffirmBanner } from "atoms/affirm/affirmBanner/AffirmBanner";
import { Banner } from "atoms/banner/Banner";
import { ReactComponent as Quiz } from "atoms/icon/icons/ic_quiz_ol.svg";
import { ReactComponent as Discover } from "atoms/icon/icons/offers_nav.svg";
import { ReactComponent as Star } from "atoms/icon/icons/star.svg";
import { Notification, useNotify } from "atoms/notification";
import useDunningMembership from "hooks/useDunningMemberships";
import { AffirmBannerLarge } from "molecules/affirm-banner/AffirmBannerLarge";
import { AffirmModal } from "molecules/affirm-modal/AffirmModal";
import { BannersContainer } from "molecules/containers/banners-container/BannersContainer";
import CampaignCard from "organisms/campaign-card/CampaignCard";
import Carousel from "organisms/carousel-with-breadcrumb/Carousel";
import MembershipPromo from "organisms/memberships-promo/MembershipsPromo";
import ProductCarousel from "organisms/product-carousel/ProductCarousel";
import { ReferAFriend } from "organisms/refer-a-friend/ReferAFriend";
import ShopFeature from "organisms/shop/shop-feature/ShopFeature";
import PromotionHero from "organisms/shop/site-wide-promotion-hero/PromotionHero";
import ShowSiteWidePromotion from "organisms/site-wide-promotion-modal/SiteWidePromotionModal";
import { queryClient } from "pages/Root";
import { useFeatureFlag } from "services/Flags";
import { checkIfListingsHaveConcerns } from "services/HelperService";
import { notFalsy } from "toolbox/Guards";
import { waitObj } from "toolbox/Promise";

import CategoriesSection from "./CategoriesSection";

const load = async () => {
  const platform = queryClient.fetchQuery(["/platform"], () =>
    get("/platform")
  );

  const shopConfig = queryClient.fetchQuery(["shop"], () => get("/shop"));

  const listings = queryClient.fetchQuery(
    ["/listings"],
    () => get("/listings"),
    { staleTime: 1000 * 60 }
  );

  const promotions = queryClient.fetchQuery(
    ["/promotions"],
    () => get("/promotions"),
    { staleTime: 1000 * 60 * 2 }
  );

  const memberships = queryClient.fetchQuery(["/memberships"], () =>
    get("/memberships")
  );
  const categories = queryClient.fetchQuery(["shop/categories"], () =>
    get("/shop/categories")
  );
  const customCategoriesList = queryClient.fetchQuery(
    ["/shop/custom-categories/listings"],
    () => get("/shop/custom-categories/listings")
  );
  return waitObj({
    platform,
    shopConfig,
    listings,
    promotions,
    memberships,
    categories,
    customCategoriesList,
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const shopIndexLoader: LoaderFunction = async () => {
  const data = await load();
  if (!data.platform.platformSettings?.marketplaceEnabled) {
    return redirect("/home");
  }

  return json<LoaderData>(data);
};

export const shopOnLoadNotificationTypes = {
  notFound: "not-found",
};

const ShopIndex = () => {
  const [searchParams] = useSearchParams();
  const initialNotificationType = searchParams.get("notification") || "";
  const navigate = useNavigate();
  const {
    platform,
    shopConfig,
    listings,
    promotions,
    categories,
    memberships,
    customCategoriesList,
  } = useLoaderData() as LoaderData;
  const flagSiteWidePromotions = useFeatureFlag("site-wide-promotions");

  const shopFeature = shopConfig?.shopFeatures?.[0];
  const membershipFeature = shopConfig?.membershipFeatures?.[0];
  const [showAffirmModal, setShowAffirmModal] = useState(false);
  const { dunningMembership } = useDunningMembership();
  const [notifyState, { info }] = useNotify();

  useEffect(() => {
    if (shopOnLoadNotificationTypes.notFound === initialNotificationType) {
      info("Sorry, we couldn't find the page you're looking for", {
        timeout: 5000,
        dismissable: false,
      });
    }
  }, [initialNotificationType, info]);

  const sortedCustomCategoryPromotions = customCategoriesList
    .filter((listItem) => listItem.promotion !== null)
    .sort((a, b) => {
      const expiresOnA = a.promotion?.expiresOn
        ? new Date(a.promotion?.expiresOn)
        : null;
      const expiresOnB = b.promotion?.expiresOn
        ? new Date(b.promotion?.expiresOn)
        : null;

      if (expiresOnA === null && expiresOnB === null) {
        return 0;
      }

      if (expiresOnA === null) {
        return 1;
      }

      if (expiresOnB === null) {
        return -1;
      }

      return expiresOnA.getTime() - expiresOnB.getTime();
    });

  const topSellingTreatmentCards = listings
    .sort((a, b) => b.sellVolume - a.sellVolume)
    .map((item) => {
      if (!item.imageUrl || !item.title || !item.baseCost) {
        return null;
      }
      return {
        to: `/pdp/package/${item.id}`,
        imageSrc: item.imageUrl,
        tag: item.type,
        title: item.title,
        price: item.baseCost,
        memberPrice: item.membershipCost,
      };
    })
    .filter(notFalsy)
    .slice(0, 5);

  const specialOffersCards = promotions
    .filter((promotion) => !promotion.redeemedOn)
    .map(
      (promotion) =>
        promotion && {
          to: "/discover?promotion=" + promotion?.id,
          tag: "Promotion",
          title: promotion.title,
          imageSrc: promotion.imageUrl ?? "",
          primary: "View Offer",
          alert:
            promotion.expiresOn &&
            `Expires ${dayjs(promotion.expiresOn).fromNow()}`,
        }
    )
    .slice(0, 5);
  const marketplaceEnabled = platform.platformSettings?.marketplaceEnabled;
  const customCategory = customCategoriesList.find(
    (listItem) => listItem.category && listItem.packageListings.length > 0
  );

  const bannerContainerShow = dunningMembership.status === false;

  const bannerAffirmShow = platform.platformSettings?.affirmEnabled;
  const bannerSitewidePromotionShow = !!customCategory;

  const promotion = customCategory?.promotion;
  const category = customCategory?.category;

  return (
    <>
      <Notification {...notifyState} />
      <AffirmModal
        platformLogo={platform.logoImageUrl ?? ""}
        open={showAffirmModal}
        hideShopButton
        onClose={() => setShowAffirmModal(false)}
      />
      {bannerContainerShow && (
        <BannersContainer>
          {bannerAffirmShow && (
            <AffirmBanner onClick={() => setShowAffirmModal(true)} />
          )}
          {flagSiteWidePromotions && bannerSitewidePromotionShow && (
            <Banner
              titleColor={promotion?.textColor ?? undefined}
              title={promotion?.title}
              backgroundColor={promotion?.backgroundColor ?? undefined}
              summary={promotion?.description ?? undefined}
              onClick={() => navigate(`/shop/custom-category/${category?.id}`)}
              arrow={true}
            ></Banner>
          )}
        </BannersContainer>
      )}
      {flagSiteWidePromotions && customCategory?.promotion && (
        <ShowSiteWidePromotion
          promotion={customCategory.promotion!}
          categoryId={customCategory.category.id}
        />
      )}
      <Carousel
        showBreadCrumb={
          flagSiteWidePromotions &&
          promotion?.imageUrl != null &&
          (shopFeature ? 1 : 0) + sortedCustomCategoryPromotions.length > 1
        }
      >
        {flagSiteWidePromotions &&
          promotion?.imageUrl != null &&
          sortedCustomCategoryPromotions.map((listItem, index) => (
            <div
              className="min-w-full snap-center"
              key={listItem.category.id}
              data-slideindex={index + 1}
            >
              {listItem.promotion && (
                <PromotionHero
                  {...listItem.promotion}
                  id={listItem.category.id}
                />
              )}
            </div>
          ))}
        {shopFeature && (
          <div
            className="min-w-full snap-center"
            data-slideindex={sortedCustomCategoryPromotions.length + 1}
          >
            <ShopFeature
              {...shopFeature}
              showBreadCrumbs={
                flagSiteWidePromotions &&
                sortedCustomCategoryPromotions.length > 0
              }
            />
          </div>
        )}
      </Carousel>
      {marketplaceEnabled && membershipFeature && memberships?.length > 0 && (
        <MembershipPromo {...membershipFeature} memberships={memberships} />
      )}
      {dunningMembership.status === false &&
        platform.platformSettings?.affirmEnabled && (
          <AffirmBannerLarge
            platformLogo={platform.logoImageUrl}
            onClick={() => setShowAffirmModal(true)}
          />
        )}

      {listings.length > 0 && (
        <ProductCarousel
          svg={Star}
          title="top selling treatments"
          cards={topSellingTreatmentCards}
        />
      )}
      <CategoriesSection
        affirmEnabled={platform.platformSettings?.affirmEnabled}
        categories={categories}
        customCategory={customCategory}
        hasMemberships={(memberships && memberships?.length > 0) || false}
        hasTreatments={listings.some((listing) => listing.type === "Treatment")}
        hasPackages={listings.some((listing) => listing.type === "Package")}
      />
      {checkIfListingsHaveConcerns(listings) && (
        <div className="bg-white">
          <CampaignCard
            svg={Quiz}
            subtitle="browse by concern"
            title="Which treatments are best for me?"
            description="Browse by concern to find the treatments that you will love!"
            primary="Browse by concern"
            onButtonClick={() => navigate("/shop/concerns")}
          />
        </div>
      )}
      {specialOffersCards.length > 0 && (
        <div className="bg-one">
          <ProductCarousel
            svg={Discover}
            title="special offers"
            cards={specialOffersCards}
          />
        </div>
      )}
      <ReferAFriend withRepeatMDFooter />
    </>
  );
};

export default ShopIndex;
