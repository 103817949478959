import type { components, paths } from "./generated";

type Rel<Path> = Path extends `/api/v1${infer S}` ? S : never;
type Full<Path> = Path extends string ? `/api/v1${Path}` : never;

export type GetPaths = {
  [Path in keyof paths]: paths[Path] extends { get: unknown }
    ? Rel<Path>
    : never;
}[keyof paths];

export type PostPaths = {
  [Path in keyof paths]: paths[Path] extends { post: unknown }
    ? Rel<Path>
    : never;
}[keyof paths];

export type PutPaths = {
  [Path in keyof paths]: paths[Path] extends { put: unknown }
    ? Rel<Path>
    : never;
}[keyof paths];

export type DeletePaths = {
  [Path in keyof paths]: paths[Path] extends { delete: unknown }
    ? Rel<Path>
    : never;
}[keyof paths];

export type RawGetResult<Path extends GetPaths> =
  paths[Full<Path>]["get"]["responses"][200]["content"]["application/json"];

export type RawPostResult<Path extends PostPaths> =
  paths[Full<Path>]["post"]["responses"][200]["content"]["application/json"];

export type RawPutResult<Path extends PutPaths> =
  paths[Full<Path>]["put"]["responses"][200]["content"]["application/json"];

/**
 * Utility type to look up an API request model by its GET route
 */
export type GetRequest<Path extends GetPaths> =
  paths[Full<Path>]["get"] extends {
    parameters?: { query?: infer Query; path?: infer Args };
  }
    ? Query & Args
    : undefined;

/**
 * Utility type to look up an API result model by its GET route
 */
export type GetResult<Path extends GetPaths> = NonNullable<
  paths[Full<Path>]["get"]["responses"][200]["content"]["application/json"]["result"]
>;

/**
 * Utility type to look up an API request model by its POST route
 */
export type PostRequest<Path extends PostPaths> =
  paths[Full<Path>]["post"] extends {
    parameters?: { path?: infer Args };
    requestBody?: { content: { "application/json": infer Request } };
  }
    ? Request extends unknown[] | string
      ? { $data: Request } & Args
      : Request & Args
    : undefined;

/**
 * Utility type to look up an API result model by its POST route
 */
export type PostResult<Path extends PostPaths> = NonNullable<
  paths[Full<Path>]["post"]["responses"][200]["content"]["application/json"]["result"]
>;
/**
 * Utility type to look up an API request model by its PUT route
 */
export type PutRequest<Path extends PutPaths> =
  paths[Full<Path>]["put"] extends {
    parameters?: { path?: infer Args };
    requestBody?: { content: { "application/json": infer Request } };
  }
    ? Request extends unknown[]
      ? { $data: Request } & Args
      : Request & Args
    : undefined;

/**
 * Utility type to look up an API result model by its PUT route
 */
export type PutResult<Path extends PutPaths> = NonNullable<
  paths[Full<Path>]["put"]["responses"][200]["content"]["application/json"]["result"]
>;

/**
 * Utility type to look up an API request model by its DELETE route
 */
export type DeleteRequest<Path extends DeletePaths> =
  paths[Full<Path>]["delete"] extends {
    parameters?: { path?: infer Args };
    requestBody?: { content: { "application/json": infer Request } };
  }
    ? Request extends unknown[]
      ? { $data: Request } & Args
      : Request & Args
    : undefined;

/**
 * Utility type to look up an API model by its name
 */
export type Models<T extends keyof components["schemas"]> =
  components["schemas"][T];

export enum RewardStatus {
  NotAvailable,
  Available,
  Redeemed,
  CashedIn,
}

export enum ShopFeatureTarget {
  None,
  Treatment,
  Package,
  Membership,
  Category,
  CustomUri,
}

export enum DiscountType {
  Percentage,
  Cash,
}
