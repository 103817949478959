import { datadogRum } from "@datadog/browser-rum-slim";
import { useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { Navigate, Outlet, useNavigate, useRouteError } from "react-router-dom";

import { get } from "api";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Left } from "atoms/icon/icons/chevron_left.svg";
import { ReactComponent as PageError } from "atoms/icon/icons/page_error.svg";
import { TextButton } from "atoms/text-button/TextButton";
import useHistory from "hooks/useHistory";
import TopNav from "molecules/navbars/TopNav";
import { identifyUser } from "services/Flags";
import Services from "services/Services";
import { setPromotionModalSeen } from "utils/functions";

import LoadingLayout from "./LoadingLayout";

/**
 * Layout route for authenticated users (post-login)
 */
const AppLayoutNoBottomNav = () => {
  const { data: user } = useQuery({
    queryKey: ["/user"],
    queryFn: () => get("/user"),
    staleTime: 1000 * 60 * 2,
    onSuccess: (data) => {
      let platformContext = undefined;
      if (data.platformDomain) {
        platformContext = {
          id: data.platformDomain.platformId,
          domain: data.platformDomain.domain || undefined,
        };
      }

      identifyUser(
        {
          id: data.id,
          firstName: data.firstName ?? undefined,
          lastName: data.lastName ?? undefined,
          email: data.email ?? undefined,
        },
        platformContext
      );
    },
  });
  return (
    <>
      <LoadingLayout />

      <div className="flex flex-auto">
        <div className="w-full">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export const AppLayoutErrorNoBottomNav = () => {
  const { routeBackNumber } = useHistory();
  const navigate = useNavigate();
  const error = useRouteError();

  const { data: logo } = useQuery({
    queryKey: ["/platform"],
    queryFn: () => get("/platform"),
    select: (data) => data.logoImageUrl,
  });

  if (isAxiosError(error) && error.response?.status === 401) {
    setPromotionModalSeen(false);
    return <Navigate to="/signin" />;
  }

  return (
    <>
      <TopNav>{logo && <img src={logo} className="h-8" />}</TopNav>

      <div className="flex flex-auto flex-col items-center justify-center gap-10">
        <div className="flex flex-col items-center gap-5">
          <Icon svg={PageError} color="darkGrey" size="medium" />
          <div className="text-body1 text-primary">
            Sorry, something went wrong!
          </div>
        </div>
        <TextButton
          startIconSVG={Left}
          onClick={() => navigate(routeBackNumber)}
        >
          Go Back
        </TextButton>
        <TextButton
          onClick={() => {
            setPromotionModalSeen(false);
            Services.auth.setAuthToken(null);
            datadogRum.clearUser();
            navigate("/signin");
          }}
        >
          Logout
        </TextButton>
      </div>
    </>
  );
};

export default AppLayoutNoBottomNav;
