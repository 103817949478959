import { useQueries, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import {
  ActionFunction,
  Form,
  json,
  LoaderFunction,
  useActionData,
  useLoaderData,
  useNavigate,
  useNavigation,
  useSubmit,
} from "react-router-dom";

import { del, get, post, put } from "api";
import { Models } from "api/types";
import {
  AFFIRM_MINIMUM_AMOUNT,
  AffirmUnavailableReason,
  NotAvailableAffirm,
} from "atoms/affirm/NotAvailableAffirm/NotAvailableAffirm";
import { ShopNowAffirm } from "atoms/affirm/shopNowAffirm/ShopNowAffirm";
import { Button } from "atoms/button/Button";
import { Dropdown } from "atoms/dropdown/Dropdown";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { Spinner } from "atoms/Spinner";
import { TermsAndCondition } from "atoms/terms-and-condition/TermsAndCondition";
import { ApplyRepeatMDCash } from "molecules/containers/cart/apply-repeatmd-cash/ApplyRepeatMDCash";
import { AvailablePromotions } from "molecules/containers/cart/available-promotions/AvailablePromotions";
import { CartItemProps } from "molecules/containers/cart/cart-item/CartItem";
import TopNav from "molecules/navbars/TopNav";
import { CartItemList } from "organisms/cart-item-list/CartItemList";
import { OrderSummary } from "organisms/order-summary/OrderSummary";
import { ModalSelectorList } from "organisms/selection-modals/modal-selector-list/ModalSelectorList";
import { queryClient } from "pages/Root";
import { getProductTitle } from "services/HelperService";
import { notNull } from "toolbox/Guards";
import { formatCurrency } from "toolbox/Money";
import { waitObj } from "toolbox/Promise";
import { readFormData } from "toolbox/ReadFormData";
import { removeArchiveItems } from "utils/functions";

import { RewardUnlockBanner } from "./RewardUnlockBanner";
import { UnableToCheckoutModal } from "./UnableToCheckoutModal";

const load = async (request: Request) => {
  const platform = queryClient.fetchQuery(["platform"], () => get("/platform"));
  const salesAssociates = queryClient.fetchQuery(
    ["platform-location/sales-associates"],
    () => get("/platform-location/sales-associates")
  );
  const membershipDiscount = queryClient.fetchQuery(
    ["orders/current/membership/details"],
    () => get("/orders/current/membership/details")
  );
  const cart = queryClient.fetchQuery({
    queryKey: ["/orders/current/details"],
    queryFn: () => get("/orders/current/details"),
  });
  /* When displaying the page in a mobile webview, we let the app handle displaying its own navbar */
  const showNavbar =
    new URL(request.url).searchParams.get("isMobileApp") !== "true";

  return waitObj({
    platform,
    salesAssociates,
    showNavbar,
    membershipDiscount,
    cart,
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const cartLoader: LoaderFunction = async ({ request }) => {
  return json<LoaderData>(await load(request));
};

type DeleteItemAction = {
  action: "deleteItem";
  shopDisplayOrderLineItemId: string;
};

type EditItemAction = {
  action: "editItem";
  shopDisplayOrderLineItemId: string;
  packageId: string;
  packagePriceId: string;
  packageListingId: string;
};

type PromoCodeAction = {
  action: "promoCodeAction";
  promoCode: string;
};

type RewardAction = {
  action: "rewardAction";
  rewardId: string;
};

type RemovePromoCodeAction = {
  action: "removePromoCodeAction";
  promoCodeId: string;
};

type ApplyCashAction = { action: "applyCash"; useRepeatCash: string };

type SetSalesAssociateAction = {
  action: "setSalesAssociate";
  salesAssociateId: string;
};

type DeleteSalesAssociateAction = {
  action: "deleteSalesAssociate";
};

type CartPageAction =
  | ApplyCashAction
  | DeleteItemAction
  | EditItemAction
  | PromoCodeAction
  | RemovePromoCodeAction
  | SetSalesAssociateAction
  | DeleteSalesAssociateAction
  | RewardAction;

type ActionResult = Awaited<
  ReturnType<typeof applyPromoCode | typeof ondeletePromoCode>
>;

const applyRepeatCash = async (data: ApplyCashAction) => {
  await put("/orders/current", {
    useRepeatCash: data.useRepeatCash === "true",
  });
  queryClient.invalidateQueries({ queryKey: ["/orders/current/details"] });
  return true;
};

const onEditCartItem = async (item: EditItemAction) => {
  await put("/orders/current/lineitems/{shopDisplayOrderLineItemId}", {
    shopDisplayOrderLineItemId: item.shopDisplayOrderLineItemId,
    packageId: item.packageId,
    packagePriceId: item.packagePriceId,
    packageListingId: item.packageListingId,
  });
  queryClient.invalidateQueries({
    queryKey: ["/orders/current/available-promos"],
  });
  queryClient.invalidateQueries({ queryKey: ["/orders/current/details"] });
  return true;
};

const ondeleteCartItem = async (item: DeleteItemAction) => {
  await del("/orders/current/lineitems/{shopDisplayOrderLineItemId}", {
    shopDisplayOrderLineItemId: item.shopDisplayOrderLineItemId,
  });
  queryClient.invalidateQueries({
    queryKey: ["/orders/current/available-promos"],
  });
  queryClient.invalidateQueries({ queryKey: ["/orders/current/details"] });
  return true;
};

const discountAppliedMessage = (
  promoCode: Models<"ShopDisplayOrderDiscountSummaryLineItem"> | undefined,
  shopDisplayOrderLineItems: Models<"ShopDisplayOrderLineItemModel">[]
) => {
  return (
    promoCode &&
    (`${
      promoCode?.percentOff
        ? `${(promoCode?.percentOff ?? 0) * 100}%`
        : formatCurrency(promoCode?.amountOff ?? 0)
    } off ${
      shopDisplayOrderLineItems?.find((item) => {
        return promoCode?.shopDisplayOrderLineItemId === item.id;
      })?.name ?? ""
    } applied` ||
      "")
  );
};

const applyPromoCode = async (data: {
  action: "promoCodeAction";
  promoCode: string;
}) => {
  try {
    const response = await post("/orders/current/promoCode", {
      // @ts-expect-error body type is marked as `object` in the API, but it actually expects a string
      $data: data.promoCode,
    });
    queryClient.invalidateQueries({ queryKey: ["/orders/current/details"] });
    const promoCodeDiscount = response.discountSummary?.discounts?.find(
      (discount) => discount.promoCode != null
    );
    const coupon = response.discountSummary?.discounts?.find(
      (discount) => discount.promoCode
    )?.promoCode;
    return {
      success: discountAppliedMessage(
        promoCodeDiscount,
        response.shopDisplayOrderLineItems || []
      ),
      discountId: promoCodeDiscount?.discountId,
      action: "promoCodeAction" as const,
      coupon: coupon,
    };
  } catch (error) {
    return {
      error: "Invalid Code. Please enter a valid code",
      action: "promoCodeAction" as const,
    };
  }
};

const ondeletePromoCode = async (item: RemovePromoCodeAction) => {
  try {
    await del("/orders/current/promoCode/{promoCodeId}", {
      promoCodeId: item.promoCodeId,
    });
    queryClient.invalidateQueries({ queryKey: ["/orders/current/details"] });
    queryClient.invalidateQueries({
      queryKey: ["/orders/current/available-promos"],
    });
    return { deleted: true, action: "deletePromoCode" as const };
  } catch (error) {
    return {
      error: "Not able to remove promo code from cart. try again later",
      action: "deletePromoCode" as const,
    };
  }
};

const setSalesAssociate = async (data: SetSalesAssociateAction) => {
  try {
    await post("/orders/current/sales-associate", {
      $data: data.salesAssociateId,
    });
    queryClient.invalidateQueries({ queryKey: ["/orders/current/details"] });
    return {
      action: "setSalesAssociate" as const,
    };
  } catch (error) {
    return {
      action: "setSalesAssociate" as const,
    };
  }
};

const deleteSalesAssociate = async () => {
  try {
    await del("/orders/current/sales-associate", undefined);
    queryClient.invalidateQueries({ queryKey: ["/orders/current/details"] });
    return {
      action: "deleteSalesAssociate" as const,
    };
  } catch (error) {
    return {
      action: "deleteSalesAssociate" as const,
    };
  }
};

const applyReward = async (data: {
  action: "rewardAction";
  rewardId: string;
}) => {
  try {
    const response = await post("/rewards/redeem", {
      $data: data.rewardId,
    });
    queryClient.invalidateQueries({ queryKey: ["/orders/current/details"] });
    return true;
  } catch (error) {
    return {
      error: "Invalid Code. Please enter a valid code",
      action: "promoCodeAction" as const,
    };
  }
};

export const cartPageAction: ActionFunction = async ({ request }) => {
  const data = await readFormData<CartPageAction>(request);
  if (data.action === "applyCash") {
    return json(await applyRepeatCash(data));
  } else if (data.action === "deleteItem") {
    return json(await ondeleteCartItem(data));
  } else if (data.action === "editItem") {
    return json(await onEditCartItem(data));
  } else if (data.action === "promoCodeAction") {
    return json(await applyPromoCode(data));
  } else if (data.action === "removePromoCodeAction") {
    return json(await ondeletePromoCode(data));
  } else if (data.action === "setSalesAssociate") {
    return json(await setSalesAssociate(data));
  } else if (data.action === "deleteSalesAssociate") {
    return json(await deleteSalesAssociate());
  } else if (data.action === "rewardAction") {
    return json(await applyReward(data));
  } else {
    throw Error("Should be unreachable");
  }
};

const CartPage = () => {
  const { platform, salesAssociates, showNavbar, membershipDiscount, ...data } =
    useLoaderData() as LoaderData;

  const { state } = useNavigation();
  const { data: cartDetails, isFetching } = useQuery({
    queryKey: ["/orders/current/details"],
    queryFn: () => get("/orders/current/details"),
    initialData: data.cart,
  });

  const { data: availablePromos } = useQuery({
    queryKey: ["/orders/current/available-promos"],
    queryFn: () => get("/orders/current/available-promos"),
  });

  const itemIdMap = useQueries({
    queries:
      cartDetails?.shopDisplayOrderLineItems?.map((item) => ({
        queryKey: ["/shop/displays/{shopDisplayId}", item.shopDisplayId],
        queryFn: () =>
          get("/shop/displays/{shopDisplayId}", {
            shopDisplayId: item.shopDisplayId,
          }),
      })) || [],
  }).reduce((acc, item) => {
    if (!item.data) {
      return { ...acc };
    }
    return { ...acc, [item.data.id]: item.data };
  }, {} as Record<string, Models<"GetShopDisplayResult">>);
  const promoCode = cartDetails?.discountSummary?.discounts?.find(
    (discount) => discount.promoCode
  );

  const discountMessage = discountAppliedMessage(
    promoCode,
    cartDetails?.shopDisplayOrderLineItems || []
  );
  const navigate = useNavigate();
  const actionData = useActionData() as ActionResult | undefined;
  const _submit = useSubmit();
  const submit: typeof _submit = (args, opts) =>
    _submit(args, { ...opts, replace: true });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    discountMessage
  );
  const [shopDisplay, setShopDisplay] =
    useState<Models<"GetShopDisplayResult">>();
  const [originalPackagePriceId, setOriginalPackagePriceId] =
    useState<string>("");
  const [newPackagePriceId, setNewPackagePriceId] = useState<string>("");
  const [editShopDisplayOrderLineItemId, setEditShopDisplayOrderLineItemId] =
    useState<string>("");
  const [originalShopDisplayPackageId, setOriginalShopDisplayPackageId] =
    useState<string>("");
  const [newShopDisplayPackageId, setNewShopDisplayPackageId] =
    useState<string>("");

  const [isTreatmentTypeSelected, setIsTreatmentTypeSelected] =
    useState<boolean>(false);
  const [checkoutPromoModalOpen, setCheckoutPromoModalOpen] =
    useState<boolean>(false);

  const [hasMoreDiscounts, setHasMoreDiscounts] = useState<boolean>(false);
  const disableApplyButton =
    originalPackagePriceId == newPackagePriceId || newPackagePriceId == "";

  const packages = shopDisplay?.shopDisplayPackages ?? [];

  const packagesPrices =
    shopDisplay?.shopDisplayPackages?.find(
      (item) => item.id == newShopDisplayPackageId
    )?.package?.packagePrices ?? [];

  const [preventCheckout, setPreventCheckout] = useState<boolean>(true);
  const [showUnableToCheckoutModal, setshowUnableToCheckoutModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (checkoutPromoModalOpen) {
      setPreventCheckout(false);
    }
  }, [checkoutPromoModalOpen]);

  const getTopNav = () => {
    return (
      showNavbar && (
        <TopNav
          startIconSVG={ChevronLeft}
          onStartIconClick={() => {
            navigate(-1);
          }}
          showHeaderItems={false}
        >
          <div className="flex max-h-[32px] items-center">
            <div className="flex w-full items-center justify-center pr-6 text-sub2 text-primary">
              YOUR SHOPPING CART
            </div>
          </div>
        </TopNav>
      )
    );
  };
  const goBackButton = (placeholder: string) => {
    return (
      <>
        {getTopNav()}
        <div className="px-4">
          <div className="py-16 text-center font-serif text-h3">
            {placeholder}
          </div>
          <Button fullWidth={true} onClick={() => navigate("/shop")}>
            Browse treatments & packages
          </Button>
        </div>
      </>
    );
  };

  const [coupon, setCoupon] = useState<string>("");
  const [rewardId, setRewardId] = useState<string>("");
  const [discountId, setDiscountId] = useState<string | undefined>("");
  const [couponAmountOff, setCouponAmountOff] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!cartDetails) return;
    setCoupon(promoCode?.promoCode ?? "");
    setRewardId(promoCode?.rewardId ?? "");
    setDiscountId(promoCode?.discountId);
    promoCode && promoCode?.amountOff > 0
      ? setCouponAmountOff(promoCode?.amountOff)
      : setCouponAmountOff(promoCode?.membershipsDiscountTotal ?? 0);
    if (actionData?.action === "promoCodeAction") setLoading(false);
  }, [cartDetails, promoCode, actionData]);

  const [salesAssociateValue, setSalesAssociateValue] = useState<string>("");
  const [salesAssociateValueText, setSalesAssociateValueText] =
    useState<string>("");

  useEffect(() => {
    if (!cartDetails) {
      return;
    }
    removeArchiveItems(cartDetails);
    setSalesAssociateValue(cartDetails.salesAssociateId ?? "");
    setSuccessMessage(discountMessage);
  }, [cartDetails]);
  useEffect(() => {
    if (actionData?.action === "promoCodeAction") {
      setSuccessMessage(actionData.success);
      setDiscountId(actionData.discountId);
      setCoupon(actionData.coupon ?? "");
    }
  }, [actionData]);
  useEffect(() => {
    if (!salesAssociates) {
      return;
    }

    if (salesAssociateValue == "") {
      return;
    }

    const salesAssociate = salesAssociates.find(
      (salesAssociate) => salesAssociate.id == salesAssociateValue
    );

    if (salesAssociate) {
      setSalesAssociateValueText(
        `${salesAssociate.firstName} ${salesAssociate.lastName}`
      );
    }
  }, [salesAssociateValue, salesAssociates]);
  if (
    !cartDetails.shopDisplayOrderLineItems ||
    cartDetails?.shopDisplayOrderLineItems?.length == 0
  )
    return goBackButton("Your shopping cart is empty");

  const containsMembership =
    cartDetails.shopDisplayOrderLineItems?.find(
      (item) => item.shopDisplayMembershipId != null
    ) != null;

  const containsPackageOrTreatment =
    cartDetails.shopDisplayOrderLineItems?.some(
      (item) => item.shopDisplayPackageId != null
    );
  const cartItems: CartItemProps[] = cartDetails.shopDisplayOrderLineItems
    ? cartDetails.shopDisplayOrderLineItems?.filter(Boolean).map((item) => {
        const itemTitle = getProductTitle(item.productName, item.name);
        return {
          id: item.id,
          imgUrl: item.imageUrl,
          type: item.displayType,
          discount:
            cartDetails.discountSummary?.discounts?.find(
              (discountItem) =>
                discountItem.shopDisplayOrderLineItemId === item.id
            )?.packagesDiscountTotal ||
            item.discountTotal ||
            0,
          title: itemTitle,
          price: item.cost,
          packageTitle:
            item.displayType == 2
              ? "Billed monthly*"
              : item.quantity == 1
              ? `${item.quantity} ${
                  item.package?.packageUnitType.singularUnitType ?? "unit"
                }`
              : `${item.quantity} ${
                  item.package?.packageUnitType.pluralUnitType ?? "units"
                }`,
          showEditButton:
            item.displayType != 2 &&
            ((itemIdMap[item.shopDisplayId]?.shopDisplayPackages?.length ?? 0) >
              1 ||
              (itemIdMap[item.shopDisplayId]?.shopDisplayPackages?.[0]?.package
                ?.packagePrices?.length ?? 0) > 1),
          onEdit: async () => {
            const shopDisplayItem = itemIdMap[item.shopDisplayId];
            setShopDisplay(shopDisplayItem);
            setEditShopDisplayOrderLineItemId(item.id);
            item.shopDisplayPackageId &&
              setOriginalShopDisplayPackageId(item.shopDisplayPackageId);
            item.shopDisplayPackageId &&
              setNewShopDisplayPackageId(item.shopDisplayPackageId);
            item.packagePriceId &&
              setOriginalPackagePriceId(item.packagePriceId);
            item.packagePriceId && setNewPackagePriceId(item.packagePriceId);
            setIsTreatmentTypeSelected(
              (shopDisplayItem.shopDisplayPackages?.length ?? 0) > 1
            );
            setOpenModal(true);
          },
          onDelete: async () => {
            submit(
              {
                action: "deleteItem",
                shopDisplayOrderLineItemId: item.id,
              },
              { method: "delete" }
            );
            setIsTreatmentTypeSelected(false);
          },
        };
      })
    : [];

  const packageChoices = packages
    ?.filter(notNull)
    .map(
      (item) =>
        item.package && {
          id: item.id,
          title: item.package.name || "",
          description: item.package.description || "",
          priceOrResults: item.package.baseCost,
        }
    )
    .filter(notNull);

  const packagePriceChoices = packagesPrices
    ?.filter(notNull)
    .map(
      (item) =>
        item && {
          id: item.id,
          title: `${item.quantity} ${
            item.quantity > 1
              ? packages[0].package?.packageUnitType.pluralUnitType ?? "units"
              : packages[0].package?.packageUnitType.singularUnitType ?? "unit"
          }`,
          priceOrResults: item.price,
          memberPrice: item.membershipPrice,
        }
    )
    .filter(notNull);

  const defaultCheckoutNowSearchParams = { type: "all" };
  const queryString = new URLSearchParams({
    ...defaultCheckoutNowSearchParams,
    ...(!showNavbar ? { isMobileApp: "true" } : {}),
  }).toString();
  const checkoutActionPath = `/cart/checkout/element?${queryString}`;
  const isOrderTotalAboveMinimum =
    cartDetails.estimatedTotal / 100 > AFFIRM_MINIMUM_AMOUNT;

  const getPromoCodeTitle =
    cartDetails.discountSummary?.discounts?.find((d) => d.membershipId == null)
      ?.title ||
    availablePromos?.find((promo) =>
      cartDetails.discountSummary?.discounts?.find(
        (d) => d.discountId == promo.discountId
      )
    )?.title;

  const ismembershipDiscountApplied =
    cartDetails.discountSummary?.discounts?.find((d) => d.membershipId != null);
  const promoDiscount =
    cartDetails.orderSummaryItems?.find(
      (item) => item.name === "PromotionCodeDiscount"
    )?.value || 0;

  const checkoutButtonClick = async () => {
    const numberOfItems = cartDetails.shopDisplayOrderLineItems?.length;
    const archivedRemoved = await removeArchiveItems(cartDetails);
    const hasMembership = cartDetails.shopDisplayOrderLineItems?.find(
      (membership) => membership.shopDisplayMembershipId
    );

    if (cartDetails.estimatedTotal === 0 && hasMembership != null) {
      setshowUnableToCheckoutModal(true);
      return;
    }
    if (numberOfItems !== archivedRemoved) {
      const hasValidPromo =
        availablePromos?.find((promo) => promo.totalDiscount > promoDiscount) ||
        (membershipDiscount?.totalDiscount &&
          membershipDiscount.totalDiscount > promoDiscount);

      if (
        (!coupon && availablePromos?.length && preventCheckout) ||
        (hasValidPromo && preventCheckout)
      ) {
        setCheckoutPromoModalOpen(true);
        if (hasValidPromo && coupon) {
          setHasMoreDiscounts(true);
        }
      } else {
        navigate(checkoutActionPath);
      }
    }
  };

  return (
    <>
      {isFetching && state === "idle" && (
        <div className="absolute inset-0 flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )}
      {getTopNav()}
      {cartItems?.length && <CartItemList cartItems={cartItems}></CartItemList>}
      <div className="flex flex-col gap-3 p-4">
        <AvailablePromotions
          checkoutPromoModalOpen={checkoutPromoModalOpen}
          setCheckoutPromoModalOpen={setCheckoutPromoModalOpen}
          checkoutButtonLabel="Continue to checkout"
          checkoutButtonClick={checkoutButtonClick}
          hasMoreDiscounts={hasMoreDiscounts}
          coupon={coupon}
          rewardId={rewardId}
          discountId={discountId}
          rewardPromos={availablePromos}
          setCoupon={setCoupon}
          setRewardId={setRewardId}
          setDiscountId={setDiscountId}
          onPromoSubmit={(promoCode, shouldLoad?: boolean) => {
            setSuccessMessage("");
            if (shouldLoad) setLoading(true);
            submit(
              {
                action: "promoCodeAction",
                promoCode: promoCode,
              },
              {
                method: "post",
              }
            );
          }}
          onDelete={() => {
            submit(
              {
                action: "removePromoCodeAction",
                promoCodeId: promoCode?.promoCodeId ?? "",
              },
              {
                method: "delete",
              }
            );
          }}
          onRewardSubmit={(rewardId) => {
            setSuccessMessage("");
            submit(
              {
                action: "rewardAction",
                rewardId: rewardId,
              },
              {
                method: "post",
              }
            );
          }}
          successMsg={successMessage}
          externalPromoAmountOff={couponAmountOff}
          errorMsg={
            actionData?.action === "promoCodeAction"
              ? actionData.error
              : undefined
          }
          successMsgOnDelete={
            actionData?.action === "deletePromoCode"
              ? actionData.deleted
              : false
          }
          appliedPromoTitle={getPromoCodeTitle}
          membershipDiscount={membershipDiscount}
          isMembershipDiscountApplied={
            ismembershipDiscountApplied ? true : false
          }
          loading={loading}
        />
        {platform.platformSettings?.repeatCashEnabled &&
          !!cartDetails.platformUser?.repeatCashBalance && (
            <div className="flex flex-col gap-6">
              <ApplyRepeatMDCash
                cash={
                  cartDetails?.useRepeatCash
                    ? cartDetails.repeatCashBalance
                    : cartDetails.platformUser?.repeatCashBalance || 0
                }
                isApplied={cartDetails.useRepeatCash}
                onApply={() => {
                  submit(
                    {
                      action: "applyCash",
                      useRepeatCash: `${!cartDetails.useRepeatCash}`,
                    },
                    { method: "put" }
                  );
                }}
              />
            </div>
          )}
      </div>
      {salesAssociates.length > 0 && (
        <div className="flex flex-col">
          <div className="bg-two pt-2" />
          <div className="p-4">
            <Dropdown
              label="Who helped you with this purchase?"
              options={[
                { id: "", name: "Who helped you with this purchase?" },
                ...salesAssociates.map((salesAssociate) => ({
                  id: salesAssociate.id,
                  name: `${salesAssociate.firstName} ${salesAssociate.lastName}`,
                })),
              ]}
              value={salesAssociateValue}
              setValue={(salesAssociateId) => {
                setSalesAssociateValue(salesAssociateId);

                if (salesAssociateId == "") {
                  submit(
                    {
                      action: "deleteSalesAssociate",
                    },
                    { method: "delete" }
                  );
                } else {
                  submit(
                    {
                      action: "setSalesAssociate",
                      salesAssociateId: salesAssociateId,
                    },
                    { method: "post" }
                  );
                }
              }}
              textValue={salesAssociateValueText}
              setTextValue={(salesAssociateValueText) => {
                if (
                  salesAssociateValueText ==
                  "Who helped you with this purchase?"
                ) {
                  setSalesAssociateValueText("");
                } else {
                  setSalesAssociateValueText(salesAssociateValueText);
                }
              }}
            />
          </div>
        </div>
      )}
      <div className="bg-two pt-2" />
      {!!cartDetails.rewardSummary?.redeemableRewardCount && (
        <div className="px-4 pt-4">
          <RewardUnlockBanner
            rewardCount={cartDetails.rewardSummary.redeemableRewardCount}
            rewards={cartDetails.rewardSummary?.rewards}
          />
        </div>
      )}
      <div className="flex flex-col px-5 pt-5">
        <OrderSummary
          cart={cartDetails}
          displayProducts={false}
          feePercentage={platform.platformSettings?.patientFeePercentage || 0}
        />
        {platform.platformSettings?.affirmEnabled && (
          <>
            {(!isOrderTotalAboveMinimum ||
              (containsMembership && containsPackageOrTreatment)) && (
              <div className="mt-5">
                <NotAvailableAffirm
                  isLargeBanner={
                    containsMembership && containsPackageOrTreatment
                  }
                  reason={
                    containsMembership && containsPackageOrTreatment
                      ? AffirmUnavailableReason.ContainsMembership
                      : AffirmUnavailableReason.BelowMinimum
                  }
                />
              </div>
            )}
            {!containsMembership && isOrderTotalAboveMinimum && (
              <div className="pt-5">
                <ShopNowAffirm />
              </div>
            )}
          </>
        )}
        <TermsAndCondition
          text={platform.termsAndConditions}
          hideText={!platform.platformSettings?.affirmEnabled}
        />
        <RepeatMDFooter />
      </div>
      <div className="sticky bottom-0 w-full bg-white p-4 pb-[calc(16px_+_env(safe-area-inset-bottom))]">
        <Button fullWidth={true} onClick={checkoutButtonClick}>
          Checkout now
        </Button>
      </div>
      {packagesPrices && !isTreatmentTypeSelected && (
        <ModalSelectorList
          size="fullscreen"
          open={openModal}
          title={
            "NUMBER OF " +
            (packages[0]?.package?.packageUnitType.pluralUnitType?.toUpperCase() ??
              "UNITS")
          }
          type="products"
          items={packagePriceChoices}
          checkedItems={[newPackagePriceId]}
          onClose={() => {
            setOpenModal(false);
            setNewPackagePriceId("");
          }}
          firstButtonLabel="Apply"
          onFirstButtonClick={async () => {
            if (newPackagePriceId != originalPackagePriceId && shopDisplay) {
              setOpenModal(false);
              submit(
                {
                  action: "editItem",
                  shopDisplayOrderLineItemId: editShopDisplayOrderLineItemId,
                  packageId: newShopDisplayPackageId,
                  packagePriceId: newPackagePriceId,
                  packageListingId: shopDisplay.id,
                },
                { method: "put" }
              );
            }
          }}
          firstButtonDisabled={openModal ? disableApplyButton : false}
          onSelect={setNewPackagePriceId}
        />
      )}
      {packages && isTreatmentTypeSelected && (
        <ModalSelectorList
          size="fullscreen"
          open={openModal}
          title={"TREATMENT TYPES"}
          type="products"
          items={packageChoices}
          checkedItems={[newShopDisplayPackageId]}
          onClose={() => {
            setOpenModal(false);
            setNewShopDisplayPackageId("");
          }}
          firstButtonLabel={packagesPrices.length > 1 ? "Next" : "Apply"}
          onFirstButtonClick={async () => {
            const packagePriceId =
              newPackagePriceId == ""
                ? packagesPrices[0].id
                : newPackagePriceId;

            setIsTreatmentTypeSelected(false);
            if (packagesPrices.length > 1) {
              setOpenModal(true);
            } else if (shopDisplay) {
              setOpenModal(false);
              submit(
                {
                  action: "editItem",
                  shopDisplayOrderLineItemId: editShopDisplayOrderLineItemId,
                  packageId: newShopDisplayPackageId,
                  packagePriceId: packagePriceId,
                  packageListingId: shopDisplay.id,
                },
                { method: "put" }
              );
            }
          }}
          onSelect={(shopDisplayPackageId) => {
            setNewShopDisplayPackageId(shopDisplayPackageId);

            if (originalShopDisplayPackageId !== shopDisplayPackageId) {
              setNewPackagePriceId("");
            } else {
              setNewPackagePriceId(originalPackagePriceId);
            }
          }}
        />
      )}
      {showUnableToCheckoutModal && (
        <UnableToCheckoutModal
          onClose={() => setshowUnableToCheckoutModal(false)}
        />
      )}
    </>
  );
};

export default CartPage;
