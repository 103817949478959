import { useQuery } from "@tanstack/react-query";

import { get } from "api";
import ProgressBar from "atoms/progress-bar/ProgressBar";
import ScanInButton from "atoms/scan-in-button/ScanInButton";
import { YouHaveScannedBanner } from "organisms/you-have-scanned-banner/YouHaveScannedBanner";
import { formatCurrency } from "toolbox/Money";
import pluralize from "utils/pluralize";

export type ScanInStoreProps = {
  userName: string | null | undefined;
  rewardsCount: number;
  nextMilestone: number;
  unlockProgressPercent: number;
  cashToUnlock?: number;
  marketplaceEnabled?: boolean;
  visitCashValue?: number;
};

export const ScanInStore = ({
  userName,
  rewardsCount,
  nextMilestone,
  unlockProgressPercent,
  cashToUnlock,
  marketplaceEnabled,
  visitCashValue,
}: ScanInStoreProps) => {
  const { data: userCanCheckIn } = useQuery({
    queryKey: ["/user/check-in"],
    queryFn: () => get("/user/check-in"),
  });

  const userMessage = (): string => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const greetings =
      currentHour < 12
        ? "Good Morning"
        : currentHour < 18
        ? "Good Afternoon"
        : "Good Evening";
    if (userName) {
      return `${greetings}, ${userName}`.toUpperCase();
    }
    return greetings;
  };

  const getTitle = (): string | undefined => {
    if (!marketplaceEnabled || visitCashValue === 0) {
      return `Just ${nextMilestone} ${pluralize(
        nextMilestone,
        "visit",
        "visits"
      )} until your next reward!`;
    }

    if (nextMilestone) {
      return `${nextMilestone} more ${pluralize(
        nextMilestone,
        "visit",
        "visits"
      )} ${
        cashToUnlock && `or spend ${formatCurrency(cashToUnlock)}`
      } for next reward!`;
    }

    if (rewardsCount > 0) {
      return `${rewardsCount} ${
        rewardsCount === 1 ? "reward" : "rewards"
      } available!`;
    }

    return undefined;
  };

  return (
    <div className="flex flex-col items-center rounded px-5 pt-4 pb-6 text-center">
      <div className="pb-4 font-sans text-sub1 text-brand-color">
        {userMessage()}
      </div>
      <ProgressBar value={unlockProgressPercent} />
      {getTitle() && (
        <h3 className="pt-4 font-serif text-h3 text-primary">{getTitle()}</h3>
      )}
      <div className="pt-1 pb-6 font-sans text-body1 leading-[22px] text-secondary">
        {!marketplaceEnabled || visitCashValue === 0
          ? "Check in every visit for rewards"
          : "Check in every visit or shop in-app for rewards"}
      </div>
      {userCanCheckIn ? <ScanInButton /> : <YouHaveScannedBanner />}
    </div>
  );
};
