import dayjs from "dayjs";

import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right_small.svg";
import { ReactComponent as RepeatMD } from "atoms/icon/icons/RepeatCash.svg";
import { formatCurrency } from "toolbox/Money";

export interface HistoryListEntryProps {
  id: string;
  title: string;
  subtitle?: string | null | undefined;
  date: string | null | undefined;
  repeatCash?: number | null;
  onClick?: () => void;
}

export const HistoryListEntry = (props: HistoryListEntryProps) => {
  return (
    <div
      className="flex cursor-pointer items-center justify-between bg-white py-4 pl-6 pr-4"
      key={props.id}
      onClick={props.onClick}
    >
      <div className="mr-4 flex flex-1 flex-col gap-1 text-left">
        <div className="text-body1 capitalize text-primary">{props.title}</div>
        {props.subtitle && !props.repeatCash && (
          <div className="text-body1 capitalize text-secondary">
            {props.subtitle}
          </div>
        )}
        {props.repeatCash && (
          <div className="flex items-center gap-1 font-sans text-body1 text-secondary">
            Banked for
            <Icon svg={RepeatMD} color="primaryText" size="xsmall" />
            {formatCurrency(props.repeatCash)}
          </div>
        )}
      </div>
      <div className="mr-3 text-body1 text-secondary">
        {dayjs(props.date).format("MM/DD/YY")}
      </div>
      {props.onClick && (
        <Icon svg={ChevronRight} color="brandColor" size="small" />
      )}
    </div>
  );
};
