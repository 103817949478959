import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { useMutation, useQuery } from "@tanstack/react-query";
import cx from "classnames";
import dayjs from "dayjs";
import { FC, SVGProps } from "react";
import { useEffect, useState } from "react";
import {
  ActionFunction,
  json,
  LoaderFunction,
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useSubmit,
} from "react-router-dom";

import { get, post } from "api";
import { Models, PostResult } from "api/types";
import { Button } from "atoms/button/Button";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right.svg";
import { ReactComponent as AddedToCartCheck } from "atoms/icon/icons/ic_added_to_cart_check_ol.svg";
import { ReactComponent as BillingInfo } from "atoms/icon/icons/ic_billing_info_small_ol.svg";
import { ReactComponent as MembershipsIcon } from "atoms/icon/icons/ic_memberships_filled_ol.svg";
import { ReactComponent as Plus } from "atoms/icon/icons/ic_plus_large_ol.svg";
import { ReactComponent as Warning } from "atoms/icon/icons/ic_warning_ol.svg";
import { ReactComponent as Membership } from "atoms/icon/icons/memberships.svg";
import { ReactComponent as MembershipFilled } from "atoms/icon/icons/memberships_filled.svg";
import { ReactComponent as Rewards } from "atoms/icon/icons/rewards.svg";
import { ReactComponent as Star } from "atoms/icon/icons/star.svg";
import { Notification, useNotify } from "atoms/notification";
import SimpleCarousel from "atoms/simple-carousel/SimpleCarousel";
import { DescriptiveTag } from "atoms/tag/DescriptiveTag";
import { TextButton } from "atoms/text-button/TextButton";
import { ExpandableItems } from "molecules/expandable-items/ExpandableItems";
import { ActionModal } from "molecules/modal-window/action-modal/ActionModal";
import { ListModal } from "molecules/modal-window/list-modal/ListModal";
import { TreatmentNotification } from "molecules/treatment-notification/TreatmentNotification";
import { IncludedMonthlyRewards } from "organisms/included-monthly-rewards/IncludedMonthlyRewards";
import MembershipPromo from "organisms/memberships-promo/MembershipsPromo";
import { ModalSelectorList } from "organisms/selection-modals/modal-selector-list/ModalSelectorList";
import { queryClient } from "pages/Root";
import { IfFlag } from "services/Flags";
import {
  getMembershipRewardGroupSubheader,
  getProductTitle,
  getProductTitleQuantity,
} from "services/HelperService";
import { waitObj } from "toolbox/Promise";
import { readFormData } from "toolbox/ReadFormData";
import { buildPlatformUrl } from "utils/functions";
import pluralize from "utils/pluralize";

import { TreatmentSelectorModal } from "./TreatmentSelectorModal";
import {
  getAvailableSelectableTreatmentsCount,
  hasAvailableTreatmentsToSelect,
  hasRolledOverTreatmentsToSelect,
} from "./utils";

export const SelectableMonthlyTreatments = ({
  selectableTreatmentsRewardGroup,
  isMembershipCanceled,
  onSelectTreatmentClick,
  inactiveMembership,
  description,
  svg,
  monthsBeforeFirstReward,
}: {
  selectableTreatmentsRewardGroup: Models<"MembershipRewardGroupModel">;
  isAdditional?: boolean;
  isMembershipCanceled?: boolean;
  onSelectTreatmentClick: (
    reward: Models<"MembershipRewardGroupModel">
  ) => void;
  inactiveMembership?: boolean | null;
  description?: string;
  svg: FC<SVGProps<SVGSVGElement>>;
  monthsBeforeFirstReward?: number | null;
}) => {
  return (
    <div className="mb-1 bg-white px-4 py-4">
      <div
        className={cx("mb-4 text-center", {
          "opacity-50": isMembershipCanceled,
        })}
      >
        <div className="flex w-full justify-center">
          <Icon
            color={inactiveMembership ? "darkGrey" : "brandColor"}
            size="xsmall"
            svg={svg}
          />
        </div>
        <div
          className={cx(
            "py-4 text-sub1",
            inactiveMembership ? "text-dark-grey" : "text-brand-color"
          )}
        >
          CHOOSE {selectableTreatmentsRewardGroup.quantityPerPeriod} OF THE
          FOLLOWING EVERY
          {selectableTreatmentsRewardGroup.periodMonths === 12
            ? " YEAR!"
            : selectableTreatmentsRewardGroup.periodMonths === 1
            ? " MONTH!"
            : ` ${selectableTreatmentsRewardGroup.periodMonths} MONTHS!`}
        </div>
        <IfFlag flag="timeDelayedTreatmentGroups">
          <div className="text-secondary">
            Starting on your{" "}
            <span className="text-bold1">
              {getMembershipRewardGroupSubheader(monthsBeforeFirstReward)}
            </span>{" "}
            as a member
          </div>
        </IfFlag>
        {description && (
          <div
            className={cx(
              "text-body",
              inactiveMembership ? "text-dark-grey" : "text-secondary"
            )}
          >
            {description}
          </div>
        )}
      </div>
      <div className="flex flex-col items-center rounded-lg border-[1px] border-solid border-light-grey px-2 pb-2 pt-6">
        <DescriptiveTag size="large" color="secondary">
          {`${
            selectableTreatmentsRewardGroup.availableTreatmentsAmount
          } ${pluralize(
            selectableTreatmentsRewardGroup.availableTreatmentsAmount,
            "treatment",
            "treatments"
          )}
          available`}
        </DescriptiveTag>
        {selectableTreatmentsRewardGroup.availableAt && (
          <div
            className={cx(
              "mt-2 mb-[14px] text-body2",
              inactiveMembership ? "text-dark-grey" : "text-secondary"
            )}
          >
            Next treatment available{" "}
            {dayjs(selectableTreatmentsRewardGroup.availableAt).format(
              "MM/DD/YYYY"
            )}
          </div>
        )}
        {!selectableTreatmentsRewardGroup.availableAt &&
          selectableTreatmentsRewardGroup.refreshDate && (
            <div
              className={cx(
                "mt-2 mb-[14px] text-body2",
                inactiveMembership ? "text-dark-grey" : "text-secondary"
              )}
            >
              Refreshes{" "}
              {dayjs(selectableTreatmentsRewardGroup.refreshDate).format(
                "MM/DD/YYYY"
              )}
            </div>
          )}
        <div className="mb-4 w-full px-2">
          <ExpandableItems
            items={selectableTreatmentsRewardGroup.membershipRewards.map(
              (mr) => ({
                id: mr.id,
                title:
                  getProductTitle(mr.packageListingTitle, mr.packageName) +
                  " " +
                  getProductTitleQuantity(mr.quantity, mr.packageUnitType),
                description: mr.packageDescription,
              })
            )}
          />
        </div>
        <Button
          fullWidth
          onClick={() =>
            onSelectTreatmentClick(selectableTreatmentsRewardGroup)
          }
          disabled={
            selectableTreatmentsRewardGroup.availableTreatmentsAmount === 0
          }
        >
          Select your treatment
        </Button>
      </div>
    </div>
  );
};

const Avatar = ({
  firstName,
  lastName,
  disabled,
}: {
  firstName?: string | null;
  lastName?: string | null;
  disabled?: boolean | null;
}) => {
  return (
    <div
      className={cx(
        "flex h-[72px] w-[72px] items-center justify-center rounded-full text-title text-white",
        disabled ? "bg-dark-grey" : "bg-brand-color"
      )}
    >
      {firstName?.charAt(0)}
      {lastName?.charAt(0)}
    </div>
  );
};

const UpdatePaymentMethod = ({
  dunningStage,
  enteredDunningAt,
  multipleMemberships,
  onClick,
}: {
  dunningStage: number;
  enteredDunningAt: string | null | undefined;
  multipleMemberships?: boolean;
  onClick?: () => void;
}) => {
  const numberOfAttempts = 4 - dunningStage;
  let addedDunningDays = 7;
  if (dunningStage == 2) {
    addedDunningDays = 14;
  } else if (dunningStage == 3) {
    addedDunningDays = 21;
  }
  return (
    <div className="flex flex-col items-center gap-3 px-3 py-5 text-center">
      <div>
        <Icon svg={Warning} size="small" color="red" />
      </div>
      <div className="text-bold1 text-red">
        We attempted to process your membership payment, we will try again on{" "}
        {dayjs(enteredDunningAt)
          .add(addedDunningDays, "day")
          .format("MM/DD/YYYY")}
      </div>
      <div className="text-body2 text-red">
        {multipleMemberships
          ? "Some membership benefits"
          : "Membership benefits"}{" "}
        are currently paused, {numberOfAttempts} attempt
        {numberOfAttempts > 1 ? "s" : ""} remaining until deactivation
      </div>
      <Button style="danger" onClick={onClick}>
        Update payment method
      </Button>
    </div>
  );
};

const load = async () => {
  const userInfo = queryClient.fetchQuery(["/user"], () => get("/user"), {
    staleTime: 1000 * 60 * 2,
  });
  const userMemberships = queryClient.fetchQuery(
    ["/platform-user-memberships"],
    () => get("/platform-user-memberships")
  );

  const userMembershipsStatus = queryClient.fetchQuery(
    ["/platform-user-memberships/status"],
    () => get("/platform-user-memberships/status")
  );

  const platform = queryClient.fetchQuery(["platform"], () => get("/platform"));

  const data = await waitObj({
    userMemberships,
    userInfo,
    userMembershipsStatus,
    platform,
  });

  if (data.userMemberships.length) {
    return { ...data, membershipFeatures: [], shopMemberships: [] };
  }

  const membershipFeatures = queryClient.fetchQuery(["/shop/memberships"], () =>
    get("/shop/memberships")
  );
  const shopMemberships = queryClient.fetchQuery(["/memberships"], () =>
    get("/memberships")
  );

  return waitObj({ membershipFeatures, shopMemberships, ...data });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const accountMembershipLoader: LoaderFunction = async () => {
  return json<LoaderData>(await load());
};

type ActionResult = {
  successfullySelectedRewards: PostResult<"/platform-user-packages/{platformUserMembershipId}">;
};

export const accountMembershipAction: ActionFunction = async ({ request }) => {
  const { data } = await readFormData<{ data: string }>(request);
  const { platformUserMembershipId, rewardIds } = JSON.parse(data);

  const successfullySelectedRewards = await post(
    "/platform-user-packages/{platformUserMembershipId}",
    {
      platformUserMembershipId,
      $data: rewardIds,
    }
  );

  return json({
    successfullySelectedRewards,
  });
};

const AccountMembershipPage = () => {
  const {
    userInfo,
    membershipFeatures,
    shopMemberships,
    userMembershipsStatus,
    platform,
    ...data
  } = useLoaderData() as LoaderData;

  const [notificationProps, notify] = useNotify();

  const submit = useSubmit();
  const actionData = useActionData() as ActionResult | undefined;
  const navigate = useNavigate();
  const [visibleMembershipId, setVisibleMembershipId] = useState(0);
  const [selectTreatmentModalStep, setSelectTreatmentModalStep] = useState(0);
  const [signUpBonusModalOpen, setSignUpBonusModalOpen] = useState(false);
  const [signUpBonusesSelected, setSignUpBonusesSelected] = useState<string[]>(
    []
  );
  const [showAddedToMyTretmentsDialog, setShowAddedToMyTretmentsDialog] =
    useState(false);
  const [openRewardGroup, setOpenRewardGroup] =
    useState<Models<"MembershipRewardGroupModel">>();
  const singleItemRedemption =
    platform.platformSettings?.membershipSingleItemRedemption;

  const [showReactivateMembershipDialog, setShowReactivateMembershipDialog] =
    useState(false);

  const [milestoneBonusOpen, setMilestoneBonusOpen] = useState<boolean>(false);
  const [milestoneBonusListModalOpen, setMilestoneBonusListModalOpen] =
    useState<boolean>(false);
  const [selectedMilestoneBonusId, setSelectedMilestoneBonusId] =
    useState<string>("");
  const [milestoneBonusesSelected, setMilestoneBonusesSelected] = useState<
    string[]
  >([]);

  const { mutate: reactivateMembership } = useMutation({
    mutationFn: async (platformUserMembershipId: string) => {
      return await post(
        "/platform-user-memberships/{platformUserMembershipId}/reactivate",
        {
          platformUserMembershipId: platformUserMembershipId,
        }
      );
    },
    onError: (error) => {
      console.log(error);
    },
    onSuccess: (response) => {
      setShowReactivateMembershipDialog(false);
      queryClient.setQueryData(
        ["/platform-user-memberships/status"],
        [response]
      );
      queryClient.invalidateQueries(["/platform-user-memberships"]);
    },
  });
  const location = useLocation();
  const isNativeApp = Capacitor.isNativePlatform();
  const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false);

  const baseUrl = buildPlatformUrl(
    platform?.scheme,
    platform?.subdomain,
    platform?.host
  );

  const getUrl = (success: boolean) => {
    const returnUrl = `${baseUrl}/stripe-landing?payment_updated=${
      success ? 1 : 0
    }`;
    return returnUrl;
  };

  const handleUpdatePayment = async (membershipId: string) => {
    if (isNativeApp) {
      handleUpdatePaymentOnCapacitor(membershipId);
    } else {
      updatePaymentMutation.mutate(membershipId);
    }
  };

  const updatePaymentMutation = useMutation({
    mutationFn: (platUserMembId: string) =>
      post(
        "/platform-user-memberships/{platformUserMembershipId}/create-stripe-update-session",
        {
          platformUserMembershipId: platUserMembId,
          successUrl: getUrl(true),
          cancelUrl: getUrl(false),
        }
      ),
    onSuccess: (response) => {
      if (response && response.stripeSession.url) {
        window.location.href = response.stripeSession.url;
      } else {
        console.log("error ocurred");
      }
    },
  });

  const handleUpdatePaymentOnCapacitor = async (membershipId: string) => {
    const response = await post(
      "/platform-user-memberships/{platformUserMembershipId}/create-stripe-update-session",
      {
        platformUserMembershipId: membershipId,
        successUrl: `${baseUrl}/stripe-landing/close-browser?successfulPayment=true`,
        cancelUrl: `${baseUrl}/stripe-landing/close-browser?successfulPayment=false`,
      }
    );

    Browser.addListener("browserFinished", async () => {
      Browser.removeAllListeners();
      setShowUpdatePaymentModal(false);

      navigate(location.pathname);
    });

    await Browser.open({ url: response.stripeSession.url });
  };

  const { data: userMemberships } = useQuery({
    queryKey: ["/platform-user-memberships"],
    queryFn: () => get("/platform-user-memberships"),
    initialData: data.userMemberships,
  });

  const { data: statuses } = useQuery({
    queryKey: ["/platform-user-memberships/status"],
    queryFn: () => get("/platform-user-memberships/status"),
    initialData: userMembershipsStatus,
  });

  const membershipInDunning = statuses?.find(
    (s) => s.dunningStage > 0 && s.dunningStage < 4 && s.canceled === false
  );

  const showReactivate = () => {
    return (
      userMemberships
        .filter(
          (x) =>
            x.membership.id === visibleMembership.membership.id &&
            x.willCancelAt
        )
        .reduce(function (d1, d2) {
          return new Date(d1.willCancelAt) > new Date(d2.willCancelAt)
            ? d2
            : d1;
        }).platformUserMembershipId ===
      visibleMembership.platformUserMembershipId
    );
  };

  const dunningMembership = membershipInDunning
    ? {
        status: true,
        dunningStage: membershipInDunning.dunningStage,
        enteredDunningAt: membershipInDunning.enteredDunningAt!,
        id: membershipInDunning.id,
      }
    : {
        status: false,
        dunningStage: 0,
        enteredDunningAt: null,
        id: null,
      };

  useEffect(() => {
    if (actionData?.successfullySelectedRewards) {
      setSelectTreatmentModalStep(0);
      setOpenRewardGroup(undefined);
      setShowAddedToMyTretmentsDialog(true);
      setMilestoneBonusOpen(false);
    }

    const params = new URLSearchParams(location.search);
    const updated = params.get("payment_updated");

    if (updated === "1") {
      notify.info("Payment method updated.", {
        timeout: 5000,
        dismissable: false,
      });
    } else if (updated === "0") {
      notify.info("Payment method not updated.", {
        timeout: 5000,
        dismissable: false,
      });
    }
  }, [actionData?.successfullySelectedRewards]);

  const handleCurrentlyVisibleMembership = (index: number) => {
    setVisibleMembershipId(index);
  };

  if (!userMemberships.length) {
    const membershipFeature = membershipFeatures?.[0];

    return (
      <div className="flex-1 bg-brand-color">
        <MembershipPromo {...membershipFeature} memberships={shopMemberships} />
      </div>
    );
  }

  const visibleMembership = userMemberships[visibleMembershipId];

  const visibleMembershipHasAvailableTreatmentsToSelect =
    hasAvailableTreatmentsToSelect([visibleMembership]);

  const visibleMembershipHasRolledOverTreatmentsToSelect =
    hasRolledOverTreatmentsToSelect([visibleMembership]);

  const visibleMembershipAvailableTreatmentsCount =
    getAvailableSelectableTreatmentsCount([visibleMembership]);

  const addCanceledMembershipToCart = async () => {
    try {
      await post("/orders/current/lineitems", {
        membershipId: visibleMembership.membership.id,
      });
      queryClient.invalidateQueries({ queryKey: ["/orders/current/summary"] });

      navigate("/cart");
    } catch (error) {
      throw new Error("Not able to add order to cart. Try again later.");
    }
  };

  const visibleMembershipStatus = statuses.find(
    (m) => m.id == visibleMembership.platformUserMembershipId
  );

  const visibleMembershipOnDunning =
    visibleMembershipStatus &&
    visibleMembershipStatus?.dunningStage > 0 &&
    visibleMembership?.dunningStage < 4;

  const signUpBonus = visibleMembership.membership.membershipRewardGroups?.find(
    (group) => {
      return (
        group.monthsBeforeFirstReward === 0 &&
        group.periodMonths === 0 &&
        group.availableTreatmentsAmount > 0
      );
    }
  );
  const signUpBonusSelectionType =
    signUpBonus?.availableTreatmentsAmount &&
    signUpBonus?.availableTreatmentsAmount > 1
      ? "multiple"
      : "single";

  const membershipMilestones =
    visibleMembership.membership.membershipRewardGroups?.filter((group) => {
      return group.periodMonths == 0;
    });

  const getMilestoneBonusType = (id: string) => {
    if (!id || membershipMilestones?.length === 0) return null;
    const selectedMilestone = membershipMilestones?.find(
      (group) => group.id === id
    );
    return selectedMilestone && selectedMilestone?.availableTreatmentsAmount > 1
      ? "multiple"
      : "single";
  };

  const getMilestoneBonusData = (id: string) => {
    if (!id || membershipMilestones?.length === 0) return null;
    const selectedMilestone = membershipMilestones?.find(
      (group) => group.id === id
    );
    return selectedMilestone;
  };

  const handleMilestoneDetailsClick = (
    id: string,
    availableAt: string | null | undefined,
    availableTreatmentsAmount: number | null | undefined,
    monthsBeforeFirstReward: number | null | undefined,
    hasSelectableRewards: boolean | null | undefined
  ) => {
    if (availableAt == null && availableTreatmentsAmount == 0) return;

    if (monthsBeforeFirstReward == 0 && hasSelectableRewards) {
      setSignUpBonusModalOpen(true);
    } else if (
      availableTreatmentsAmount &&
      availableTreatmentsAmount > 0 &&
      hasSelectableRewards
    ) {
      setSelectedMilestoneBonusId(id);
      setMilestoneBonusOpen(true);
    } else if (availableTreatmentsAmount == 0 && availableAt != null) {
      setSelectedMilestoneBonusId(id);
      setMilestoneBonusListModalOpen(true);
    }
  };

  const getMilestoneBonusButtonLabel = (
    availableAt: string | null | undefined,
    availableTreatmentsAmount: number | null | undefined
  ) => {
    return availableAt == null && availableTreatmentsAmount == 0
      ? "Received"
      : availableTreatmentsAmount && availableTreatmentsAmount > 0
      ? "Select"
      : "Details";
  };

  const getMilestoneBonusStatus = (
    availableAt: string | null | undefined,
    receivedAt: string | null | undefined,
    availableTreatmentsAmount: number | null | undefined
  ) => {
    return availableAt == null && availableTreatmentsAmount == 0
      ? "received"
      : availableTreatmentsAmount && availableTreatmentsAmount > 0
      ? "available"
      : receivedAt != null
      ? "received"
      : "upcoming";
  };

  const getMilestoneBonusDescription = (
    availableAt: string | null | undefined,
    monthsBeforeFirstReward: number,
    quantityPerPeriod: number,
    availableTreatmentsAmount: number | null | undefined,
    receivedAt: string | null | undefined
  ) => {
    if (!monthsBeforeFirstReward || !quantityPerPeriod) {
      return;
    }
    return monthsBeforeFirstReward == 0
      ? `${quantityPerPeriod} ${pluralize(
          quantityPerPeriod,
          "treatment",
          "treatments"
        )} available`
      : availableTreatmentsAmount && availableTreatmentsAmount > 0
      ? `${availableTreatmentsAmount} ${pluralize(
          quantityPerPeriod,
          "treatment",
          "treatments"
        )} available`
      : availableAt
      ? `Bonus available ${availableAt}`
      : `Bonus received ${receivedAt}`;
  };

  const onSelectMilestoneBonus = async (clickedItem: string) => {
    let newMilestoneBonusesSelected;
    const milestoneBonusSelectedType = getMilestoneBonusType(
      selectedMilestoneBonusId
    );

    if (milestoneBonusSelectedType === "single") {
      newMilestoneBonusesSelected = [clickedItem];
    } else if (signUpBonusesSelected.includes(clickedItem)) {
      newMilestoneBonusesSelected = milestoneBonusesSelected.filter(
        (bonus) => bonus !== clickedItem
      );
    } else {
      newMilestoneBonusesSelected = [clickedItem, ...milestoneBonusesSelected];
    }
    setMilestoneBonusesSelected(newMilestoneBonusesSelected);
  };

  const getMilestoneListModalDescription = (
    hasSelectableRewards: boolean | undefined
  ) => {
    const quantityPerPeriod = getMilestoneBonusData(
      selectedMilestoneBonusId
    )?.quantityPerPeriod;
    const monthsBeforeFirstReward = getMilestoneBonusData(
      selectedMilestoneBonusId
    )?.monthsBeforeFirstReward;

    if (!monthsBeforeFirstReward || !quantityPerPeriod) {
      return;
    }

    if (hasSelectableRewards)
      return (
        <div>
          <span className="text-bold1">Select {quantityPerPeriod}</span> bonus{" "}
          {pluralize(quantityPerPeriod, "treatment", "treatments")} from the
          below after{" "}
          {monthsBeforeFirstReward +
            " " +
            pluralize(monthsBeforeFirstReward, "month", "months")}{" "}
          as a {visibleMembership.membership.name} member
        </div>
      );

    return (
      <div>
        <span className="text-bold1">Receive {quantityPerPeriod}</span> bonus{" "}
        {pluralize(quantityPerPeriod, "treatment", "treatments")} after{" "}
        {monthsBeforeFirstReward +
          " " +
          pluralize(monthsBeforeFirstReward, "month", "months")}{" "}
        as a {visibleMembership.membership.name} member
      </div>
    );
  };

  const getMilestoneSelectModalSubtitle = () => {
    const availableTreatments = getMilestoneBonusData(
      selectedMilestoneBonusId
    )?.availableTreatmentsAmount;
    const monthsBeforeFirstReward = getMilestoneBonusData(
      selectedMilestoneBonusId
    )?.monthsBeforeFirstReward;

    if (!availableTreatments) {
      return;
    }

    return (
      <div className="text-center text-body1 text-primary">
        <span className="text-bold1">Select {availableTreatments}</span>{" "}
        {pluralize(availableTreatments, "item", "items")} for your{" "}
        {monthsBeforeFirstReward}
        -month bonus
      </div>
    );
  };

  return (
    <div>
      <Notification {...notificationProps} />
      {dunningMembership.status == true && (
        <UpdatePaymentMethod
          dunningStage={
            visibleMembershipOnDunning
              ? visibleMembershipStatus?.dunningStage
              : dunningMembership.dunningStage
          }
          enteredDunningAt={
            visibleMembershipOnDunning
              ? visibleMembershipStatus.enteredDunningAt
              : dunningMembership.enteredDunningAt || null
          }
          multipleMemberships={userMembershipsStatus.length > 1}
          onClick={() => handleUpdatePayment(dunningMembership.id!)}
        />
      )}
      <div className="py-5 text-center text-body2 text-secondary">
        {userMemberships.filter((m) => !m.canceled).length} active memberships
      </div>
      <div>
        <SimpleCarousel
          onUpdateIndex={handleCurrentlyVisibleMembership}
          slides={userMemberships.map((m) => (
            <div
              key={m.platformUserMembershipId}
              className={cx(
                "flex flex-col items-center justify-center rounded-lg bg-white px-7 pt-4 pb-5 shadow-subtle",
                {
                  "opacity-50": visibleMembership.canceled,
                }
              )}
            >
              <div className="relative">
                {visibleMembership.membership.imageUrl ? (
                  <div
                    className={cx(
                      visibleMembershipOnDunning ? "grayscale" : ""
                    )}
                  >
                    <img
                      src={visibleMembership.membership.imageUrl}
                      className="h-[72px] w-[72px] rounded-full object-cover"
                    />
                  </div>
                ) : (
                  <Avatar
                    firstName={userInfo.firstName}
                    lastName={userInfo.lastName}
                    disabled={visibleMembershipOnDunning || false}
                  />
                )}

                {visibleMembershipHasAvailableTreatmentsToSelect && (
                  <div className="absolute top-1 right-1 h-2.5 w-2.5 rounded-full bg-red outline outline-[3px] outline-white" />
                )}
              </div>
              <div
                className={cx(
                  "my-4 text-sub1",
                  visibleMembershipOnDunning
                    ? "text-dark-grey"
                    : "text-brand-color"
                )}
              >
                {userInfo.firstName} {userInfo.lastName}
              </div>
              <DescriptiveTag
                color={visibleMembershipOnDunning ? "disabled" : "secondary"}
              >{`${m.membership.name} Member`}</DescriptiveTag>
              {/* TODO: For Beta */}
              {/* <div className="hidden mt-3 flex items-center">
                <div className="text-body3 text-secondary">
                  You&apos;ve saved{" "}
                </div>
                <div className="mx-1 text-bold3 text-secondary">
                  {formatCurrency(180000)}
                </div>
                <div className="text-body3 text-secondary">
                  since joining on 03/22/22
                </div>
              </div> */}
            </div>
          ))}
        />

        {/* Deactivated membership UI */}
        {((visibleMembershipStatus && visibleMembershipStatus?.canceled) ||
          visibleMembershipStatus?.willCancelAt) && (
          <div className="mx-6 mb-6 mt-2 flex flex-col items-center">
            <div className="mb-6 text-bold1 text-red">
              {new Date(visibleMembershipStatus.willCancelAt!) < new Date() &&
                visibleMembershipStatus.canceled &&
                `Membership deactivated on ${dayjs(
                  visibleMembership.canceledDate
                ).format("MM/DD/YYYY")}`}
              {new Date(visibleMembershipStatus.willCancelAt!) > new Date() &&
                `Membership will deactivate on ${dayjs(
                  visibleMembershipStatus.willCancelAt
                ).format("MM/DD/YYYY")}`}
            </div>

            {visibleMembership.membership.active && showReactivate() && (
              <Button
                fullWidth
                onClick={() =>
                  new Date(visibleMembershipStatus.willCancelAt!) < new Date()
                    ? addCanceledMembershipToCart()
                    : setShowReactivateMembershipDialog(true)
                }
              >
                Reactivate
              </Button>
            )}
            {!visibleMembership.membership.active && (
              <div className="flex flex-col items-center text-center">
                <div className="mb-6 mt-2 text-secondary">
                  This membership is no longer available, click below to find
                  similar memberships
                </div>

                <Button
                  fullWidth
                  style="secondary"
                  onClick={() => {
                    navigate("/shop/memberships");
                  }}
                >
                  Browse Memberships
                </Button>
              </div>
            )}
          </div>
        )}
        {/* Deactivated membership UI  */}

        {(visibleMembershipHasRolledOverTreatmentsToSelect ||
          visibleMembershipHasAvailableTreatmentsToSelect ||
          signUpBonus) && (
          <div className="mb-1 flex flex-col gap-5 rounded-t-2xl bg-white px-6 pt-6 pb-7">
            <div className="text-center text-body1 text-primary">
              You have treatments available!
            </div>
            <div className="-mx-2 flex flex-col gap-2">
              {signUpBonus && (
                <TreatmentNotification
                  buttonLabel="Select"
                  status="available"
                  type="signup"
                  icon={MembershipFilled}
                  onClick={() => setSignUpBonusModalOpen(true)}
                  text="Select your sign-up bonus now!"
                  description={`${
                    signUpBonus.availableTreatmentsAmount
                  } treatment${
                    signUpBonus.availableTreatmentsAmount > 1 ? "s" : ""
                  } available`}
                />
              )}
              {membershipMilestones && membershipMilestones?.length > 0 && (
                <>
                  {membershipMilestones?.map(
                    (rg, index) =>
                      rg.hasSelectableRewards &&
                      rg.availableTreatmentsAmount > 0 &&
                      rg.monthsBeforeFirstReward > 0 && (
                        <div key={index}>
                          <TreatmentNotification
                            buttonLabel="Select"
                            status={getMilestoneBonusStatus(
                              rg.availableAt,
                              rg.receivedAt,
                              rg.availableTreatmentsAmount
                            )}
                            type="milestone"
                            icon={MembershipFilled}
                            onClick={() => {
                              handleMilestoneDetailsClick(
                                rg.id,
                                rg.availableAt,
                                rg.availableTreatmentsAmount,
                                rg.monthsBeforeFirstReward,
                                rg.hasSelectableRewards
                              );
                            }}
                            text={`${rg.monthsBeforeFirstReward}-month member bonus`}
                            description={getMilestoneBonusDescription(
                              rg.availableAt,
                              rg.monthsBeforeFirstReward,
                              rg.quantityPerPeriod,
                              rg.availableTreatmentsAmount,
                              rg.receivedAt
                            )}
                          />
                        </div>
                      )
                  )}
                </>
              )}
              {(visibleMembershipHasRolledOverTreatmentsToSelect ||
                visibleMembershipHasAvailableTreatmentsToSelect) && (
                <TreatmentNotification
                  buttonLabel="View"
                  status="available"
                  type="monthly"
                  icon={Membership}
                  onClick={() => window.scrollTo(0, document.body.scrollHeight)}
                  text={`You have ${visibleMembershipAvailableTreatmentsCount} treatment${
                    visibleMembershipAvailableTreatmentsCount > 1 ? "s" : ""
                  } available`}
                />
              )}
            </div>
          </div>
        )}

        {!!visibleMembership.membership.membershipBenefits.length && (
          <div
            className={cx("mb-1 flex flex-col gap-6 bg-white px-6 pt-4 pb-6", {
              "rounded-t-2xl":
                !visibleMembershipHasRolledOverTreatmentsToSelect &&
                !visibleMembershipHasAvailableTreatmentsToSelect &&
                !signUpBonus,
              "opacity-50": visibleMembership.canceled,
            })}
          >
            <div>
              <div className="flex items-center justify-center">
                <Icon
                  color={visibleMembershipOnDunning ? "darkGrey" : "brandColor"}
                  size="xsmall"
                  svg={Star}
                />
              </div>
              <div
                className={cx(
                  "my-4 text-center text-sub1",
                  visibleMembershipOnDunning
                    ? "text-dark-grey"
                    : "text-brand-color"
                )}
              >
                {visibleMembership.membership.name.toUpperCase()} MEMBER
                BENEFITS
              </div>
              <ul
                className={cx(
                  "list-inside list-disc marker:text-[21px]",
                  visibleMembershipOnDunning
                    ? "marker:text-dark-grey"
                    : "marker:text-brand-color"
                )}
              >
                {visibleMembership.membership.membershipBenefits.map((item) => (
                  <li key={item.id} className="py-2 px-1">
                    <div
                      className={cx(
                        "text-body -ml-1.5 inline",
                        visibleMembershipOnDunning
                          ? "text-dark-grey"
                          : "text-primary"
                      )}
                    >
                      {item.description}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>

      {(visibleMembership.membership.membershipRewardGroups ?? [])
        .filter((rg) => !!rg && rg?.periodMonths > 0)
        .map((rg, index) => (
          <>
            {!rg?.hasSelectableRewards ? (
              <IncludedMonthlyRewards
                freeMembershipRewards={rg?.membershipRewards || []}
                inactiveMembership={visibleMembershipOnDunning || false}
                periodMonths={rg?.periodMonths}
                svg={index == 0 ? Rewards : Plus}
                monthsBeforeFirstReward={rg?.monthsBeforeFirstReward}
              />
            ) : (
              <SelectableMonthlyTreatments
                selectableTreatmentsRewardGroup={rg}
                isMembershipCanceled={visibleMembership.canceled}
                onSelectTreatmentClick={(rewardGroup) => {
                  if (rewardGroup.membershipRewards.length) {
                    setSelectTreatmentModalStep(1);
                    setOpenRewardGroup(rewardGroup);
                  }
                }}
                inactiveMembership={visibleMembershipOnDunning || false}
                svg={index == 0 ? Rewards : Plus}
                monthsBeforeFirstReward={rg?.monthsBeforeFirstReward}
              />
            )}
          </>
        ))}

      {membershipMilestones && membershipMilestones?.length > 0 && (
        <IfFlag flag="timeDelayedTreatmentGroups">
          <div className="mb-1 bg-white px-4 py-6">
            <div className="mb-6 text-center">
              <div className="flex w-full justify-center">
                <Icon color="brandColor" size="xsmall" svg={MembershipsIcon} />
              </div>
              <div className="pt-6 pb-2 text-sub1 uppercase text-brand-color">
                Membership Milestones
              </div>
              <div className="text-body1 text-secondary">
                One-time bonuses that you earn at certain milestones as a{" "}
                {visibleMembership.membership.name} member
              </div>
              <div className="py-3">
                {membershipMilestones?.map((rg, index) => (
                  <div key={index} className="pb-2">
                    <TreatmentNotification
                      buttonLabel={getMilestoneBonusButtonLabel(
                        rg.availableAt,
                        rg.availableTreatmentsAmount
                      )}
                      status={getMilestoneBonusStatus(
                        rg.availableAt,
                        rg.receivedAt,
                        rg.availableTreatmentsAmount
                      )}
                      type="milestone"
                      icon={MembershipFilled}
                      onClick={() => {
                        handleMilestoneDetailsClick(
                          rg.id,
                          rg.availableAt,
                          rg.availableTreatmentsAmount,
                          rg.monthsBeforeFirstReward,
                          rg.hasSelectableRewards
                        );
                      }}
                      text={
                        rg.monthsBeforeFirstReward == 0
                          ? "Select your sign-up bonus now!"
                          : `${rg.monthsBeforeFirstReward}-month member bonus`
                      }
                      description={getMilestoneBonusDescription(
                        rg.availableAt,
                        rg.monthsBeforeFirstReward,
                        rg.quantityPerPeriod,
                        rg.availableTreatmentsAmount,
                        rg.receivedAt
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </IfFlag>
      )}

      {openRewardGroup?.membershipRewards && (
        <TreatmentSelectorModal
          treatments={openRewardGroup.membershipRewards.map((rg) => ({
            id: rg.id,
            name: getProductTitle(rg.packageListingTitle, rg.packageName),
            description: rg.packageDescription || "",
            quantity: rg.quantity,
            packageUnitType: rg.packageUnitType,
          }))}
          singleItemRedemption={singleItemRedemption}
          onSubmit={(ids) =>
            submit(
              {
                data: JSON.stringify({
                  rewardIds: ids,
                  platformUserMembershipId: visibleMembership.id,
                }),
              },
              { method: "post" }
            )
          }
          onNext={() => {
            if (!singleItemRedemption) {
              setSelectTreatmentModalStep((step) => step + 1);
            }
          }}
          onCancel={() => {
            setOpenRewardGroup(undefined);
            setSelectTreatmentModalStep(0);
          }}
          onBack={() => {
            if (!singleItemRedemption) {
              setSelectTreatmentModalStep((step) => step - 1);
            }
          }}
          currentStep={selectTreatmentModalStep}
          stepCount={
            !singleItemRedemption
              ? openRewardGroup.availableTreatmentsAmount
              : 1
          }
        />
      )}

      {showAddedToMyTretmentsDialog && (
        <ActionModal
          size="small"
          open={showAddedToMyTretmentsDialog}
          title="Added to my treatments"
          description={actionData?.successfullySelectedRewards.map((r) => (
            <div key={r.packageId} className="mb-2 px-2">
              <div className="mb-1.5 flex items-center justify-between text-body1 text-primary">
                <div>{r.variantName}</div>
                <div>Free</div>
              </div>
              <div className="text-left text-body2 text-secondary">
                {r.totalTreatmentAmount}{" "}
                {r.totalTreatmentAmount > 1
                  ? r.packageUnitType?.pluralUnitType
                  : r.packageUnitType?.singularUnitType}
              </div>
            </div>
          ))}
          headerIconSvg={AddedToCartCheck}
          firstButtonLabel={`View my treatment${
            actionData?.successfullySelectedRewards.length ? "s" : ""
          }`}
          onClose={() => setShowAddedToMyTretmentsDialog(false)}
          onFirstButtonClick={() => navigate("/account/my-treatments")}
        />
      )}

      {showReactivateMembershipDialog && (
        <ActionModal
          open={showReactivateMembershipDialog}
          title="Reactivate your membership?"
          description={`Your deactivation will be canceled and your membership will remain active. Your next payment will be due on 
          ${dayjs(visibleMembership.expiration).format("MM/DD/YYYY")}`}
          size="small"
          firstButtonLabel="Reactivate"
          onFirstButtonClick={() =>
            reactivateMembership(visibleMembership.platformUserMembershipId)
          }
          onClose={() => setShowReactivateMembershipDialog(false)}
        />
      )}

      {signUpBonus && signUpBonus.membershipRewards?.length > 0 && (
        <ModalSelectorList
          open={signUpBonusModalOpen}
          size="fullscreen"
          type="products"
          selectionType={signUpBonusSelectionType}
          title="Select your sign-up bonus"
          subtitle={
            <div className="text-center text-body1 text-primary">
              <span className="text-bold1">
                Select {signUpBonus.availableTreatmentsAmount}
              </span>{" "}
              {pluralize(
                signUpBonus.availableTreatmentsAmount,
                "item",
                "items"
              )}{" "}
              for your sign-up bonus
            </div>
          }
          items={signUpBonus.membershipRewards.map((item) => ({
            id: item.id,
            title: item.packageName || "",
            subtitle: `${item.quantity} ${pluralize(
              item.quantity,
              item.packageUnitType?.singularUnitType ?? "unit",
              item.packageUnitType?.pluralUnitType ?? "units"
            )}`,
            disabled:
              !signUpBonusesSelected.includes(item.id) &&
              signUpBonusesSelected.length >=
                signUpBonus.availableTreatmentsAmount,
          }))}
          checkedItems={signUpBonusesSelected}
          firstButtonLabel="Confirm"
          onFirstButtonClick={() =>
            submit(
              {
                data: JSON.stringify({
                  rewardIds: signUpBonusesSelected,
                  platformUserMembershipId: visibleMembership.id,
                }),
              },
              { method: "post" }
            )
          }
          firstButtonDisabled={
            signUpBonusesSelected.length !==
            signUpBonus.availableTreatmentsAmount
          }
          secondButtonLabel="Cancel"
          onSecondButtonClick={() => {
            setSignUpBonusesSelected([]);
            setSignUpBonusModalOpen(false);
          }}
          onClose={() => {
            setSignUpBonusesSelected([]);
            setSignUpBonusModalOpen(false);
          }}
          onSelect={(clickedItem) => {
            let newSignUpBonusesSelected;
            if (signUpBonusSelectionType === "single") {
              newSignUpBonusesSelected = [clickedItem];
            } else if (signUpBonusesSelected.includes(clickedItem)) {
              newSignUpBonusesSelected = signUpBonusesSelected.filter(
                (bonus) => bonus !== clickedItem
              );
            } else {
              newSignUpBonusesSelected = [
                clickedItem,
                ...signUpBonusesSelected,
              ];
            }
            setSignUpBonusesSelected(newSignUpBonusesSelected);
          }}
        />
      )}

      {membershipMilestones &&
        membershipMilestones?.length > 0 &&
        selectedMilestoneBonusId && (
          <>
            <ModalSelectorList
              open={milestoneBonusOpen}
              size="fullscreen"
              type="products"
              selectionType={
                getMilestoneBonusType(selectedMilestoneBonusId) ?? "single"
              }
              title={
                "Select your " +
                getMilestoneBonusData(selectedMilestoneBonusId)
                  ?.monthsBeforeFirstReward +
                "-month bonus"
              }
              subtitle={getMilestoneSelectModalSubtitle()}
              items={
                getMilestoneBonusData(
                  selectedMilestoneBonusId
                )?.membershipRewards?.map((item) => ({
                  id: item.id,
                  title: item.packageName || "",
                  subtitle: `${item.quantity} ${pluralize(
                    item.quantity,
                    item.packageUnitType?.singularUnitType ?? "unit",
                    item.packageUnitType?.pluralUnitType ?? "units"
                  )}`,
                  disabled: false,
                })) || []
              }
              checkedItems={milestoneBonusesSelected}
              firstButtonLabel="Confirm"
              onFirstButtonClick={() => {
                submit(
                  {
                    data: JSON.stringify({
                      rewardIds: milestoneBonusesSelected,
                      platformUserMembershipId: visibleMembership.id,
                    }),
                  },
                  { method: "post" }
                );
              }}
              firstButtonDisabled={
                milestoneBonusesSelected.length !==
                membershipMilestones.find(
                  (mm) => mm.id === selectedMilestoneBonusId
                )?.availableTreatmentsAmount
              }
              secondButtonLabel="Cancel"
              onSecondButtonClick={() => {
                setMilestoneBonusesSelected([]);
                setMilestoneBonusOpen(false);
              }}
              onClose={() => {
                setMilestoneBonusesSelected([]);
                setMilestoneBonusOpen(false);
              }}
              onSelect={(clickedItem) => {
                onSelectMilestoneBonus(clickedItem);
              }}
            />

            <ListModal
              open={milestoneBonusListModalOpen}
              onClose={() => setMilestoneBonusListModalOpen(false)}
              size="fullscreen"
              title={
                getMilestoneBonusData(selectedMilestoneBonusId)
                  ?.monthsBeforeFirstReward + "-month bonus"
              }
              firstButtonLabel="Okay"
              onFirstButtonClick={() => {
                setMilestoneBonusListModalOpen(false);
              }}
              firstButtonDisabled={false}
            >
              <div className="flex flex-col gap-5 p-6">
                <div className="text-center text-body1 text-primary">
                  {getMilestoneListModalDescription(
                    getMilestoneBonusData(selectedMilestoneBonusId)
                      ?.hasSelectableRewards
                  )}
                </div>
                <div>
                  <ExpandableItems
                    items={
                      getMilestoneBonusData(
                        selectedMilestoneBonusId
                      )?.membershipRewards?.map((item) => {
                        return {
                          id: item.id,
                          title: getProductTitle(
                            item.packageListingTitle,
                            item.packageName
                          ),
                          description: item.packageDescription,
                        };
                      }) || []
                    }
                  />
                </div>
              </div>
            </ListModal>
          </>
        )}

      {showUpdatePaymentModal && (
        <ActionModal
          size="small"
          open={showUpdatePaymentModal}
          title="Update payment method"
          description="You will be sent to our payment provider to update your subscription payment method. Any updates will take effect on your next payment date."
          firstButtonLabel="Update payment method"
          onFirstButtonClick={() =>
            handleUpdatePayment(visibleMembership.platformUserMembershipId)
          }
          headerIconSvg={BillingInfo}
          secondButtonLabel="Cancel"
          onSecondButtonClick={() => setShowUpdatePaymentModal(false)}
          onClose={() => setShowUpdatePaymentModal(false)}
        />
      )}
      {!visibleMembership.canceled && (
        <div className="my-6 flex justify-center">
          <TextButton
            endIconSVG={ChevronRight}
            onClick={() => setShowUpdatePaymentModal(true)}
          >
            Update payment method
          </TextButton>
        </div>
      )}
    </div>
  );
};

export default AccountMembershipPage;
