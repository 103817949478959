import { useQuery } from "@tanstack/react-query";
import cx from "classnames";
import { useNavigate } from "react-router-dom";

import { get } from "api";
import type { Models } from "api/types";
import { Button } from "atoms/button/Button";

export interface PromotionHeroProps extends Models<"SiteWidePromo"> {
  onClick?: () => void;
  id: string;
  homeStyle?: boolean;
}

const PromotionHero = (props: PromotionHeroProps) => {
  const navigate = useNavigate();
  const { data: customShopCategories } = useQuery({
    queryKey: ["/shop/custom-categories/listings"],
    queryFn: () => get("/shop/custom-categories/listings"),
  });
  const customShopCategory = customShopCategories?.find(
    (result) => result.category.id === props.id
  );
  const customShopCategoryTo =
    customShopCategory?.packageListings.length === 1
      ? `/pdp/package/${customShopCategory.packageListings[0].id}`
      : `/shop/custom-category/${props.id}`;

  const handleClick = () => {
    navigate(customShopCategoryTo);
  };

  return (
    <div
      onClick={handleClick}
      className={cx(
        "flex flex-col items-center bg-cover bg-center text-white",
        !props.homeStyle ? "h-[360px]" : "-mt-3 aspect-[390/434] p-8"
      )}
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.2) 12%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0.25) 80%), url(${props.imageUrl})`,
      }}
    >
      <div className="mt-10 text-center font-serif text-display text-shadow">
        {props.title}
      </div>

      {props.description && (
        <div className="sticky top-[244px] text-center text-body2 text-shadow">
          {props.description}
        </div>
      )}
      {props.id !== null && props.callToActionText && (
        <div className="sticky top-[278px] text-center">
          <Button style="outline">{props.callToActionText}</Button>
        </div>
      )}
    </div>
  );
};

export default PromotionHero;
