import React, { useEffect, useRef, useState } from "react";

const Carousel = ({
  children,
  showBreadCrumb,
}: {
  children: any;
  showBreadCrumb: boolean;
}) => {
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const slideRef = useRef<HTMLDivElement | null>(null);
  const slideContainerRef = useRef<HTMLDivElement | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  let slideWidth = 390;
  if (slideRef.current) {
    slideWidth = slideRef.current.offsetWidth;
  }

  const handleScroll = () => {
    if (slideContainerRef.current) {
      const scrollLeft = slideContainerRef.current.scrollLeft;
      setActiveIndex(Math.floor(scrollLeft / slideWidth));
    }
  };

  useEffect(() => {
    if (slideContainerRef.current) {
      slideContainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (slideContainerRef.current) {
        slideContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [slideWidth]);
  useEffect(() => {
    if (!carouselRef.current || !slideContainerRef.current) {
      return;
    }

    const navigate = (arg: string | number) => {
      if (!slideContainerRef.current) return;
      const newScrollPosition = getNewScrollPosition(arg);
      setActiveIndex(Math.floor(newScrollPosition / slideWidth));
      slideContainerRef.current.scrollLeft = newScrollPosition;
    };

    const getNewScrollPosition = (arg: string | number) => {
      if (!slideContainerRef.current) return 0;

      const maxScrollLeft = slideContainerRef.current.scrollWidth - slideWidth;

      if (arg === "forward") {
        const x = slideContainerRef.current.scrollLeft + slideWidth;
        return x <= maxScrollLeft + slideWidth / 2 ? x : 0;
      } else if (arg === "backward") {
        const x = slideContainerRef.current.scrollLeft - slideWidth;
        return x >= 0 ? x : maxScrollLeft;
      } else if (typeof arg === "number") {
        return arg * slideWidth;
      }

      return 0;
    };

    const handleIndicatorClick = (index: number) => {
      clearInterval(autoplay);
      navigate(index);
    };

    document.querySelectorAll(".slide-indicator").forEach((dot, index) => {
      dot.addEventListener("click", () => handleIndicatorClick(index));
    });

    let autoplay = setInterval(() => navigate("forward"), 5000);
    const restartInterval = () => {
      autoplay = setInterval(() => navigate("forward"), 5000);
    };
    const handleResize = () => {
      if (slideRef.current) {
        slideWidth = slideRef.current.offsetWidth;
      }
    };

    window.addEventListener("resize", handleResize);
    if (slideContainerRef.current) {
      slideContainerRef.current.addEventListener("mouseenter", () =>
        clearInterval(autoplay)
      );
      slideContainerRef.current.addEventListener("mouseleave", () =>
        restartInterval()
      );
      slideContainerRef.current.addEventListener("touchstart", () =>
        clearInterval(autoplay)
      );
      slideContainerRef.current.addEventListener("touchend", () =>
        restartInterval()
      );
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      clearInterval(autoplay);
    };
  }, []);

  return (
    <div className="relative mx-auto flex max-w-7xl flex-col" ref={carouselRef}>
      <div
        id="slide-container"
        className="flex h-full snap-x snap-mandatory items-center overflow-y-hidden overflow-x-scroll scroll-smooth"
        ref={slideContainerRef}
      >
        {React.Children.map(children, (child, index) => {
          if (index === 0 && React.isValidElement(child)) {
            return React.cloneElement(child as React.ReactElement, {
              ref: slideRef,
            });
          }
          return child;
        })}
      </div>
      {showBreadCrumb && (
        <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center">
          {React.Children.map(children, (_, index) => (
            <div
              key={index}
              className={
                "slide-indicator" + (activeIndex === index ? " active" : "")
              }
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Carousel;
