import { ReactComponent as Chevron } from "atoms/icon/icons/chevron_right.svg";

interface ProductCategoryProps {
  /** Product Category text */
  text: string;

  /**  Product Category secondary text */
  secondaryText: string;

  /**  Product Category image */
  imageUrl: string;

  /** Callback when product category is clicked */
  onClick?: () => void;

  backgroundColor?: string;

  color?: string;
}

export const ProductCategory = ({
  text,
  secondaryText,
  imageUrl,
  onClick,
  backgroundColor,
  color,
}: ProductCategoryProps) => {
  return (
    <div
      style={{ backgroundColor: backgroundColor }}
      className="flex h-[120px] w-full cursor-pointer items-center justify-between rounded-lg bg-white pr-3 shadow"
      onClick={onClick}
    >
      <img
        src={imageUrl}
        className="aspect-square h-full rounded-l-lg object-cover pr-4"
      />
      <div className="flex-grow items-center pr-3">
        <div className="pb-2 font-serif text-h3 text-primary" style={{ color }}>
          {text}
        </div>
        <div className="font-sans text-body2 text-secondary " style={{ color }}>
          {secondaryText}
        </div>
      </div>
      <div className="items-center fill-brand-color">
        <Chevron style={{ fill: color }} className="h-6 w-6 " />
      </div>
    </div>
  );
};
