import { ContextType, createContext, useState } from "react";

type App = {
  platformId: string;
  apiUrl: string;
  appIconNames: string[];
};

export const AppContext = createContext({
  platformId: "",
  apiUrl: "",
  appIconNames: [] as string[],
  setPlatformId: (id: string): void => undefined,
  setApiUrl: (url: string): void => undefined,
  setAppIcons: (icons?: string[]): void => undefined,
});

export const useAppState = (): ContextType<typeof AppContext> => {
  const [app, setApp] = useState<App>({
    platformId: localStorage.getItem("platformId") || "",
    apiUrl: localStorage.getItem("baseApiUrl") || "",
    appIconNames: JSON.parse(localStorage.getItem("iconNames") || "[]"),
  });
  return {
    platformId: app.platformId,
    apiUrl: app.apiUrl,
    appIconNames: app.appIconNames,
    setPlatformId: (id) => {
      if (!id) {
        localStorage.removeItem("platformId");
      } else {
        localStorage.setItem("platformId", id);
      }
      setApp({ ...app, platformId: id });
    },
    setApiUrl: (url) => {
      if (!url) {
        localStorage.removeItem("baseApiUrl");
      } else {
        localStorage.setItem("baseApiUrl", url);
      }
      setApp({ ...app, apiUrl: url });
    },
    setAppIcons: (icons) => {
      if (!icons) {
        localStorage.removeItem("iconNames");
      } else {
        localStorage.setItem("iconNames", JSON.stringify(icons));
      }
      setApp({ ...app, appIconNames: icons || [] });
    },
  };
};
