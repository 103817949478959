import { useEffect, useState } from "react";

import { queueTaskInBackground } from "utils/queueTaskInBackground";

type Props = {
  /** The "filled" progress, relative to `max` */
  value: number;
  /** The "max" progress (defaults to `100`) */
  max?: number;
};

/** Atomic progress bar component */
const ProgressBar = ({ value, max = 100 }: Props) => {
  const [valueToAnimate, setValueToAnimate] = useState(0);

  useEffect(() => {
    queueTaskInBackground(() =>
      setValueToAnimate(value !== 0 ? Math.min((value / max) * 100, 100) : 0)
    );
  }, [value, max]);

  return (
    <div className="h-2 w-full rounded-full bg-gray-200">
      <div
        className="h-full rounded-full bg-brand-color transition-[width] duration-1000"
        style={{
          width: `${valueToAnimate}%`,
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
