import { json, LoaderFunction, Outlet, useLoaderData } from "react-router-dom";

import { get } from "api";
import TopNav from "molecules/navbars/TopNav";
import { Tabs } from "molecules/navigation/tabs/Tabs";
import { queryClient } from "pages/Root";
import { notFalsy } from "toolbox/Guards";
import { waitObj } from "toolbox/Promise";

import { hasAvailableTreatmentsToSelect } from "./utils";

const load = async () => {
  const platform = queryClient.fetchQuery(["/platform"], () =>
    get("/platform")
  );

  const promotions = queryClient.fetchQuery(
    ["/promotions"],
    () => get("/promotions"),
    { staleTime: 1000 * 60 * 2 }
  );

  const memberships = queryClient.fetchQuery(
    ["/platform-user-memberships"],
    () => get("/platform-user-memberships")
  );
  const treatments = queryClient.fetchQuery(["/platform-user-packages"], () =>
    get("/platform-user-packages")
  );

  const data = await waitObj({
    platform,
    promotions,
    memberships,
    treatments,
  });

  return {
    marketplaceEnabled: !!data.platform.platformSettings?.marketplaceEnabled,
    notifyMemberships: hasAvailableTreatmentsToSelect(data.memberships),
    hasPromotions: data.promotions.some((p) => !!p.redeemedOn),
    hasMemberships: data.memberships.length > 0,
    hasTreatments: data.treatments.length > 0,
  };
};

export type LoaderData = Awaited<ReturnType<typeof load>>;
export const accountLayoutLoader: LoaderFunction = async () => {
  return json(await load());
};

const AccountLayout = () => {
  const {
    marketplaceEnabled,
    notifyMemberships,
    hasPromotions,
    hasMemberships,
    hasTreatments,
  } = useLoaderData() as LoaderData;

  const tabs = [
    (marketplaceEnabled || hasTreatments) && {
      to: "/account/my-treatments",
      label: "My treatments",
    },
    hasPromotions && {
      notify: true,
      to: "/account/offers",
      label: "My Offers",
    },
    (marketplaceEnabled || hasMemberships) && {
      notify: notifyMemberships,
      to: "/account/membership",
      label: "Membership",
    },
    {
      to: "/account/settings",
      label: "Settings",
    },
  ].filter(notFalsy);

  return (
    <div className="flex min-h-full flex-col">
      <TopNav footer={<Tabs items={tabs} />}>
        <h1 className="text-xl">Account</h1>
      </TopNav>
      <div className="flex grow flex-col bg-one">
        <Outlet />
      </div>
    </div>
  );
};

export default AccountLayout;
