import dayjs from "dayjs";
const LAST_BANNER_VIEWED_TIME_IN_TIMESTAMP =
  "repeatmd-app-last-idle-time-in-timestamp";

export const setIdleTimeInTimestamp = (userId: string | undefined) => {
  if (!userId) {
    return;
  }
  const lastIdleTime = {
    date: dayjs().format("MM-DD-YYYY"),
    userId: userId,
  };

  localStorage.setItem(
    LAST_BANNER_VIEWED_TIME_IN_TIMESTAMP + "-" + userId,
    JSON.stringify(lastIdleTime)
  );
};

export const linkToApp = (platformId: string | undefined) => {
  if (!platformId) {
    return;
  }
  const isNonProdEnv =
    location.href.includes(".dev.") || location.href.includes(".staging.");
  const link = new URL("https://repeatmd.page.link");
  link.search = new URLSearchParams({
    link: `https://amora.dev.repeatmd.app/login?platformId=${platformId}&isProd=${!isNonProdEnv}`,
    apn: "com.repeatmd.mobile",
    isi: "6448703360",
    ibi: "com.repeatmd.mobile",
    efr: "1",
    ofl: "https://amora.repeatmd.app",
  }).toString();

  window.location.replace(link.href);
};

export const showViewAppFooter = (userId: string | undefined) => {
  if (!userId) {
    return false;
  }
  const lastTimeStamp = JSON.parse(
    localStorage.getItem(LAST_BANNER_VIEWED_TIME_IN_TIMESTAMP + "-" + userId) ||
      "{}"
  );
  // We show app banner if user hasn't seen it before (no timestamp)
  if (Object.keys(lastTimeStamp).length == 0) {
    return true;
  }

  const lastViewedDate = dayjs(lastTimeStamp.date);
  const today = dayjs();
  const isNewDay = today.diff(lastViewedDate, "days") > 0;

  // We show app banner if it's a different day
  if (isNewDay) {
    return true;
  }

  return false;
};
