import cx from "classnames";

import { Checkbox } from "atoms/checkbox/Checkbox";
import { Radio } from "atoms/radio/Radio";

interface ModalSelectorProps {
  /** Specify title */
  title: string;
  /** Specify subtext label of this selector (example: "recommended") */
  subtitle?: string;
  /** Specify label next to title */
  label: React.ReactNode | null;
  /** Specify description */
  description?: string | null;
  /** Specify default checked value */
  checked?: boolean;
  /** Specify onChange selector */
  onChange: () => void;
  /** Specify the type of selector, as default radio */
  type?: "radio" | "checkbox";
  disabled?: boolean;
}

const onClick = (
  e: React.MouseEvent<HTMLElement>,
  onChange: ModalSelectorProps["onChange"]
) => {
  onChange();
};

export const ModalSelector = ({
  title,
  subtitle,
  label,
  description,
  checked,
  onChange,
  type = "radio",
  disabled = false,
}: ModalSelectorProps) => {
  return (
    <div
      className="border-b border-background-two py-1.5"
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        if (!disabled) {
          onClick(e, onChange);
        }
      }}
    >
      <div className="flex items-center justify-between">
        <div className="content-right flex gap-5 text-right text-primary">
          {type === "checkbox" && (
            <Checkbox
              label={title}
              subtitle={subtitle}
              checked={checked}
              onChange={onChange}
              disabled={disabled}
              textStyle="text-bold2"
            />
          )}
          {type === "radio" && (
            <div className="flex items-center">
              <Radio checked={checked} onChange={onChange} />
              <div
                className="ml-1.5 flex cursor-pointer flex-col gap-0.5 text-left align-middle"
                onClick={onChange}
              >
                <div
                  className={cx(
                    "text-primary",
                    description ? "text-bold1" : "text-bold2"
                  )}
                >
                  {title}
                </div>
                {subtitle && (
                  <div className="text-body2 text-secondary">{subtitle}</div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="text-right text-secondary">{label}</div>
      </div>
      {description && (
        <div className="content-right pl-[50px] pb-2.5 text-body2 text-secondary">
          {description}
        </div>
      )}
    </div>
  );
};
