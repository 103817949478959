import React, { useEffect, useState } from "react";

import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Info } from "atoms/icon/icons/info.svg";

interface Props {
  title: string;
  price?: string;
  feePercentage: number;
}

export const ServiceFeeRow: React.FC<Props> = ({
  title,
  price,
  feePercentage,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handler = () => {
      if (show) {
        setShow(false);
      }
    };
    document.addEventListener("click", handler);

    return () => {
      document.removeEventListener("click", handler);
    };
  }, [show]);

  return (
    <div className="relative text-body2">
      {show && (
        <div className="absolute bottom-full rounded bg-nearly-black p-3 text-center font-sans text-white">
          This fee is used to offset admin costs related to your treatment or
          service.
        </div>
      )}
      <div className="flex justify-between py-2">
        <button
          type="submit"
          className="inline-flex items-center justify-center gap-1 text-secondary"
          onClick={(event) => {
            event.stopPropagation();
            setShow(!show);
          }}
        >
          {(feePercentage * 100).toFixed(2)}% {title}
          <Icon svg={Info} size="x2small" color="secondaryText" />
        </button>
        {price && <div>{price}</div>}
      </div>
    </div>
  );
};
