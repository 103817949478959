import { useNavigate } from "react-router-dom";

import { Models } from "api/types";
import { ReactComponent as AddedToCartCheck } from "atoms/icon/icons/ic_added_to_cart_check_ol.svg";
import { ActionModal } from "molecules/modal-window/action-modal/ActionModal";
import { formatCurrency } from "toolbox/Money";

type AddedToCartModalProps = {
  title: string | null | undefined;
  subtitle: string;
  cost: number;
  open: boolean;
  membership: Models<"MembershipModel"> | null | undefined;
  onClose: () => void;
};

export const AddedToCartModal = (props: AddedToCartModalProps) => {
  const navigate = useNavigate();

  return (
    <ActionModal
      open={props.open}
      onClose={props.onClose}
      size="small"
      firstButtonLabel="View cart"
      onFirstButtonClick={() => navigate(`/cart`)}
      headerIconSvg={AddedToCartCheck}
      title="Added to cart!"
      description={
        <div className="flex flex-col justify-center text-center">
          <div className="flex justify-between gap-2 border-t-[1px] border-t-background-two px-2 pt-4">
            <div className="flex flex-col gap-1.5">
              <div className="text-left text-body1 text-primary">
                {props.title}
              </div>
              <div className="text-left text-body2 text-secondary">
                {props.subtitle}
              </div>
            </div>
            <div>
              <div className="text-body1 text-primary">
                {formatCurrency(props.cost)}
              </div>
            </div>
          </div>
          {props.membership && (
            <div className="flex justify-between gap-2 border-t-[1px] border-t-background-two py-2">
              <div className="flex flex-col text-left">
                <div className="text-left text-body1 text-primary">
                  {props.membership.name}
                </div>
                <div className="text-left text-body2 text-secondary">
                  Membership
                </div>
              </div>
              <div className="text-body1 text-primary">
                {formatCurrency(props.membership.monthlyCost)}
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};
