import dayjs from "dayjs";
import { json, LoaderFunction, redirect, useLoaderData } from "react-router";
import { Link } from "react-router-dom";

import { get } from "api";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Right } from "atoms/icon/icons/chevron_right.svg";
import { DescriptiveTag } from "atoms/tag/DescriptiveTag";
import { queryClient } from "pages/Root";
import { waitObj } from "toolbox/Promise";

const load = async () => {
  const promotions = queryClient.fetchQuery({
    queryKey: ["/promotions"],
    queryFn: () => get("/promotions"),
    staleTime: 1000 * 60 * 2,
  });

  return waitObj({
    promotions: promotions.then((ps) => ps.filter((p) => !!p.redeemedOn)),
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const accountOffersLoader: LoaderFunction = async () => {
  const data = await load();
  if (!data.promotions.length) return redirect("..");
  return json<LoaderData>(data);
};

const AccountOffersPage = () => {
  const { promotions } = useLoaderData() as LoaderData;

  return (
    <div className="px-4 py-6">
      <OffersList
        title="Available Claimed Offers"
        cards={promotions.map((p) => ({
          to: p.id,
          imageUrl: p.imageUrl,
          title: p.title,
          disclaimer: p.disclaimer,
          tag: p.expiresOn
            ? { alert: `Expires ${dayjs(p.expiresOn).fromNow()}` }
            : null,
        }))}
      />
    </div>
  );
};

export default AccountOffersPage;

type Tag = { alert: string } | { primary: string };
type TreatmentListProps = {
  title?: string | null;
  cards: {
    to: string;
    title: string;
    disclaimer?: string | null;
    tag?: Tag | null;
    imageUrl?: string | null;
  }[];
};

// TODO: Update the TreatmentList component to allow using it for both
// treatments and offers
const OffersList = (props: TreatmentListProps) => {
  const tag = (t?: Tag | null) => {
    if (!t) return null;

    const type = "alert" in t ? "alert" : "primary";
    const text = "alert" in t ? t.alert : t.primary;

    return (
      <DescriptiveTag size="small" color={type}>
        {text}
      </DescriptiveTag>
    );
  };

  return (
    <div className="flex flex-col gap-6">
      {props.title && (
        <h1 className="text-center text-sub2 uppercase text-secondary">
          {props.title}
        </h1>
      )}
      <ul className="divide-y divide-background-two overflow-clip rounded-lg shadow-subtle">
        {props.cards.map((card) => (
          <li key={card.to}>
            <Link to={card.to} className="flex items-center gap-3 bg-white p-2">
              {card.imageUrl && (
                <img
                  className="aspect-square h-24 w-24 rounded object-cover"
                  src={card.imageUrl}
                />
              )}
              <div className="flex grow flex-col  text-primary">
                <div className="py-2">{tag(card.tag)}</div>
                <div className="flex flex-col gap-0.5">
                  <div className="text-bold1 line-clamp-2">{card.title}</div>
                  <div className="text-body3 italic text-secondary line-clamp-3">
                    {card.disclaimer}
                  </div>
                </div>
              </div>
              <div className="mr-2 self-center">
                <Icon svg={Right} size="xsmall" />
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
