import {
  json,
  LoaderFunction,
  Navigate,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get } from "api";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { ProductCardLarge } from "molecules/cards/product-card-large/ProductCardLarge";
import { queryClient } from "pages/Root";
import { FilterAndSortListings } from "pages/root/shop/FilterAndSortListings";
import { notNull } from "toolbox/Guards";
import { waitObj } from "toolbox/Promise";

import { shopOnLoadNotificationTypes } from "./ShopIndex";
import { ShopListHeader } from "./ShopListHeader/ShopListHeader";

const loader = async () => {
  const shopData = queryClient.fetchQuery(["/shop"], () => get("/shop"));
  const customShopCategories = queryClient.fetchQuery(
    ["/shop/custom-categories/listings"],
    () => get("/shop/custom-categories/listings")
  );

  return waitObj({ customShopCategories, shopData });
};

type LoaderData = Awaited<ReturnType<typeof loader>>;
export const shopCustomCategoryLoader: LoaderFunction = async () => {
  return json<LoaderData>(await loader());
};

const ShopCustomCategoryPage = () => {
  const navigate = useNavigate();
  const { customShopCategories, shopData } = useLoaderData() as LoaderData;
  const [firstCustomCategory] = customShopCategories;

  if (!firstCustomCategory) {
    return (
      <Navigate
        to={`/shop/?notification=${shopOnLoadNotificationTypes.notFound}`}
      />
    );
  }

  const category = firstCustomCategory.category;
  const listings = firstCustomCategory.packageListings;
  const types = [...new Set(listings.map((listing) => listing.type))];
  const filteredConcerns =
    shopData.concerns?.filter((concern) =>
      listings.flatMap((listing) => listing.concernIds).includes(concern.id)
    ) ?? [];
  const filteredAreas =
    shopData.areas?.filter((area) =>
      listings.flatMap((listing) => listing.areaIds).includes(area.id)
    ) ?? [];

  return (
    <div className="flex flex-col bg-two">
      <ShopListHeader
        title={category.title || ""}
        subtitle={category.description || ""}
        backgroundImageUrl={category.headerImageUrl || ""}
        bgGradient
      />
      {listings.length && (
        <FilterAndSortListings
          types={types}
          areas={filteredAreas}
          concerns={filteredConcerns}
          listings={listings}
        >
          {(listing) => (
            <ProductCardLarge
              key={listing.id}
              title={listing.title}
              description={listing.description}
              imageUrl={listing.imageUrl}
              type={listing.type}
              tag={""}
              concerns={filteredConcerns
                .filter((concern) => listing.concernIds.includes(concern.id))
                .map((concern) => concern.name)
                .filter(notNull)}
              price={listing.baseCost}
              memberPrice={listing.membershipCost}
              onClick={() => navigate(`/pdp/package/${listing.id}`)}
            />
          )}
        </FilterAndSortListings>
      )}
      <RepeatMDFooter />
    </div>
  );
};

export default ShopCustomCategoryPage;
