import cx from "classnames";
import React, { useCallback, useState } from "react";

import { IconButton } from "atoms/icon-button/IconButton";

import { ReactComponent as ArrowLeft } from "../icon/icons/arrow_left.svg";
import { ReactComponent as ArrowRight } from "../icon/icons/arrow_right.svg";

interface Props {
  /** Specify the content of the slides and title */
  slides: { url: string; title?: string; type: "Video" | "Image" | "Gif" }[];

  /** Specify the testid for current component */
  "data-testid"?: string;
}

export const Carousel: React.FC<Props> = ({
  slides,
  "data-testid": dataTestId,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const ref = React.useRef<(HTMLImageElement | HTMLVideoElement | null)[]>([]);

  const goToSlide = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
    ref.current[slideIndex]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    ref.current[newIndex]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };
  const goToNext = useCallback(() => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    ref.current[newIndex]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [currentIndex, slides]);

  return (
    <div className="flex flex-col rounded" data-testid={dataTestId}>
      <div
        className={cx(
          "flex",
          "aspect-[4/3]",
          "w-full",
          "rounded",
          "overflow-x-hidden",
          "snap-x",
          "snap-mandatory",
          "gap-10"
        )}
      >
        {slides.map((item, index) => {
          if (item.type == "Video") {
            return (
              <video
                ref={(e) => (ref.current[index] = e)}
                key={item.url}
                autoPlay
                className="block aspect-[4/3] h-full snap-center object-cover"
                src={item.url}
              />
            );
          }
          return (
            <img
              ref={(e) => (ref.current[index] = e)}
              key={item.url}
              src={item.url}
              className="block aspect-[4/3] h-full w-full snap-center object-cover"
              alt={item.title}
            />
          );
        })}
      </div>
      {slides.length > 1 && (
        <div className="w-fill flex items-center justify-between py-1">
          <IconButton
            svg={ArrowLeft}
            size="xsmall"
            style={currentIndex == 0 ? "inactive" : "brandIcon"}
            onClick={goToPrevious}
          />
          <div className="flex items-center gap-3">
            {slides.map((slide, index) => (
              <div
                key={slide.url}
                className={cx(
                  "relative",
                  "rounded-full",
                  "hover:bg-brand-color",
                  currentIndex === index ? "h-2.5 w-2.5" : "h-1.5 w-1.5",
                  currentIndex === index ? "bg-brand-color" : "bg-dark-grey"
                )}
                onClick={() => goToSlide(index)}
              ></div>
            ))}
          </div>
          <IconButton
            svg={ArrowRight}
            size="xsmall"
            style={currentIndex + 1 == slides.length ? "inactive" : "brandIcon"}
            onClick={goToNext}
          />
        </div>
      )}
    </div>
  );
};

export default Carousel;
