/**
 * Tries to convert a hex color to rgb. If the hex includes an alpha channel, it
 * will be used. If not, and a default alpha is provided (value from 0 to 1), it
 * will be used.
 */
export const hexToRgb = (hex?: string | null, defaultAlpha?: number) => {
  const longHex = hex?.replace(
    /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d])?$/i,
    (_, r, g, b, a) => r + r + g + g + b + b + (a ? a + a : "")
  );

  const rgb = longHex?.replace(
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i,
    (_, r, g, b, _a) => {
      const rgb = [r, g, b].map((x) => parseInt(x, 16)).join(" ");
      const a = _a ? parseInt(_a, 16) / 255 : defaultAlpha;
      return a ? `${rgb} / ${a}` : rgb;
    }
  );

  return rgb === hex || rgb === longHex ? undefined : rgb;
};

export const isDarkColorRgb = (color: string) => {
  const targetColor = color.charAt(0) === "#" ? color.substring(1, 7) : color;
  const r = parseInt(targetColor.substring(0, 2), 16); // hexToR
  const g = parseInt(targetColor.substring(2, 4), 16); // hexToG
  const b = parseInt(targetColor.substring(4, 6), 16); // hexToB
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? false : true;
};
