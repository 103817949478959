import cx from "classnames";
import { FC, MouseEventHandler, SVGProps } from "react";

import { ReactComponent as ChevronRight } from "atoms/icon/icons/ic_chevron_right_small_ol.svg";
import { isDarkColorRgb } from "toolbox/Colors";

import { Icon } from "../icon/Icon";

interface BannerProps {
  /** Callback to handle banner click events */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** Add an icon before the label. Will also 'pill' shape the banner and change horizontal padding to 16px. */
  startIconSVG?: FC<SVGProps<SVGSVGElement>>;
  /** Specify the background color. */
  backgroundColor?: string;
  /** Specify the background image. It takes precedence over color. */
  backgroundImage?: string;
  /** Specify the title color */
  titleColor?: string;
  /** Title of the banner */
  title?: string;
  /** Summary of the banner */
  summary?: string;
  /** Has clickable arrow at the rightmost space */
  arrow?: boolean;
  /** Specify the testid for current component */
  "data-testid"?: string;
}

/**
 * Atomic component - Banner
 */
export const Banner = ({
  onClick,
  startIconSVG: Svg,
  backgroundColor = "#FFFFFF",
  backgroundImage = "",
  titleColor = isDarkColorRgb(backgroundColor) ? "#FFFFFF" : "#000000",
  title = "",
  summary = "",
  arrow = false,
  "data-testid": dataTestId,
}: BannerProps) => {
  const content = (
    <div className="flex h-9 cursor-pointer flex-col gap-0.5 overflow-hidden whitespace-nowrap">
      <h3 className="overflow-hidden text-ellipsis text-bold2">{title}</h3>
      <h4 className="overflow-hidden text-ellipsis text-body3">{summary}</h4>
    </div>
  );

  return (
    <section
      className={cx(
        "flex",
        "items-center",
        "justify-between",
        "cursor-pointer",
        "gap-x-6",
        "p-3",
        "pl-5",
        backgroundImage && "bg-cover bg-center"
      )}
      onClick={onClick}
      data-testid={dataTestId}
      style={{
        color: titleColor,
        backgroundColor: backgroundImage ? "none" : backgroundColor,
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : "initial",
      }}
    >
      {Svg ? (
        <div className={cx("flex", "items-center", "gap-x-5")}>
          <div className={cx("flex h-8 items-center")}>
            <Svg className={cx("h-8")} />
          </div>
          {content}
        </div>
      ) : (
        content
      )}
      {arrow && (
        <Icon
          className="flex"
          size="xsmall"
          svg={ChevronRight}
          color="currentColor"
        />
      )}
    </section>
  );
};
