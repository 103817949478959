import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right.svg";
import { ReactComponent as RepeatMD } from "atoms/icon/icons/RepeatCash.svg";
import { DescriptiveTag } from "atoms/tag/DescriptiveTag";
import { formatCurrency } from "toolbox/Money";

export interface AvailableRewardProps {
  id: string;
  title?: string | null;
  valueIfBanked?: number | null;
  onClick?: () => void;
  repeatCashEnabled?: boolean | null;
  tagDescription?: string | null;
  disclaimer?: string | null;
}

export const AvailableReward = (props: AvailableRewardProps) => {
  return (
    <div
      className="flex cursor-pointer items-center justify-between bg-white px-4 pt-4 pb-3"
      data-testid="div-available-reward"
      onClick={props.onClick}
    >
      <div>
        <div className="pb-2">
          <DescriptiveTag color="primary" size="small">
            {props.tagDescription ||
              `REDEEM ${props.repeatCashEnabled ? "OR BANK" : ""} NOW!`}
          </DescriptiveTag>
        </div>

        <div className="pb-1 pr-4 font-sans">
          <div className="text-bold1 text-primary">{props.title}</div>
          {props.disclaimer && (
            <div className="pt-0.5 text-body3 italic leading-[18px] text-secondary line-clamp-3">
              {props.disclaimer}
            </div>
          )}
        </div>

        {props.repeatCashEnabled && (
          <div className="flex items-center gap-0.5 font-sans text-body2 text-secondary">
            Can convert to
            <Icon svg={RepeatMD} color="primaryText" size="x2small" />
            {props.valueIfBanked
              ? formatCurrency(props.valueIfBanked)
              : "N/A"}{" "}
            cash
          </div>
        )}
      </div>
      {props.onClick && (
        <div>
          <Icon svg={ChevronRight} size="xsmall" color="brandColor" />
        </div>
      )}
    </div>
  );
};
