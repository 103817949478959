import cx from "classnames";

import { TextButton } from "atoms/text-button/TextButton";
import { formatCurrency } from "toolbox/Money";

type SavingsInfoProps = {
  baseCost: number;
  memberCost: number;
  membershipName: string;
  isMember: boolean;
  onViewMoreInfo: () => void;
};

export const SavingsInfo = (props: SavingsInfoProps) => {
  const savings = (
    <span className="text-bold2">
      {formatCurrency(props.baseCost - props.memberCost)}
    </span>
  );

  return (
    <div
      className={cx(
        "flex w-full flex-col items-center rounded bg-brand-tint py-[10px]"
      )}
    >
      <div className="text-center text-body2 text-secondary">
        Save {savings} with {props.isMember ? "your" : "a"}{" "}
        {props.membershipName} Membership
      </div>
      {!props.isMember && (
        <div
          className="pt-[6px] text-sub2 uppercase text-brand-color"
          onClick={props.onViewMoreInfo}
        >
          View more info
        </div>
      )}
    </div>
  );
};
