import {
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get } from "api";
import { Button } from "atoms/button/Button";
import { UserAndCash } from "molecules/subheaders/user-and-cash/UserAndCash";
import {
  NoTreatmentsContainer,
  TreatmentList,
} from "organisms/treatment-list/TreatmentList";
import { queryClient } from "pages/Root";
import { useFeatureFlag } from "services/Flags";
import {
  getBookAppointmentAction,
  getProductTitle,
} from "services/HelperService";
import { waitObj } from "toolbox/Promise";

const load = async () => {
  const userInfo = queryClient.fetchQuery(["/user"], () => get("/user"), {
    staleTime: 1000 * 60 * 2,
  });
  const platform = queryClient.fetchQuery(["/platform"], () =>
    get("/platform")
  );
  const myTreatments = queryClient.fetchQuery(["/platform-user-packages"], () =>
    get("/platform-user-packages")
  );
  const memberships = queryClient.fetchQuery(
    ["/platform-user-memberships"],
    () => get("/platform-user-memberships")
  );
  const platformLocation = queryClient.fetchQuery(["/platform-location"], () =>
    get("/platform-location")
  );

  return waitObj({
    platform,
    myTreatments,
    memberships,
    userInfo,
    platformLocation,
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const accountMyTreatmentsLoader: LoaderFunction = async () => {
  const data = await load();
  if (
    !data.platform.platformSettings?.marketplaceEnabled &&
    !data.myTreatments.length &&
    !data.memberships.length
  ) {
    return redirect("/account/settings");
  }
  return json<LoaderData>(data);
};

const AccountMyTreatmentsPage = () => {
  const navigate = useNavigate();
  const { userInfo, platform, myTreatments, memberships, platformLocation } =
    useLoaderData() as LoaderData;
  const isUnitedDerm = useFeatureFlag("unitedDerm");

  const memberBenefits = myTreatments.filter(
    ({ type }) => type === "MembershipTreatment"
  );

  const treatments = myTreatments.filter(
    ({ type }) => type === "Treatment" || type === "Package"
  );

  const getBonusText = (membershipRewardGroupIds?: string[] | null) => {
    if (!membershipRewardGroupIds) return null;

    const treatmentTypes = memberships
      .flatMap((m) => m.membership.membershipRewardGroups)
      .filter((rg) => rg && membershipRewardGroupIds?.includes(rg.id))
      .map((rg) => {
        if (rg && rg.periodMonths !== 0) {
          return "Member Benefit";
        } else if (rg && rg.monthsBeforeFirstReward === 0) {
          return "Sign-up Bonus";
        } else {
          return `${rg && rg.monthsBeforeFirstReward}-Month Bonus`;
        }
      });

    const treatmentTypesSet = new Set(treatmentTypes);

    if (
      (treatmentTypesSet.size === 1 &&
        treatmentTypesSet.has("Member Benefit")) ||
      treatmentTypesSet.size > 1
    ) {
      return null;
    }
    return treatmentTypesSet.values().next().value;
  };

  const { bookAppointmentUrl, phoneNumber, email } = platformLocation;

  const bookAppointmentHref = getBookAppointmentAction(
    bookAppointmentUrl,
    phoneNumber,
    email
  );

  return (
    <div className="flex grow flex-col">
      <UserAndCash
        firstName={userInfo.firstName}
        lastName={userInfo.lastName}
        repeatCashBalance={userInfo.repeatCashBalance}
        hideRepeatCash={!platform.platformSettings?.repeatCashEnabled}
      />

      {myTreatments.length === 0 && (
        <div className="mx-4 overflow-clip rounded-lg">
          <NoTreatmentsContainer text="You haven’t purchased any treatments yet. Browse the shop to buy your first treatment and start your journey!" />
        </div>
      )}

      {memberBenefits.length > 0 && (
        <>
          <div className="mb-6 text-center text-sub2 text-secondary">
            MEMBER BENEFITS
          </div>
          <div className="mb-6 px-4">
            <TreatmentList
              treatments={memberBenefits.map((t) => ({
                id: t.packageId,
                imgUrl: t.imageUrl,
                type: t.type,
                bonus: getBonusText(t.membershipRewardGroupIds),
                title: getProductTitle(t.productName, t.variantName),
                active: t.active,
                remainingTreatmentsCount: t.remainingTreatmentAmount,
                totalTreatments: t.totalTreatmentAmount,
                packageUnitType: t.packageUnitType,
                onClick: () =>
                  navigate(
                    `${t.packageId}?isFromMembership=true&active=${t.active}`
                  ),
              }))}
            />
          </div>
        </>
      )}

      {treatments.length > 0 && (
        <>
          <div className="mb-6 text-center text-sub2 text-secondary">
            PURCHASED TREATMENTS
          </div>
          <div className="px-4 pb-10">
            <TreatmentList
              treatments={treatments.map((t) => ({
                id: t.packageId,
                imgUrl: t.imageUrl,
                type: t.type,
                title: getProductTitle(t.productName, t.variantName),
                active: t.active,
                remainingTreatmentsCount: t.remainingTreatmentAmount,
                totalTreatments: t.totalTreatmentAmount,
                packageUnitType: t.packageUnitType,
                onClick: () =>
                  navigate(
                    `${t.packageId}?isFromMembership=false&active=${t.active}`
                  ),
              }))}
            />
          </div>
        </>
      )}
      {bookAppointmentHref && treatments.length > 0 && (
        <div className="sticky bottom-14 mt-auto bg-one p-4">
          <a
            href={bookAppointmentHref}
            target={bookAppointmentUrl ? "_blank" : undefined}
            rel="noreferrer"
          >
            <Button size="medium" style="primary" fullWidth>
              {!isUnitedDerm ? "Book appointment" : "Request appointment"}
            </Button>
          </a>
        </div>
      )}
    </div>
  );
};

export default AccountMyTreatmentsPage;
