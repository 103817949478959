import cx from "classnames";

import { DescriptiveTag } from "atoms/tag/DescriptiveTag";
import { TextButton } from "atoms/text-button/TextButton";
import { formatCurrency } from "toolbox/Money";

export interface CartItemProps {
  id: string;
  imgUrl?: string | null;
  type: number;
  title?: string | null;
  discount: number;
  price: number;
  packageTitle: string;
  showEditButton: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

enum TypeName {
  TREATMENT = 0,
  PACKAGE = 1,
  MEMBERSHIP = 2,
}

export const CartItem = ({
  imgUrl,
  type,
  title,
  price,
  packageTitle,
  showEditButton,
  discount,
  onEdit,
  onDelete,
}: CartItemProps) => {
  const typeName = TypeName[type];
  const isMembership = typeName == TypeName[2];
  const hasDiscount = Boolean(discount && discount > 0);
  return (
    <div
      className={cx(
        "flex",
        "cursor-pointer",
        "items-center",
        isMembership ? "bg-brand-tint" : "bg-one",
        "pt-4",
        "pl-4"
      )}
    >
      <div className="h-[72px] items-center">
        <img
          className="-mt-2 h-[72px] w-[72px] rounded object-cover"
          src={imgUrl ?? undefined}
          height={72}
          alt={typeName}
        />
      </div>
      <div className="w-[80%] pl-4">
        <div className="flex items-baseline justify-between">
          <div className="pr-4">
            <div className="font-sans text-bold1 line-clamp-2">{title}</div>
            <div className="my-1.5">
              <DescriptiveTag color="secondary" size="small">
                {typeName}
              </DescriptiveTag>
            </div>
          </div>
          <div className="flex flex-col pr-4 text-right font-sans text-bold2">
            {hasDiscount && <span>{formatCurrency(price - discount)}</span>}
            <span
              className={
                hasDiscount ? "font-thin text-secondary line-through" : ""
              }
            >
              {formatCurrency(price)}
            </span>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="pb-4 font-sans text-body2 text-secondary">
            {packageTitle}
          </div>
          <div className="items-bottom -mt-3 flex justify-between pr-4">
            {showEditButton && (
              <TextButton onClick={onEdit} xsText>
                EDIT
              </TextButton>
            )}
            <TextButton onClick={onDelete} xsText>
              REMOVE
            </TextButton>
          </div>
        </div>
      </div>
    </div>
  );
};
