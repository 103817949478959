import { Icon } from "atoms/icon/Icon";
import { ReactComponent as RepeatMD } from "atoms/icon/icons/RepeatCash.svg";
import { TextButton } from "atoms/text-button/TextButton";
import { formatCurrency } from "toolbox/Money";

type ApplyRepeatMDCashProps = {
  cash: number;
  isApplied: boolean;
  onApply?: () => void;
};

export const ApplyRepeatMDCash = ({
  cash,
  isApplied,
  onApply,
}: ApplyRepeatMDCashProps) => {
  return (
    <div className="flex w-full items-center justify-between rounded border border-light-grey py-3 pl-3 pr-1">
      <div className="flex items-center gap-0.5">
        <Icon svg={RepeatMD} color="primaryText" size="xsmall" />
        <div className="text-bold2">Cash:</div>
        {!isApplied && <div className="text-body2">You have</div>}
        <div className="text-bold2">{formatCurrency(cash, 2)}</div>
        <div className="text-body2">
          {!isApplied ? "available" : "has been applied"}
        </div>
      </div>
      <div className="pr-4">
        <TextButton onClick={onApply} xsText>
          {isApplied ? "REMOVE" : "APPLY"}
        </TextButton>
      </div>
    </div>
  );
};
