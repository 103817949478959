import { useNavigate } from "react-router-dom";

import { IconButton } from "atoms/icon-button/IconButton";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";

type PageHeaderProps = {
  title: string;
};

export const PageHeader = ({ title }: PageHeaderProps) => {
  const navigate = useNavigate();

  return (
    <div className="sticky top-0 z-10 flex items-center justify-between bg-white pb-[5px] pt-[calc(20px_+_env(safe-area-inset-top))] pr-4 shadow">
      <IconButton
        size="small"
        style="iconOnly"
        svg={ChevronLeft}
        onClick={() => {
          navigate(-1);
        }}
      />
      <div className="text-sub2 uppercase">{title}</div>
      {/* Padding via component to match existing styling in other pages */}
      <div className="invisible w-11"></div>
    </div>
  );
};
