import { useNavigate } from "react-router-dom";

import type { Models } from "api/types";
import { ShopFeatureTarget } from "api/types";
import { Button } from "atoms/button/Button";

export interface ShopFeatureProps extends Models<"ShopFeatureResult"> {
  onClick?: () => void;
  "data-testid"?: string;
  showBreadCrumbs?: boolean;
}

const ShopFeature = (props: ShopFeatureProps) => {
  const navigate = useNavigate();
  const handleClick = () => {
    switch (props.targetType) {
      case ShopFeatureTarget.Package:
      case ShopFeatureTarget.Treatment:
        navigate(`/pdp/package/${props.targetId}`);
        break;
      case ShopFeatureTarget.Membership:
        navigate(`/pdp/membership/${props.targetId}`);
        break;
      case ShopFeatureTarget.Category:
        navigate(`/shop/categories/${props.targetId}`);
        break;
      case ShopFeatureTarget.CustomUri:
        break;
      default:
        break;
    }
  };

  return (
    <div
      onClick={handleClick}
      className="flex h-[360px] flex-col items-center bg-cover bg-center text-white"
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.2) 12%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0.25) 80%), url(${props.imageUrl})`,
      }}
    >
      <div className="mt-10 text-center font-serif text-display text-shadow">
        {props.title}
      </div>
      {props.subtitle && (
        <div className="sticky top-[214px] text-center text-button text-shadow">
          {props.subtitle}
        </div>
      )}
      {props.information && (
        <div className="sticky top-[244px] text-center text-body2 text-shadow">
          {props.information}
        </div>
      )}
      {props.targetId !== null && props.callToActionText && (
        <div className="sticky top-[278px] text-center">
          <Button style="outline">{props.callToActionText}</Button>
        </div>
      )}
      {props.showBreadCrumbs && <div className="p-2"></div>}
    </div>
  );
};

export default ShopFeature;
