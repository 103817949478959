import cx from "classnames";
import { FC, SVGProps, useEffect, useState } from "react";
import {
  json,
  LoaderFunction,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get } from "api";
import { Button } from "atoms/button/Button";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as OpenRewardXL } from "atoms/icon/icons/ic_open_reward_XL_ol.svg";
import { ReactComponent as ClosedRewardXL } from "atoms/icon/icons/ic_reward_XL_ol.svg";
import ProgressBar from "atoms/progress-bar/ProgressBar";
import { AnimateConfetti as animateConfetti } from "organisms/animated-confetti/AnimatedConfetti";
import { AvailableRewardList } from "organisms/available-reward-list/AvailableRewardList";
import { queryClient } from "pages/Root";
import { waitObj } from "toolbox/Promise";
import pluralize from "utils/pluralize";

const load = async () => {
  const availableRewards = queryClient.fetchQuery(
    ["/rewards", { status: 1 }],
    () => get("/rewards", { status: 1 })
  );

  return waitObj({ availableRewards });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const accountCreatedLoader: LoaderFunction = async () => {
  return json<LoaderData>(await load());
};

export const AccountCreatedPage = () => {
  const navigate = useNavigate();
  const { availableRewards } = useLoaderData() as LoaderData;
  const [rewardProgress, setRewardProgress] = useState(0);
  const [icon, setIcon] = useState<FC<SVGProps<SVGSVGElement>>>(ClosedRewardXL);
  const progressBarValue = availableRewards.length > 0 ? 100 : 0;
  const [title, setTitle] = useState("Account created!");
  const [description, setDescription] = useState(
    "Click next to get started and earn towards your first reward!"
  );

  useEffect(() => {
    setTimeout(() => setRewardProgress(progressBarValue));

    // Set the title, icon, and description after the progress bar has reached 100%
    setTimeout(() => {
      if (availableRewards.length > 0 && progressBarValue >= 100) {
        setIcon(OpenRewardXL);
        setTitle(
          `${availableRewards.length} ${pluralize(
            availableRewards.length,
            "reward",
            "rewards"
          )} unlocked!`
        );
        setDescription(
          "See your free reward from signing up below. Click next to get started!"
        );
        animateConfetti();
      }
    }, 1500);
  }, [progressBarValue, availableRewards]);

  return (
    <div
      className={cx(
        "flex h-full flex-col  pt-[40px]",
        availableRewards.length > 0 && "bg-one"
      )}
    >
      <div className="flex flex-col items-center gap-7 p-4">
        <div>
          <Icon svg={icon} size="xlarge" color="brandColor" />
        </div>
        <div className="font-serif text-h2 text-primary">{title}</div>
        <div className="text-center text-body1 text-secondary">
          {description}
        </div>
        {availableRewards.length > 0 && (
          <>
            <div className="w-full transition delay-1000">
              <ProgressBar value={rewardProgress ?? progressBarValue} />
            </div>
            <div className="w-full">
              <AvailableRewardList
                rewards={availableRewards.map((r, index) => ({
                  id: index + "",
                  title: r.title,
                  valueIfBanked: r.valueIfBanked,
                  repeatCashEnabled: r.repeatCashEnabled,
                  tagDescription: "reward unlocked!",
                  disclaimer: r.termsAndConditions,
                }))}
                showTitle={false}
                delay={1000}
              />
            </div>
          </>
        )}
      </div>
      <div className="absolute inset-x-0 bottom-0 mx-auto p-4 pb-9 sm:w-[390px]">
        <Button
          fullWidth
          onClick={() => {
            navigate("/signin/friend-referral");
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
