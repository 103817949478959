import { useMutation } from "@tanstack/react-query";
import cx from "classnames";
import dayjs from "dayjs";
import { useEffect, useReducer, useState } from "react";
import {
  json,
  Link,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { get, post } from "api";
import { Models } from "api/types";
import { Button } from "atoms/button/Button";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right.svg";
import { ReactComponent as AddedToCartCheck } from "atoms/icon/icons/ic_added_to_cart_check_ol.svg";
import { ReactComponent as QrCode } from "atoms/icon/icons/qr_code.svg";
import { DescriptiveTag } from "atoms/tag/DescriptiveTag";
import { TextButton } from "atoms/text-button/TextButton";
import { DropdownSelector } from "molecules/containers/dropdown-selector/DropdownSelector";
import { ExpandableItems } from "molecules/expandable-items/ExpandableItems";
import { ActionModal } from "molecules/modal-window/action-modal/ActionModal";
import TopNav from "molecules/navbars/TopNav";
import { AddedToCartModal } from "organisms/added-to-cart-modal/AddedToCartModal";
import { ModalSelectorList } from "organisms/selection-modals/modal-selector-list/ModalSelectorList";
import { queryClient } from "pages/Root";
import { PricingPreview } from "pages/root/shop/PackagePDP/pricing-preview/PricingPreview";
import { useFeatureFlag } from "services/Flags";
import {
  getBookAppointmentAction,
  getProductTitle,
} from "services/HelperService";
import { waitObj } from "toolbox/Promise";
import pluralize from "utils/pluralize";

import { QuantitySelectorModal, TreatmentProps } from "./QuantitySelectorModal";
import { TreatmentSelectorModal } from "./TreatmentSelectorModal";

type Action =
  | { type: "package"; packageId: string | null; priceId: string | null }
  | { type: "price"; priceId: string }
  | { type: "open"; modal: "cart" }
  | { type: "close" };

type State = {
  packageId: string | null | undefined;
  priceId: string | null | undefined;
  modal: "cart" | null;
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "package":
      return { ...state, packageId: action.packageId, priceId: action.priceId };

    case "price":
      return { ...state, priceId: action.priceId };

    case "open":
      return { ...state, modal: action.modal };

    case "close":
      return { ...state, modal: null };
  }
};

const load = async (
  packageId: string,
  isFromMembership: boolean,
  active: boolean,
  comingFromScan: boolean
) => {
  const platformLocation = queryClient.fetchQuery(["/platform-location"], () =>
    get("/platform-location")
  );

  const platformUserPackage = queryClient.fetchQuery(
    ["/platform-user-packages", { packageId }],
    () =>
      get("/platform-user-packages/{packageId}", {
        isFromMembership,
        active,
        packageId,
      })
  );

  const data = await waitObj({ platformUserPackage, platformLocation });

  const id = data.platformUserPackage.packageListingId || null;
  let packageData = null;
  let listing = null;

  if (id) {
    packageData = queryClient.fetchQuery(
      ["/listings/{id}/packages", { id }],
      () =>
        get("/listings/{id}/packages", {
          id,
        })
    );

    listing = queryClient.fetchQuery(["/listings/{id}", { id }], () =>
      get("/listings/{id}", { id })
    );
  }

  const authClaims = queryClient.fetchQuery(["/auth/claims"], () =>
    get("/auth/claims")
  );
  const platform = queryClient.fetchQuery(["/platform"], () =>
    get("/platform")
  );

  const isAdminImpersonation = authClaims.then(
    (response) => !!response?.claims.adminUserId
  );

  return waitObj({
    platformUserPackage,
    platformLocation,
    platform,
    packageId,
    packageData,
    listing,
    comingFromScan,
    isAdminImpersonation,
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const accountMyTreatmentLoader: LoaderFunction = async ({
  params: { packageId },
  request,
}) => {
  if (!packageId) return redirect("..");

  const searchParams = new URL(request.url).searchParams;
  const isFromMembership = searchParams.get("isFromMembership") === "true";
  const active = searchParams.get("active");
  const comingFromScan = searchParams.get("comingFromScan");

  return json<LoaderData>(
    await load(
      packageId,
      isFromMembership as unknown as boolean,
      active as unknown as boolean,
      comingFromScan as unknown as boolean
    )
  );
};

const singlePackage = (packageData: any) =>
  packageData.length == 1 ? packageData[0].id : null;

const singlePrice = (packageData: any, packageId: string | null) => {
  const $package = packageData.find((p: any) => p.id == packageId);
  return $package && $package.packagePrices.length === 1
    ? $package.packagePrices[0].id
    : null;
};

type BuyAgainModalProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  onClickDropdownSelector: () => void;
  price: Models<"PackagePriceModel"> | undefined | null;
  package: Models<"PackageModel"> | undefined;
  platformUserPackage: Models<"PlatformUserPackageModel">;
  platform: Models<"PlatformModel">;
  previousSelectedAmount: Models<"PackagePriceModel"> | undefined | null;
  cost: number;
  listing: Models<"ListingModel">;
  state: State;
  onClickAddToCart: () => void;
};

const BuyAgainModal = (props: BuyAgainModalProps) => {
  return (
    <ActionModal
      open={props.open}
      onClose={props.onClose}
      size="small"
      title={props.title}
      firstButtonLabel="Add to cart"
      onFirstButtonClick={props.onClickAddToCart}
      description={
        <div className="flex flex-col items-center gap-5">
          <div className="text-sub2 uppercase text-secondary">
            Confirm Quantity
          </div>
          <DropdownSelector
            text={
              props.price
                ? `${props.price?.quantity} ${
                    props.price?.quantity > 1
                      ? props.package?.packageUnitType.pluralUnitType ?? "units"
                      : props.package?.packageUnitType.singularUnitType ??
                        "unit"
                  }`
                : `${props.platformUserPackage.totalTreatmentAmount} ${
                    props.package?.packageUnitType.pluralUnitType ?? "units"
                  }`
            }
            state="selected"
            onClick={props.onClickDropdownSelector}
          />
          <div className="w-full pb-5">
            <PricingPreview
              affirmEnabled={props.platform.platformSettings?.affirmEnabled}
              defaultPrice={props.cost}
              memberPrice={
                props.price?.membershipPrice ??
                props.listing.membershipCost ??
                undefined
              }
              isPriceSet={props.state.priceId ? true : false}
              packageUnitType={
                props.package?.packageUnitType.singularUnitType ?? "unit"
              }
            />
          </div>
        </div>
      }
    />
  );
};

export const AccountMyTreatmentPage = () => {
  const navigate = useNavigate();
  const [showRedeemSuccessModal, setShowRedeemSuccessModal] = useState(false);
  const [showTreatmentSelectorModal, setShowTreatmentSelectorModal] =
    useState(false);
  const [showTreatmentQuantityModal, setShowTreatmentQuantityModal] =
    useState(false);
  const {
    platformUserPackage,
    platformLocation,
    platform,
    packageData,
    packageId,
    listing,
    comingFromScan,
    isAdminImpersonation,
  } = useLoaderData() as LoaderData;
  const [showPackageQuantityModal, setShowPackageQuantityModal] =
    useState<boolean>(false);
  const [showBuyAgainModal, setShowBuyAgainModal] = useState<boolean>(false);
  const isPackageAvailable = platformUserPackage.packageListingActive;
  const [searchParams, setSearchParams] = useSearchParams();
  const isUnitedDerm = useFeatureFlag("unitedDerm");
  const [treatmentDetails, setTreatmentDetails] = useState<TreatmentProps>({
    id: platformUserPackage.platformUserPackageItemModels[0].productId,
    name: platformUserPackage.platformUserPackageItemModels[0].name,
    remainingQuantity:
      platformUserPackage.platformUserPackageItemModels[0].remainingQuantity,
  });
  const [
    treatmentSelectorModalButtonText,
    setTreatmentSelectorModalButtonText,
  ] = useState<string>(isAdminImpersonation ? "Redeem now" : "Next");

  const $package = packageData?.find((p) => p.id === packageId);
  let previousSelectedAmount =
    $package?.packagePrices.find(
      (p) => p.quantity == platformUserPackage.totalTreatmentAmount
    ) || null;

  const [state, dispatch] = useReducer(reducer, {
    packageId: isPackageAvailable ? singlePackage(packageData) : null,
    priceId:
      isPackageAvailable && previousSelectedAmount
        ? previousSelectedAmount.id
        : isPackageAvailable
        ? singlePrice(packageData, singlePackage(packageData))
        : null,
    modal: null,
  });

  const price =
    $package?.packagePrices.find((p) => p.id === state.priceId) || null;
  const cost =
    price?.price ?? $package?.baseCost ?? (listing?.baseCost || null);

  useEffect(() => {
    if (comingFromScan) {
      setShowRedeemSuccessModal(true);
    }
  }, [comingFromScan]);

  const closeRedeemSuccessModal = () => {
    setShowRedeemSuccessModal(false);
    if (searchParams.has("comingFromScan")) {
      searchParams.delete("comingFromScan");
      setSearchParams(searchParams);
    }
  };

  const { bookAppointmentUrl, phoneNumber, email } = platformLocation;

  const bookAppointmentHref = getBookAppointmentAction(
    bookAppointmentUrl,
    phoneNumber,
    email
  );

  const isPackage =
    platformUserPackage.platformUserPackageItemModels.length > 1;

  const handleTreatmentSelectorOnClick = (id: string) => {
    setShowTreatmentSelectorModal(false);
    const selectedTreatment =
      platformUserPackage.platformUserPackageItemModels.find((p) => {
        return p.productId == id;
      });
    setTreatmentDetails({
      id: selectedTreatment?.productId ?? "",
      name: selectedTreatment?.name ?? "",
      remainingQuantity: selectedTreatment?.remainingQuantity ?? 0,
    });
    if (
      selectedTreatment?.remainingQuantity &&
      selectedTreatment.remainingQuantity > 1
    ) {
      setShowTreatmentQuantityModal(true);
    } else {
      handleRedeeming(1, id);
    }
  };

  const handleTreatmentQuantityOnClick = (quantity: number) => {
    setShowTreatmentQuantityModal(false);
    if (isPackage) {
      handleRedeeming(quantity, treatmentDetails.id);
    } else {
      handleRedeeming(quantity);
    }
  };

  const isPreviousTreatment =
    platformUserPackage.remainingTreatmentAmount === 0;

  const handleRedeemOnClick = () => {
    if (!isPackage) {
      const itemModel = platformUserPackage.platformUserPackageItemModels[0];
      if (itemModel.remainingQuantity > 1) {
        setShowTreatmentQuantityModal(true);
      } else {
        handleRedeeming(1);
      }
      return;
    }

    setShowTreatmentSelectorModal(true);
  };

  const handleRedeeming = (quantity: number, productId?: string) => {
    const productIdInput =
      productId ||
      platformUserPackage.platformUserPackageItemModels[0].productId;

    if (isAdminImpersonation) {
      redeemMutation.mutate({ quantity, productId: productIdInput });
    } else {
      handleScanToRedeemNavigation(quantity, productIdInput);
    }
  };

  const isFromMembership = platformUserPackage.type === "MembershipTreatment";

  const handleScanToRedeemNavigation = (
    quantity: number,
    productId: string
  ) => {
    navigate(`/scan-redeem-treatment-in-location`, {
      state: {
        packageId: platformUserPackage.packageId,
        isFromMembership: isFromMembership.toString(),
        productId,
        remainingAmount:
          platformUserPackage.remainingTreatmentAmount - quantity,
        quantity,
      },
    });
  };

  const redeemMutation = useMutation({
    mutationFn: ({
      quantity,
      productId,
    }: {
      quantity: number;
      productId: string;
    }) =>
      post("/platform-user-package-items/redeem-in-app", {
        packageId: platformUserPackage.packageId,
        isFromMembership,
        productId,
        quantity,
      }),
    onSuccess: (_, variables) => {
      const isPackageActive = !!(
        platformUserPackage.remainingTreatmentAmount - variables.quantity
      );
      navigate(
        `/account/my-treatments/${platformUserPackage.packageId}?isFromMembership=${isFromMembership}&active=${isPackageActive}&comingFromScan=true`
      );
    },
  });
  const activeAndAvailable = listing?.active;

  return (
    <div className="flex h-full flex-col bg-two">
      <TopNav
        startIconSVG={ChevronLeft}
        onStartIconClick={() => {
          navigate("/account/my-treatments");
        }}
      >
        <span className="flex items-center">
          <h1 className="text-xl">Account</h1>
        </span>
      </TopNav>

      <div className="bg-white px-4 py-8 text-center">
        <div className="text-primar mb-2 font-serif text-h1">
          {platformUserPackage &&
            getProductTitle(
              platformUserPackage.productName,
              platformUserPackage.variantName
            )}
        </div>
        <div className="mx-2 mb-4 text-body2 text-secondary">
          Purchased{" "}
          {dayjs(platformUserPackage.purchasedDate).format("MMMM D YYYY")}
        </div>
        {platformUserPackage.imageUrl && (
          <div className="mx-2 mb-6">
            <img
              className={cx(
                "block aspect-[4/3] h-full w-full snap-center rounded object-cover",
                isPreviousTreatment && "opacity-90"
              )}
              src={platformUserPackage.imageUrl}
              height={86}
              alt="Treatment"
            />
          </div>
        )}
        <div
          className={cx(
            "text-bold1 text-primary",
            isPackage && platformUserPackage.remainingTreatmentAmount > 0
              ? "mb-2"
              : "mb-6"
          )}
        >
          {platformUserPackage.remainingTreatmentAmount} of{" "}
          {platformUserPackage.totalTreatmentAmount}{" "}
          {pluralize(
            platformUserPackage.totalTreatmentAmount,
            platformUserPackage.packageUnitType?.singularUnitType || "",
            platformUserPackage.packageUnitType?.pluralUnitType || ""
          )}{" "}
          remaining
        </div>

        {isPackage && platformUserPackage.remainingTreatmentAmount > 0 && (
          <ul className="mb-6 text-body1 text-secondary">
            {platformUserPackage.platformUserPackageItemModels.map(
              (packageItem) => {
                if (packageItem.remainingQuantity === 0) {
                  return null;
                }

                return (
                  <li key={packageItem.productId}>
                    {packageItem.remainingQuantity}x {packageItem.name}
                  </li>
                );
              }
            )}
          </ul>
        )}
        {!isPreviousTreatment && (
          <>
            <div className="mb-3">
              <Button size="large" fullWidth onClick={handleRedeemOnClick}>
                {!isAdminImpersonation && (
                  <Icon size="xsmall" svg={QrCode} color="white" />
                )}
                <div className="ml-1.5">
                  {isAdminImpersonation
                    ? "Redeem now"
                    : "Scan in office to redeem"}
                </div>
              </Button>
            </div>
            {bookAppointmentHref && (
              <a
                href={bookAppointmentHref}
                target={bookAppointmentUrl ? "_blank" : undefined}
                rel="noreferrer"
              >
                <Button size="large" style="secondary" fullWidth>
                  {!isUnitedDerm ? "Book appointment" : "Request appointment"}
                </Button>
              </a>
            )}
          </>
        )}
        {isPreviousTreatment && (
          <div className="mt-6">
            {!activeAndAvailable && (
              <h1 className="pb-5 text-secondary">
                This service is no longer available, browse our shop now to find
                similar services
              </h1>
            )}
            <Button
              size="large"
              style={!activeAndAvailable ? "secondary" : "primary"}
              fullWidth
              onClick={() =>
                !activeAndAvailable
                  ? navigate("/shop")
                  : navigate(`/pdp/package/${listing?.id}`)
              }
            >
              {!activeAndAvailable ? "Browse shop" : "Buy again"}
            </Button>
          </div>
        )}
      </div>

      {isPackage && (
        <div className="bg-one px-6 pb-6">
          <div className="my-6 text-center text-sub1 text-brand-color">
            WHAT IS INCLUDED
          </div>

          <ExpandableItems
            items={platformUserPackage.platformUserPackageItemModels.map(
              (p) => ({
                id: p.name,
                title: (
                  <div className="flex items-center justify-between">
                    <div>
                      {p.totalQuantity}x {p.name}
                    </div>
                    {p.remainingQuantity < p.totalQuantity && (
                      <DescriptiveTag size="small" color="secondary">
                        {p.remainingQuantity === 0
                          ? "REDEEMED"
                          : `${
                              p.totalQuantity - p.remainingQuantity
                            }x REDEEMED`}
                      </DescriptiveTag>
                    )}
                  </div>
                ),
                description: p.description,
              })
            )}
          />
        </div>
      )}

      {!!platformUserPackage.expectations.length && (
        <div className="bg-two">
          <div className="my-6 text-center text-sub1 text-brand-color">
            KEY TREATMENT INFO
          </div>
          <div className="mx-4 space-y-2">
            {platformUserPackage.expectations.map((e) => (
              <div className="rounded bg-white p-6" key={e.id}>
                <div className="mb-3 text-center text-sub2 text-primary">
                  {e.title.toUpperCase()}
                </div>
                <div className="text-body1 text-secondary">{e.content}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      {!!platformUserPackage.packageListingId && (
        <div className="my-6 flex justify-center">
          <Link to={`/pdp/package/${platformUserPackage.packageListingId}`}>
            <TextButton endIconSVG={ChevronRight}>
              More {isPackage ? "package" : "treatment"} info
            </TextButton>
          </Link>
        </div>
      )}

      <ActionModal
        open={showRedeemSuccessModal}
        size="small"
        description={
          isAdminImpersonation ? (
            ""
          ) : (
            <div className="px-2">
              You don&apos;t need to do anything else, just take a seat or speak
              to the front desk if you have any questions.
            </div>
          )
        }
        firstButtonLabel="Close"
        headerIconSvg={AddedToCartCheck}
        onClose={() => closeRedeemSuccessModal()}
        onFirstButtonClick={() => closeRedeemSuccessModal()}
        title="Treatment redeemed!"
      />

      {showTreatmentSelectorModal && (
        <TreatmentSelectorModal
          primaryOnClick={handleTreatmentSelectorOnClick}
          primaryLabel={treatmentSelectorModalButtonText}
          onClose={() => setShowTreatmentSelectorModal(false)}
          treatments={platformUserPackage.platformUserPackageItemModels.reduce(
            (acc: { id: string; name: string }[], curr) => {
              if (curr.remainingQuantity === 0) {
                return acc;
              }

              return [
                ...acc,
                {
                  id: curr.productId,
                  name: curr.name,
                  quantity: curr.remainingQuantity,
                },
              ];
            },
            []
          )}
        />
      )}

      {showTreatmentQuantityModal && (
        <QuantitySelectorModal
          primaryOnClick={handleTreatmentQuantityOnClick}
          primaryLabel={isAdminImpersonation ? "Redeem now" : "Scan now"}
          onClose={() => setShowTreatmentQuantityModal(false)}
          treatment={treatmentDetails}
        />
      )}

      {cost && isPackageAvailable && listing && showBuyAgainModal && (
        <BuyAgainModal
          open={showBuyAgainModal}
          onClose={() => setShowBuyAgainModal(false)}
          title={platformUserPackage.variantName ?? ""}
          onClickDropdownSelector={() => setShowPackageQuantityModal(true)}
          price={price}
          package={$package}
          platform={platform}
          platformUserPackage={platformUserPackage}
          previousSelectedAmount={previousSelectedAmount}
          cost={cost}
          listing={listing}
          state={state}
          onClickAddToCart={async () => {
            if (!$package || !price) return;

            await post("/orders/current/lineitems", {
              packageListingId: listing.id,
              packageId: $package.id,
              packagePriceId: price.id,
            });

            queryClient.invalidateQueries({
              queryKey: ["/orders/current/summary"],
            });

            dispatch({ type: "open", modal: "cart" });
            setShowBuyAgainModal(false);
          }}
        />
      )}

      {isPackageAvailable &&
        showPackageQuantityModal &&
        listing &&
        $package && (
          <ModalSelectorList
            size="fullscreen"
            open={showPackageQuantityModal}
            onClose={() => setShowPackageQuantityModal(false)}
            title={
              "NUMBER OF " +
              `${
                $package?.packageUnitType.pluralUnitType?.toUpperCase() ??
                "UNITS"
              }`
            }
            type="products"
            items={$package.packagePrices.map((p) => ({
              id: p.id,
              priceOrResults: p.price,
              membershipPrice: p.membershipPrice,
              title:
                p.quantity === 1
                  ? `${p.quantity} ${
                      $package?.packageUnitType.singularUnitType ?? "unit"
                    }`
                  : `${p.quantity} ${
                      $package?.packageUnitType.pluralUnitType ?? "units"
                    }`,
            }))}
            checkedItems={state.priceId ? [state.priceId] : []}
            onSelect={(priceId) => {
              dispatch({ type: "price", priceId });
              previousSelectedAmount = null;
            }}
            firstButtonLabel="Add to cart"
            onFirstButtonClick={async () => {
              if (!$package || !price) return;

              await post("/orders/current/lineitems", {
                packageListingId: listing.id,
                packageId: $package.id,
                packagePriceId: price.id,
              });

              queryClient.invalidateQueries({
                queryKey: ["/orders/current/summary"],
              });

              dispatch({ type: "open", modal: "cart" });
              setShowPackageQuantityModal(false);
            }}
          />
        )}

      {isPackageAvailable && $package && price && (
        <AddedToCartModal
          title={$package.name}
          open={state.modal === "cart"}
          subtitle={
            price.quantity === 1
              ? `${price.quantity} ${$package.packageUnitType.singularUnitType}`
              : `${price.quantity} ${$package.packageUnitType.pluralUnitType}`
          }
          cost={price.price}
          membership={null}
          onClose={() => {
            dispatch({ type: "close" });
            setShowPackageQuantityModal(false);
            setShowBuyAgainModal(false);
          }}
        />
      )}
    </div>
  );
};
