import dayjs from "dayjs";

import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Warning } from "atoms/icon/icons/ic_warning_ol.svg";

export const DeletionRequestedBanner = ({
  deletionRequestDate,
}: {
  deletionRequestDate: string;
}) => {
  return (
    <div className="flex items-center space-x-2 rounded border bg-red/10 px-4 py-3 text-body2 text-red">
      <Icon svg={Warning} size="xsmall" color="red" />
      <span className="leading-3">
        Account deletion requested{" "}
        {dayjs(deletionRequestDate).format("MM/DD/YY")}
      </span>
    </div>
  );
};
