import { Models } from "api/types";
import { ReactComponent as Amex } from "atoms/icon/icons/amex.svg";
import { ReactComponent as Diners } from "atoms/icon/icons/diners.svg";
import { ReactComponent as Discover } from "atoms/icon/icons/discover.svg";
import { ReactComponent as Jcb } from "atoms/icon/icons/jcb.svg";
import { ReactComponent as Mastercard } from "atoms/icon/icons/mastercard.svg";
import { ReactComponent as Unionpay } from "atoms/icon/icons/unionpay.svg";
import { ReactComponent as Visa } from "atoms/icon/icons/visa.svg";

export const getPaymentSystemByCardNumber = (
  cardNumber: string
): Models<"CardBrand"> => {
  if (cardNumber.startsWith("4")) {
    return "Visa";
  }

  if (cardNumber.startsWith("34") || cardNumber.startsWith("37")) {
    return "Amex";
  }

  if (
    cardNumber.startsWith("51") ||
    cardNumber.startsWith("52") ||
    cardNumber.startsWith("53") ||
    cardNumber.startsWith("54") ||
    cardNumber.startsWith("55")
  ) {
    return "Mastercard";
  }

  if (cardNumber.startsWith("35")) {
    return "Jcb";
  }

  if (cardNumber.startsWith("36")) {
    return "Diners";
  }

  return "Unknown";
};

export const getCardBrandByCardNumber = (cardNumber: string) => {
  const paymentSystem = getPaymentSystemByCardNumber(cardNumber);

  if (paymentSystem === undefined) {
    return undefined;
  }

  return getCardBrandIcon(paymentSystem);
};

export const getCardBrandIcon = (value: Models<"CardBrand">) => {
  if (value === "Unknown") {
    return undefined;
  }

  return {
    Visa,
    Mastercard,
    Amex,
    Jcb,
    Diners,
    Discover,
    Unionpay,
  }[value];
};

export const isCreditCardNumberValid = (cardNumber: string): boolean => {
  const trimmedCardNumber = cardNumber.replace(/\s/g, "");

  if (trimmedCardNumber.length < 13 || trimmedCardNumber.length > 19) {
    return false;
  }

  return true;
};
