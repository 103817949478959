import cx from "classnames";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Models } from "api/types";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronDownSmall } from "atoms/icon/icons/ic_chevron_down_small_ol.svg";
import { ReactComponent as ChevronRightSmall } from "atoms/icon/icons/ic_chevron_right_small_ol.svg";
import { ReactComponent as ChevronUpSmall } from "atoms/icon/icons/ic_chevron_up_small_ol.svg";
import { ReactComponent as RewardsIcon } from "atoms/icon/icons/ic_rewards_ol.svg";

export interface InclusionsExclusionsListProps {
  title: string;
  disclaimer: string;
  inclusions: Models<"RewardInclusionsModel">;
}

export const InclusionsExclusionsList = ({
  title,
  disclaimer,
  inclusions,
}: InclusionsExclusionsListProps) => {
  const ref = useRef<any>(null);
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(true);
  const [showMoreButton, setShowMoreButton] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const inclusionsListHeight = ref.current.offsetHeight;
      if (inclusionsListHeight >= 500) {
        setShowMore(false);
        setShowMoreButton(true);
      }
    }
  }, [ref]);

  return (
    <div className="mx-4 mt-4">
      <div className="rounded bg-two py-5 px-4" ref={ref}>
        <div className="mb-1 flex items-center gap-4">
          <div>
            <Icon svg={RewardsIcon} size="xsmall" color="brandColor" />
          </div>
          <div className="text-bold1 text-primary">{title}</div>
        </div>

        <div
          className={cx(
            "relative flex flex-col overflow-hidden",
            !showMore && "max-h-[340px]"
          )}
        >
          {Object.entries(inclusions).map(([key, value], index) => {
            const isValueArray = value instanceof Array;
            const navigationPath =
              key === "treatments" || key === "packages"
                ? "/pdp/package/"
                : "/pdp/membership/";
            return (
              isValueArray &&
              value.length > 0 && (
                <div key={index}>
                  <div className="py-2 pl-1 text-bold1 capitalize">
                    {key == "memberships"
                      ? "Memberships (1st month only)"
                      : key}
                  </div>
                  <ul className="ml-6 list-disc">
                    {isValueArray &&
                      value.map((item: any) => (
                        <li
                          key={item.id}
                          onClick={() => navigate(navigationPath + item.id)}
                          className="cursor-pointer"
                        >
                          <div className="flex w-full justify-between py-[1px] pl-5">
                            <div>{item.name}</div>
                            <Icon
                              svg={ChevronRightSmall}
                              size="xsmall"
                              color="brandColor"
                            />
                          </div>
                          {item && item.variants && (
                            <ul className="ml-8 list-['-_']">
                              {item.allVariants == true ? (
                                <li>
                                  <div className="flex w-full justify-between py-[1px] pl-4">
                                    <div>All Variants</div>
                                  </div>
                                </li>
                              ) : (
                                <>
                                  {item.variants.map(
                                    (variant: any, index: any) => (
                                      <li key={index}>
                                        <div className="flex w-full justify-between py-[1px] pl-4">
                                          <div>{variant}</div>
                                        </div>
                                      </li>
                                    )
                                  )}
                                </>
                              )}
                            </ul>
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              )
            );
          })}

          {!showMore && (
            <div className="absolute bottom-0 h-10 w-full bg-gradient-to-t from-background-two to-transparent" />
          )}
        </div>

        {showMoreButton && (
          <div
            className="flex cursor-pointer pt-[6px]"
            onClick={() => setShowMore((x) => !x)}
          >
            <Icon
              svg={!showMore ? ChevronDownSmall : ChevronUpSmall}
              size="small"
              color="brandColor"
            />
            <div className="ml-3 text-bold1 text-brand-color">
              {!showMore ? "Show More" : "Show Less"}
            </div>
          </div>
        )}
      </div>
      {disclaimer && (
        <div className="px-4 pt-6 pb-2 text-center text-body2 italic text-secondary">
          {disclaimer}
        </div>
      )}
    </div>
  );
};
