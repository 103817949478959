import { useNavigate } from "react-router-dom";

import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Info } from "atoms/icon/icons/info.svg";
import { ReactComponent as RepeatCash } from "atoms/icon/icons/RepeatCash.svg";
import { ActionModal } from "molecules/modal-window/action-modal/ActionModal";
import { formatCurrency } from "toolbox/Money";

interface SuccessfullyBankedModalProps {
  repeatCash: number;
  onClose: () => void;
}

export const SuccessfullyBankedModal = ({
  repeatCash,
  onClose,
}: SuccessfullyBankedModalProps) => {
  const navigate = useNavigate();

  return (
    <ActionModal
      size="medium"
      open={true}
      headerIconSvg={RepeatCash}
      onClose={onClose}
      onFirstButtonClick={() => navigate("/shop")}
      firstButtonLabel="Explore shop"
      onSecondButtonClick={() => navigate("..")}
      secondButtonLabel="View rewards"
      title="Reward banked!"
      description={
        <div className="px-4">
          <div className="mb-4 text-sub1">YOUR NEW BALANCE</div>
          <div className="rounded-t-lg bg-one">
            <div className="flex items-center justify-center py-5">
              <Icon svg={RepeatCash} size="medium" />
              {/* TODO: Have cash balance and add 2 decimals. */}
              <div className="mr-1 ml-0.5 text-title text-primary">
                {formatCurrency(repeatCash, 2)}
              </div>
              <div className="text-title text-secondary">cash</div>
            </div>
          </div>
          <div className="flex items-center rounded-b-lg bg-two px-4 py-[10px]">
            <Icon svg={Info} size="xsmall" color="secondaryText" />
            <div className="ml-1.5 text-body2 text-secondary">
              Apply cash in cart to save on treatments
            </div>
          </div>
        </div>
      }
    />
  );
};
