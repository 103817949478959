import { Capacitor } from "@capacitor/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  createContext,
  CSSProperties,
  lazy,
  Suspense,
  useEffect,
  useState,
} from "react";
import {
  json,
  LoaderFunction,
  Outlet,
  ScrollRestoration,
  useLoaderData,
  useRevalidator,
} from "react-router-dom";

import { get } from "api";
import AppUrlListener from "appUrlListener";
import { CartContext, useCartState } from "contexts/CartContext";
import Favicon from "pages/Favicon";
import { FFlagProvider } from "services/Flags";
import Services from "services/Services";
import { hexToRgb } from "toolbox/Colors";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const ReactQueryDevtools = lazy(() =>
  import("@tanstack/react-query-devtools/build/lib/index.prod.js").then(
    ({ ReactQueryDevtools }) => ({ default: ReactQueryDevtools })
  )
);

/**
 * Render the React Query Devtools in development and (on demand) in production
 */
const ReactQueryDevTools = () => {
  const [showTools, setShowTools] = useState(
    // Show devtools only in development on the web by default
    process.env.NODE_ENV === "development" && !Capacitor.isNativePlatform()
  );

  useEffect(() => {
    // @ts-expect-error This function isn't called from the app, it's just for
    // debugging purposes in production
    window.toggleReactQueryDevTools = () => setShowTools((prev) => !prev);
  }, []);

  if (!showTools) return null;

  return (
    <Suspense fallback={null}>
      <ReactQueryDevtools />
    </Suspense>
  );
};

const transformThemeToCss = (theme: {
  brandColor?: string | null;
  brandTint?: string | null;
}) => {
  return {
    "--repeatmd-brand-color": hexToRgb(theme.brandColor),
    "--repeatmd-brand-tint": hexToRgb(theme.brandTint ?? theme.brandColor, 0.1),
  } as CSSProperties;
};

const load = async () => {
  Services.auth.loadAuthToken();

  if (Services.auth.isAuthorized && Capacitor.isNativePlatform()) {
    const user = await queryClient.fetchQuery(["/user"], () => get("/user"), {
      staleTime: 1000 * 60 * 2,
    });
    const platformId = user.platformDomain?.platformId;

    if (!platformId) {
      throw new Error("User without platform id.");
    }

    const theme = await queryClient.fetchQuery(
      ["/multi-platform/platform/{platformId}/theme", { platformId }],
      () => get("/multi-platform/platform/{platformId}/theme", { platformId })
    );

    return {
      theme: transformThemeToCss(theme),
    };
  }

  const theme = await queryClient.fetchQuery(["/platform/theme"], () =>
    get("/platform/theme")
  );

  return {
    theme: transformThemeToCss(theme),
  };
};

export const rootLoader: LoaderFunction = async () => {
  return json<LoaderData>(await load());
};

type LoaderData = Awaited<ReturnType<typeof load>>;

export const ThemeContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetTheme: () => {},
});

const Root = () => {
  const { theme } = useLoaderData() as LoaderData;
  const cartState = useCartState();
  const revalidator = useRevalidator();

  const resetTheme = () => {
    revalidator.revalidate();
  };

  return (
    <QueryClientProvider client={queryClient}>
      <AppUrlListener />
      <FFlagProvider>
        <Favicon />
        <CartContext.Provider value={cartState}>
          <div
            style={theme}
            className="relative mx-auto flex min-h-full flex-col sm:w-[390px] sm:border"
          >
            <ThemeContext.Provider value={{ resetTheme }}>
              <Outlet />
            </ThemeContext.Provider>
          </div>
          <ScrollRestoration />
        </CartContext.Provider>
      </FFlagProvider>
      <ReactQueryDevTools />
    </QueryClientProvider>
  );
};

export default Root;
