import cx from "classnames";
import { useCallback, useEffect, useState } from "react";

import { ListModal } from "molecules/modal-window/list-modal/ListModal";
import { QuantitySelector } from "molecules/quanity-selector/QuantitySelector";

export interface TreatmentProps {
  id: string;
  name: string;
  remainingQuantity: number;
}

export interface QuantitySelectorModalProps {
  treatment: TreatmentProps;
  primaryOnClick: (quantity: number) => void;
  primaryLabel: string;
  onClose: () => void;
}

export const QuantitySelectorModal = ({
  treatment,
  primaryOnClick,
  primaryLabel,
  onClose,
}: QuantitySelectorModalProps) => {
  const [quantityState, setQuantityState] = useState({
    quantity: 1,
    invalid: false,
  });
  return (
    <ListModal
      size="medium"
      onClose={onClose}
      open={true}
      title="Select Quantity"
      firstButtonLabel={primaryLabel}
      onFirstButtonClick={() => primaryOnClick(quantityState.quantity)}
      firstButtonDisabled={quantityState.invalid}
    >
      <div className="border-b border-b-background-two px-4">
        <div className="flex min-h-[68px] items-center gap-3 py-1.5 pl-3">
          <div className="flex grow flex-col items-start py-1">
            <div className="text-bold2">{treatment.name}</div>
            <div
              className={cx(
                "pt-0.5 text-body2 text-secondary",
                "pt-0.5 text-body2 text-secondary",
                quantityState.quantity > treatment.remainingQuantity &&
                  "text-red"
              )}
            >
              {treatment.remainingQuantity} units available
            </div>
          </div>
          <div>
            <QuantitySelector
              maxValue={treatment.remainingQuantity}
              showMaxHelpText={false}
              onChange={useCallback((quantity: number, invalid: boolean) => {
                setQuantityState({ quantity, invalid });
              }, [])}
            />
          </div>
        </div>
      </div>
    </ListModal>
  );
};
