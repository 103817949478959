import { useNavigate } from "react-router-dom";

import { ReactComponent as OpenReward } from "atoms/icon/icons/open_reward.svg";
import { ActionModal } from "molecules/modal-window/action-modal/ActionModal";

interface OnlineOffSpecificItemRedeemedModalProps {
  onClose: () => void;
}

export const OnlineOffSpecificItemRedeemedModal = ({
  onClose,
}: OnlineOffSpecificItemRedeemedModalProps) => {
  const navigate = useNavigate();

  return (
    <ActionModal
      size="medium"
      open={true}
      headerIconSvg={OpenReward}
      onClose={onClose}
      onFirstButtonClick={() => navigate("/shop")}
      firstButtonLabel="Explore shop"
      onSecondButtonClick={() => navigate("/cart")}
      secondButtonLabel="View cart"
      title="Congratulations! Your reward has been added to cart!"
      description={
        <div className="px-2">
          Explore our shop now to add to your order or view cart to checkout now
          with your discount applied.
        </div>
      }
    />
  );
};
