import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useHistory = () => {
  const location = useLocation();

  const [routeBackNumber, setRouteBackNumber] = useState(
    parseInt(localStorage.getItem("routeBackNumber") || "-1")
  );

  useEffect(() => {
    if (location.pathname && location.search) {
      const currentLocation = location.pathname + location.search;

      setTimeout(() => {
        if (localStorage.getItem("currentLocation") !== currentLocation) {
          setRouteBackNumber((prevRouteBackNumber) => prevRouteBackNumber - 1);
          localStorage.setItem("currentLocation", currentLocation);
        }
      }, 10);
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    localStorage.setItem("routeBackNumber", routeBackNumber.toString());
  }, [routeBackNumber]);
  useEffect(() => {
    const currentLocation = location.pathname + location.search;
    if (localStorage.getItem("currentLocation") === currentLocation) {
      return;
    }
    return () => {
      localStorage.removeItem("currentLocation");
      localStorage.removeItem("routeBackNumber");
    };
  }, [location.pathname, location.search]);
  return {
    routeBackNumber,
    setRouteBackNumber,
  };
};

export default useHistory;
