import React from "react";

import { ReactComponent as OfferIcon } from "atoms/icon/icons/ic_offers_ol.svg";
import { ActionModal } from "molecules/modal-window/action-modal/ActionModal";

type PromotionCheckoutModalProps = {
  title: string;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  checkoutButtonClick: () => void;
  checkoutButtonLabel: string;
};

export const PromotionCheckoutModal = (props: PromotionCheckoutModalProps) => {
  return (
    <ActionModal
      open={props.open}
      onClose={props.onClose}
      headerIconSvg={OfferIcon}
      title={props.title}
      firstButtonLabel={props.checkoutButtonLabel}
      onFirstButtonClick={props.checkoutButtonClick}
      description={
        <>
          <div className="flex flex-col justify-center px-2 text-center">
            <div className="flex flex-col items-center gap-2">
              <div className="pb-1 text-body1 text-secondary">
                Click to apply now or continue to checkout
              </div>
            </div>
          </div>
          <div className="pb-12">{props.children}</div>
        </>
      }
      size="small"
    />
  );
};
