import {
  CartItem,
  CartItemProps,
} from "molecules/containers/cart/cart-item/CartItem";

export interface CartItemListProps {
  cartItems: CartItemProps[];
}

export const CartItemList = ({ cartItems }: CartItemListProps) => {
  if (!cartItems.length) return null;

  return (
    <div className="bg-one">
      <div className="divide-y divide-solid divide-light-grey">
        {cartItems.map((cartItem) => (
          <CartItem key={cartItem.id} {...cartItem} />
        ))}
      </div>
      <hr className="bg-light-grey" />
    </div>
  );
};
