import { notEmpty } from "toolbox/Guards";

export const FormattedString = (props: { children: string }) => {
  const paragraphs = props.children.split(/\n\s*\n|\n/).flatMap((p) =>
    p
      .split("\n")
      .reduce(
        (acc, curr) => {
          const trimmed = curr.trim();
          const [prev, ...tail] = acc;
          return curr.startsWith("#")
            ? ["", trimmed, prev, ...tail]
            : [`${prev} ${trimmed}`.trim(), ...tail];
        },
        [""]
      )
      .filter(notEmpty)
      .reverse()
  );

  const components = paragraphs.map((p, i) => {
    const isHeader = p.startsWith("#");
    return isHeader ? (
      <h1 key={i} className="text-bold1">
        {p.replace(/^#+\s+/, "")}
      </h1>
    ) : (
      <p key={i} className="text-body1">
        {p}
      </p>
    );
  });

  return <div className="flex flex-col gap-3">{components}</div>;
};
