import { Capacitor } from "@capacitor/core";
import { Currencies, Money } from "ts-money";

export function formatCurrency(value: number, maximumFractionDigits = 0) {
  const amount = Money.fromInteger(value, Currencies.USD).toDecimal();

  if (Capacitor.isNativePlatform()) {
    // Custom formatting for Capacitor because Intl.NumberFormat throws a
    // RangeError when maximumFractionDigits is 0
    const formattedValue = amount
      .toFixed(maximumFractionDigits)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `$${formattedValue}`;
  } else {
    // Use Intl.NumberFormat for web
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits,
    }).format(amount);
  }
}
