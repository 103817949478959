import {
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get } from "api";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { ProductCardLarge } from "molecules/cards/product-card-large/ProductCardLarge";
import TopNav from "molecules/navbars/TopNav";
import { queryClient } from "pages/Root";
import { FilterAndSortListings } from "pages/root/shop/FilterAndSortListings";
import { notNull } from "toolbox/Guards";
import { waitObj } from "toolbox/Promise";

import { ShopListHeader } from "./ShopListHeader/ShopListHeader";

const load = async (concernId: string) => {
  const shopData = queryClient.fetchQuery(["/shop"], () => get("/shop"));
  const listings = queryClient.fetchQuery(
    ["/listings"],
    () => get("/listings"),
    { staleTime: 1000 * 60 }
  );

  return waitObj({ concernId, shopData, listings });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const shopByConcernLoader: LoaderFunction = async ({
  params: { id },
}) => {
  if (!id) return redirect("/shop");
  return json<LoaderData>(await load(id));
};

const ShopByConcernPage = () => {
  const { concernId, shopData, listings } = useLoaderData() as LoaderData;
  const navigate = useNavigate();

  const concern = shopData.concerns?.find((concern) => concern.id == concernId);
  const filteredListings = listings.filter((listing) =>
    listing.concernIds.includes(concernId)
  );
  const filteredConcerns =
    shopData.concerns?.filter((concern) =>
      filteredListings
        .flatMap((listing) => listing.concernIds)
        .includes(concern.id)
    ) ?? [];
  return (
    <>
      <TopNav
        startIconSVG={ChevronLeft}
        onStartIconClick={() => navigate("/shop/concerns")}
      >
        <div className="flex max-h-[32px] items-center">
          <h1 className="text-xl">Browse by concern</h1>
        </div>
      </TopNav>
      <div className="flex h-full flex-col bg-two">
        {concern && concern.name && (
          <ShopListHeader
            title={concern.name}
            subtitle={concern.description ?? ""}
            bgGradient
            backgroundColor="rgb(var(--repeatmd-brand-color))"
          />
        )}
        {filteredListings && (
          <FilterAndSortListings
            types={["Treatment", "Package"]}
            areas={[]}
            concerns={[]}
            listings={filteredListings}
          >
            {(listing) => (
              <ProductCardLarge
                key={listing.id}
                title={listing.title}
                description={listing.description}
                imageUrl={listing.imageUrl}
                type={listing.type}
                tag={""}
                concerns={filteredConcerns
                  .filter((concern) => listing.concernIds.includes(concern.id))
                  .map((concern) => concern.name)
                  .filter(notNull)}
                price={listing.baseCost}
                memberPrice={listing.membershipCost}
                onClick={() => navigate(`/pdp/package/${listing.id}`)}
              />
            )}
          </FilterAndSortListings>
        )}
        <RepeatMDFooter />
      </div>
    </>
  );
};

export default ShopByConcernPage;
