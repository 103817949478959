import { datadogRum } from "@datadog/browser-rum-slim";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
  Route,
  RouterProvider,
} from "react-router-dom";

import AppUrlListener from "appUrlListener";
import Root, { rootLoader } from "pages/Root";
import AccountLayout, {
  accountLayoutLoader,
} from "pages/root/account/AccountLayout";
import AccountMembershipPage, {
  accountMembershipAction,
  accountMembershipLoader,
} from "pages/root/account/AccountMembershipPage";
import AccountMyTreatmentsPage, {
  accountMyTreatmentsLoader,
} from "pages/root/account/AccountMyTreatmentsPage";
import {
  accountMyTreatmentLoader,
  AccountMyTreatmentPage,
} from "pages/root/account/AccountMyTreatmentsPage/AccountMyTreatmentPage";
import ScanRedeemTreatmentPage from "pages/root/account/AccountMyTreatmentsPage/ScanRedeemTreatmentPage";
import AccountOfferPage, {
  accountOfferLoader,
} from "pages/root/account/AccountOfferPage";
import AccountOffersPage, {
  accountOffersLoader,
} from "pages/root/account/AccountOffersPage";
import AccountSettingsPage, {
  accountSettingsLoader,
} from "pages/root/account/AccountSettingsPage";
import {
  accountDetailsAction,
  accountDetailsLoader,
  AccountDetailsPage,
} from "pages/root/account/settings/account-details/AccountDetailsPage";
import { DeleteMyAccountPage } from "pages/root/account/settings/account-details/delete-my-account";
import {
  orderHistoryDetailsAction,
  orderHistoryDetailsLoader,
  OrderHistoryDetailsPage,
} from "pages/root/account/settings/order-history/OrderHistoryDetailsPage";
import { OrderHistoryPage } from "pages/root/account/settings/order-history/OrderHistoryPage";
// import {
//   billingInformationAction,
//   billingInformationLoader,
//   BillingInformationPage,
// } from "pages/root/account/settings/payment-methods/BillingInformationPage";
import {
  paymentMethodAction,
  paymentMethodLoader,
  PaymentMethodPage,
} from "pages/root/account/settings/payment-methods/PaymentMethodPage";
import { RewardHistoryPage } from "pages/root/account/settings/RewardHistoryPage";
import {
  loader as switchMedspaLoader,
  SwitchMedspaPage,
} from "pages/root/account/settings/SwitchMedspaPage";
import { VisitHistoryPage } from "pages/root/account/settings/VisitHistoryPage";
import AnonymousLayout, { AnonymousLoader } from "pages/root/AnonymousLayout";
import AppLayout, {
  AppLayoutError,
  appLayoutLoader,
} from "pages/root/AppLayout";
import AppLayoutNoBottomNav, {
  AppLayoutErrorNoBottomNav,
} from "pages/root/AppLayoutNoBottomNav";
import CartPage, { cartLoader, cartPageAction } from "pages/root/cart/CartPage";
import CheckoutElementPage, {
  checkoutElementLoader,
} from "pages/root/cart/CheckoutElementPage";
import {
  checkoutRewardTrackerLoader,
  CheckoutRewardTrackerPage,
} from "pages/root/cart/CheckoutRewardTrackerPage";
import CheckoutSelectPaymentMethodPage, {
  checkoutSelectPaymentLoader,
} from "pages/root/cart/CheckoutSelectPaymentMethodPage";
import CheckoutSuccessPage, {
  CheckoutSuccessPageLoader,
} from "pages/root/cart/CheckoutSuccessPage";
import CloseBrowserPaymentUpdatedPage from "pages/root/CloseBrowserPaymentUpdatedPage";
import DiscoverPage, { discoverLoader } from "pages/root/DiscoverPage";
import HomePage, { homeLoader } from "pages/root/home/HomePage";
import RewardsIndex, {
  rewardsIndexLoader,
} from "pages/root/rewards/RewardsIndex";
import RewardsPage, {
  rewardsAction,
  rewardsLoader,
} from "pages/root/rewards/RewardsPage";
import ScanCheckInPage, {
  ScanCheckInPageLoader,
} from "pages/root/ScanCheckInPage";
import ScanRedeemOfferInLocationPage, {
  ScanRedeemOfferInLocationLoader,
} from "pages/root/ScanRedeemOfferInLocationPage";
import ScanRedeemRewardInLocationPage from "pages/root/ScanRedeemRewardInLocationPage";
import MembershipPDP from "pages/root/shop/MembershipPDP";
import { membershipPDPLoader } from "pages/root/shop/MembershipPDP/MembershipPDP";
import PackagePDP from "pages/root/shop/PackagePDP";
import { packagePDPLoader } from "pages/root/shop/PackagePDP/PackagePDP";
import ShopBrowseByConcernPage, {
  shopBrowseByConcernsLoader,
} from "pages/root/shop/ShopBrowseByConcernPage";
import ShopByConcernPage, {
  shopByConcernLoader,
} from "pages/root/shop/ShopByConcernPage";
import ShopCustomCategoryPage, {
  shopCustomCategoryLoader,
} from "pages/root/shop/ShopCustomCategory";
import ShopIndex, { shopIndexLoader } from "pages/root/shop/ShopIndex";
import ShopMembershipsPage, {
  shopMembershipsLoader,
} from "pages/root/shop/ShopMembershipsPage";
import ShopPackagesPage, {
  shopPackagesLoader,
} from "pages/root/shop/ShopPackagesPage";
import ShopTreatmentsPage, {
  shopTreatmentsLoader,
} from "pages/root/shop/ShopTreatmentsPage";
import ShopPage from "pages/root/ShopPage";
import {
  accountCreatedLoader,
  AccountCreatedPage,
} from "pages/root/signIn/AccountCreatedPage";
import { adminImpersonateLoader } from "pages/root/signIn/AdminImpersonationPage";
import {
  friendReferralLandingLoader,
  FriendReferralLandingPage,
} from "pages/root/signIn/FriendReferralLandingPage";
import {
  loader as platformSelectorLoader,
  PlatformSelectorPage,
} from "pages/root/signIn/PlatformSelectorPage";
import SignInPage, { signInDisplayLoader } from "pages/root/signIn/SignInPage";
import { loader as signUpLoader } from "pages/root/signIn/signup";
import {
  completeProfileLoader,
  CompleteProfilePage,
} from "pages/root/signIn/signup/CompleteProfilePage";
import { FindMedspaPage } from "pages/root/signIn/signup/find-medspa";
import { SearchLocationPage } from "pages/root/signIn/signup/find-medspa/SearchLocationPage";
import {
  signUpFriendReferralLoader,
  SignUpFriendReferralPage,
} from "pages/root/signIn/SignUpFriendReferralPage";
import {
  v1MigrationLandingLoader,
  V1MigrationLandingPage,
} from "pages/root/signIn/V1MigrationLandingPage";
import {
  signInAction,
  VerificationCodePage,
} from "pages/root/signIn/VerificationCodePage";
import RootError from "pages/RootError";
import SearchPage from "pages/SearchPage";
import "./index.css";
import { FFlagProvider } from "services/Flags";

import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_DD_ENV) {
  datadogRum.init({
    applicationId: "dfe22215-963f-4e3e-95a0-dca35c91283c",
    clientToken: "pubd1728bd8bc02b6ea16033ce9aee7942e",
    service: "patient-web",
    site: "datadoghq.com",
    env: process.env.REACT_APP_DD_ENV,
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: 10,
    sessionReplaySampleRate: 0,
    allowedTracingUrls: [location.origin],
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  });

  datadogRum.setGlobalContextProperty("origin", location.origin);
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      loader={rootLoader}
      element={<Root />}
      errorElement={<RootError />}
    >
      <Route index loader={() => redirect("/home")} />
      <Route
        path="/app/*"
        loader={(request) => {
          const path = request.params["*"];
          return redirect(`/${path}`);
        }}
      />

      <Route element={<AnonymousLayout />} loader={AnonymousLoader}>
        <Route path="/signin">
          <Route
            index
            element={<SignInPage />}
            handle={{ name: "Sign In" }}
            loader={signInDisplayLoader}
          />
          <Route
            path="/signin/verification"
            action={signInAction}
            element={<VerificationCodePage />}
            handle={{ name: "Sign In" }}
          />
          <Route
            path="/signin/platform-selector"
            loader={platformSelectorLoader}
            element={<PlatformSelectorPage />}
            handle={{ name: "Sign In" }}
          />

          <Route path="/signin/signup">
            <Route index handle={{ name: "Sign Up" }} loader={signUpLoader} />

            <Route path="/signin/signup/find-medspa">
              <Route
                index
                element={<FindMedspaPage />}
                handle={{ name: "Sign Up" }}
              />

              <Route
                path="/signin/signup/find-medspa/search-location"
                element={<SearchLocationPage />}
                handle={{ name: "Sign Up" }}
              />
            </Route>

            <Route
              path="/signin/signup/complete-profile"
              element={<CompleteProfilePage />}
              handle={{ name: "Sign Up" }}
              loader={completeProfileLoader}
            />
          </Route>
          <Route
            path="/signin/account-created"
            element={<AccountCreatedPage />}
            handle={{ name: "Account Created" }}
            loader={accountCreatedLoader}
          />
          <Route
            path="/signin/friend-referral"
            element={<SignUpFriendReferralPage />}
            handle={{ name: "Share Reward" }}
            loader={signUpFriendReferralLoader}
          />
        </Route>

        <Route
          // This page route should be kept in sync with the admin app.
          // The admin app hardcodes a link to this page in
          // Shared/RepeatMD.Shared/Commands/Admin/Users/RequestPlatformUserPatientAppMagicLinkCommand.cs
          path="/admin-impersonate"
          loader={adminImpersonateLoader}
          handle={{ name: "Admin Impersonate" }}
        />
        <Route
          path="/referral/:referralCode"
          element={<FriendReferralLandingPage />}
          loader={friendReferralLandingLoader}
          handle={{ name: "Sign In Referral" }}
        />
        <Route
          path="/v1migration"
          element={<V1MigrationLandingPage />}
          loader={v1MigrationLandingLoader}
          handle={{ name: "V1 Referral Landing Page" }}
        />
        <Route
          path="/stripe-landing/close-browser"
          element={<CloseBrowserPaymentUpdatedPage />}
        />
      </Route>

      <Route
        element={<AppLayout />}
        loader={appLayoutLoader}
        errorElement={<AppLayoutError />}
      >
        <Route
          path="/home"
          loader={homeLoader}
          element={<HomePage />}
          handle={{ name: "Home" }}
        />
        <Route
          path="/discover"
          element={<DiscoverPage />}
          loader={discoverLoader}
          handle={{ name: "Discover" }}
        />
        <Route path="/shop" element={<ShopPage />}>
          <Route
            index
            loader={shopIndexLoader}
            element={<ShopIndex />}
            handle={{ name: "Shop" }}
          />
          <Route
            path="/shop/memberships"
            loader={shopMembershipsLoader}
            element={<ShopMembershipsPage />}
            handle={{ name: "Shop Memberships" }}
          />
          <Route
            path="/shop/treatments"
            loader={shopTreatmentsLoader}
            element={<ShopTreatmentsPage />}
            handle={{ name: "Shop Treatments" }}
          />
          <Route
            path="/shop/packages"
            loader={shopPackagesLoader}
            element={<ShopPackagesPage />}
            handle={{ name: "Shop Packages" }}
          />
          <Route
            path="/shop/concerns"
            loader={shopBrowseByConcernsLoader}
            element={<ShopBrowseByConcernPage />}
            handle={{ name: "Shop Browse by Concern" }}
          />
          <Route
            path="/shop/categories/:id"
            element={<ShopPackagesPage />}
            handle={{ name: "Shop Categories" }}
          />
          <Route
            path="/shop/custom-category/:id"
            loader={shopCustomCategoryLoader}
            element={<ShopCustomCategoryPage />}
          />
        </Route>
        <Route
          path="/shop/concern/:id"
          loader={shopByConcernLoader}
          element={<ShopByConcernPage />}
          handle={{ name: "Shop By Concern" }}
        />
        <Route
          path="/pdp/membership/:id"
          loader={membershipPDPLoader}
          element={<MembershipPDP />}
        />
        <Route
          path="/pdp/membership/:id/:source"
          loader={membershipPDPLoader}
          element={<MembershipPDP />}
        />
        <Route
          path="/pdp/package/:id"
          loader={packagePDPLoader}
          element={<PackagePDP />}
        />
        <Route
          path="/scan-checkin-page"
          element={<ScanCheckInPage />}
          loader={ScanCheckInPageLoader}
        />
        <Route
          path="/scan-redeem-offer-in-location"
          element={<ScanRedeemOfferInLocationPage />}
          loader={ScanRedeemOfferInLocationLoader}
        />
        <Route
          path="/scan-redeem-reward-in-location"
          element={<ScanRedeemRewardInLocationPage />}
        />
        <Route
          path="/scan-redeem-treatment-in-location"
          element={<ScanRedeemTreatmentPage />}
        />
        <Route path="/rewards">
          <Route
            index
            loader={rewardsIndexLoader}
            element={<RewardsIndex />}
            handle={{ name: "Rewards" }}
          />
          <Route
            path="/rewards/:rewardId"
            action={rewardsAction}
            loader={rewardsLoader}
            element={<RewardsPage />}
            handle={{ name: "Reward Details" }}
          />
        </Route>

        <Route path="/account">
          <Route index loader={() => redirect("/account/my-treatments")} />
          <Route loader={accountLayoutLoader} element={<AccountLayout />}>
            <Route
              path="/account/my-treatments"
              loader={accountMyTreatmentsLoader}
              element={<AccountMyTreatmentsPage />}
              handle={{ name: "Account Treatments" }}
            />
            <Route
              path="/account/offers"
              loader={accountOffersLoader}
              element={<AccountOffersPage />}
              handle={{ name: "Account Offers" }}
            />
            <Route
              path="/account/membership"
              loader={accountMembershipLoader}
              action={accountMembershipAction}
              element={<AccountMembershipPage />}
              handle={{ name: "Account Membership" }}
            />
            <Route
              path="/account/settings"
              loader={accountSettingsLoader}
              element={<AccountSettingsPage />}
              handle={{ name: "Account Settings" }}
            />
          </Route>
          <Route
            path="/account/my-treatments/:packageId"
            element={<AccountMyTreatmentPage />}
            loader={accountMyTreatmentLoader}
            handle={{ name: "My Treatment Details" }}
          />
          <Route
            path="/account/offers/:offerId"
            element={<AccountOfferPage />}
            loader={accountOfferLoader}
            handle={{ name: "Account Offer Details" }}
          />
          <Route
            path="/account/settings/details"
            action={accountDetailsAction}
            loader={accountDetailsLoader}
            element={<AccountDetailsPage />}
            handle={{ name: "Account Details" }}
          />
          <Route
            path="/account/settings/delete-my-account"
            element={<DeleteMyAccountPage />}
            handle={{ name: "Delete My Account" }}
          />
          <Route path="/account/settings/visits">
            <Route
              index
              element={<VisitHistoryPage />}
              handle={{ name: "Visit History" }}
            />
          </Route>
          <Route path="/account/settings/switch-medspa">
            <Route
              index
              loader={switchMedspaLoader}
              element={<SwitchMedspaPage />}
              handle={{ name: "Switch Medspa" }}
            />
          </Route>
          <Route path="/account/settings/orders">
            <Route
              index
              element={<OrderHistoryPage />}
              handle={{ name: "Order History" }}
            />
          </Route>
          <Route path="/account/settings/orders/:shopDisplayOrderId">
            <Route
              index
              loader={orderHistoryDetailsLoader}
              action={orderHistoryDetailsAction}
              element={<OrderHistoryDetailsPage />}
              handle={{ name: "Order History Details" }}
            />
          </Route>
          {/* The billing information was cut out of scope. While don't want to show it until
              we have this feature implemented
          */}
          {/*<Route path="/account/settings/payment-methods">
            <Route
              index
              loader={billingInformationLoader}
              action={billingInformationAction}
              element={<BillingInformationPage />}
              handle={{ name: "Billing Information" }}
            />
            <Route
              path="/account/settings/payment-methods/:paymentMethodId"
              loader={paymentMethodLoader}
              action={paymentMethodAction}
              element={<PaymentMethodPage />}
              handle={{ name: "Payment Method" }}
            />
          </Route>*/}
          <Route
            path="/account/settings/reward-history"
            element={<RewardHistoryPage />}
            handle={{ name: "Reward History" }}
          />
        </Route>
        <Route path="/search" element={<SearchPage />} />
      </Route>

      <Route
        element={<AppLayoutNoBottomNav />}
        errorElement={<AppLayoutErrorNoBottomNav />}
      >
        <Route
          path="/discoverNoBottomNav"
          element={<DiscoverPage />}
          loader={discoverLoader}
          handle={{ name: "Discover" }}
        />
        <Route path="/cart">
          <Route
            index
            action={cartPageAction}
            loader={cartLoader}
            element={<CartPage />}
            handle={{ name: "Your Shopping Cart" }}
          />
          <Route
            path="/cart/checkout/element"
            loader={checkoutElementLoader}
            element={<CheckoutElementPage />}
            handle={{ name: "Checkout Element" }}
          />
          <Route path="/cart/checkout/paymentmethod">
            <Route
              index
              loader={checkoutSelectPaymentLoader}
              element={<CheckoutSelectPaymentMethodPage />}
              handle={{ name: "Checkout Select Payment Method" }}
            />
            <Route
              path="/cart/checkout/paymentmethod/:paymentMethodId"
              loader={paymentMethodLoader}
              action={paymentMethodAction}
              element={<PaymentMethodPage />}
              handle={{ name: "Checkout Add / Edit Card" }}
            />
          </Route>
          <Route
            path="/cart/checkout/success"
            element={<CheckoutSuccessPage />}
            handle={{ name: "Checkout Success" }}
            loader={CheckoutSuccessPageLoader}
          />
          <Route
            path="/cart/checkout/reward"
            element={<CheckoutRewardTrackerPage />}
            handle={{ name: "Checkout Reward Tracker" }}
            loader={checkoutRewardTrackerLoader}
          />
        </Route>
      </Route>
      <Route path="/stripe-landing">
        <Route
          index
          loader={() => {
            const params = new URLSearchParams(window.location.search);

            return redirect(`/account/membership?${params.toString()}`);
          }}
        />
      </Route>
    </Route>
  )
);

// dayjs plugin configuration
dayjs.extend(relativeTime);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <FFlagProvider>
      <RouterProvider router={router} />
    </FFlagProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
