import { notEmpty } from "./Guards";

export function formatAddress(
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  postalCode?: string | null
) {
  const stateCountryPostalCode = [state, country, postalCode]
    .filter(notEmpty)
    .join(" ");
  return [address1, address2, city, stateCountryPostalCode]
    .filter(notEmpty)
    .join(", ");
}

export function formatAddressNoCountry(
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null
) {
  const statePostalCode = [state, postalCode].filter(notEmpty).join(" ");
  return [address1, address2, city, statePostalCode]
    .filter(notEmpty)
    .join(", ");
}
