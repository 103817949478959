import { type InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import { debounce } from "lodash";
import {
  type KeyboardEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { get } from "api";
import type { Models } from "api/types";
import { IconButton } from "atoms/icon-button/IconButton";
import { ReactComponent as ChevronDown } from "atoms/icon/icons/chevron_down_small.svg";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { SearchField } from "atoms/search-field/SearchField";
import { Spinner } from "atoms/Spinner";
import { TextButton } from "atoms/text-button/TextButton";
import { PlatformWithLocationsList } from "organisms/PlatformWithLocationsList/PlatformWithLocationsList";
import { ThemeContext } from "pages/Root";

const platformsFromLocations = (
  locations: Models<"GetMultiPlatformLocationsResult">[]
): Record<string, Models<"GetMultiPlatformLocationsResult">[]> => {
  const platforms: Record<string, Models<"GetMultiPlatformLocationsResult">[]> =
    {};

  locations.forEach((location) => {
    if (!platforms[location.platformId]) {
      platforms[location.platformId] = [];
    }

    platforms[location.platformId].push(location);
  });

  return platforms;
};

const getSearchResults = (
  pages: InfiniteData<
    Models<"GetMultiPlatformLocationsResultPagedResults">
  >["pages"] = []
) =>
  pages.reduce(
    (accumulator, page) => accumulator.concat(page.items || []),
    [] as Models<"GetMultiPlatformLocationsResult">[]
  ) || [];

export const SearchLocationPage = () => {
  const [zipCodeSearchQuery, setZipCodeSearchQuery] = useState("");
  const [debouncedZipCodeSearchQuery, setDebouncedZipCodeSearchQuery] =
    useState(zipCodeSearchQuery);
  const navigate = useNavigate();
  const prevState = useLocation();
  const [searchParams] = useSearchParams();
  const { resetTheme } = useContext(ThemeContext);

  const {
    data: searchResults,
    /**
     * We use `isFetching` here instead of `isLoading` because
     * of a known quirky behavior of `isLoading` property when
     * `enabled` property is used.
     * @see {@link https://github.com/TanStack/query/issues/3584}
     */
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    retry: false,
    enabled: !!debouncedZipCodeSearchQuery,
    staleTime: Infinity,
    queryKey: ["/multi-platform/locations", debouncedZipCodeSearchQuery],
    queryFn: ({ pageParam = 1, queryKey }) => {
      return get("/multi-platform/locations", {
        page: pageParam,
        zipCode: queryKey[queryKey.length - 1],
      });
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.totalPages > lastPage.page) {
        return lastPage.page + 1;
      }
    },
    getPreviousPageParam: (firstPage) => {
      if (firstPage.page !== 1) {
        return firstPage.page - 1;
      }
    },
  });

  useEffect(() => {
    setZipCodeSearchQuery(prevState?.state?.zip || "");
  }, [prevState]);

  const updateDebouncedZipCode = useRef(
    debounce((zip) => setDebouncedZipCodeSearchQuery(zip), 300)
  ).current;

  useEffect(() => {
    updateDebouncedZipCode(zipCodeSearchQuery);
  }, [zipCodeSearchQuery, updateDebouncedZipCode]);

  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.currentTarget.blur();
    }
  };

  const referralCode = searchParams.get("referralCode");

  const allSearchResults = getSearchResults(searchResults?.pages);
  const platforms = platformsFromLocations(allSearchResults);

  return (
    <div>
      <div className="sticky top-0 w-full bg-white pt-[env(safe-area-inset-top)] pl-3">
        <span className="flex items-center justify-between">
          <Link to="..">
            <IconButton size="small" style="iconOnly" svg={ChevronLeft} />
          </Link>

          <div className="text-sub2 uppercase">Search by location</div>

          <div className="invisible">
            <IconButton size="small" style="iconOnly" svg={ChevronLeft} />
          </div>
        </span>
      </div>

      <div className="flex flex-col gap-4 p-4">
        <SearchField
          placeholder="10001"
          autoFocus
          label="Zipcode"
          name="zip_code"
          value={zipCodeSearchQuery}
          onChange={setZipCodeSearchQuery}
          onKeyDown={handleInputKeyDown}
          onIconClick={(side) => side == "right" && setZipCodeSearchQuery("")}
        />

        {isFetching && !allSearchResults.length ? (
          <div className="mt-10 flex flex-col items-center justify-center gap-6">
            <Spinner />
            <div className="text-body2 text-secondary">Searching medspas</div>
          </div>
        ) : (
          <div>
            {!!zipCodeSearchQuery && (
              <div className="mb-2 text-body2 text-secondary">
                {searchResults?.pages?.[0]?.count ?? 0} results found
              </div>
            )}

            {!!zipCodeSearchQuery && !allSearchResults.length && (
              <div className="mt-10 flex flex-col items-center justify-center text-center">
                <div className="mb-2 text-body1">No results found</div>
                <div className="mb-2 text-body2 text-secondary">
                  Not the result you expected?
                </div>
                <a
                  href="mailto:support@repeatmd.com"
                  className="text-brand-color"
                >
                  Contact Us
                </a>
              </div>
            )}

            <PlatformWithLocationsList
              platforms={platforms}
              onItemClick={async (
                platformId: string,
                nearestLocationId: string
              ) => {
                try {
                  const platformTheme = await get(
                    "/multi-platform/platform/{platformId}/theme",
                    { platformId }
                  );

                  const referralCodeParam = referralCode
                    ? `&referralCode=${referralCode}`
                    : "";
                  const redirectUrl = `/signin/signup/complete-profile?platformId=${platformId}&platformImageUrl=${platformTheme.logoImageUrl}&nearestLocationId=${nearestLocationId}${referralCodeParam}`;

                  resetTheme();
                  navigate(redirectUrl, {
                    state: { zip: zipCodeSearchQuery },
                  });
                } catch (error) {
                  throw new Error("Get platform theme request failed.");
                }
              }}
            />
            {hasNextPage && (
              <div className="flex items-center justify-center py-4">
                <TextButton
                  disabled={isFetching}
                  onClick={() => fetchNextPage()}
                  endIconSVG={ChevronDown}
                >
                  Load more results
                </TextButton>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
