import {
  BarcodeFormat,
  BarcodeScanner,
  LensFacing,
} from "@capacitor-mlkit/barcode-scanning";
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from "capacitor-native-settings";
import { useEffect, useRef } from "react";

import { Button } from "atoms/button/Button";

export type Props = {
  facingMode: "front" | "back";
  onResult: (code: string) => void;
  userDeniedCameraPermissions: boolean;
  onUserDeniedCameraPermissions: (value: boolean) => void;
};

export const QRScannerForMobile = ({
  facingMode,
  userDeniedCameraPermissions,
  onResult,
  onUserDeniedCameraPermissions,
}: Props) => {
  const checkPermissions = async () => {
    const { camera } = await BarcodeScanner.checkPermissions();
    return camera;
  };

  const requestPermissions = async () => {
    const { camera } = await BarcodeScanner.requestPermissions();
    return camera;
  };

  useEffect(() => {
    const scanSingleBarcode = async (lensFacing: LensFacing) => {
      await BarcodeScanner.addListener("barcodeScanned", async (result) => {
        if (result) {
          onResult(result.barcode.displayValue);
        }
      });

      await BarcodeScanner.startScan({
        lensFacing,
        formats: [BarcodeFormat.QrCode],
      });
    };

    (async () => {
      const currentCameraPermissions = await checkPermissions();
      if (
        currentCameraPermissions !== "granted" &&
        currentCameraPermissions !== "limited"
      ) {
        const requestedCameraPermissions = await requestPermissions();
        console.log("requestedCameraPermissions", requestedCameraPermissions);

        if (
          requestedCameraPermissions !== "granted" &&
          requestedCameraPermissions !== "limited"
        ) {
          onUserDeniedCameraPermissions(true);
          return;
        }
      }

      onUserDeniedCameraPermissions(false);

      await scanSingleBarcode(
        facingMode === "back" ? LensFacing.Back : LensFacing.Front
      );
    })();

    return () => {
      (async () => {
        await BarcodeScanner.removeAllListeners();
        await BarcodeScanner.stopScan();
      })();
    };
  }, [facingMode, onResult, onUserDeniedCameraPermissions]);

  useEffect(() => {
    document.querySelector("body")?.classList.add("qr-code-scanner-active");

    return () => {
      document
        .querySelector("body")
        ?.classList.remove("qr-code-scanner-active");

      BarcodeScanner.removeAllListeners();
      BarcodeScanner.stopScan();
    };
  }, []);

  const handleEnableCameraClick = () => {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  };

  return userDeniedCameraPermissions ? (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 px-4">
      <div className="text-center text-bold1 text-secondary">
        Please enable camera permission in order to scan QR codes
      </div>

      <Button onClick={handleEnableCameraClick}>Enable camera</Button>
    </div>
  ) : null;
};
