import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Notification, useNotify } from "atoms/notification";

const CloseBrowserPaymentUpdatedPage = () => {
  const [notificationProps, notify] = useNotify();
  const [searchParams] = useSearchParams();
  const successfulPayment = searchParams.get("successfulPayment");

  useEffect(() => {
    if (successfulPayment === "true") {
      notify.info("Payment method updated.", {
        timeout: 5000,
        dismissable: false,
      });
    } else if (successfulPayment === "false") {
      notify.error("Failed to update payment method.", {
        timeout: 5000,
        dismissable: false,
      });
    }
  }, [successfulPayment, notify]);

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="mb-2">
        <Notification {...notificationProps} />
      </div>
      <div className="text-body1">Please close the browser</div>
    </div>
  );
};

export default CloseBrowserPaymentUpdatedPage;
