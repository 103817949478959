import { Capacitor } from "@capacitor/core";
import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";

import { IconButton } from "atoms/icon-button/IconButton";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as CameraFlip } from "atoms/icon/icons/camera_flip.svg";
import { ReactComponent as CloseIcon } from "atoms/icon/icons/close.svg";
import { ReactComponent as PlusSignIcon } from "atoms/icon/icons/plus.svg";
import { ReactComponent as QrCode } from "atoms/icon/icons/qr_code.svg";
import { ReactComponent as BottomLeftCorner } from "atoms/icon/icons/qr_code_scanner_bottom_left_corner.svg";
import { ReactComponent as BottomRightCorner } from "atoms/icon/icons/qr_code_scanner_bottom_right_corner.svg";
import { ReactComponent as UpperLeftCorner } from "atoms/icon/icons/qr_code_scanner_upper_left_corner.svg";
import { ReactComponent as UpperRightCorner } from "atoms/icon/icons/qr_code_scanner_upper_right_corner.svg";

import { QRScannerForMobile } from "./QRScannerForMobile";
import { QRScannerForWeb } from "./QRScannerForWeb";

type Props = {
  errorMessage?: string;
  onClose: () => void;
  onResult: (code: string) => void;
};

export const QRScanner = ({ errorMessage, onClose, onResult }: Props) => {
  const [
    mobileUserDeniedCameraPermissions,
    setMobileUserDeniedCameraPermissions,
  ] = useState(false);
  const [networkError, setNetworkError] = useState("");

  const [facingMode, setFacingMode] = useState<"front" | "back">("back");
  const toggleFacingMode = () => {
    setFacingMode((prev) => (prev === "back" ? "front" : "back"));
  };

  useEffect(() => {
    window.addEventListener("offline", () => {
      setNetworkError("Error connecting to the internet - please try again");
    });

    window.addEventListener("online", () => {
      setNetworkError("");
    });
  }, []);

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Dialog open={true} onClose={() => {}} data-testid="qr-code-scanner-modal">
      <div className="absolute top-0 right-0 left-0 bottom-0 z-10 h-full">
        {!mobileUserDeniedCameraPermissions && (
          <>
            <div className="qr-code-scanner-frame absolute top-0 right-0 left-0 bottom-0 z-10 bg-black/50" />
            <div className="absolute z-10 flex h-full w-full items-center justify-center">
              <Icon size="xsmall" color="white" svg={PlusSignIcon} />
            </div>
            {/* numbers are related to 278px which is the frame length so 139 is half of that
              24px is size of icon
              6 px is margin from icon to the frame
            */}
            <div className="absolute top-[calc(50%_-_145px)] left-[calc(50%_-_145px)] z-10">
              <Icon svg={UpperLeftCorner} size="xsmall" />
            </div>
            <div className="absolute top-[calc(50%_-_145px)] left-[calc(50%_+_121px)] z-10">
              <Icon svg={UpperRightCorner} size="xsmall" />
            </div>
            <div className="absolute top-[calc(50%_+_121px)] left-[calc(50%_-_145px)] z-10">
              <Icon svg={BottomLeftCorner} size="xsmall" />
            </div>
            <div className="absolute top-[calc(50%_+_121px)] left-[calc(50%_+_121px)] z-10">
              <Icon svg={BottomRightCorner} size="xsmall" />
            </div>
            <div
              className="absolute bottom-20 left-1/2 z-10 -translate-x-1/2 transform cursor-pointer"
              onClick={toggleFacingMode}
            >
              <IconButton svg={CameraFlip} size="camera" style="camera" />
            </div>
          </>
        )}

        <div className="absolute top-[env(safe-area-inset-top)] z-10 flex w-full flex-col p-6">
          {mobileUserDeniedCameraPermissions ? (
            <div className="w-fit cursor-pointer" onClick={onClose}>
              <Icon size="xsmall" color="brandColor" svg={CloseIcon} />
            </div>
          ) : (
            <>
              <div className="w-fit cursor-pointer" onClick={onClose}>
                <Icon size="xsmall" color="white" svg={CloseIcon} />
              </div>
              <div className="flex flex-col items-center pt-4">
                <Icon size="xlarge" color="white" svg={QrCode} />
                <h1 className="mt-6 text-center text-white">
                  Scan our QR code in office
                </h1>
              </div>
            </>
          )}
        </div>

        {Capacitor.isNativePlatform() ? (
          <QRScannerForMobile
            facingMode={facingMode}
            onResult={onResult}
            userDeniedCameraPermissions={mobileUserDeniedCameraPermissions}
            onUserDeniedCameraPermissions={setMobileUserDeniedCameraPermissions}
          />
        ) : (
          <QRScannerForWeb facingMode={facingMode} onResult={onResult} />
        )}

        {(errorMessage || networkError) && (
          <div
            className={
              "absolute bottom-0 z-10 w-full bg-red pt-4 pb-6 text-center text-body2 text-white"
            }
          >
            {errorMessage || networkError}
          </div>
        )}
      </div>
    </Dialog>
  );
};
