import { Button } from "atoms/button/Button";

type Props = {
  handleDelete: () => void;
  disabled: boolean;
  handleCancel: () => void;
};

export const DisclaimerDeleteMyAccountPage = ({
  handleDelete,
  handleCancel,
  disabled,
}: Props) => {
  return (
    <div className="mx-6 mt-6 flex h-full flex-col">
      <div className="flex-grow px-4 py-2">
        <h1 className="text-title">
          Are you sure you want to delete your account?
        </h1>
        <p className="mt-4 text-body1">
          Your account and all of its data will be deleted including:
          <ul className="mt-6 list-disc space-y-3 pl-6">
            <li className="marker:text-secondary">Unclaimed rewards</li>
            <li className="marker:text-secondary">Order History</li>
            <li className="marker:text-secondary">Reward History</li>
            <li className="marker:text-secondary">Visit History</li>
          </ul>
          <span className="mt-6 block">
            Any unused treatments and existing memberships will need to be
            claimed in store upon account deletion.
          </span>
        </p>
      </div>
      <div className="flex w-full flex-col justify-center space-y-3 p-4">
        <Button
          disabled={disabled}
          fullWidth
          onClick={handleDelete}
          style="dangerSolid"
        >
          <div className="text-bold1">Yes, request account deletion</div>
        </Button>
        <Button fullWidth onClick={handleCancel} style="secondary">
          <div className="text-bold1">No, keep my account</div>
        </Button>
      </div>
    </div>
  );
};
