import cx from "classnames";
import { useState } from "react";
import { Link } from "react-router-dom";

import { Button } from "atoms/button/Button";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronDownSmall } from "atoms/icon/icons/chevron_down_small.svg";
import { ReactComponent as ChevronUpSmall } from "atoms/icon/icons/chevron_up_small.svg";
import {
  Treatment,
  TreatmentProps,
} from "molecules/containers/treatment/Treatment";

export interface TreatmentListProps {
  treatments: TreatmentProps[];
}

export const NoTreatmentsContainer = ({ text }: { text: string }) => {
  return (
    <div className="flex flex-col items-center bg-white px-7 py-8">
      <div className="mb-5 text-center text-body1 text-secondary">{text}</div>

      <Link to="/shop">
        <Button size="small">Browse Shop</Button>
      </Link>
    </div>
  );
};

export const TreatmentList = ({ treatments }: TreatmentListProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded((expanded) => !expanded);
  };

  const availableTreatments = treatments.filter((t) => t.active === true);
  const previousTreatments = treatments.filter((t) => t.active === false);

  const visibleTreatments = expanded ? treatments : availableTreatments;

  return (
    <div>
      <div className="divide-y divide-solid divide-background-two overflow-clip rounded-lg shadow-subtle">
        <>
          {!!treatments.length && !availableTreatments.length && (
            <NoTreatmentsContainer
              text={
                treatments.every((t) => t.type === "MembershipTreatment")
                  ? "You don’t have any active member benefits right now. Check your membership to see when they refresh."
                  : "You don’t have any active treatments, browse shop to buy more, or rebook a past treatment below"
              }
            />
          )}
          {visibleTreatments.map((treatment) => (
            <Treatment
              key={`${treatment.id}${treatment.active}`}
              {...treatment}
            />
          ))}
          <div
            className={cx(
              "flex cursor-pointer justify-between bg-white px-4 py-3",
              !previousTreatments.length && "hidden"
            )}
            onClick={handleClick}
          >
            <div className="text-bold1 text-brand-color">
              {expanded ? "Hide past treatments" : "See past treatments"}
            </div>
            <Icon
              color="brandColor"
              size="xsmall"
              svg={expanded ? ChevronUpSmall : ChevronDownSmall}
            />
          </div>
        </>
      </div>
    </div>
  );
};
