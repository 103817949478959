import cx from "classnames";
import { useState } from "react";

import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronDownSmall } from "atoms/icon/icons/chevron_down_small.svg";
import { ReactComponent as ChevronUpSmall } from "atoms/icon/icons/chevron_up_small.svg";

interface ExpandableItemsProps {
  items: {
    id: string;
    title: React.ReactNode;
    description: string | null | undefined;
  }[];
  disabled?: boolean | null;
}

export const ExpandableItems = (props: ExpandableItemsProps) => {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  const changeExpanded = (id: string) => () => {
    setExpanded({ [id]: !expanded[id] });
  };

  return (
    <ul
      className={cx(
        "list-inside list-disc divide-y divide-solid divide-light-grey",
        props.disabled ? "marker:text-dark-grey" : ""
      )}
    >
      {props.items.map(({ id, title, description }) => (
        <li key={id} className="pl-2.5">
          <div
            className="ml-[-8px] inline-flex w-[calc(100%_-_22px)] cursor-pointer py-3 pr-2"
            onClick={description ? changeExpanded(id) : () => null}
          >
            <div
              className={cx(
                "flex-1 pr-2",
                expanded[id] ? "text-bold1" : "text-body1",
                props.disabled ? "text-dark-grey" : "text-primary"
              )}
            >
              {title}
            </div>
            {description && (
              <Icon
                svg={expanded[id] ? ChevronUpSmall : ChevronDownSmall}
                size="xsmall"
                color={props.disabled ? "darkGrey" : "primaryText"}
              />
            )}
          </div>
          {expanded[id] && (
            <div className="pl-[21px] pr-6 pb-6 text-body2 text-secondary">
              {description}
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};
