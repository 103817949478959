import {
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get } from "api";
import FriendShareImg from "assets/images/Sign-Up-Referral.jpg";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Reward } from "atoms/icon/icons/rewards.svg";
import { TextButton } from "atoms/text-button/TextButton";
import { ReferAFriend } from "organisms/refer-a-friend/ReferAFriend";
import { queryClient } from "pages/Root";
import { waitObj } from "toolbox/Promise";

const load = async () => {
  const rewards = queryClient.fetchQuery(["/rewards/referrable"], () =>
    get("/rewards/referrable")
  );

  return waitObj({ rewards });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const signUpFriendReferralLoader: LoaderFunction = async () => {
  const data = await load();

  if (!data.rewards.length) {
    return redirect("/home");
  }

  return json<LoaderData>(data);
};

export const SignUpFriendReferralPage = () => {
  const navigate = useNavigate();
  const { rewards } = useLoaderData() as LoaderData;

  return (
    <div className="flex h-full flex-col  pt-[40px]">
      <div className="flex flex-col items-center gap-7 p-4">
        <div>
          <Icon svg={Reward} size={"large"} color={"brandColor"} />
        </div>
        <div className="font-serif text-h1 text-primary">
          Sharing is caring!
        </div>
        <div className="text-body1 text-secondary">
          Gift your friend a free {rewards?.[0].title}
        </div>
        <div className="flex-grow p-4">
          <img className="rounded" src={FriendShareImg} />
        </div>
        <div className="absolute inset-x-0 bottom-0 mx-auto flex flex-col items-center p-4 pb-9 sm:w-[390px]">
          <ReferAFriend buttonOnly={true} />
          <TextButton onClick={() => navigate("/home")}>Maybe later</TextButton>
        </div>
      </div>
    </div>
  );
};
