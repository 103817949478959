import cx from "classnames";

import { Models } from "api/types";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right.svg";
import { ReactComponent as Memberships } from "atoms/icon/icons/memberships_filled.svg";
import { DescriptiveTag } from "atoms/tag/DescriptiveTag";

export interface TreatmentProps {
  id: string;
  imgUrl?: string | null;
  type: string;
  bonus?: string;
  title: string;
  active?: boolean;
  remainingTreatmentsCount?: number;
  totalTreatments?: number;
  session?: string | null;
  onClick?: () => void;
  packageUnitType?: Models<"PackageUnitTypeModel"> | null | undefined;
}

const TypeTagTitle: Record<string, string> = {
  Treatment: "Treatment",
  Package: "Package",
  MembershipTreatment: "Member Benefit",
  Membership: "Membership",
};

export const Treatment = ({
  imgUrl,
  type,
  title,
  bonus,
  active,
  remainingTreatmentsCount,
  onClick,
  session = null,
  packageUnitType,
}: TreatmentProps) => {
  const isPreviousTreatment = active === false;

  return (
    <div
      className="flex cursor-pointer items-center justify-between bg-white py-2 pl-2 pr-4"
      onClick={onClick}
    >
      <div className="flex">
        {imgUrl && (
          <div className="mr-3 h-[86px] w-[86px] self-center">
            <img
              className={cx(
                "h-[86px] w-[86px] max-w-none rounded object-cover",
                isPreviousTreatment && "opacity-90"
              )}
              src={imgUrl}
              height={86}
              alt="Treatment"
            />
          </div>
        )}
        <div className={cx("my-2 mr-2", !imgUrl && "pl-2")}>
          <div
            className={cx(
              "flex gap-1 pb-2",
              isPreviousTreatment && "opacity-90"
            )}
          >
            {type === "MembershipTreatment" && (
              <Icon color="brandColor" size="x2small" svg={Memberships} />
            )}
            <DescriptiveTag color="secondary" size="small">
              {bonus ?? TypeTagTitle[type]}
            </DescriptiveTag>
          </div>

          <div
            className={cx(
              "flex flex-col items-start justify-start pb-0.5 pr-4 text-left font-sans text-bold1",
              isPreviousTreatment ? "text-secondary" : "text-primary"
            )}
          >
            {title}
          </div>
          {session ? (
            <div className="text-left font-sans text-body2 text-secondary">
              {session}
            </div>
          ) : (
            <div
              className={cx(
                "text-left font-sans text-body2",
                isPreviousTreatment ? "text-dark-grey" : "text-secondary"
              )}
            >
              {remainingTreatmentsCount}{" "}
              {remainingTreatmentsCount && remainingTreatmentsCount > 1
                ? packageUnitType?.pluralUnitType
                : packageUnitType?.singularUnitType}{" "}
              available
            </div>
          )}
        </div>
      </div>
      {!session && <Icon svg={ChevronRight} size="xsmall" color="brandColor" />}
    </div>
  );
};
