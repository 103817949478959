import cx from "classnames";
import { ReactNode } from "react";

interface ModalProps {
  /** Header */
  header?: ReactNode;
  /** Body */
  children: ReactNode;
  /** Footer */
  footer?: ReactNode;
  /** Open/hide */
  open?: boolean;
  /** On close callback */
  onClose?: () => void;
  /** Specify the size */
  size?: string;
}

/**
 * Atomic component - Modal
 */
export const Modal = ({
  header,
  children,
  footer,
  open,
  onClose,
  size,
}: ModalProps) => {
  if (!open) {
    return null;
  }

  return (
    <>
      <div
        className="fixed inset-0 z-40 bg-black/15"
        onClick={onClose}
        data-testid="div-dismiss-modal"
      />
      <div
        className={cx(
          "fixed left-0 right-0 bottom-0 z-40 mx-auto flex max-h-[calc(100%_-_env(safe-area-inset-top)_-_12px)] w-full flex-col rounded-t-lg bg-white sm:w-[390px]",
          size && size,
          open && "modal-slide-in"
        )}
      >
        <div className="flex-none">{header}</div>
        <div className="grow overflow-auto">{children}</div>
        <div className="flex-none pb-[env(safe-area-inset-bottom)]">
          {footer}
        </div>
      </div>
    </>
  );
};
