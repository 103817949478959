import { useMutation } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  json,
  LoaderFunction,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { get, post } from "api";
import { QRScanner } from "organisms/qr-scanner/QRScanner";
import { queryClient } from "pages/Root";
import { waitObj } from "toolbox/Promise";

const load = async () => {
  const userInfo = queryClient.fetchQuery(["/user"], () => get("/user"), {
    staleTime: 1000 * 60 * 2,
  });
  return waitObj({ userInfo });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const ScanRedeemOfferInLocationLoader: LoaderFunction = async () => {
  return json<LoaderData>(await load());
};

const redeemOffer = debounce(
  ({ scannedCode, promotionId }) =>
    post("/promotions/redeem-in-store", {
      scannedCode: scannedCode,
      promotionId: promotionId,
    }),
  500,
  { leading: true, trailing: false }
);

const ScanRedeemOfferInLocationPage = () => {
  const [scannedQRCode, setScannedQRCode] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [errorMessage, setErrorMessage] = useState(
    "Something went wrong - please contact support if this issue persists"
  );

  const mutation = useMutation({
    mutationFn: redeemOffer,
    onSuccess: () => {
      setScannedQRCode("");
      queryClient.invalidateQueries(["/promotions"]);
      navigate(
        `/account/offers/${state.promotionId}?claimed=1&comingFromScan=1`
      );
    },
    onError: (err: any) => {
      if (err.response.status == 400) {
        setErrorMessage("Unrecognized QR code - please try again");
      }
    },
  });

  const handleShowErrorMessage = useCallback(() => {
    if (mutation.isError) {
      setShowErrorMessage(true);
      return setTimeout(() => {
        setShowErrorMessage(false);
        setScannedQRCode("");
      }, 2000);
    }
  }, [mutation.isError]);

  useEffect(() => {
    const timeoutId = handleShowErrorMessage();
    return () => clearTimeout(timeoutId);
  }, [handleShowErrorMessage]);

  const handleScanResult = useCallback(() => {
    if (
      scannedQRCode &&
      !showErrorMessage &&
      !mutation.isLoading &&
      !mutation.isSuccess
    ) {
      mutation.mutate({
        scannedCode: scannedQRCode,
        promotionId: state.promotionId,
      });
    }
  }, [scannedQRCode, showErrorMessage, mutation, state.promotionId]);

  useEffect(() => {
    handleScanResult();
  }, [handleScanResult]);

  return (
    <QRScanner
      errorMessage={showErrorMessage ? errorMessage : undefined}
      onClose={() => navigate(-1)}
      onResult={setScannedQRCode}
    />
  );
};

export default ScanRedeemOfferInLocationPage;
