import { Models } from "api/types";
import { notFalsy } from "toolbox/Guards";

export const hasAvailableTreatmentsToSelect = (
  membershipsResponse: Models<"PlatformUserMembershipModel">[]
): boolean => {
  return membershipsResponse.some((membershipObj) => {
    const firstMonthlyRewardGroup =
      membershipObj.membership.membershipRewardGroups?.find(
        (rg) => !rg.exclusiveGroupId
      );

    const additionalMonthlyRewardGroup =
      membershipObj.membership.membershipRewardGroups?.find(
        (rg) => rg.exclusiveGroupId
      );

    const hasAvailableTreatments = [
      firstMonthlyRewardGroup,
      additionalMonthlyRewardGroup,
    ]
      .filter(notFalsy)
      .filter(
        // remove sign-up and milestone bonuses
        (rg) => rg.periodMonths !== 0
      )
      .some(
        (rg) => rg.hasSelectableRewards && rg.availableTreatmentsAmount > 0
      );

    return hasAvailableTreatments;
  });
};

export const getAvailableSelectableTreatmentsCount = (
  membershipsResponse: Models<"PlatformUserMembershipModel">[]
): number => {
  return membershipsResponse.reduce((acc, membershipObj) => {
    const firstMonthlyRewardGroup =
      membershipObj.membership.membershipRewardGroups?.find(
        (rg) => !rg.exclusiveGroupId
      );

    const additionalMonthlyRewardGroup =
      membershipObj.membership.membershipRewardGroups?.find(
        (rg) => rg.exclusiveGroupId
      );

    const availableTreatmentsAmount = [
      firstMonthlyRewardGroup,
      additionalMonthlyRewardGroup,
    ]
      .filter(notFalsy)
      .filter(
        // remove sign-up and milestone bonuses
        (rg) => rg.periodMonths !== 0
      )
      .reduce((acc, curr) => {
        if (curr?.hasSelectableRewards) {
          return acc + curr.availableTreatmentsAmount;
        }
        return acc;
      }, 0);

    return acc + availableTreatmentsAmount;
  }, 0);
};

export const hasRolledOverTreatmentsToSelect = (
  membershipsResponse: Models<"PlatformUserMembershipModel">[]
): boolean => {
  return membershipsResponse.some((membershipObj) => {
    const firstMonthlyRewardGroup =
      membershipObj.membership.membershipRewardGroups?.find(
        (rg) => !rg.exclusiveGroupId
      );

    const additionalMonthlyRewardGroup =
      membershipObj.membership.membershipRewardGroups?.find(
        (rg) => rg.exclusiveGroupId
      );

    const hasAvailableTreatments = [
      firstMonthlyRewardGroup,
      additionalMonthlyRewardGroup,
    ]
      .filter(notFalsy)
      .some(
        (rg) =>
          rg.hasSelectableRewards &&
          rg.availableTreatmentsAmount > rg.quantityPerPeriod
      );

    return hasAvailableTreatments;
  });
};
