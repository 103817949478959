import { Capacitor } from "@capacitor/core";
import { useMutation } from "@tanstack/react-query";
import cx from "classnames";
import dayjs from "dayjs";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import {
  ActionFunction,
  Form,
  json,
  LoaderFunction,
  redirect,
  useActionData,
  useLoaderData,
  useNavigate,
  useSubmit,
} from "react-router-dom";

import { get, post } from "api";
import { PostRequest } from "api/types";
import { Button } from "atoms/button/Button";
import { IconButton } from "atoms/icon-button/IconButton";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { TextButton } from "atoms/text-button/TextButton";
import { TextField } from "atoms/text-field/TextField";
import { ActionModal } from "molecules/modal-window/action-modal/ActionModal";
import { queryClient } from "pages/Root";
import { waitObj } from "toolbox/Promise";
import { readFormData } from "toolbox/ReadFormData";

import { DeletionRequestedBanner } from "./components/DeletionRequestedBanner";

type ActionResult = {
  saveSuccessTimestamp: number;
};

const load = async () => {
  const userInfo = queryClient.fetchQuery(["/user"], () => get("/user"), {
    staleTime: 1000 * 60 * 2,
  });

  return waitObj({ userInfo });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const accountDetailsLoader: LoaderFunction = async () => {
  return json<LoaderData>(await load());
};

export const accountDetailsAction: ActionFunction = async ({ request }) => {
  const data = await readFormData<
    PostRequest<"/user/update"> & { shouldGoBackOnSuccess?: boolean }
  >(request);

  await post("/user/update", data);

  if (data.shouldGoBackOnSuccess) {
    return redirect("..");
  }

  queryClient.invalidateQueries(["/user"]);

  return json({
    saveSuccessTimestamp: new Date().getTime(),
  });
};

export const AccountDetailsPage = () => {
  const { userInfo } = useLoaderData() as LoaderData;
  const submit = useSubmit();
  const actionData = useActionData() as ActionResult | undefined;
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [hideSuccessfullySavedMessage, setHideSuccessfullySavedMessage] =
    useState(false);
  const savedUserInfo = {
    birthday: userInfo.dateOfBirth,
    firstName: userInfo.firstName || "",
    lastName: userInfo.lastName || "",
    phoneNumber: userInfo.phoneNumber || "",
    email: userInfo.email || "",
  };
  const [formData, setFormData] = useState(savedUserInfo);

  useEffect(() => {
    if (actionData?.saveSuccessTimestamp) {
      setHideSuccessfullySavedMessage(false);
    }
  }, [actionData?.saveSuccessTimestamp]);

  useEffect(() => {
    if (actionData?.saveSuccessTimestamp && !hideSuccessfullySavedMessage) {
      const interval = setInterval(() => {
        setHideSuccessfullySavedMessage(true);
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [actionData?.saveSuccessTimestamp, hideSuccessfullySavedMessage]);

  const cancelDeletionRequestMutation = useMutation({
    mutationFn: () => post("/user/undo-request-deletion", undefined),
    onSuccess: () => {
      queryClient.invalidateQueries(["/user"]);
      window.location.reload();
    },
  });

  const hasChanges = !isEqual(savedUserInfo, formData);

  const submitForm = (shouldGoBackOnSuccess?: boolean) => () =>
    submit(
      {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        ...(shouldGoBackOnSuccess ? { shouldGoBackOnSuccess: "true" } : {}),
      },
      { method: "post" }
    );

  return (
    <div className="flex h-full flex-col">
      <div className="sticky top-0 z-10 flex items-center justify-between bg-white pb-[5px] pt-[calc(20px_+_env(safe-area-inset-top))] pr-4 shadow">
        <IconButton
          size="small"
          style="iconOnly"
          svg={ChevronLeft}
          onClick={() => {
            if (hasChanges) {
              setOpenModal(true);
            } else {
              navigate(-1);
            }
          }}
        />
        <div className="text-sub2">ACCOUNT DETAILS</div>
        <div className={cx(!hasChanges && "invisible")}>
          <TextButton onClick={submitForm()}>
            <div className="text-bold1">Save</div>
          </TextButton>
        </div>
      </div>

      <div
        className={cx(
          "absolute top-[76px] left-2 right-2 z-10 rounded bg-black py-4 pl-6 text-body2 text-white",
          (!actionData?.saveSuccessTimestamp || hideSuccessfullySavedMessage) &&
            "hidden"
        )}
      >
        Your changes were saved
      </div>
      {!!userInfo.deletionRequestDate && Capacitor.isNativePlatform() && (
        <div className="mx-6 mt-6 flex flex-col items-center space-y-4">
          <DeletionRequestedBanner
            deletionRequestDate={userInfo.deletionRequestDate}
          />
          <Button
            style="white"
            disabled={cancelDeletionRequestMutation.isLoading}
            onClick={async () => {
              cancelDeletionRequestMutation.mutate();
            }}
          >
            <div className="text-bold2">Cancel request</div>
          </Button>
        </div>
      )}
      <div className="flex-grow">
        <div className="mt-6 mb-4 text-center text-body2 text-secondary">
          Click on a field to update your details
        </div>

        <Form className="mx-6 flex flex-col gap-4">
          <TextField
            label="First name"
            value={formData.firstName}
            onChange={(value) => setFormData({ ...formData, firstName: value })}
            disabled={
              !!userInfo.deletionRequestDate && Capacitor.isNativePlatform()
            }
          />
          <TextField
            label="Last name"
            value={formData.lastName}
            onChange={(value) => setFormData({ ...formData, lastName: value })}
            disabled={
              !!userInfo.deletionRequestDate && Capacitor.isNativePlatform()
            }
          />
          <TextField
            type="tel"
            label="Phone number"
            disabled
            value={formData.phoneNumber}
          />
          <TextField
            type="email"
            label="Email"
            value={formData.email}
            onChange={(value) => setFormData({ ...formData, email: value })}
            disabled={
              !!userInfo.deletionRequestDate && Capacitor.isNativePlatform()
            }
          />
          <TextField
            label="Birthday"
            disabled
            value={dayjs(userInfo.dateOfBirth).format("MM/DD/YYYY")}
          />
        </Form>
      </div>

      {!userInfo.deletionRequestDate && Capacitor.isNativePlatform() && (
        <div className="flex w-full items-end justify-center p-4">
          <Button
            style="white"
            onClick={() => {
              navigate("/account/settings/delete-my-account");
            }}
          >
            <div className="text-body1">Delete my account</div>
          </Button>
        </div>
      )}

      <ActionModal
        size="small"
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        onFirstButtonClick={submitForm(true)}
        onSecondButtonClick={() => {
          navigate(-1);
        }}
        firstButtonLabel="Save changes"
        secondButtonLabel="Discard changes"
        title="Unsaved changes"
        description={
          <div className="px-2">
            Do you want to save your changes? Clicking discard will revert all
            of your changes.
          </div>
        }
      />
    </div>
  );
};
