import cx from "classnames";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  ActionFunction,
  json,
  LoaderFunction,
  redirect,
  useActionData,
  useLoaderData,
  useNavigate,
  useRevalidator,
  useSearchParams,
  useSubmit,
} from "react-router-dom";

import { get, post } from "api";
import { DiscountType, RewardStatus } from "api/types";
import { Button } from "atoms/button/Button";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { ReactComponent as RewardOpen } from "atoms/icon/icons/ic_open_reward_small_ol.svg";
import { ReactComponent as Info } from "atoms/icon/icons/info.svg";
import { ReactComponent as QrCode } from "atoms/icon/icons/qr_code.svg";
import { ReactComponent as RepeatCash } from "atoms/icon/icons/RepeatCash.svg";
import { ReactComponent as Rewards } from "atoms/icon/icons/rewards_nav.svg";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { InclusionsExclusionsList } from "molecules/inclusions-exclusions-list/InclusionsExclusionsList";
import TopNav from "molecules/navbars/TopNav";
import { queryClient } from "pages/Root";
import { formatCurrency } from "toolbox/Money";
import { waitObj } from "toolbox/Promise";

import { ConfirmBankModal } from "../ConfirmBankModal";
import { InStoreRedeemedModal } from "../InStoreRedeemedModal";
import { MultipleInclusionsModal } from "../MultipleInclusionsModal";
import { OnlineOffMultipleItemsRedeemedModal } from "../OnlineOffMultipleItemsRedeemedModal";
import { OnlineOffNextPurchaseRedeemedModal } from "../OnlineOffNextPurchaseRedeemedModal";
import { OnlineOffSpecificItemRedeemedModal } from "../OnlineOffSpecificItemRedeemedModal";
import { SuccessfullyBankedModal } from "../SuccessfullyBankedModal";

import { ReactComponent as BackgroundPattern } from "./background.svg";

const RewardBanner = ({
  reward: { status, title },
}: Pick<LoaderData, "reward">) => {
  return (
    <div className="relative flex flex-col items-center bg-brand-color bg-[image:linear-gradient(82deg,transparent,rgb(255,255,255,.2)_34%,rgb(255,255,255,.2)_46%,transparent)] px-4 py-11 text-center text-white">
      <BackgroundPattern className="absolute inset-0 w-full opacity-15" />
      <Icon
        color="white"
        svg={
          status === RewardStatus.Available
            ? Rewards
            : status === RewardStatus.CashedIn
            ? RepeatCash
            : RewardOpen
        }
        size="xsmall"
      />
      <span className="mt-4 text-sub2 uppercase">
        Reward{" "}
        {status === RewardStatus.Available
          ? "Unlocked"
          : status === RewardStatus.Redeemed
          ? "Redeemed"
          : "Banked"}
        !
      </span>
      <h1 className="mt-6 font-serif text-h1">{title}</h1>
    </div>
  );
};

type ActionResult = {
  redeemSuccess: boolean;
  bankSuccess: boolean;
};

const load = async (rewardId: string, request: Request) => {
  const comingFromScan = new URL(request.url).searchParams.get(
    "comingFromScan"
  );
  const reward = queryClient.fetchQuery(["/rewards", { rewardId }], () =>
    get("/rewards/{rewardId}", { rewardId })
  );
  const inclusions = queryClient.fetchQuery(
    ["/rewards/inclusions", { rewardId }],
    () => get("/rewards/{rewardId}/inclusions", { rewardId })
  );

  const userInfo = queryClient.fetchQuery(["/user"], () => get("/user"), {
    staleTime: 1000 * 60 * 2,
  });

  const authClaims = queryClient.fetchQuery(["/auth/claims"], () =>
    get("/auth/claims")
  );

  const isAdminImpersonation = authClaims.then(
    (response) => !!response?.claims.adminUserId
  );

  return waitObj({
    reward,
    repeatCash: userInfo.then((u) => u.repeatCashBalance),
    comingFromScan: comingFromScan == "true" ? true : false,
    isAdminImpersonation,
    inclusions,
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const rewardsLoader: LoaderFunction = async ({
  params: { rewardId },
  request,
}) => {
  if (!rewardId) return redirect("..");
  return json<LoaderData>(await load(rewardId, request));
};

export const rewardsAction: ActionFunction = async ({ params, request }) => {
  if (!params.rewardId) throw Error("Missing route data");
  const formData = await request.formData();
  const shouldRedeem = formData.get("type") === "redeem";
  // const shouldBank = formData.get("type") === "bank";

  try {
    if (shouldRedeem) {
      const reward = await post("/rewards/redeem", { $data: params.rewardId });
      if (reward.isInStoreRedeemable && reward.discount?.appliesTo === 1) {
        queryClient.invalidateQueries({
          queryKey: ["/orders/current/details"],
        });
      }
      return json({ redeemSuccess: true });
    }

    const cashReward = await post("/rewards/cash", { $data: params.rewardId });
    if (cashReward) {
      queryClient.invalidateQueries({
        queryKey: ["/user"],
      });
    }
    return json({ bankSuccess: true });
  } catch (error: any) {
    //TODO: Refactor to prevent doing string comparison
    if (
      error.response.status === 400 &&
      error.response.data === "itemsInCartNotIncludedInDiscount"
    ) {
      return json({ redeemSuccess: false });
    }
  }
};

const RewardsPage = () => {
  const navigate = useNavigate();
  const {
    reward,
    repeatCash,
    comingFromScan,
    isAdminImpersonation,
    inclusions,
  } = useLoaderData() as LoaderData;
  const data = useActionData() as ActionResult | undefined;
  const submit = useSubmit();
  const [showSuccessfullRedemptionModal, setShowSuccessfullRedemptionModal] =
    useState(false);
  const [showBankRewardModal, setShowBankRewardModal] = useState<
    "confirmBank" | "successfullyBanked" | false
  >(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const rewardHasMultipleInclusions =
    reward.discount?.inclusions && reward.discount?.inclusions?.length > 1;
  const [showInclusionsModal, setShowInclusionsModal] = useState(false);
  const revalidator = useRevalidator();
  const inclusionsListHasData =
    inclusions &&
    (inclusions.memberships.length > 0 ||
      inclusions.packages.length > 0 ||
      inclusions.treatments.length > 0);

  useEffect(() => {
    setShowSuccessfullRedemptionModal(
      data?.redeemSuccess || comingFromScan ? true : false
    );
    if (
      data &&
      !data?.redeemSuccess &&
      rewardHasMultipleInclusions &&
      !showBankRewardModal
    ) {
      setShowInclusionsModal(true);
    }
  }, [data?.redeemSuccess, comingFromScan, rewardHasMultipleInclusions, data]);

  useEffect(() => {
    setShowBankRewardModal(
      data?.bankSuccess && repeatCash > 0 ? "successfullyBanked" : false
    );
  }, [data?.bankSuccess, repeatCash]);

  const inStoreRedeemable = reward.isInStoreRedeemable;
  const onlineOffSpecificItemRedeemable =
    !reward.isInStoreRedeemable && reward.discount?.appliesTo === 1;
  const onlineOffMultipleItemsRedeemable =
    !reward.isInStoreRedeemable &&
    reward.discount?.inclusions &&
    reward.discount?.inclusions.length > 1;
  const onlineOffNextPurchaseRedeemable =
    !reward.isInStoreRedeemable && reward.discount?.appliesTo === 0;

  const closeSuccessfullRedemptionModal = () => {
    setShowSuccessfullRedemptionModal(false);
    if (searchParams.has("comingFromScan")) {
      searchParams.delete("comingFromScan");
      setSearchParams(searchParams);
    }
  };

  const closeBankRewardModal = () => {
    setShowBankRewardModal(false);
  };

  const handleAdminRedeemInStoreReward = async () => {
    await post("/rewards/redeem-in-store", {
      rewardId: reward.rewardId,
    });
    // Revalidate the reward details query to update the reward status
    revalidator.revalidate();
  };

  const rewardInclusionsListTitle =
    !inStoreRedeemable &&
    reward.discount?.type == DiscountType.Percentage &&
    reward.discount?.percentOff !== 1
      ? `Apply this reward in cart to receive ${(
          reward.discount.percentOff * 100
        ).toFixed(0)}% off of the following services:`
      : reward.discount?.type == DiscountType.Cash
      ? `Apply this reward in cart to receive $${
          reward.discount.amountOff / 100
        } off when you purchase at least one of the following services in-app:`
      : `Apply this reward in cart to receive 1 of the following services for free:`;

  const rewardInclusionsDisclaimer =
    !inStoreRedeemable && reward.discount?.type == DiscountType.Cash
      ? `$${
          reward.discount.amountOff / 100
        } saving applies to your total purchase amount, not per individual item.`
      : reward.discount?.type == DiscountType.Percentage &&
        reward.discount.percentOff === 1
      ? "Free service discount will only apply to the most expensive valid item in your cart."
      : "";

  return (
    <div className="relative flex min-h-full flex-col">
      <TopNav
        startIconSVG={ChevronLeft}
        onStartIconClick={() => navigate("/rewards")}
      >
        <div className="flex max-h-[32px] items-center">
          <h1 className="text-xl">Rewards</h1>
        </div>
      </TopNav>
      <RewardBanner reward={reward} />
      {reward.status === RewardStatus.CashedIn && (
        <div className="mx-4 mt-4 flex cursor-pointer items-center justify-center rounded bg-two py-5 text-center">
          <Icon svg={RepeatCash} size="small" />
          <div className="ml-2 text-title font-normal text-primary">
            Banked for{" "}
          </div>
          <div className="mx-1 text-title text-primary">
            {reward.valueIfBanked
              ? formatCurrency(reward.valueIfBanked)
              : "N/A"}
          </div>
          <div className="text-title font-normal text-primary">cash</div>
        </div>
      )}
      {reward.status === RewardStatus.Redeemed && !inStoreRedeemable && (
        <div className="mx-4 mt-4 flex items-center justify-center rounded bg-two py-5 px-4">
          <div>
            <Icon svg={RewardOpen} size="small" color="brandColor" />
          </div>
          <div className="ml-4 text-bold1">
            Congratulations! This reward has already been redeemed
          </div>
        </div>
      )}
      {(reward.status === RewardStatus.Redeemed ||
        reward.status === RewardStatus.CashedIn) && (
        <>
          <div className="py-4 text-center text-body1 text-primary">
            {reward.status === RewardStatus.Redeemed ? "Redeemed" : "Banked"}:{" "}
            {dayjs(reward.redeemedDateTime).format("ddd, MM/DD/YYYY - hh:mma")}
          </div>

          {reward.status === RewardStatus.Redeemed &&
            reward.rewardPromoCode &&
            inStoreRedeemable && (
              <div className="mx-4 flex cursor-pointer items-center justify-center rounded bg-two p-4 text-center">
                <div className="flex items-center">
                  <div className="flex items-center">
                    <Icon svg={Info} size="small" color="secondaryText" />
                  </div>
                  <div className="mr-6 pl-2 text-left text-body1 text-primary">
                    Please show this page in-office to a staff member to book
                    your reward
                  </div>
                </div>
              </div>
            )}
        </>
      )}
      {inclusionsListHasData &&
        reward.status === RewardStatus.Available &&
        !inStoreRedeemable && (
          <InclusionsExclusionsList
            title={rewardInclusionsListTitle}
            disclaimer={rewardInclusionsDisclaimer}
            inclusions={inclusions}
          />
        )}
      {reward.status === RewardStatus.Redeemed && inStoreRedeemable && (
        <div className="mx-4">
          <div className="mb-4 mt-6 text-center text-body1 text-secondary">
            Reward successfully redeemed in store.
          </div>
          <div className="mb-5 text-center text-body1 text-secondary">
            In store code: {reward.rewardPromoCode}
          </div>
        </div>
      )}
      {reward.status === RewardStatus.CashedIn && (
        <div className="mx-8 mb-5 text-center text-body1 text-secondary">
          Banked RepeatCash can be put towards in-app purchases. Explore shop
          now to spend this balance and save on treatments.
        </div>
      )}
      {reward.status === RewardStatus.Available && inStoreRedeemable && (
        <div className="mx-4 mt-6 flex items-center rounded bg-two px-3 py-2.5">
          <Icon svg={Info} color="secondaryText" size="xsmall" />
          <div className="ml-3 text-body2 text-primary">
            Reward applies to in-office purchases only
          </div>
        </div>
      )}
      {reward.status === RewardStatus.Available &&
        reward.termsAndConditions && (
          <p
            className={cx(
              "px-4 text-center text-body2 italic text-secondary",
              !rewardInclusionsDisclaimer || !inclusionsListHasData
                ? "py-6"
                : "pb-6"
            )}
          >
            {reward.termsAndConditions}
          </p>
        )}

      {reward.status === RewardStatus.Redeemed && !inStoreRedeemable && (
        <p className="px-4 pb-6 text-center text-body2 italic text-secondary">
          Reward can only be used once
        </p>
      )}
      <div
        className={cx(
          "flex flex-col items-center gap-4 px-4",
          reward.status === RewardStatus.Available && !reward.termsAndConditions
            ? "mt-6"
            : ""
        )}
      >
        {reward.status === RewardStatus.Available && (
          <>
            {inStoreRedeemable && isAdminImpersonation && (
              <Button
                fullWidth
                size="large"
                onClick={handleAdminRedeemInStoreReward}
                data-testid="button-redeem-reward"
              >
                Redeem reward now
              </Button>
            )}

            {(!inStoreRedeemable ||
              (!inStoreRedeemable && isAdminImpersonation)) && (
              <Button
                fullWidth
                size="large"
                onClick={() => {
                  const formData = new FormData();
                  formData.append("type", "redeem");
                  submit(formData, {
                    method: "post",
                  });
                }}
                data-testid="button-redeem-reward"
              >
                Redeem reward now
              </Button>
            )}

            {inStoreRedeemable && !isAdminImpersonation && (
              <Button
                fullWidth
                size="large"
                onClick={() => {
                  navigate(`/scan-redeem-reward-in-location`, {
                    state: {
                      rewardId: reward.rewardId,
                    },
                  });
                }}
                data-testid="button-redeem-reward"
              >
                <Icon svg={QrCode} size="xsmall" color="white" />
                <span className="ml-1">Scan in office to redeem</span>
              </Button>
            )}

            {reward.repeatCashEnabled && (
              <Button
                fullWidth
                size="large"
                style="secondary"
                onClick={() => setShowBankRewardModal("confirmBank")}
              >
                <Icon svg={RepeatCash} size="xsmall" />
                <span className="ml-1">
                  Bank for{" "}
                  {reward.valueIfBanked
                    ? formatCurrency(reward.valueIfBanked)
                    : "N/A"}{" "}
                  in RepeatCash
                </span>
              </Button>
            )}
          </>
        )}
      </div>
      <div className="mt-auto">
        <RepeatMDFooter />
      </div>
      {inStoreRedeemable && showSuccessfullRedemptionModal && (
        <InStoreRedeemedModal onClose={closeSuccessfullRedemptionModal} />
      )}
      {onlineOffNextPurchaseRedeemable && showSuccessfullRedemptionModal && (
        <OnlineOffNextPurchaseRedeemedModal
          onClose={closeSuccessfullRedemptionModal}
        />
      )}
      {onlineOffSpecificItemRedeemable && showSuccessfullRedemptionModal && (
        <OnlineOffSpecificItemRedeemedModal
          onClose={closeSuccessfullRedemptionModal}
        />
      )}
      {onlineOffMultipleItemsRedeemable && showSuccessfullRedemptionModal && (
        <OnlineOffMultipleItemsRedeemedModal
          onClose={closeSuccessfullRedemptionModal}
        />
      )}
      {rewardHasMultipleInclusions && showInclusionsModal && (
        <MultipleInclusionsModal
          onClose={() => {
            setShowInclusionsModal(false);
          }}
          onButtonClick={() => {
            navigate("/shop");
          }}
        />
      )}
      {showBankRewardModal === "confirmBank" && (
        <ConfirmBankModal
          valueIfBanked={reward.valueIfBanked}
          onBankReward={() => {
            const formData = new FormData();
            formData.append("type", "bank");

            submit(formData, { method: "post" });
          }}
          onClose={closeBankRewardModal}
        />
      )}
      {showBankRewardModal === "successfullyBanked" && (
        <SuccessfullyBankedModal
          repeatCash={repeatCash}
          onClose={closeBankRewardModal}
        />
      )}
    </div>
  );
};

export default RewardsPage;
