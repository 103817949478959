import { ReactComponent as RepeatCash } from "atoms/icon/icons/RepeatCash.svg";
import { ActionModal } from "molecules/modal-window/action-modal/ActionModal";
import { formatCurrency } from "toolbox/Money";

interface ConfirmBankModalProps {
  valueIfBanked?: number | null;
  onBankReward: () => void;
  onClose: () => void;
}

export const ConfirmBankModal = ({
  valueIfBanked,
  onBankReward,
  onClose,
}: ConfirmBankModalProps) => {
  return (
    <ActionModal
      size="medium"
      open={true}
      headerIconSvg={RepeatCash}
      onClose={onClose}
      onFirstButtonClick={onBankReward}
      firstButtonLabel="Yes, bank reward"
      onSecondButtonClick={onClose}
      secondButtonLabel="Not now"
      title="Bank your reward for RepeatCash?"
      description={
        <div className="px-2">
          You will exchange your reward for{" "}
          {valueIfBanked ? formatCurrency(valueIfBanked) : "N/A"} worth of
          RepeatCash which can be put towards in-app purchases only.
        </div>
      }
    />
  );
};
