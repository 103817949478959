import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { get } from "api";
import { Models } from "api/types";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { ReactComponent as SearchError } from "atoms/icon/icons/ic_search_error_XL_ol.svg";
import { SearchField } from "atoms/search-field/SearchField";
import useHistory from "hooks/useHistory";
import { ProductCardLarge } from "molecules/cards/product-card-large/ProductCardLarge";
import TopNav from "molecules/navbars/TopNav";
import { notNull } from "toolbox/Guards";

type DidntFindProps = {
  shopData?: Models<"ShopConfigModel">;
};

const DidntFindText = ({ shopData }: DidntFindProps) => {
  return (
    <div className="mt-2 text-center text-body2 text-secondary">
      Didn’t find what you were looking for?
      <br />
      Check your spelling or try{" "}
      <span className="font-bold text-brand-color">
        {shopData?.concerns && shopData.concerns.length > 0 ? (
          <Link to="/shop/concerns">Browse by concern</Link>
        ) : (
          <Link to="/shop">browsing Shop</Link>
        )}
      </span>
    </div>
  );
};

const SearchPage = () => {
  const { routeBackNumber } = useHistory();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [term, setTerm] = useState(
    searchParams.get("term")?.toLowerCase() ?? ""
  );
  const { data: listings } = useQuery({
    queryKey: ["/listings"],
    queryFn: () => get("/listings"),
    staleTime: 1000 * 60,
  });
  const { data: shopData } = useQuery({
    queryKey: ["/shop"],
    queryFn: () => get("/shop"),
  });
  const filterText = searchParams.get("term")?.toLowerCase() ?? "";
  const topResults =
    listings?.filter((li) => li.title.toLowerCase().includes(filterText)) || [];
  const moreResults =
    listings?.filter(
      (li) =>
        !topResults?.includes(li) &&
        li.description.toLowerCase().includes(filterText)
    ) || [];
  const resultsLength = (topResults.length || 0) + (moreResults.length || 0);
  useEffect(() => {
    if (filterText === term) return;
    if (term.length === 0) {
      setSearchParams({ term: "" }, { replace: true });
    }
    if (term.length > 2) {
      const update = setTimeout(
        () => setSearchParams({ term }, { replace: true }),
        1000
      );
      return () => clearTimeout(update);
    }
  }, [setSearchParams, filterText, term]);
  return (
    <>
      <TopNav
        startIconSVG={ChevronLeft}
        onStartIconClick={() => {
          navigate(routeBackNumber);
        }}
        showHeaderItems={false}
      >
        <div className="flex items-center">
          <SearchField
            autoFocus
            value={term}
            onChange={setTerm}
            onIconClick={(side) => {
              if (side === "right") {
                setTerm("");
              }
            }}
            placeholder="Search shop"
          />
        </div>
      </TopNav>
      <div className="flex h-full flex-col bg-background-two p-5">
        {!listings && filterText && (
          <div className="flex h-full items-center justify-center">
            <TailSpin
              height="36"
              width="36"
              radius="1"
              color="rgb(var(--repeatmd-brand-color))"
            />
          </div>
        )}
        {filterText && topResults && shopData && resultsLength > 0 && (
          <>
            <div className="mb-[30px] text-body2 text-secondary">
              {resultsLength} results found
            </div>
            {topResults.length > 0 && (
              <div className="mb-6">
                <div className="mb-6 text-center text-sub2 text-secondary">
                  TOP RESULTS
                </div>
                <div className="flex flex-col gap-4">
                  {topResults?.map((listing) => {
                    return (
                      <ProductCardLarge
                        key={listing.id}
                        title={listing.title}
                        description={listing.description}
                        imageUrl={listing.imageUrl}
                        type={listing.type}
                        tag={""}
                        concerns={shopData.concerns
                          ?.filter((concern) =>
                            listing.concernIds.includes(concern.id)
                          )
                          .map((concern) => concern.name)
                          .filter(notNull)}
                        price={listing.baseCost}
                        memberPrice={listing.membershipCost}
                        onClick={() => navigate(`/pdp/package/${listing.id}`)}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {moreResults.length > 0 && (
              <div className="mb-8">
                <div className="mb-6 text-center text-sub2 text-secondary">
                  {topResults.length > 0 ? "MORE RESULTS" : "SEARCH RESULTS"}
                </div>
                <div className="flex flex-col gap-4">
                  {moreResults.map((listing) => {
                    return (
                      <ProductCardLarge
                        key={listing.id}
                        title={listing.title}
                        description={listing.description}
                        imageUrl={listing.imageUrl}
                        type={listing.type}
                        tag={""}
                        concerns={shopData.concerns
                          ?.filter((concern) =>
                            listing.concernIds.includes(concern.id)
                          )
                          .map((concern) => concern.name)
                          .filter(notNull)}
                        price={listing.baseCost}
                        memberPrice={listing.membershipCost}
                        onClick={() => navigate(`/pdp/package/${listing.id}`)}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            <DidntFindText shopData={shopData} />
          </>
        )}
        {listings && resultsLength === 0 && (
          <div className="flex flex-col items-center pt-12">
            <div className="opacity-50">
              <Icon svg={SearchError} size="large" color="darkGrey" />
            </div>
            <div className="mt-1 text-body1 text-primary">No results found</div>
            <DidntFindText shopData={shopData} />
          </div>
        )}
      </div>
    </>
  );
};

export default SearchPage;
