import { useQueries } from "@tanstack/react-query";
import dayjs from "dayjs";
import {
  ActionFunction,
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get } from "api";
import { IconButton } from "atoms/icon-button/IconButton";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Affirm } from "atoms/icon/icons/affirm.svg";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { ReactComponent as RepeatMD } from "atoms/icon/icons/RepeatCash.svg";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { queryClient } from "pages/Root";
import { useFeatureFlag } from "services/Flags";
import { formatCurrency } from "toolbox/Money";
import { waitObj } from "toolbox/Promise";

const load = async (shopDisplayOrderId: string) => {
  const orderHistoryDetails = queryClient.fetchQuery(
    ["/user/orders/{shopDisplayOrderId}", { shopDisplayOrderId }],
    () => get("/user/orders/{shopDisplayOrderId}", { shopDisplayOrderId })
  );

  return waitObj({
    orderHistoryDetails: orderHistoryDetails,
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const orderHistoryDetailsLoader: LoaderFunction = async ({
  params: { shopDisplayOrderId },
}) => {
  if (!shopDisplayOrderId) return redirect("..");
  return json<LoaderData>(await load(shopDisplayOrderId));
};

export const orderHistoryDetailsAction: ActionFunction = async () => {
  return true;
};

export const OrderHistoryDetailsPage = () => {
  const navigate = useNavigate();
  const { orderHistoryDetails } = useLoaderData() as LoaderData;
  const isPaidWithRepeatCash =
    orderHistoryDetails.orderSummary.estimatedTotal == 0 &&
    orderHistoryDetails.orderSummary.repeatCashApplied;
  const isUnitedDerm = useFeatureFlag("unitedDerm");

  const memberships = useQueries({
    queries: orderHistoryDetails.membershipLineItems.map((lineItem) => {
      return {
        queryFn: () =>
          get("/memberships/{membershipId}", {
            membershipId: lineItem.membershipId,
          }),
        queryKey: [
          "/memberships/{membershipId}",
          {
            membershipId: lineItem.membershipId,
          },
        ],
      };
    }),
  });

  const packages = useQueries({
    queries: orderHistoryDetails.packageLineItems.map((lineItem) => {
      return {
        queryFn: () =>
          get("/packages/{packageId}", {
            packageId: lineItem.packageId,
          }),
        queryKey: [
          "/packages/{packageId}",
          {
            packageId: lineItem.packageId,
          },
        ],
      };
    }),
  });

  return (
    <div className="relative h-full bg-one">
      <div className="sticky top-0 z-10 flex items-center justify-between bg-white pb-2 pt-[calc(8px_+_env(safe-area-inset-top))] pr-1 shadow">
        <IconButton
          size="small"
          style="iconOnly"
          svg={ChevronLeft}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className="text-sub2">ORDER DETAILS</div>
        <div className="invisible w-11"></div>
      </div>

      <div className="p-5">
        <div className="flex flex-col gap-5 rounded-lg bg-white py-6 px-7 shadow-subtle">
          <div className="flex justify-between">
            <div className="pb-2 text-bold1 capitalize text-primary">
              Order: {orderHistoryDetails.orderNumber}
            </div>
            <div className="text-body1">
              {dayjs(orderHistoryDetails.date).format("MM/DD/YY")}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-1 justify-start text-bold3">PRODUCT</div>
            <div className="flex flex-1 justify-center text-bold3">QTY</div>
            <div className="flex flex-1 justify-end text-bold3">PRICE</div>
          </div>
          <div className="flex flex-col gap-4 border-y-[1px] border-light-grey py-4">
            {orderHistoryDetails.packageLineItems.map((lineItem) => {
              const packageModel = packages.find(
                (packageModel) => packageModel.data?.id === lineItem.packageId
              );
              if (!packageModel) return null;
              return (
                <div key={lineItem.packageId} className="flex justify-between">
                  <div className="flex flex-1 justify-start text-body2">
                    {packageModel.data?.name}
                  </div>
                  <div className="flex flex-1 justify-center text-body2">
                    {lineItem.quantity || 1}
                  </div>
                  <div className="flex flex-1 justify-end text-body2">
                    {formatCurrency(lineItem.cost, 2)}
                  </div>
                </div>
              );
            })}
            {orderHistoryDetails.membershipLineItems.map((lineItem) => {
              const membership = memberships.find(
                (membership) => membership.data?.id === lineItem.membershipId
              );
              if (!membership) return null;
              return (
                <div
                  key={lineItem.membershipId}
                  className="flex justify-between"
                >
                  <div className="flex flex-1 justify-start text-body2">
                    {membership.data?.name}
                  </div>
                  <div className="flex flex-1 justify-center text-body2">1</div>
                  <div className="flex flex-1 justify-end text-body2">
                    {formatCurrency(lineItem.cost, 2)}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex justify-between">
              <div className="text-body2">Subtotal</div>
              <div className="text-body2">
                {formatCurrency(orderHistoryDetails.orderSummary.subTotal, 2)}
              </div>
            </div>
            {!!orderHistoryDetails.orderSummary.promoCodeDiscount && (
              <div className="flex justify-between">
                <div className="text-body2">
                  Discounts ({orderHistoryDetails.promoCode}):
                </div>
                <div className="text-body2">
                  {formatCurrency(
                    orderHistoryDetails.orderSummary.promoCodeDiscount,
                    2
                  )}
                </div>
              </div>
            )}
            {!!orderHistoryDetails.orderSummary.membershipDiscount && (
              <div className="flex justify-between">
                <div className="text-body2">Membership Discounts:</div>
                <div className="text-body2">
                  {formatCurrency(
                    orderHistoryDetails.orderSummary.membershipDiscount,
                    2
                  )}
                </div>
              </div>
            )}
            <div className="flex justify-between">
              <div className="text-body2">
                {!isUnitedDerm ? "Convenience Fee" : "Credit card fee"}:
              </div>
              <div className="text-body2">
                {formatCurrency(orderHistoryDetails.orderSummary.serviceFee, 2)}
              </div>
            </div>
            {orderHistoryDetails.orderSummary.repeatCashApplied != 0 && (
              <div className="flex justify-between">
                <div className="text-body2">Discounts (Repeat Cash):</div>
                <div className="text-body2">
                  {formatCurrency(
                    orderHistoryDetails.orderSummary.repeatCashApplied,
                    2
                  )}
                </div>
              </div>
            )}

            {orderHistoryDetails.orderSummary.salesTax > 0 && (
              <div className="flex justify-between">
                <div className="text-body2">Tax:</div>
                <div className="text-body2">
                  {formatCurrency(
                    orderHistoryDetails.orderSummary.salesTax ?? 0,
                    2
                  )}
                </div>
              </div>
            )}

            <div className="flex justify-between">
              <div className="text-bold2">Order total:</div>
              <div className="text-bold2">
                {formatCurrency(
                  orderHistoryDetails.orderSummary.estimatedTotal,
                  2
                )}
              </div>
            </div>
          </div>
          {orderHistoryDetails.paymentMethod?.nonFinancedPayment?.name ==
            "affirm" && (
            <div className="flex flex-col gap-2 border-t-[1px] pt-3">
              <div className="pt-3 text-bold2">Payment method</div>
              <div>
                <Icon svg={Affirm} />
              </div>
              <div className="-mt-2 text-body3 text-secondary">
                This purchase was <br />
                financed through affirm
              </div>
            </div>
          )}
          {isPaidWithRepeatCash && (
            <div className="flex flex-col gap-2 border-t-[1px] pt-3">
              <div className="pt-3 text-bold2">Payment method</div>
              <div className="flex items-center gap-1">
                <Icon svg={RepeatMD} />
                <div className="text-bold2">RepeatCash</div>
              </div>
              <div className="text-body3 text-secondary">
                This purchase was completed <br />
                using your RepeatCash balance
              </div>
            </div>
          )}
          {orderHistoryDetails.paymentMethod?.financedPayment?.last4 && (
            <div className="flex flex-col gap-2 border-t-[1px] pt-3">
              <div className="pt-3 text-bold2">Payment method</div>
              <div className="text-body2">
                <div className="capitalize">
                  {orderHistoryDetails.paymentMethod?.financedPayment?.cardType}
                </div>
                <div>
                  ending in{" "}
                  {orderHistoryDetails.paymentMethod?.financedPayment?.last4}
                </div>
              </div>
              <div className="text-body3 text-secondary">
                This charge will appear as <br />
                RepeatMD, Inc. on your <br />
                billing statement.
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 mx-auto p-4 pb-9 sm:w-[390px]">
        <RepeatMDFooter />
      </div>
    </div>
  );
};
