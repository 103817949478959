import { Models } from "api/types";
import { Button } from "atoms/button/Button";
import { IconButton } from "atoms/icon-button/IconButton";
import { ReactComponent as Close } from "atoms/icon/icons/close.svg";
import { Modal } from "atoms/modal/Modal";
import { TextButton } from "atoms/text-button/TextButton";
import PaymentMethodButton from "pages/root/cart/PaymentMethodButton";

interface ModalSelectPaymentMethodProps {
  open?: boolean;
  onClose: () => void;
  title: string;
  items?: {
    id: string;
    name: string;
    cardType: Models<"CardBrand">;
  }[];
  currentId: string | null | undefined;
  onSelect: (value: string) => void;
  onEdit: (value: string) => void;
}

export const ModalSelectPaymentMethodList = (
  props: ModalSelectPaymentMethodProps
) => {
  return (
    <Modal
      /* TODO: This code is not currently used but if ever reactivated should
      be refactored to use the ActionModal component */
      open={props.open}
      onClose={props.onClose}
      size="small"
      header={
        <div className="flex w-full items-center justify-between py-3 px-4">
          <div className="content-right">
            <IconButton
              svg={Close}
              size="small"
              style="iconOnly"
              onClick={props.onClose}
            />
          </div>
          <div className="flex font-sans text-sub2 text-primary">
            {props.title}
          </div>
          <div className="">
            <TextButton onClick={props.onClose}>Save</TextButton>
          </div>
        </div>
      }
      footer={
        <>
          <hr className="mt-2 bg-light-grey" />
          <div className="px-4 pb-2 pt-4">
            <Button fullWidth={true} onClick={() => props.onClose()}>
              Save payment method
            </Button>
          </div>
        </>
      }
    >
      <hr className="mt-2 bg-light-grey" />
      <div className="flex flex-col gap-2 p-3 pt-8 font-sans text-body1 text-secondary">
        {props.items?.map((item) => {
          return (
            <PaymentMethodButton
              key={item.id}
              text={item.name || ""}
              cardType={item.cardType}
              id={item.id}
              selected={props.currentId == item.id}
              onSelect={() => {
                props.onSelect(item.id);
              }}
              onEdit={() => {
                props.onEdit(item.id);
              }}
            />
          );
        })}
      </div>
    </Modal>
  );
};
