import cx from "classnames";
import { FC, MouseEventHandler, ReactNode, SVGProps } from "react";

import { Icon } from "../icon/Icon";

type ButtonSize = "xsmall" | "small" | "medium" | "large" | "xlarge";
type ButtonStyle =
  | "primary"
  | "white"
  | "outline"
  | "secondary"
  | "danger"
  | "dangerSolid";

interface ButtonProps {
  /** Label */
  children: ReactNode;
  /** Optionally disable the button. */
  disabled?: boolean;
  /** If proveded, button will take full width of the container. */
  fullWidth?: boolean;
  /** Callback to handle button click events */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** Specify the size. */
  size?: ButtonSize;
  /** Add an icon before the label. Will also 'pill' shape the button and change horizontal padding to 16px. */
  startIconSVG?: FC<SVGProps<SVGSVGElement>>;
  /** Specify the variant. */
  style?: ButtonStyle;
  /** If true, button will submit parent form */
  submit?: boolean;
  /** Specify the testid for current component */
  "data-testid"?: string;
}

/**
 * Atomic component - Button
 */
export const Button = ({
  children,
  disabled,
  fullWidth,
  onClick,
  size = "medium",
  startIconSVG,
  style = "primary",
  submit = false,
  "data-testid": dataTestId,
}: ButtonProps) => {
  const styles = {
    primary:
      "bg-brand-color text-white enabled:active:bg-gradient-to-t enabled:active:from-white/20 enabled:active:to-white/20 enabled:hover:bg-gradient-to-t enabled:hover:from-white/10 enabled:hover:to-white/10",
    white:
      "bg-white text-brand-color enabled:active:bg-white/90 enabled:hover:bg-gradient-to-t enabled:hover:from-black/5 enabled:hover:to-black/5",
    outline:
      "border border-white text-white enabled:active:bg-white/5 enabled:hover:bg-black/5 shadow text-shadow",
    secondary:
      "border border-brand-color text-brand-color enabled:active:opacity-60 enabled:hover:bg-black/2",
    danger:
      "border border-red text-red enabled:active:opacity-60 enabled:hover:bg-black/2",
    dangerSolid:
      "border border-red bg-red text-white enabled:active:opacity-60 enabled:hover:bg-red/90",
  };

  const sizes = {
    xsmall: "h-8 !px-3.5 text-bold2",
    small: "h-10",
    medium: "h-11",
    large: "h-[50px]",
    xlarge: "h-14",
  };

  return (
    <button
      type={submit ? "submit" : "button"}
      className={cx(
        "flex",
        "items-center",
        "justify-center",
        "rounded",
        startIconSVG ? "pl-4 pr-6" : "px-6",
        "text-button",
        "disabled:opacity-20",
        fullWidth ? "w-full" : "w-fit",
        startIconSVG && "rounded-full",
        styles[style],
        sizes[size]
      )}
      disabled={disabled}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {startIconSVG ? (
        <div className="flex items-center justify-center">
          <div className="pr-2">
            <Icon svg={startIconSVG} color="currentColor" />
          </div>
          {children}
        </div>
      ) : (
        children
      )}
    </button>
  );
};
