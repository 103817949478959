import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right.svg";
import { ImagePlaceholder } from "atoms/image-placeholder/ImagePlaceholder";

type NoLogoAvatarProps = {
  platformName: string | null | undefined;
};
const NoLogoAvatar = ({ platformName }: NoLogoAvatarProps) => {
  const nameWithoutTheAsPrefix = platformName?.replace(/^the\s+/i, "");
  const firstLetter = nameWithoutTheAsPrefix?.charAt(0);

  return (
    <div className="mr-4 flex h-[120px] w-[120px] items-center justify-center bg-one text-2xl font-semibold leading-6 text-brand-color">
      {firstLetter}
    </div>
  );
};

type PlatformLocationProps = {
  platform: {
    id: string;
    imageUrl?: string | null;
    name: string | null | undefined;
    isBlocked: boolean;
  };
  onClick: () => void;
};

export const PlatformLocation = ({
  platform,
  onClick,
}: PlatformLocationProps) => {
  return (
    <li
      onClick={onClick}
      className={`flex cursor-pointer items-center overflow-hidden rounded-lg bg-white shadow-subtle ${
        platform.isBlocked ? "cursor-default opacity-50" : ""
      }`}
    >
      <ImagePlaceholder url={platform.imageUrl} title={platform.name} />
      <div className="ml-4 flex flex-1 flex-col">
        <span className="font-serif text-h3">{platform.name}</span>
        {platform.isBlocked && (
          <div className="flex flex-col gap-2.5">
            <span className="text-body2 text-secondary">
              Your account is disabled due to suspicious activity
            </span>
            <div className="flex items-center">
              <a
                href="mailto:support@repeatmd.com"
                className="text-bold2 text-red"
              >
                Contact us
              </a>
              <Icon svg={ChevronRight} size="x3small" color="red" />
            </div>
          </div>
        )}
      </div>
      {!platform.isBlocked && (
        <div className="mx-3 h-fit w-fit">
          <Icon svg={ChevronRight} size="xsmall" color="brandColor" />
        </div>
      )}
    </li>
  );
};
