import { Models } from "api/types";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right.svg";
import { ImagePlaceholder } from "atoms/image-placeholder/ImagePlaceholder";

interface Props {
  platforms: Record<string, Models<"GetMultiPlatformLocationsResult">[]>;
  onItemClick: (id: string, nearestLocationId: string) => void;
}

export const PlatformWithLocationsList = ({
  platforms,
  onItemClick,
}: Props) => {
  if (!Object.keys(platforms).length) {
    return null;
  }

  return (
    <ul className="flex flex-col">
      {Object.keys(platforms).map((platformId) => {
        const platform = platforms[platformId];
        const platformImageUrl = platform[0].platformImageUrl;
        const platformName = platform[0].platformName;

        return (
          <li
            key={platformId}
            onClick={() => onItemClick(platformId, platform[0].id)}
            className="flex cursor-pointer items-center gap-4 rounded-lg bg-white py-2"
          >
            <ImagePlaceholder
              className="rounded"
              url={platformImageUrl}
              title={platformName}
              size="sm"
            />
            <div className="flex-1">
              {platformName && <div className="text-body1">{platformName}</div>}
              <div className="text-body3">
                {platform[0].distance && (
                  <span>
                    {platform[0].distance}mi<span className="mx-1">•</span>
                  </span>
                )}
                {platform[0].platformLocationName && (
                  <span>
                    {platform[0].platformLocationName}
                    <span className="mx-1">-</span>
                  </span>
                )}
                <span className="text-secondary">
                  {platform[0].address1}, {platform[0].postalCode}
                </span>
                {platform.length > 1 &&
                  `+${platform.length - 1} more locations`}
              </div>
            </div>

            <Icon svg={ChevronRight} size="x2small" color="brandColor" />
          </li>
        );
      })}
    </ul>
  );
};
