import { FC, SVGProps } from "react";
import { Link } from "react-router-dom";

import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/ic_chevron_right_small_ol.svg";

export interface SettingsContainerProps {
  children: string;
  to?: string;
  startIconSVG?: FC<SVGProps<SVGSVGElement>>;
}

export const SettingsContainer = ({
  children,
  to,
  startIconSVG: Svg,
}: SettingsContainerProps) => {
  return (
    <div>
      <Link to={to || ""} className="cursor-pointer">
        <button className="flex w-full items-center gap-4 bg-white p-4 text-left text-body1 text-primary">
          {Svg && <Svg className="h-6 w-6 fill-[theme(textColor.secondary)]" />}
          <div className="flex-1">
            {children.charAt(0).toUpperCase() + children.slice(1).toLowerCase()}
          </div>
          <div>
            <Icon svg={ChevronRight} color="brandColor" size="xsmall" />
          </div>
        </button>
      </Link>
    </div>
  );
};
