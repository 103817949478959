import confetti from "canvas-confetti";
import dayjs from "dayjs";
import { ReactNode, useEffect } from "react";
import {
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router";

import { get } from "api";
import { Models } from "api/types";
import { Button } from "atoms/button/Button";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Left } from "atoms/icon/icons/chevron_left.svg";
import { ReactComponent as Right } from "atoms/icon/icons/chevron_right.svg";
import { ReactComponent as Check } from "atoms/icon/icons/ic_added_to_cart_check_ol.svg";
import { ReactComponent as Info } from "atoms/icon/icons/info.svg";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { BadgeTag } from "atoms/tag/BadgeTag";
import { PromoCodeBox } from "molecules/containers/promo-code-box/PromoCodeBox";
import { ActionModal } from "molecules/modal-window/action-modal/ActionModal";
import TopNav from "molecules/navbars/TopNav";
import { queryClient } from "pages/Root";

type LoaderData = {
  promotion: Models<"PromotionModel">;
  claimed: boolean;
  comingFromScan: boolean;
};

export const accountOfferLoader: LoaderFunction = async ({
  params: { offerId },
  request,
}) => {
  if (!offerId) return redirect("/account/offers");
  const searchParams = new URL(request.url).searchParams;
  const claimed = searchParams.has("claimed");
  const comingFromScan = searchParams.has("comingFromScan");

  const promotions = await queryClient.fetchQuery({
    queryKey: ["/promotions"],
    queryFn: () => get("/promotions"),
    staleTime: 1000 * 60 * 2,
  });
  const promotion = promotions.find((p) => p.id === offerId);

  if (!promotion?.redeemedOn) return redirect("/account/offers");

  return json<LoaderData>({ promotion, claimed, comingFromScan });
};

const AccountOfferPage = () => {
  const { promotion, claimed, comingFromScan } = useLoaderData() as LoaderData;
  const navigate = useNavigate();

  const expiry = promotion.expiresOn ? dayjs(promotion.expiresOn) : null;

  useEffect(() => {
    if (claimed) {
      setTimeout(
        () => confetti({ particleCount: 200, spread: 80, origin: { y: 0.75 } }),
        1000
      );
    }
  }, [claimed]);

  const secondButtonProps = !comingFromScan
    ? {
        onSecondButtonClick: () => navigate(""),
        secondButtonLabel: "View offer",
      }
    : {};

  return (
    <div className="flex min-h-full flex-col">
      <TopNav
        startIconSVG={Left}
        onStartIconClick={() => navigate("/account/offers")}
      >
        <h1 className="flex items-center text-xl">Offers</h1>
      </TopNav>
      <div className="relative flex h-full grow flex-col">
        {promotion.imageUrl && (
          <>
            {expiry && (
              <div className="absolute top-3 left-3">
                <BadgeTag color="alert">{`Expires ${expiry.fromNow()}`}</BadgeTag>
              </div>
            )}
            <img
              className="aspect-video w-full object-cover"
              src={promotion.imageUrl}
            />
          </>
        )}
        <div className="flex grow flex-col items-center gap-6 px-4 py-6 text-primary">
          <h1 className="font-serif text-h2">{promotion.title}</h1>
          {!promotion.isInStoreRedeemable && (
            <>
              <PromoCodeBox
                fullWidth
                isUsed={false}
                code={promotion.promoCodeModel.code}
              />
              <div className="flex flex-col gap-4 text-center text-secondary">
                {expiry && (
                  <div className="text-bold1">
                    {`Redeem online by ${expiry?.format("ddd, MM/DD/YY")}`}
                  </div>
                )}
                <div className="text-body1">
                  Offer applies to online purchases only. Apply this code in
                  your cart to access your offer discount.
                </div>
                <p className="text-body2 italic text-secondary">
                  {promotion.disclaimer}
                </p>
                <Button
                  fullWidth
                  style="white"
                  onClick={() => navigate("/shop")}
                >
                  <div className="flex items-center">
                    <div>Explore shop</div>
                    <Icon color="brandColor" svg={Right} size="x2small" />
                  </div>
                </Button>
              </div>
            </>
          )}
          {promotion.isInStoreRedeemable && (
            <>
              <div className="text-center text-body1 text-secondary">
                Claimed{" "}
                {dayjs(promotion.redeemedOn).format("ddd, MM/DD/YYYY - hh:mma")}
              </div>
              <div className="flex cursor-pointer items-center justify-center rounded bg-two p-4 text-center">
                <div className="flex items-center">
                  <div className="flex items-center">
                    <Icon svg={Info} size="small" color="secondaryText" />
                  </div>
                  <div className="mr-6 pl-2 text-left text-body1 text-primary">
                    Please show this page in-office to a staff member to book
                    your offer
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2.5">
                {expiry && (
                  <div className="text-bold1 text-secondary">
                    {`Redeem in-office by ${expiry?.format("ddd, MM/DD/YY")}`}
                  </div>
                )}
                <div className="mb-5 text-center text-body1 text-secondary">
                  In-office code: {promotion.promoCodeModel.code}
                </div>
              </div>
            </>
          )}
        </div>
        <RepeatMDFooter />
      </div>
      {claimed && (
        <ActionModal
          open={true}
          size="medium"
          onClose={() => navigate("")}
          headerIconSvg={Check}
          headerIconColor="brandColor"
          title={`Congratulations! Your offer has been ${
            !comingFromScan ? "applied to cart!" : "claimed!"
          }`}
          description={
            <div className="flex h-full flex-col items-center gap-12 px-2.5 text-center">
              <div className="flex grow flex-col gap-8">
                <div className="text-body1 text-secondary">
                  {!comingFromScan
                    ? "Explore our shop now to take advantage of this offer - it will automatically apply to your cart unless you remove it."
                    : "Click below to see your confirmation and show this to a staff member in the store"}
                </div>
              </div>
            </div>
          }
          firstButtonLabel={!comingFromScan ? "Shop now" : "View confirmation"}
          onFirstButtonClick={() => {
            !comingFromScan ? navigate("/shop") : navigate("");
          }}
          {...secondButtonProps}
        />
      )}
    </div>
  );
};

export default AccountOfferPage;
