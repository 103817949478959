import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Lock } from "atoms/icon/icons/lock.svg";
import { ReactComponent as RepeatMD } from "atoms/icon/icons/RepeatCash.svg";
import { DescriptiveTag } from "atoms/tag/DescriptiveTag";
import { formatCurrency } from "toolbox/Money";

export interface FutureRewardProps {
  id: string;
  visitsToUnlock?: number | null;
  title?: string | null;
  valueIfBanked?: number | null;
  repeatCashEnabled?: boolean | null;
  disclaimer?: string | null;
}

export const FutureReward = (props: FutureRewardProps) => {
  return (
    <div
      className="flex items-center justify-between bg-white px-4 pt-4 pb-3"
      data-testid="div-future-reward"
    >
      <div>
        <div className="pb-2">
          <DescriptiveTag color="secondary" size="small">
            {`${props.visitsToUnlock} MORE VISITS`}
          </DescriptiveTag>
        </div>

        <div className="pb-1 pr-4 font-sans text-dark-grey">
          <div className="text-bold1">{props.title ?? "Reward hidden"}</div>
          {props.disclaimer && (
            <div className="pt-0.5 text-body3 italic leading-[18px] text-dark-grey line-clamp-3">
              {props.disclaimer}
            </div>
          )}
        </div>

        {props.repeatCashEnabled && (
          <div className="flex items-center gap-0.5 font-sans text-body2 text-dark-grey">
            Can convert to
            <Icon svg={RepeatMD} color="darkGrey" size="x2small" />
            {props.valueIfBanked
              ? formatCurrency(props.valueIfBanked)
              : "N/A"}{" "}
            cash
          </div>
        )}
      </div>
      <div>
        <Icon svg={Lock} size="xsmall" color="darkGrey" />
      </div>
    </div>
  );
};
