import { Models } from "api/types";
import { Radio } from "atoms/radio/Radio";

interface AddressSelectorProps {
  addresses: Models<"UserAddressModel">[];
  selected: string;
  onChange: (addressId: string) => void;
}

export const AddressSelector = ({
  addresses,
  selected,
  onChange,
}: AddressSelectorProps) => {
  return (
    <>
      {addresses.map((address) => (
        <div
          className="mb-4 flex items-center rounded border-[1px] border-solid border-light-grey px-3 py-4"
          key={address.id}
        >
          <Radio
            checked={address.id === selected}
            onChange={() => onChange(address.id)}
          />
          <div className="ml-3">
            <div className="mb-1 text-bold2 text-primary">
              {address.firstName} {address.lastName}
            </div>
            <div className="text-body2 text-secondary">
              <div>
                {address.address2
                  ? `${address.address1}, ${address.address2}`
                  : address.address1}
              </div>
              <div>
                {address.city}, {address.state} {address.postalCode}
              </div>
              <div>United States</div>
              <div>Phone: {address.phoneNumber}</div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
