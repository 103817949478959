import { Capacitor } from "@capacitor/core";

import { del, get } from "api";
import { components } from "api/generated";
import { queryClient } from "pages/Root";

import { PROMOTION_MODAL_SEEN_KEY } from "../constants";

export const setPromotionModalSeen = (seen: boolean) => {
  if (seen) {
    window.localStorage.setItem(PROMOTION_MODAL_SEEN_KEY, "true");
  } else {
    window.localStorage.removeItem(PROMOTION_MODAL_SEEN_KEY);
  }
};
export const getPromotionModalSeen = () => {
  return window.localStorage.getItem(PROMOTION_MODAL_SEEN_KEY) === "true";
};

export const removeArchiveItems = async (
  details: components["schemas"]["ShopDisplayOrderResult"]
) => {
  let itemsArchived = 0;

  if (details?.shopDisplayOrderLineItems?.length) {
    const archivedItems = details.shopDisplayOrderLineItems.filter(
      (item) => !item.active
    );
    if (archivedItems.length) {
      itemsArchived = archivedItems.length;
      const results = await Promise.allSettled(
        archivedItems.map(
          async (archiveItem) =>
            await del(
              `/orders/current/lineitems/{shopDisplayOrderLineItemId}`,
              {
                shopDisplayOrderLineItemId: archiveItem.id,
              }
            )
        )
      );

      await queryClient.invalidateQueries({
        queryKey: ["/orders/current/details"],
      });

      const isAnyFailure = results.some(({ status }) => status === "rejected");
      if (isAnyFailure) {
        throw new Error("Some items failed to be removed.");
      }
    }
  }

  return itemsArchived;
};

export const buildPlatformUrl = (
  scheme: string | null | undefined,
  subdomain: string | null | undefined,
  host: string | null | undefined,
  page?: string | null | undefined
) => {
  if (page == "checkout" && Capacitor.isNativePlatform()) {
    return `${window.location.origin}`;
  }
  const uri = (host ?? "").includes(subdomain ?? "")
    ? host
    : `${subdomain}.${host}`;
  return `${scheme}://${uri}`;
};
