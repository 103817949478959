interface RadioProps {
  checked?: boolean;
  onChange?: (value: boolean) => void;
}

export const Radio = ({ checked, onChange }: RadioProps) => {
  return (
    <input
      type="radio"
      checked={checked}
      onChange={(e) => onChange?.(e.target.checked)}
      className="radio-button "
    />
  );
};
