import dayjs from "dayjs";

import { ReactComponent as ChevronDownSmall } from "atoms/icon/icons/chevron_down_small.svg";
import { TextButton } from "atoms/text-button/TextButton";
import {
  HistoryListEntry,
  HistoryListEntryProps,
} from "molecules/history-list-entry/HistoryListEntry";

export interface HistoryListProps {
  historyListItems: {
    date: string;
    items: HistoryListEntryProps[];
  }[];
  showLoadMoreResultsButton?: boolean;
  onLoadMoreClick?: () => void;
}

export const HistoryList = ({
  historyListItems,
  showLoadMoreResultsButton,
  onLoadMoreClick,
}: HistoryListProps) => {
  if (!historyListItems.length) return null;

  return (
    <div>
      <div>
        {historyListItems.map((item) => (
          <div className="flex flex-col text-center" key={item.date}>
            <div className="p-4 text-sub2 uppercase text-secondary">
              {dayjs(item.date).format("MMMM YYYY")}
            </div>
            <div className="divide-y divide-solid divide-background-two overflow-clip border-y-[1px] border-y-background-two">
              {item.items?.map((i) => (
                <HistoryListEntry
                  key={i.id}
                  id={i.id}
                  title={i.title}
                  subtitle={i.subtitle}
                  date={i.date}
                  repeatCash={i.repeatCash}
                  onClick={i.onClick}
                />
              ))}
            </div>
          </div>
        ))}
        {showLoadMoreResultsButton && (
          <div className="flex w-full items-center justify-center p-2">
            <TextButton endIconSVG={ChevronDownSmall} onClick={onLoadMoreClick}>
              Load more results
            </TextButton>
          </div>
        )}
      </div>
    </div>
  );
};
