/**
 * This page is used by the admin app as part of a flow that allows
 * admin users to log into their patient's client side app. The admin
 * app assumes that this page exists at `/admin-impersonate`.
 *
 * The hardcoded link to this page is stored in
 * Shared/RepeatMD.Shared/Commands/Admin/Users/RequestPlatformUserPatientAppMagicLinkCommand.cs
 */

import { LoaderFunction, redirect } from "react-router-dom";

import { post } from "api";
import { queryClient } from "pages/Root";
import Services from "services/Services";

export const adminImpersonateLoader: LoaderFunction = async ({ request }) => {
  queryClient.invalidateQueries();
  queryClient.resetQueries();

  const authToken = new URL(request.url).searchParams.get("authToken");
  if (!authToken) return redirect("/signin");

  const response = await post("/auth/exchange-admin-token", { authToken });

  if (response.token) {
    // second argument set to true as we're impersonating
    Services.auth.setAuthToken(response.token, true);

    return redirect("/home");
  } else {
    return redirect("/signin");
  }
};
