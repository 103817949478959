import { GetResult, Models } from "api/types";
import { Checkbox } from "atoms/checkbox/Checkbox";
import { DescriptiveTag } from "atoms/tag/DescriptiveTag";
import { TextButton } from "atoms/text-button/TextButton";
import { DropdownSelector } from "molecules/containers/dropdown-selector/DropdownSelector";
import { ServiceFeeRow } from "organisms/order-summary/ServiceFeeRow";
import { PricingPreview } from "pages/root/shop/PackagePDP/pricing-preview/PricingPreview";
import { useFeatureFlag } from "services/Flags";
import { formatCurrency } from "toolbox/Money";

import { SavingsInfo } from "../savings-info/SavingsInfo";

type SelectTreatmentPlanProps = {
  baseCost: number;
  memberCost?: number;
  isPriceSet: boolean;
  isMember: boolean;
  serviceFee?: number;
  productType: "Package" | "Treatment";
  packages: GetResult<"/listings/{id}/packages">;
  package?: Models<"PackageModel">;
  price?: Models<"PackagePriceModel">;

  onClickPlanSelector: () => void;
  onClickSessionsSelector: () => void;
  onMemberInfo: () => void;
  onViewMoreInfo: () => void;
};

export const SelectTreatmentPlan = (props: SelectTreatmentPlanProps) => {
  const singularUnitType =
    props.package?.packageUnitType.singularUnitType ?? props.packages.length > 0
      ? props.packages[0].packageUnitType.singularUnitType
      : "unit";
  const pluralUnitType =
    props.package?.packageUnitType.pluralUnitType ?? props.packages.length > 0
      ? props.packages[0].packageUnitType.pluralUnitType
      : "units";
  const isUnitedDerm = useFeatureFlag("unitedDerm");
  return (
    <div className="flex flex-col items-center gap-6 text-primary">
      <div className="text-sub1 uppercase text-brand-color">
        select a treatment plan
      </div>
      {props.packages.length === 1 ? (
        <>
          {props.price ? (
            <DropdownSelector
              text={`${props.price.quantity} ${
                props.price.quantity > 1 ? pluralUnitType : singularUnitType
              }`}
              state="selected"
              onClick={props.onClickSessionsSelector}
            />
          ) : (
            <DropdownSelector
              text={"Select number of " + `${pluralUnitType}`}
              state="unselected"
              onClick={props.onClickSessionsSelector}
            />
          )}
        </>
      ) : props.package ? (
        <>
          <DropdownSelector
            text={props.package.name}
            secondaryText={props.package.description}
            state="selected"
            onClick={props.onClickPlanSelector}
          />
          {props.price ? (
            <DropdownSelector
              text={`${props.price.quantity} ${
                props.price.quantity > 1 ? pluralUnitType : singularUnitType
              }`}
              state="selected"
              onClick={props.onClickSessionsSelector}
            />
          ) : (
            <DropdownSelector
              text={"Select number of " + `${pluralUnitType}`}
              state="unselected"
              onClick={props.onClickSessionsSelector}
            />
          )}
        </>
      ) : (
        <DropdownSelector
          text="Select treatment plan"
          state="unselected"
          onClick={props.onClickPlanSelector}
        />
      )}
      <div className="w-full">
        <PricingPreview
          defaultPrice={props.baseCost}
          memberPrice={props.memberCost}
          isPriceSet={props.isPriceSet}
          packageUnitType={singularUnitType}
        />
      </div>
      {!props.isMember && !!props.memberCost && props.price?.membership && (
        <MemberInfo
          baseCost={props.baseCost}
          memberCost={props.memberCost}
          productType={props.productType}
          membership={props.price.membership}
          onMemberInfo={props.onMemberInfo}
          onViewMoreInfo={props.onViewMoreInfo}
        />
      )}
      {props.isMember && !!props.memberCost && props.price?.membership && (
        <SavingsInfo
          baseCost={props.baseCost}
          memberCost={props.memberCost}
          membershipName={props.price.membership.name}
          isMember={props.isMember}
          onViewMoreInfo={props.onViewMoreInfo}
        />
      )}
      {props.serviceFee != null && props.serviceFee > 0 && (
        <ServiceFeeRow
          title={
            !isUnitedDerm
              ? "convenience fee applied at checkout"
              : "credit card fee applied at checkout"
          }
          feePercentage={props.serviceFee}
        />
      )}
    </div>
  );
};

const MemberInfo = (props: {
  baseCost: number;
  memberCost: number;
  productType: "Package" | "Treatment";
  membership: Models<"MembershipModel">;
  onMemberInfo: () => void;
  onViewMoreInfo: () => void;
}) => {
  const save = (
    <span className="text-bold2">
      save {formatCurrency(props.baseCost - props.memberCost)}
    </span>
  );

  return (
    <div className="flex w-full items-center gap-4 rounded bg-brand-tint px-3">
      <div className="flex">
        <Checkbox onChange={props.onMemberInfo} />
        <div className="flex flex-col pt-4">
          <div className="flex flex-col pl-3">
            <div className="flex items-center gap-1 text-bold2">
              Become a
              <DescriptiveTag color="secondary">
                {props.membership.name + " Member"}
              </DescriptiveTag>
            </div>
            <div className="pt-3 text-body2">
              Add to cart now to {save} on this{" "}
              {props.productType == "Package" ? "package" : "treatment"} today,
              plus monthly benefits
            </div>
            <div className="pt-1 text-body3 text-secondary">
              {formatCurrency(props.membership.monthlyCost)}/month membership
              fee applies
            </div>
          </div>
          <div>
            <TextButton onClick={props.onViewMoreInfo}>
              <div className=" pb-1 text-sub2 uppercase text-brand-color">
                View more info
              </div>
            </TextButton>
          </div>
        </div>
      </div>
    </div>
  );
};
