import cx from "classnames";

export interface ShopListHeaderProps {
  title: string;
  subtitle?: string;
  backgroundImageUrl?: string;
  backgroundColor?: string;
  bgGradient?: boolean;
}

export const ShopListHeader = ({
  title,
  subtitle,
  backgroundImageUrl,
  backgroundColor,
  bgGradient,
}: ShopListHeaderProps) => {
  const gradientForSolidBg =
    "linear-gradient(90deg, rgba(0, 0, 0, 0.20) 15.23%, rgba(0, 0, 0, 0.18) 32.6%, rgba(0, 0, 0, 0.14) 44.92%, rgba(0, 0, 0, 0.00) 78.16%)";
  const gradientForImage =
    "linear-gradient(90deg, rgba(0, 0, 0, 0.40) 15.23%, rgba(0, 0, 0, 0.36) 32.6%, rgba(0, 0, 0, 0.29) 44.92%, rgba(0, 0, 0, 0.00) 78.16%)";
  const backgroundStyle = {
    ...(backgroundImageUrl &&
      !bgGradient && {
        backgroundImage: `url(${backgroundImageUrl})`,
      }),
    ...(backgroundImageUrl &&
      bgGradient && {
        backgroundImage: `${gradientForImage}, url(${backgroundImageUrl})`,
      }),
    ...(!backgroundImageUrl &&
      bgGradient && {
        backgroundImage: gradientForSolidBg,
      }),
    ...(backgroundColor && {
      backgroundColor: backgroundColor,
    }),
  };

  return (
    <div className="flex flex-col">
      <div
        className="relative h-[200px] w-full overflow-hidden bg-cover bg-center"
        style={backgroundStyle}
      >
        <div
          className={cx(
            "flex",
            "flex-col",
            "h-full",
            backgroundImageUrl ? "w-1/2" : "w-full",
            "justify-center",
            "gap-3",
            "items-start"
          )}
        >
          <div
            className={cx(
              "px-6",
              "font-serif",
              "text-h1",
              backgroundImageUrl && !bgGradient ? "text-primary" : "text-white",
              bgGradient ? "text-white text-shadow-subtle" : "text-primary"
            )}
          >
            {title}
          </div>
          {subtitle && (
            <div
              className={cx(
                "px-6",
                "text-body2",
                backgroundImageUrl && !bgGradient
                  ? "text-primary"
                  : "text-white",
                bgGradient ? "text-white text-shadow-subtle" : "text-primary"
              )}
            >
              {subtitle}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
