import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Models } from "api/types";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as CheckSmall } from "atoms/icon/icons/ic_check_small_ol.svg";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/ic_chevron_right_small_ol.svg";
import { ReactComponent as PromoIconDot } from "atoms/icon/icons/ic_offers+1_nav_ol.svg";
import { ReactComponent as PromoIcon } from "atoms/icon/icons/ic_offers_nav_ol.svg";
import { Available } from "molecules/cart-promotions/modal/list/available/Available";
import { TextEntry } from "molecules/cart-promotions/modal/list/text-entry/TextEntry";
import { ListModal } from "molecules/modal-window/list-modal/ListModal";
import { PromotionCheckoutModal } from "organisms/promotion-checkout-modal/PromotionCheckoutModal";

type AvailablePromotionsProps = {
  rewardPromos?: Models<"RewardPromo">[] | null;
  onPromoSubmit: (promo: string, shouldLoad?: boolean) => void;
  setCoupon: (promo: string) => void;
  errorMsg?: string;
  coupon: string;
  onDelete: () => void;
  onRewardSubmit: (rewardId: string) => void;
  rewardId: string;
  discountId?: string;
  setRewardId: (rewardId: string) => void;
  setDiscountId: (discountId: string) => void;
  successMsg?: string;
  externalPromoAmountOff?: number | 0;
  appliedPromoTitle?: string | null;
  successMsgOnDelete?: boolean | null;
  membershipDiscount?: Models<"GetMembershipDetailsResult"> | undefined;
  isMembershipDiscountApplied?: boolean;
  loading?: boolean;
  checkoutPromoModalOpen: boolean;
  setCheckoutPromoModalOpen: (open: boolean) => void;
  checkoutButtonClick: () => void;
  checkoutButtonLabel: string;
  hasMoreDiscounts: boolean;
};
const PromotionModal = ({
  children,
  openModal,
  setOpenModal,
}: {
  children: React.ReactNode;
  openModal: boolean;
  setOpenModal: (val: boolean) => void;
}) => {
  return (
    <ListModal
      open={openModal}
      size="fullscreen"
      title="Promotions"
      onClose={() => setOpenModal(false)}
      firstButtonLabel="Save & continue checkout"
      onFirstButtonClick={() => setOpenModal(false)}
    >
      <div className="flex flex-col items-center py-3 px-4">
        <div className="pb-[18px] pt-[6px] text-secondary">
          Select available promotion or enter promo code
        </div>
        {children}
      </div>
    </ListModal>
  );
};
export const AvailablePromotions = (props: AvailablePromotionsProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [customPromo, setCustomPromo] = useState<string>("");
  const [resetTextEntry, setResetTextEntry] = useState<boolean>(false);
  const [alreadyAppliedPromo, setAlreadyAppliedPromo] = useState("");
  const refContainer = useRef<null | HTMLDivElement>(null);
  const membershipDiscountIsApplied = props.isMembershipDiscountApplied;
  const couponIsFromAvailablePromotionsList =
    props.rewardPromos &&
    props.rewardPromos.some(
      (p) =>
        (props.coupon == p.promoCode && props.discountId == p.discountId) ||
        props.discountId == p.discountId
    );
  useEffect(() => {
    if (!props.loading && customPromo) {
      const inputElement = refContainer.current?.querySelector("input");
      if (inputElement) {
        inputElement.blur();
      }
      setCustomPromo("");
    }
  }, [props.loading]);
  useEffect(() => {
    // Auto-scroll behavior for textEntry
    refContainer.current?.scrollIntoView({ behavior: "smooth" });
  });
  useEffect(() => {
    if (props.checkoutPromoModalOpen) {
      if (props.coupon) {
        setAlreadyAppliedPromo(props.coupon);
      }
    }
  }, [props.checkoutPromoModalOpen]);

  const promosAvailableTitle = (title: string) => {
    // if title is too long, cut it off, add ellipsis, and add "applied!" to the end
    // othrwise just return title with "applied!" to the end.
    if (
      title.substring(title.lastIndexOf(" ") + 1).toLowerCase() == "applied" ||
      title.substring(title.lastIndexOf(" ") + 1).toLowerCase() == "applied!"
    ) {
      title = title.substring(0, title.lastIndexOf(" "));
    }
    if (title.length > 59) {
      if (
        title.lastIndexOf(".") === title.length - 1 ||
        title.lastIndexOf("!") === title.length - 1
      ) {
        return (
          title.substring(0, title.length - 1).substring(0, 59) +
          "..." +
          " applied!"
        );
      }
      return title.substring(0, 59) + "..." + " applied!";
    } else {
      if (
        title.lastIndexOf(".") === title.length - 1 ||
        title.lastIndexOf("!") === title.length - 1
      ) {
        return (title = title.substring(0, title.length - 1) + " applied!");
      }
      return title + " applied!";
    }
  };

  const promosAvailable = (
    <div
      className="flex w-full items-center justify-between rounded border border-light-grey p-3"
      onClick={() => {
        setOpenModal(true);
      }}
    >
      <div className="flex items-center gap-3">
        <Icon
          svg={
            (props.coupon && props.successMsg) || membershipDiscountIsApplied
              ? CheckSmall
              : props.rewardPromos && props.rewardPromos.length > 0
              ? PromoIconDot
              : PromoIcon
          }
          color={
            (props.coupon && props.successMsg) || membershipDiscountIsApplied
              ? "successGreen"
              : "secondaryText"
          }
          size="small"
        />
        <div className="flex flex-col gap-1">
          <div className="float-left flex text-bold2">
            <span className="inline-block line-clamp-2">
              {props.coupon && props.successMsg && props.appliedPromoTitle
                ? promosAvailableTitle(props.appliedPromoTitle)
                : props.coupon && props.successMsg
                ? promosAvailableTitle(props.successMsg)
                : membershipDiscountIsApplied
                ? "Membership pricing applied!"
                : props.rewardPromos && props.rewardPromos.length > 0
                ? "You have available promotions!"
                : "Apply a promo code"}
            </span>
          </div>
          <div className="text-body2 text-secondary">
            {props.rewardPromos && props.rewardPromos.length > 0
              ? "Click to view all available promotions"
              : "Click to apply your code"}
          </div>
        </div>
      </div>
      <Icon svg={ChevronRight} color="brandColor" size="xsmall" />
    </div>
  );

  const removePromo = () => {
    props.onDelete();
  };

  const resetPromoAndReward = () => {
    props.setCoupon("");
    props.setRewardId("");
    props.setDiscountId("");
  };

  const handlePromoSubmit = (promoCode: string, shouldLoad?: boolean) => {
    resetPromoAndReward();
    props.setCoupon(promoCode);
    props.onPromoSubmit(promoCode, shouldLoad);
  };

  const handleRewardSubmit = (rewardId: string) => {
    resetPromoAndReward();
    props.setRewardId(rewardId);
    props.onRewardSubmit(rewardId);
  };

  useEffect(() => {
    if (props.successMsgOnDelete) {
      resetPromoAndReward();
    }
  }, [props.successMsgOnDelete]);
  const memberShipPromotion = props.membershipDiscount?.membershipName &&
    props.membershipDiscount.totalDiscount && (
      <Available
        textButton={!membershipDiscountIsApplied ? "APPLY" : ""}
        isMembership={true}
        onClick={() => {
          removePromo();
        }}
        title={props.membershipDiscount.membershipName + " membership pricing"}
        totalDiscount={props.membershipDiscount.totalDiscount ?? undefined}
        isHighlighted={
          membershipDiscountIsApplied && !couponIsFromAvailablePromotionsList
        }
      />
    );
  const maxDiscountPromo = props?.rewardPromos?.reduce(
    (max, current) =>
      current.totalDiscount > max.totalDiscount ? current : max,
    props.rewardPromos[0]
  );
  const memberShipHasMaxDiscount =
    (props?.membershipDiscount?.totalDiscount || 0) >
    (maxDiscountPromo?.totalDiscount || 0);
  const discountAndRewards = (reminder?: boolean) => (
    <>
      {reminder && memberShipHasMaxDiscount && memberShipPromotion}
      {props.rewardPromos &&
        (!reminder
          ? props.rewardPromos
          : memberShipHasMaxDiscount
          ? []
          : [{ ...maxDiscountPromo }]
        ).map((p) => (
          <div key={p.rewardId ?? p.promotionId} className="w-full">
            <Available
              textButton={
                props.successMsg &&
                (props.coupon == p.promoCode ||
                  props.rewardId == p.rewardId ||
                  props.discountId == p.discountId)
                  ? "REMOVE"
                  : "APPLY"
              }
              onClick={() => {
                if (
                  props.successMsg &&
                  (props.coupon == p.promoCode ||
                    props.discountId == p.discountId)
                ) {
                  if (alreadyAppliedPromo && reminder) {
                    handlePromoSubmit(alreadyAppliedPromo);
                  } else {
                    removePromo();
                  }
                } else {
                  if (p && p.rewardId) {
                    handleRewardSubmit(p.rewardId);
                  } else if (p && p.promoCode) {
                    handlePromoSubmit(p.promoCode);
                  }
                }
              }}
              title={p.title}
              totalDiscount={p.totalDiscount}
              expiresOn={p.expiresOn}
              isHighlighted={
                props.successMsg &&
                (props.coupon == p.promoCode ||
                  props.discountId == p.discountId)
              }
              isReward={p.rewardId ? true : false}
            />
          </div>
        ))}
    </>
  );

  return (
    <div>
      {promosAvailable}
      <PromotionModal openModal={openModal} setOpenModal={setOpenModal}>
        <div className="flex w-full flex-col gap-3">
          {memberShipPromotion}
          {discountAndRewards()}
          {(!props.coupon ||
            !props.successMsg ||
            (props.coupon && couponIsFromAvailablePromotionsList)) && (
            <div ref={refContainer}>
              <TextEntry
                buttonText={customPromo || resetTextEntry ? "APPLY" : "REMOVE"}
                errorMsg={
                  !couponIsFromAvailablePromotionsList &&
                  !resetTextEntry &&
                  props.errorMsg &&
                  !props.successMsg &&
                  !props.loading
                    ? true
                    : false
                }
                value={customPromo}
                loading={props.loading}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  props.errorMsg ? setResetTextEntry(true) : "";
                  e.target.value.length > 0
                    ? setCustomPromo(e.target.value)
                    : setCustomPromo("");
                }}
                onClick={() => {
                  if (
                    !resetTextEntry &&
                    props.coupon &&
                    !couponIsFromAvailablePromotionsList
                  ) {
                    removePromo();
                    resetPromoAndReward();
                  } else {
                    setResetTextEntry(false);
                    handlePromoSubmit(customPromo, true);
                  }
                }}
              />
            </div>
          )}
          {props.coupon &&
            props.successMsg &&
            !couponIsFromAvailablePromotionsList && (
              <Available
                textButton="REMOVE"
                onClick={() => {
                  removePromo();
                }}
                title={props.successMsg}
                totalDiscount={props.externalPromoAmountOff}
                isHighlighted={true}
              />
            )}
        </div>
      </PromotionModal>

      <PromotionCheckoutModal
        title={
          (props.hasMoreDiscounts && props.coupon && props.successMsg) ||
          membershipDiscountIsApplied
            ? "You can save even more!"
            : "You can save today!"
        }
        checkoutButtonClick={props.checkoutButtonClick}
        checkoutButtonLabel={props.checkoutButtonLabel}
        open={props.checkoutPromoModalOpen}
        onClose={() => props.setCheckoutPromoModalOpen(false)}
      >
        <div className="mt-2.5 flex flex-col gap-3">
          {discountAndRewards(true)}
        </div>
      </PromotionCheckoutModal>
    </div>
  );
};
