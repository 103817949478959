import { useState } from "react";

import { ListModal } from "molecules/modal-window/list-modal/ListModal";

interface Props {
  /** Displays a description for terms and conditions */
  text: string | null | undefined;

  hideText?: boolean;

  /** Specify the testid for current component */
  "data-testid"?: string;
}

export const TermsAndCondition = ({
  text: _text,
  /**
   * Once `text` property is used instead of the
   * hardcoded text, drop this property since it
   * will unlikely be needed.
   */
  hideText = false,
  "data-testid": dataTestId,
}: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleTermsOpenClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setOpenModal(true);
  };

  return (
    <div className="py-6 text-body3 text-secondary">
      {!hideText && (
        <div className="flex flex-col pb-1" data-testid={dataTestId}>
          {/* hardcode the terms and conditions for now */}
          Important Terms and Conditions: All financing subject to credit
          approval. APRs depend on creditworthiness, term length, and other
          factors. “As low as” does not guarantee your rate or payment options
          as this is dependent on your creditworthiness. Check with your
          provider about additional amounts required for procedure that may be
          ineligible for financing. Scheduled payments in example based on the
          full 24-month term. Length of financing is up to you and/or your
          creditworthiness.
        </div>
      )}
      <a href="#" className="underline" onClick={handleTermsOpenClick}>
        {"View "}
        {!hideText && "additional "}
        Terms and Conditions and Patient Membership Agreement here
      </a>
      <ListModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        size="fullscreen"
        title="PATIENT MEMBERSHIP AGREEMENT"
      >
        <div className="gap-2 p-4 pb-6 text-body3 text-primary">
          <div className="pb-2">IMPORTANT TERMS AS OF 04/01/22</div>
          <ul className="list-disc px-4">
            <li className="py-1">
              NOTICE TO PURCHASER: DO NOT SIGN THIS AGREEMENT UNTIL YOU READ IT.
            </li>
            <li className="py-[2px]">
              By signing this Agreement, you are committing to an obligation
              period as stated on the Your Order Summary page which you cannot
              cancel early, except for the limited reasons listed in Section 3.1
              and Section 3.2 of this Agreement.
            </li>
            <li className="py-[2px]">
              Monthly memberships will automatically renew for the same
              obligation period at the end of the obligation period, unless
              canceled under Section 3 of this Agreement. Paid-in-full
              memberships automatically expire unless renewed. See Section 1.3
              of this Agreement for more information.
            </li>
            <li className="py-[2px]">
              Please read this Agreement carefully, as it contains important
              information regarding your legal rights, including without
              limitation mandatory arbitration and your waiver of class relief.
            </li>
            <li className="py-[2px]">
              You have a right to cancel this Agreement by midnight of the third
              (3rd) business day after the day you sign this Agreement if no
              items have been claimed. See Section 3.1 of this Agreement for
              more information. You also have certain rights to cancel this
              Agreement if the Treatment Provider goes out of business or
              relocates or if you die or become totally and permanently
              disabled. See Section 3.2 of this Agreement for more information.
            </li>
            <li className="py-[2px]">
              By signing below, I am acknowledging that I have received and read
              and understand this Agreement and the following membership terms,
              and I am hereby agreeing to all terms and conditions of this
              Agreement, including without limitation, my compliance with this
              Agreement and the Treatment Provider`s policies described in this
              Agreement.
            </li>
          </ul>
          <div className="py-2 text-bold2">MEMBERSHIP TERMS</div>
          <ol className="list-decimal px-3">
            <li className="py-[2px]">YOUR MEMBERSHIP</li>
            <ol className="list-decimal pl-4">
              <li className="py-[2px]">
                Overview: This Agreement sets forth the terms and conditions
                that apply to your membership (“Membership”) to Treatment
                Provider. You are responsible for complying with this Agreement.
                A copy of your Agreement is available at
                memberships@repeatmd.com.
              </li>
              <li className="py-[2px]">
                Minimum Commitment: Your Membership Minimum Commitment Period is
                stated on the Your Order Form page during checkout (the “Initial
                Period”) and, except for those limited cancellation rights
                expressly set forth in Section 3.1 and 3.2 below, you may not
                cancel your Membership or otherwise terminate this Agreement
                during the Initial Period.
              </li>
              <li className="py-[2px]">
                Renewals: Your Membership and this Agreement will automatically
                renew at the end of the Initial Period and continue for the same
                obligation period until your Membership is cancelled by you or
                the Treatment Provider, as described in Section 3. You have
                fourteen (14) days after the annual billing cycle begins to
                cancel Your membership. Email: memberships@repeatmd.com if you
                wish to cancel within the fourteen (14) day period after the
                annual billing cycle begins.
              </li>
              <li className="py-[2px]">
                Non-Transferable: Your Membership is personal to you and is
                non-transferable and non-descendible. Only you may use your
                Membership, and you may not permit any other individual to
                access and use your Membership.
              </li>
              <li className="py-[2px]">
                Treatment Provider: Your “Treatment Provider” is where your
                Membership was purchased. “Treatment Provider” is defined as a
                person or facility where You receive Treatments.
              </li>
              <li className="py-[2px]">
                Treatment Provider Facility: “Treatment Provider Facility” is
                where your Treatments will be performed.
              </li>
              <li className="py-[2px]">
                Treatment: “Treatment” is any service performed by a Treatment
                Provider at a Treatment Provider Facility.
              </li>
              <li className="py-[2px]">
                Treatment Provider Facility Closures: During your Membership,
                Treatment Provider may, in its discretion, temporarily close or
                suspend or limit your access to certain Treatment Provider
                Facilities or areas, features or amenities, for purposes of
                renovation, special events or otherwise, at any time and in its
                sole discretion.
              </li>
            </ol>
            <li className="py-[2px]">PAYMENT POLICIES</li>
            <ol className="list-decimal pl-4">
              <li className="py-[2px]">
                Membership Dues: During the Initial Period, your monthly (or,
                for paid-in-full memberships, annual) membership dues
                (“Membership Dues”) will not change. Membership Dues are not
                based on or related to actual usage of Treatments provided by
                Treatment Provider and you are responsible for the payment of
                your Membership Dues in full regardless of your use of, or
                failure to use Treatment Provider.
              </li>
              <li className="py-[2px]">
                Ancillary Charges: You are responsible for paying all amounts
                you incur during your Membership, including, without limitation,
                fees for other ancillary services, as well as purchases of
                retail or other items (collectively, “Ancillary Charges”).
                Payment for Ancillary Charges is due in full at the time of
                purchase, and Treatment Provider has no obligation to provide
                you with any ancillary service or good until it has received
                such payment. Pricing for all Ancillary Charges is in Treatment
                Provider`s discretion and may be prospectively modified by
                Treatment Provider at any time, with or without notice to you.
              </li>
              <li className="py-[2px]">
                Non-Refundable: Your initiation fee, Membership Dues and
                Ancillary Charges are non-refundable and, unless otherwise
                expressly set forth in Section 3, you will not receive a refund
                of any initiation fee, Membership Dues or Ancillary Charges as a
                result of Membership cancellation or otherwise. From time to
                time Treatment Provider may provide you with certain
                complimentary or promotional items. Complimentary items have no
                cash value and you are not entitled to any refund or other
                amount for any complimentary item.
              </li>
              <li className="py-[2px]">
                Good Standing: You are required to keep your Membership in good
                standing by ensuring all Membership Dues and any Ancillary
                Charges are paid on time. You are responsible for promptly
                notifying Treatment Provider of any changes to your credit card,
                checking account or other payment account information (“Payment
                Information”)
              </li>
              <li className="py-[2px]">
                Separate Buyer: If another individual (a “Buyer”) purchased your
                Membership on your behalf and agreed to have their Payment
                Information retained on-file with Treatment Provider, you, not
                Buyer, are ultimately liable for all Membership Dues and
                Ancillary Charges payable hereunder and you agree to make all
                payments hereunder in the event Buyer fails to do so. This
                Agreement does not provide Buyer with any rights of Membership
                and Buyer may not access and use Treatment Provider under your
                Membership.
              </li>
              <li className="py-[2px]">
                Past-Due Balances: Treatment Provider reserves the right to take
                any lawful action in response to any past-due Membership Dues
                and/or Ancillary Charges, including, without limitation: (1)
                charging the Payment Information then on-file with Treatment
                Provider; (2) cancelling your Membership or otherwise suspending
                your access to Treatment Provider until all past-due amounts are
                paid; (3) charging you interest on past-due amounts; and/or (4)
                providing your information, including without limitation name
                and contact information, to a collections agency who will
                attempt to collect your past-due amounts on behalf of Treatment
                Provider. You will be responsible for any collection and/or
                legal costs incurred by Treatment Provider in collecting any
                past-due amounts associated with your Membership. Treatment
                Provider may charge you a $20 fee for each credit card charge or
                ACH transfer that is dishonored or rejected as invalid. Please
                note that cancellation of your Membership will not relieve you
                of your obligation to pay any past-due or outstanding amounts.
              </li>
            </ol>
            <li className="py-[2px]">CANCELLATION RIGHTS</li>
            <ol className="list-decimal pl-4">
              <li className="py-[2px]">
                Cancellation of Contract for Full Refund: IF YOU DECIDE YOU DO
                NOT WISH TO REMAIN A MEMBER OF TREATMENT PROVIDER, YOU MAY
                CANCEL THIS AGREEMENT BY EMAILING BILLING@REPEATMD BY MIDNIGHT
                OF THE THIRD (3RD) BUSINESS DAY AFTER THE DAY YOU SIGN THIS
                AGREEMENT A NOTICE STATING YOUR DESIRE TO CANCEL THIS AGREEMENT.
              </li>
              <li className="py-[2px]">
                ADDITIONAL RIGHTS TO CANCELLATION: You may also cancel your
                Membership and this Agreement, at any time during or after the
                Initial Period, for either of the following reasons:
              </li>
              <li className="py-[2px]">
                (1) IF YOUR TREATMENT PROVIDER GOES OUT OF BUSINESS OR IF
                TREATMENT PROVIDER MOVES MORE THAN TEN (10) MILES FROM THE
                PRECEDING LOCATION, YOU MAY CANCEL THIS AGREEMENT BY EMAIL
                STATING YOUR DESIRE TO CANCEL THIS AGREEMENT, ACCOMPANIED BY
                PROOF OF PAYMENT ON YOUR AGREEMENT TO: MEMBERSHIPS@REPEATMD.COM
                (2) IF YOU DIE OR BECOME TOTALLY AND PERMANENTLY DISABLED AFTER
                THE DATE THIS AGREEMENT TAKES EFFECT, YOU OR YOUR ESTATE MAY
                CANCEL THIS AGREEMENT AND RECEIVE A PARTIAL REFUND OF YOUR
                UNUSED MEMBERSHIP FEE BY EMAILING A NOTICE TO TREATMENT PROVIDER
                STATING YOUR DESIRE TO CANCEL THIS AGREEMENT. TREATMENT PROVIDER
                MAY REQUIRE PROOF OF DISABILITY OR DEATH. THE WRITTEN NOTICE
                MUST BE EMAIL TO THE FOLLOWING ADDRESS: MEMBERSHIPS@REPEATMD.COM
                Treatment Provider may require you or your estate to provide
                reasonable proof of your disability or death, such as a signed
                letter from your doctor or a copy of your death certificate.
                Notice should be accompanied by a receipt or other proof of
                payment made under this Agreement, as well as any membership
                cards or fobs or other membership documentation given to you by
                Treatment Provider. Within thirty (30) days after Treatment
                Provider receives notice of such cancellation, it will refund,
                using the Payment Information on-file, any unearned payments.
                Unearned payments will be calculated by (1) rounding, as
                applicable, the date of closure or relocation or your notice of
                death or disability is received and the Agreement’s expiration
                date to the nearest full month; (2) subtracting that rounded
                date of closure, relocation or receipt of notification from that
                rounded expiration date, with the result expressed in whole
                months and representing the number of months remaining on the
                Agreement; (3) computing the gross monthly payment by adding all
                Membership Dues under the Agreement, including any initiation
                fees, and dividing the result amount by the total number of
                months in the term of the Agreement; and (4) multiplying the
                number of months remaining on the contract (determined under
                clause (2)) by the gross monthly payment (determined under
                clause (3)).Cancellation after Initial Period: If you do not
                wish to continue your Membership after the Initial Period, you
                may cancel your Membership and this Agreement thirty (30) days
                prior to the end of the Initial Period with a notice to
                MEMBERSHIPS@REPEATMD.COM. For clarity, your cancellation will go
                into effect thirty (30) days after your cancellation notice is
                received; however, if you provide cancellation notice more than
                thirty (30) days before the end of the Initial Period, your
                cancellation will not go into effect until the last day of the
                Initial Period. You are required to pay all Membership Dues for
                the Initial Period even if you submit a cancellation notice more
                than thirty (30) days before the Initial Period ends. Please
                note you will not receive a refund of any charges, including but
                not limited to unused Treatments at the time your cancellation
                goes into effect. To cancel your membership after the Initial
                Period, you must provide notice of cancellation by emailing
                MEMBERSHIPS@REPEATMD.COM.
              </li>
              <li className="py-[2px]">
                Record of Cancellation: You are responsible for retaining (and
                presenting, if necessary) all records relating to your
                cancellation of Membership. In the event of a dispute as to
                whether and when notice of cancellation was received, Treatment
                Provider`s records will control.
              </li>
              <li className="py-[2px]">
                Revocation of Membership: Treatment Provider may, in its
                discretion, revoke or suspend your Membership, or limit your
                right to access certain Treatment Provider Facilities or
                participate in certain Treatment Provider offerings or ancillary
                services, upon notice to you provided at any time and for any
                reason, including without limitation failure to pay Membership
                Dues or Ancillary Charges or failure to comply with Treatment
                Provider Policies (as defined in Section 5.1). In some cases,
                notice of revocation or suspension may be provided orally by a
                Manager, Membership Administration or Treatment Provider
                management. If Treatment Provider revokes your Membership, it
                will promptly refund, using the Payment Information on-file with
                Treatment Provider, any prepaid unused Membership Dues and any
                prepaid unused Ancillary Charges, less any outstanding amounts
                which may be owed to Treatment Provider hereunder. Revocation or
                suspension of Membership will be without limitation to any other
                rights or remedies which Treatment Provider may have at law or
                in equity, and Treatment Provider reserves the right to, in its
                discretion, prohibit you from rejoining Treatment Provider in
                the future.
              </li>
            </ol>
            <li className="py-[2px]">YOUR HEALTH</li>
            <ol className="list-decimal pl-4">
              <li className="py-[2px]">
                Health Warranty: By signing this Agreement and using Treatment
                Provider, you are representing and warranting to Treatment
                Provider that you are in good health and have no injury,
                impairment, disability, disease, ailment or condition that
                prevents you from safely engaging in Treatments or that
                increases your risk of injury or adverse health consequences as
                a result of engaging in Treatments. We encourage you to see your
                doctor on a regular basis and seek their advice prior to
                engaging in any new or modified treatment regimen or if you have
                any questions or concerns regarding your treatment regimen or
                the diagnosis of any medical conditions.
              </li>
              <li className="py-[2px]">
                Responsibility to Notify: You are responsible for notifying
                Treatment Provider before any Treatment of any limitation to
                your ability to participate, including without limitation if you
                have any pre-existing or current injuries, impairments,
                disabilities, diseases, ailments or conditions that may prevent
                or effect your participation. You are also responsible for
                immediately informing your Treatment Provider if you experience
                any pain or discomfort and/or if you cannot or do not wish to
                continue your Treatment. IF YOU EXPERIENCE ANY PAIN, DIFFICULTY,
                DIZZINESS, ILLNESS OR DISCOMFORT WHEN ENGAGING IN ANY SESSION,
                ACTIVITY OR OTHERWISE, STOP AND CONSULT YOUR DOCTOR OR SEEK
                EMERGENCY MEDICAL ATTENTION IMMEDIATELY. You hereby consent to
                receive any medical treatment which may be deemed advisable by
                Treatment Provider and/or any emergency professionals in the
                event of injury, accident, illness and/or other incapacitation
                while at any Treatment Provider Facilities.
              </li>
              <li className="py-[2px]">
                Covenant of Due Care: By agreeing to this Patient Membership
                Agreement and using Treatment Provider, you are also
                acknowledging that the activities you may engage in at the
                Treatment Provider Facilities, including without limitation when
                participating in any Treatment involves risks and danger
                inherent in engaging in such Treatments. Specific risks vary
                from one Treatment to another, and range from minor injuries to
                major injuries, including death. In consideration of the
                Membership privileges provided to you hereunder and on behalf of
                your heirs, beneficiaries, distributees, legal representatives,
                successors, assigns and guests, you hereby voluntarily and
                knowingly acknowledge and assume all risks when undergoing any
                Treatments by any Treatment Provider and you hereby agree to
                indemnify, defend, and hold harmless Treatment Provider, its
                parents, subsidiaries and other affiliates, and their respective
                officers, directors, employees, contractors, agents,
                representatives, successors and assigns, technology and payment
                processor, RepeatMD, (collectively “Treatment Provider Parties”)
                from any and all liability, damages, losses, suits, demands,
                causes of action or other claims of any nature whatsoever,
                including without limitation any property damage, personal
                injury, injury to others or death, to the extent any of the
                foregoing arise out of or relate in any way to your negligence,
                intentional acts and/or failure to exercise reasonable care when
                receiving Treatments in Treatment Provider`s Facilities.
                Further, you acknowledge that Treatment Provider does not
                manufacture the equipment and machines used in its Treatment
                Provider Facilities and agree that Treatment Provider is
                providing Treatments and may not be held liable for defective
                products.
              </li>
              <li className="py-[2px]">
                Waiver and Release: IN CONSIDERATION OF THE MEMBERSHIP
                PRIVILEGES PROVIDED TO YOU HEREUNDER, AND ON BEHALF OF YOUR
                HEIRS, BENEFICIARIES, DISTRIBUTEES, LEGAL REPRESENTATIVES,
                SUCCESSORS, ASSIGNS AND GUESTS, YOU HEREBY VOLUNTARILY AND
                KNOWINGLY, FOREVER WAIVE, RELEASE, COVENANT NOT TO SUE,
                DISCHARGE AND HOLD HARMLESS REPEATMD AND THE TREATMENT PROVIDER
                PARTIES (AS DEFINED ABOVE) FROM, AND SUCH REPEATMD AND TREATMENT
                PROVIDER PARTIES WILL NOT BE LIABLE TO YOU OR ANY OTHER PERSON
                FOR, ANY LOSSES OR DAMAGES, WHETHER DIRECT, INDIRECT,
                INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY,
                INCLUDING WITHOUT LIMITATION FOR ANY PROPERTY LOSS OR DAMAGE,
                LOSS OF EARNINGS OR EARNING CAPACITY, PERSONAL INJURY, ILLNESS
                OR IMPAIRMENT, PHYSICAL PAIN, MENTAL ANGUISH, PARALYSIS, HEART
                ATTACK OR DEATH, ARISING OUT OF, IN CONNECTION WITH OR RELATED
                TO THIS AGREEMENT, YOUR MEMBERSHIP, THE USE OR NON-USE OF ANY
                SERVICE, PRODUCT OR EQUIPMENT PROVIDED OR OFFERED HEREUNDER,
                WHETHER RELATED TO TREATMENT OR NOT AND REGARDLESS OF LEGAL
                THEORY OR WHETHER ARISING IN OR BY STATUTE, TORT, CONTRACT,
                STRICT LIABILITY, BREACH OF WARRANTY OR OTHERWISE, INCLUDING
                THOSE LOSSES OR DAMAGES RESULTING FROM OR CAUSED BY, IN WHOLE OR
                IN PART, THE NEGLIGENCE OR GROSS NEGLIGENCE OF ANY TREATMENT
                PROVIDER PARTY AND REGARDLESS OF WHETHER SUCH LOSSES OR DAMAGES
                ARE KNOWN OR UNKNOWN TO YOU OR ANY OTHER PERSON; PROVIDED THAT
                THE FOREGOING RELEASE AND WAIVER OF LIABILITY SHALL NOT APPLY TO
                ANY LOSSES OR DAMAGES TO THE EXTENT PROHIBITED BY LAW.
                NONETHELESS, THIS RELEASE IS INTENDED BY BOTH PARTIES TO BE AS
                BROAD IN EFFECT AS ALLOWED BY LAW AND SHALL OVER OR INCLUDE ANY
                CLAIM OR DEMAND YOU HAVE, HAD OR EVER WILL HAVE.
              </li>
            </ol>
            <li className="py-[2px]">
              YOU ACKNOWLEDGE AND AGREE THAT REPEATMD HAS MADE NO WARRANTIES
              REGARDING THE SERVICES OR TREATMENTS PROVIDED BY ANY TREATMENT
              PROVIDER. ADDITIONALLY YOU ACKNOWLEDGE AND AGREE THAT YOU RELEASE,
              INDEMNIFY, AND HOLD HARMLESS REPEATMD, AND ITS AFFILIATES AND
              THEIR OFFICERS, EMPLOYEES, DIRECTORS, ATTORNEYS, SHAREHOLDERS,
              MEMBERS, PARTNERS, AND AGENTS FROM AND AGAINST ANY AND ALL
              LIABILITY, LOSSES, DAMAGES, EXPENSES, INCLUDING ATTORNEY’S FEES,
              RIGHTS, CLAIMS ACTIONS OF ANY KIND AND INJURY (INCLUDING DEATH)
              ARISING OUT OF OR RELATING TO YOUR USE OF A TREATMENT PROVIDER,
              INFORMATION PROVIDED TO REPEATMD, INFORMATION PROVIDED TO A
              TREATMENT PROVIDER, THIS AGREEMENT, AND/OR ANY CLAIMS BY A
              TREATMENT PROVIDER AGAINST YOU. FURTHER, YOU RECOGNIZE,
              ACKNOWLEDGE, AND AGREE THAT REPEATMD IS ONLY A TECHNOLOGY PROVIDER
              FOR TREATMENT PROVIDER AND A PAYMENT PROCESSOR WITH NO CONTROL
              OVER YOU, YOUR HEALTH, TREATMENTS, TREATMENT SAFETY, TREATMENT
              PROVIDER, TREATMENT PROVIDER FACILITIES, OR TREATMENT PROVIDER
              EQUIPMENT.
            </li>
            <li className="py-[2px]">MEMBERSHIP POLICIES</li>
            <ol className="list-decimal pl-4">
              <li className="py-[2px]">
                Treatment Provider Policies: Treatments will be available to You
                as long as You are in compliance with this Agreement. Any unused
                Treatments pursuant to this Agreement shall not expire as long
                as all payments by You under this Agreement are paid in full and
                paid timely. However, any Treatments that are unused at the time
                of an event of Cancellation or this Agreement terminating shall
                terminate any right to the unused Treatments.
              </li>
              <li className="py-[2px]">
                Your Information: Treatment Provider and Treatment Provider
                Parties may collect, use, and disclose your personal information
                but shall not disclose protected health information in
                compliance with the Health Insurance Portability and
                Accountability Act of 1996 (HIPAA) if Treatment Provider is
                deemed a covered entity under HIPAA. If Treatment Provider is a
                covered entity under HIPAA, it shall implement compliance
                procedures in accordance with the HIPAA Security Rule.
              </li>
            </ol>
            <li className="py-[2px]">NOTICES</li>
            <li className="py-[2px]">
              Your notice of cancellation must be provided in accordance with
              Section 3 above. Treatment Provider and Treatment Provider Parties
              has the right to communicate with you regarding your Membership,
              this Agreement or otherwise by any lawful method, including
              without limitation by mail, courier, telephone, email, and text
              message, and may communicate with you using any contact
              information you have provided to Treatment Provider. Treatment
              Provider and Treatment Provider Parties may also provide general
              member-facing or Treatment Provider location-facing communications
              or notices (if provided), including without limitation changes to
              Treatment Provider Policies and notices regarding inclement
              weather or change to operating hours on the Treatment Provider
              website and/or Treatment Provider mobile app. Notices will be
              deemed given by Treatment Provider or Treatment Provider Parties
              on the date deposited in the mail or given to a courier, the date
              a phone call is made or an email or text is sent, or the date
              posted in Treatment Provider location, online or in-app. You are
              responsible for providing accurate, current contact information
              and must promptly notify Treatment Provider and Treatment Provider
              Parties if any of your contact information changes. Treatment
              Provider and Treatment Provider Parties will not be responsible
              for your failure to receive any communication or notice as a
              result of your failure to provide accurate and current contact
              information or as a result of any e-mail filtering by your ISP or
              email provider, insufficient space in your email account or any
              errors or losses of any postal or delivery service. You hereby
              consent and give Treatment Provide rand Treatment Provider Parties
              permission to use (or have a third-party provider use on Treatment
              Provider`s behalf) an automated telephone dialing system and/or
              artificial or prerecorded voice (where applicable) to call or text
              you, including on or to any telephone number you provide to
              Treatment Provider, including your mobile phone.
            </li>
            <li className="py-[2px]">
              ARBITRATION AGREEMENT AND CLASS ACTION WAIVER
            </li>
            <ol className="list-decimal pl-4">
              <li className="py-[2px]">
                Informal Dispute Resolution: Our goal is to do our best to
                ensure that every experience you have with Treatment Provider
                exceeds your expectations. If that doesn`t happen, we hope you
                will give us the opportunity to try to address any problem or
                concern. To do so, please contact us at LEGAL@REPEATMD.COM. When
                contacting us, we ask that you include your name, address, phone
                number and email address, a description of your problem or
                concern and any specific relief you seek.
              </li>
              <li className="py-[2px]">
                Arbitration: You agree to submit any and all Disputes (as
                defined in Section 7.4) to binding arbitration pursuant to the
                Federal Arbitration Act (Title 9 of the United States Code),
                which will govern the interpretation and enforcement of this
                arbitration agreement (“Arbitration Agreement”). Arbitration
                will be before either (1) JAMS (formerly known as Judicial
                Arbitration and Mediation Services), http://www.jamsadr.com, or
                (2) the American Arbitration Association (“AAA”),
                http://www.adr.org. If you initiate arbitration, you may choose
                between these two arbitration forums; if Treatment Provider
                initiates arbitration, it will have the choice as between these
                two arbitration forums.
              </li>
              <li className="py-[2px]">
                YOU AND TREATMENT PROVIDER AGREE THAT, EXCEPT AS PROVIDED IN
                SECTION 7.4, ANY AND ALL DISPUTES WHICH ARISE AFTER YOU ENTER
                INTO THIS AGREEMENT WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY
                BINDING ARBITRATION RATHER THAN IN COURT BY A JUDGE OR JURY, IN
                ACCORDANCE WITH THIS ARBITRATION AGREEMENT. YOU HEREBY WAIVE
                YOUR RIGHT TO TRIAL BY JURY.Class Action Waiver: You agree that
                the arbitration of any Dispute will be conducted on an
                individual, not a class-wide, basis, and that no arbitration
                proceeding may be consolidated with any other arbitration or
                other legal proceeding involving Treatment Provider or any other
                person. You further agree that you, and anyone asserting a claim
                through you, will not be a class representative, class member,
                or otherwise participate in a class, representative, or
                consolidated proceeding against Treatment Provider, and that the
                arbitrator of any Dispute between you and Treatment Provider may
                not consolidate more than one person`s claims, and may not
                otherwise preside over any form of a class or representative
                proceeding or claim (such as a class action, representative
                action, consolidated action or private attorney general action).
                If the foregoing class action waiver (“Class Action Waiver”) or
                any portion thereof is found to be invalid, illegal,
                unenforceable, unconscionable, void or voidable, then the
                Arbitration Agreement will be unenforceable and the Dispute will
                be decided by a court of competent jurisdiction. Any claim that
                all or part of the Class Action Waiver is invalid, illegal,
                unenforceable, unconscionable, void or voidable may be
                determined only by a court of competent jurisdiction and not by
                an arbitrator.
              </li>
              <li className="py-[2px]">
                Definition of “Dispute”: Subject to the following exclusions,
                “Dispute” means any dispute, claim, or controversy between you
                and Treatment Provider regarding any aspect of your relationship
                with Treatment Provider, whether based in contract, statute,
                regulation, ordinance, tort (including without limitation fraud,
                misrepresentation, fraudulent inducement, negligence, gross
                negligence or reckless behavior), or any other legal, statutory
                or equitable theory, and includes without limitation the
                validity, enforceability or scope of the Agreement (except for
                the scope, enforceability and interpretation of the Arbitration
                Agreement and Class Action Waiver). However, “Dispute” will not
                include (1) personal injury claims or claims for lost, stolen,
                or damaged property; (2) claims that all or part of the Class
                Action Waiver is invalid, unenforceable, unconscionable, void or
                voidable; and (3) any claim for public injunctive relief, i.e.,
                injunctive relief that has the primary purpose and effect of
                prohibiting alleged unlawful acts that threaten future injury to
                the general public. Such claims may be determined only by a
                court of competent jurisdiction and not by an arbitrator.
              </li>
              <li className="py-[2px]">
                Arbitration Procedures and Location: Either you or Treatment
                Provider may initiate arbitration proceedings. Arbitration will
                be conducted before a single arbitrator. If you or Treatment
                Provider initiate arbitration, you and we have a choice of doing
                so before JAMS or the AAA:
              </li>
            </ol>
            <li className="py-[2px]">
              (1) For arbitration before JAMS, the JAMS Comprehensive
              Arbitration Rules & Procedures and the JAMS Recommended
              Arbitration Discovery Protocols for Domestic, Commercial Cases
              will apply. The JAMS rules are available at http://www.jamsadr.com
              or by calling 1-800-352-5267. (2) Which particular rules apply in
              AAA arbitration will depend on how much money is at issue. For
              less than $75,000, the AAA`s Supplementary Procedures for
              Consumer-Related Disputes/Consumer Arbitration Rules will apply;
              for Disputes involving $75,000 or more, the AAA`s Commercial
              Arbitration Rules will apply. The AAA rules are available at
              http://www.adr.org or by calling 1-800-778-7879. If required for
              the enforceability of the Arbitration Agreement under the Federal
              Arbitration Act, Treatment Provider will pay all arbitrator`s
              costs and expenses. If not, those costs will be paid as specified
              in the above-referenced rules. The arbitrator shall be instructed,
              and the parties shall cooperate, with completing the arbitration
              with a ruling, if possible, in writing on each issue in dispute
              within 120 days of the arbitrator`s appointment by the JAMS or
              AAA. The arbitrator shall have the power to award damages,
              equitable relief, reasonable attorney`s fees and expenses, and the
              fees and Procedure 54(d) or successor Rule. The arbitrator`s
              rulings and awards shall be final and binding upon the parties and
              judgment thereon may be entered in any court having competent
              jurisdiction. The fees and expenses of the arbitrator and of the
              JAMS or AAA shall be awarded by the arbitrator. You and Treatment
              Provider both agree to bring the arbitration in Harris County,
              Texas. As set forth in Section 8.5 below, the arbitrator will
              apply Texas law.
            </li>
            <li className="py-[2px]">GENERAL TERMS</li>
            <ol className="list-decimal pl-4">
              <li className="py-[2px]">
                Entire Agreement: This Agreement, together with the Treatment
                Provider Policies, constitutes the entire and exclusive
                agreement between you and Treatment Provider relating to your
                Membership and supersedes any prior or contemporaneous
                representations, inducements, promises, understandings or
                agreements, whether oral, written or otherwise.
              </li>
              <li className="py-[2px]">
                Modifications: No provision of this Agreement, or the
                enforcement thereof, may be modified or waived, except as may be
                stated in a writing signed by Treatment Provider or corporate
                management. Notwithstanding the foregoing or anything to the
                contrary herein, Treatment Provider may, in its discretion,
                modify this Agreement at any time (subject to Section 2.1), upon
                at least thirty (30) days` notice to you.
              </li>
              <li className="py-[2px]">
                Interpretation: If any provision or portion of this Agreement,
                or the application thereof to any person, party or
                circumstances, is be deemed invalid or unenforceable by a court
                or arbitrator of competent jurisdiction, (1) that invalidity or
                unenforceability will not affect the remainder of this Agreement
                and (2) Treatment Provider may, in its discretion, modify such
                provision or portion in order to render it valid and
                enforceable.
              </li>
              <li className="py-[2px]">
                Assignment: Treatment Provider may assign or transfer this
                Agreement and your Membership, whether by operation of law or
                otherwise, to an affiliate of Treatment Provider or to a third
                party in the event of any merger, acquisition, sale of assets,
                change of control or other corporate transaction between
                Treatment Provider (or one of its affiliates) and such third
                party (or one of its affiliates), in each case without notice to
                you, and you hereby consent to any such assignment or transfer.
                You acknowledge that this Agreement is personal to you and that
                you have no rights to transfer or assign this Agreement to any
                other individual or entity.
              </li>
              <li className="py-[2px]">
                Governing Law: This Agreement, your Membership, and any claims,
                disputes and matters arising hereunder, will be governed by and
                construed in accordance with the laws of the State of Texas,
                without reference to its conflicts of law principles. Except for
                Disputes that you or Treatment Provider submit to binding
                arbitration pursuant to the Arbitration Agreement, all claims,
                disputes and matters arising hereunder will be submitted
                exclusively to the jurisdiction of the federal and state courts
                of competent jurisdiction located in Harris County, Texas or
                Travis County, Texas or the county where your Treatment Provider
                is located, and you and Treatment Provider each hereby
                irrevocably consent to the jurisdiction of such courts and waive
                all objections thereto.
              </li>
            </ol>
          </ol>
        </div>
      </ListModal>
    </div>
  );
};
