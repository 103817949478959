import cx from "classnames";

import { IconButton } from "atoms/icon-button/IconButton";
import { ReactComponent as Affirm } from "atoms/icon/icons/affirm.svg";
import { ReactComponent as RightIcon } from "atoms/icon/icons/chevron_right_small.svg";
import { ReactComponent as Close } from "atoms/icon/icons/close.svg";
import { ReactComponent as Cart } from "atoms/icon/icons/ic_affirm_Step-1_ol.svg";
import { ReactComponent as Calendar } from "atoms/icon/icons/ic_affirm_Step-3_ol.svg";
import { ReactComponent as Star } from "atoms/icon/icons/ic_affirm_Step-4_ol.svg";
import { TextButton } from "atoms/text-button/TextButton";

interface AffirmModalProps {
  platformLogo: string;
  /** Open/hide */
  open?: boolean;
  /** On close callback */
  onClose?: () => void;
  /** On shop now callback */
  onShopNow?: () => void;
  /** To hide show now button */
  hideShopButton?: boolean;
}

const steps = [
  {
    description: "Choose your favorite services in ‘Shop’",
    Icon: <Cart className="h-8 w-8 fill-brand-color" />,
  },
  {
    description: "Select Affirm at checkout",
    Icon: <Affirm className="mt-1 mb-0.5 h-7" width={55} />,
  },
  {
    description: "Get approved and choose the payment plan that suits you",
    Icon: <Calendar className="h-8 w-8" />,
  },
  {
    description: "Checkout now to treat today, pay later, earn rewards",
    Icon: <Star className="h-8 w-8 fill-brand-color" />,
  },
];
const CloseSvg = () => <Close className="h-5 w-5 text-center" />;
const AffirmSteps = () => (
  <div className="mb-px mt-1 flex flex-wrap items-center justify-center gap-4 gap-y-2">
    {steps.map((step, index: number) => (
      <div key={index} className="mt-4 h-[134px] w-[144px] text-center">
        <div className="my-4 flex h-16 flex-col items-center gap-2">
          {step.Icon}
          <p className="font-serif text-h3 text-primary"> Step {index + 1}</p>
        </div>
        <div className="w-[140px] text-body2 text-primary">
          {step.description}
        </div>
      </div>
    ))}
  </div>
);
export const AffirmModal = ({
  platformLogo,
  open,
  onClose,
  onShopNow,
  hideShopButton,
}: AffirmModalProps) => {
  if (!open) {
    return null;
  }
  return (
    <>
      <div
        className="fixed inset-0 z-40 bg-black/[35%]"
        onClick={onClose}
        data-testid="div-dismiss-modal"
      />
      <div
        className={cx(
          "fixed left-0 right-0 bottom-0 top-0 z-40 mx-auto my-auto flex w-full flex-col",
          "items-center justify-center"
        )}
      >
        <div
          className={cx(
            "rounded-lg",
            hideShopButton ? "h-[526px]" : "h-[575px]",
            "bg-light-grey",
            "w-[374px] bg-[url('assets/images/affirmModalBG.jpeg')] bg-cover bg-center"
          )}
        >
          <div className="pt-0.5">
            <IconButton
              svg={CloseSvg}
              size="small"
              style="iconOnly"
              onClick={onClose}
            />
          </div>
          <div className="mb-4 flex items-end justify-center gap-x-2.5 pt-2.5">
            <Affirm width={98} className="h-12" />
            <p className="mb-px pb-0.5 text-body1 text-primary">with</p>
            <img
              src={platformLogo}
              alt="logo"
              className="max-width-[140px] h-[38px] pb-0.5"
            />
          </div>
          <div className="flex justify-center font-serif text-h3 text-primary">
            Treat today. Pay later. Earn rewards.
          </div>
          <AffirmSteps />
          {!hideShopButton && (
            <div className="mt-10 flex justify-center text-body2 text-primary">
              <TextButton onClick={onShopNow}>
                Shop now <RightIcon className="h-6 w-6 fill-brand-color" />
              </TextButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
