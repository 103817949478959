import { Capacitor } from "@capacitor/core";
import { LoaderFunctionArgs, redirect } from "react-router-dom";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const referralCode = new URL(request.url).searchParams.get("referralCode");

  if (Capacitor.isNativePlatform()) {
    const redirectUrl = referralCode
      ? `find-medspa?referralCode=${referralCode}`
      : "find-medspa";

    return redirect(redirectUrl);
  }

  const redirectUrl = referralCode
    ? `complete-profile?referralCode=${referralCode}`
    : "complete-profile";

  return redirect(redirectUrl);
};
