import { Icon } from "atoms/icon/Icon";

import { ReactComponent as InfoIcon } from "../../icon/icons/info.svg";

export const AFFIRM_MINIMUM_AMOUNT = 50;
export enum AffirmUnavailableReason {
  BelowMinimum = "BelowMinimum",
  ContainsMembership = "ContainsMembership",
}
interface NotAvailableAffirmProps {
  reason: AffirmUnavailableReason;
  isLargeBanner: boolean;
}

export const NotAvailableAffirm = ({
  reason,
  isLargeBanner,
}: NotAvailableAffirmProps) => {
  const classes = {
    bold: isLargeBanner ? "text-bold1" : "text-bold2",
    textMessage: isLargeBanner ? "mb-2 text-body1" : "pl-3 pr-4 text-body2",
    icon: isLargeBanner ? "pr-2 pt-2" : "pl-3",
    wrapper: `rounded bg-background-two ${
      isLargeBanner ? "px-3 py-6" : "items-center py-2.5"
    }`,
  };

  const getAffirmMessage = () => {
    const message =
      reason === AffirmUnavailableReason.ContainsMembership
        ? "when signing up for a new membership"
        : "for cart values under $50";
    return `financing is not available ${message}`;
  };

  return (
    <div className={classes.wrapper}>
      <div className={`flex ${isLargeBanner ? "" : "items-center"}`}>
        <div className={classes.icon}>
          <Icon
            svg={InfoIcon}
            size={isLargeBanner ? "small" : "xsmall"}
            color="secondaryText"
          />
        </div>
        <div className="flex flex-col">
          <div className={classes.textMessage}>
            <span className={classes.bold}>affirm</span> {getAffirmMessage()}
          </div>
          {isLargeBanner && (
            <div className="text-body2 text-secondary">
              To take advantage of affirm financing please check out your
              membership first before checking out the other items in your cart
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
