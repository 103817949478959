import { ReactComponent as OpenReward } from "atoms/icon/icons/open_reward.svg";
import { ActionModal } from "molecules/modal-window/action-modal/ActionModal";

interface MultipleInclusionsModalProps {
  onClose: () => void;
  onButtonClick: () => void;
}

export const MultipleInclusionsModal = ({
  onClose,
  onButtonClick,
}: MultipleInclusionsModalProps) => {
  return (
    <ActionModal
      size="small"
      open={true}
      onClose={onClose}
      onFirstButtonClick={onButtonClick}
      firstButtonLabel="Shop Now"
      title="This reward cannot be redeemed yet"
      description="You can redeem it once you have at least one valid item in your cart - shop now to add to your cart and redeem this reward!"
    />
  );
};
