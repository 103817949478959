import cx from "classnames";

import { ReactComponent as Affirm } from "../../icon/icons/affirm.svg";

interface AffirmBannerProps {
  hasBorderRadius?: boolean;
  hasShadow?: boolean;
  hasColorGradient?: boolean;
  onClick?: () => void;
}

export const AffirmBanner = ({
  hasBorderRadius,
  hasShadow,
  hasColorGradient,
  onClick,
}: AffirmBannerProps) => {
  return (
    <div
      onClick={onClick}
      className={cx(
        "flex",
        "cursor-pointer",
        "text-secondary",
        "bg-light-grey",
        "bg-[url('assets/images/affirmBannerBG.jpeg')] bg-cover bg-center",
        hasBorderRadius ? "rounded-lg" : "",
        hasShadow ? "shadow-[0_2px_10px_0px_rgba(0,0,0,0.06)]" : ""
      )}
    >
      <div
        className={cx(
          "flex",
          "w-full",
          "h-[60px]",
          "items-center",
          "justify-center",
          hasBorderRadius ? "rounded-lg" : "",
          hasColorGradient
            ? "bg-gradient-to-r from-black/5 via-light-grey/40 to-black/5"
            : ""
        )}
      >
        <Affirm width={58} height={28} className="mt-0.5" />
        <div className="ml-5">
          <p className="text-bold2 text-primary">
            Treat today. Pay later. Earn rewards.
          </p>
          <p className="text-body3">Make monthly payments with no late fees</p>
        </div>
      </div>
    </div>
  );
};
