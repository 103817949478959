import { useState } from "react";
import {
  json,
  LoaderFunction,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get } from "api";
import { AffirmBanner } from "atoms/affirm/affirmBanner/AffirmBanner";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import useDunningMembership from "hooks/useDunningMemberships";
import { AffirmModal } from "molecules/affirm-modal/AffirmModal";
import { ProductCardLarge } from "molecules/cards/product-card-large/ProductCardLarge";
import { queryClient } from "pages/Root";
import { FilterAndSortListings } from "pages/root/shop/FilterAndSortListings";
import { notNull } from "toolbox/Guards";
import { waitObj } from "toolbox/Promise";

import { ShopListHeader } from "./ShopListHeader/ShopListHeader";

const load = async () => {
  const shopData = queryClient.fetchQuery(["/shop"], () => get("/shop"));
  const listings = queryClient.fetchQuery(
    ["/listings"],
    () => get("/listings"),
    { staleTime: 1000 * 60 }
  );
  const theme = queryClient.fetchQuery(["/platform/theme"], () =>
    get("/platform/theme")
  );
  const categories = queryClient.fetchQuery(["shop/categories"], () =>
    get("/shop/categories")
  );
  const platform = queryClient.fetchQuery(["/platform"], () =>
    get("/platform")
  );

  return waitObj({ platform, shopData, listings, categories, theme });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const shopPackagesLoader: LoaderFunction = async () => {
  return json<LoaderData>(await load());
};

const ShopPackagesPage = () => {
  const { platform, listings, shopData, categories, theme } =
    useLoaderData() as LoaderData;
  const navigate = useNavigate();
  const [showAffirmModal, setShowAffirmModal] = useState(false);
  const headerImage = theme.resources?.find(
    (resource) => resource.key === "curated-packages-header"
  )?.url;

  const packages = listings.filter((listing) => listing.type == "Package");

  const filteredConcerns =
    shopData.concerns?.filter((concern) =>
      packages.flatMap((listing) => listing.concernIds).includes(concern.id)
    ) ?? [];

  const filteredAreas =
    shopData.areas?.filter((area) =>
      packages.flatMap((listing) => listing.areaIds).includes(area.id)
    ) ?? [];

  const packagesCategory = categories.find(
    (c) => c.defaultCategoryId === "packages"
  );
  const { dunningMembership } = useDunningMembership();
  return (
    <div className="flex flex-col bg-two">
      {platform.platformSettings?.affirmEnabled && (
        <AffirmModal
          platformLogo={platform.logoImageUrl ?? ""}
          open={showAffirmModal}
          hideShopButton
          onClose={() => setShowAffirmModal(false)}
        />
      )}
      {packagesCategory && (
        <ShopListHeader
          title={packagesCategory.title}
          subtitle={packagesCategory.description}
          bgGradient
          // TODO(CAA-158) using the header image from theme until we implement CAA-158
          backgroundImageUrl={headerImage}
        />
      )}
      {packages.length && (
        <FilterAndSortListings
          types={["Package"]}
          areas={filteredAreas}
          concerns={filteredConcerns}
          listings={packages}
        >
          {(listing, index) => (
            <>
              <ProductCardLarge
                key={listing.id}
                title={listing.title}
                description={listing.description}
                imageUrl={listing.imageUrl}
                type={listing.type}
                tag={""}
                concerns={filteredConcerns
                  .filter((concern) => listing.concernIds.includes(concern.id))
                  .map((concern) => concern.name)
                  .filter(notNull)}
                price={listing.baseCost}
                memberPrice={listing.membershipCost}
                onClick={() => navigate(`/pdp/package/${listing.id}`)}
              />
              {dunningMembership.status === false &&
                platform.platformSettings?.affirmEnabled &&
                (packages.length < 2 || index === 1) && (
                  <AffirmBanner
                    onClick={() => setShowAffirmModal(true)}
                    hasColorGradient
                    hasBorderRadius
                    hasShadow
                  />
                )}
            </>
          )}
        </FilterAndSortListings>
      )}
      <RepeatMDFooter />
    </div>
  );
};

export default ShopPackagesPage;
