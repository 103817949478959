type AwaitedObject<T extends Record<string, unknown>> = {
  [K in keyof T]: Awaited<T[K]>;
};

/**
 * Accepts an object with awaitable values, and awaits all of them in parallel
 */
export const waitObj = async <O extends Record<string, unknown>>(
  awaitables: O
): Promise<AwaitedObject<O>> => {
  const keys = Object.keys(awaitables);
  const results = await Promise.all(Object.values(awaitables));

  return Object.fromEntries(
    keys.map((k, i) => [k, results[i]])
  ) as AwaitedObject<O>;
};
