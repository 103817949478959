import { FC, SVGProps } from "react";

import { Button } from "atoms/button/Button";
import { IconButton } from "atoms/icon-button/IconButton";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Right } from "atoms/icon/icons/chevron_right_small.svg";
import { ReactComponent as Facebook } from "atoms/icon/icons/facebook.svg";
import { ReactComponent as Instagram } from "atoms/icon/icons/instagram.svg";
import { ReactComponent as Mail } from "atoms/icon/icons/mail.svg";
import { ReactComponent as MapPin } from "atoms/icon/icons/map_pin.svg";
import { ReactComponent as Phone } from "atoms/icon/icons/phone.svg";
import { ReactComponent as Twitter } from "atoms/icon/icons/twitter.svg";
import { BadgeTag } from "atoms/tag/BadgeTag";
import { TextButton } from "atoms/text-button/TextButton";
import { IfFlag, useFeatureFlag } from "services/Flags";

type Tag = { alert: string } | { primary: string } | { secondary: string };
const Tag = (props: Tag) => {
  if ("alert" in props) {
    return <BadgeTag color="alert">{props.alert}</BadgeTag>;
  } else if ("primary" in props) {
    return <BadgeTag color="primary">{props.primary}</BadgeTag>;
  } else if ("secondary" in props) {
    return <BadgeTag color="secondary">{props.secondary}</BadgeTag>;
  } else {
    return null;
  }
};

interface StandardProps {
  /** SVG icon to render at the very top of the card */
  svg?: FC<SVGProps<SVGSVGElement>> | null;

  /** SVG icon to render next to the button text, if any is applicable */
  buttonSvg?: FC<SVGProps<SVGSVGElement>> | null;
  /** Allcaps text that appears at the top of the card */
  subtitle?: string | null;

  /** Source url of the banner image */
  imageSrc?: string | null;

  /** Tag that shows up on the upper left of the card */
  tag?: Tag | null;

  /** The main text of this card */
  title: string;

  /** Descriptive text that appears below the main title */
  description: string;

  /** Text to render as the primary button */
  primary: string;

  /** Text to render as a secondary button */
  secondary?: string | null;

  /** Handler that is called when either the primary or secondary buttons are clicked */
  onButtonClick: (button: "primary" | "secondary") => void;
}

export const StandardCard = (props: StandardProps) => {
  return (
    <div className="flex w-full flex-col items-center px-4">
      {props.svg && (
        <div className="pt-6">
          <Icon size="xsmall" svg={props.svg} color="brandColor" />
        </div>
      )}

      {props.subtitle && (
        <div className="pt-6 text-sub1 uppercase text-brand-color">
          {props.subtitle}
        </div>
      )}

      {props.imageSrc && (
        <div className="relative w-full pt-6 first:pt-8">
          {props.tag && (
            <div className="absolute left-3 top-12">
              <Tag {...props.tag} />
            </div>
          )}
          <img
            className="aspect-video w-full rounded object-cover"
            src={props.imageSrc}
          />
        </div>
      )}

      <div className="flex flex-col gap-2 pt-6 text-center">
        <h1 className="font-serif text-h1 text-primary">{props.title}</h1>
        <div className="text-body1 text-secondary">{props.description}</div>
      </div>

      <div className="flex w-full flex-col gap-3 pt-5 pb-6">
        <Button
          style="primary"
          fullWidth
          onClick={() => props.onButtonClick("primary")}
        >
          {props.buttonSvg && (
            <>
              <Icon svg={props.buttonSvg} size="xsmall" color="white" />
              <span className="ml-1">{props.primary}</span>
            </>
          )}
          {!props.buttonSvg && props.primary}
        </Button>
        {props.secondary && (
          <TextButton
            endIconSVG={Right}
            onClick={() => props.onButtonClick("secondary")}
          >
            {props.secondary}
          </TextButton>
        )}
      </div>
    </div>
  );
};

interface ContactProps {
  /** Allcaps text that appears at the top of the card */
  subtitle: string;

  /** Source url of the banner image */
  imageSrc?: string | null;

  /** Logo icon that's used in place of the banner image, if one is not provided */
  logoSrc?: string | null;

  /** Address line to display below the image */
  address?: string | null;

  /** Email line to display below the image */
  email?: string | null;

  /** Phone number to display below the image */
  phone?: string | null;

  /** Text to render as the primary button */
  primary: string;

  /** Text to render as a secondary button */
  secondary?: string | null;

  /** Handler that is called when either the primary or secondary buttons are clicked */
  onButtonClick: (button: "primary" | "secondary") => void;

  /** Facebook url */
  facebook?: string | null;

  /** Twitter url */
  twitter?: string | null;

  /** Instagram url */
  instagram?: string | null;

  /** If true, the primary button will be disabled */
  firstButtonDisabled?: boolean;
}

export const ContactUsCard = (props: ContactProps) => {
  const unitedDermFlag = useFeatureFlag("unitedDerm");
  if (!props.address && !props.email && !props.phone) return null;

  return (
    <div className="flex flex-col items-center gap-6 px-4 py-6">
      <div className="text-sub1 uppercase text-brand-color">
        {props.subtitle}
      </div>

      {props.imageSrc && (
        <img
          className="aspect-video w-full rounded object-cover"
          src={props.imageSrc}
        />
      )}

      {!props.imageSrc && props.logoSrc && (
        <div className="flex w-full items-center justify-center rounded bg-two p-10">
          <img
            className="aspect-video max-h-full max-w-full object-contain"
            src={props.logoSrc}
          />
        </div>
      )}

      <div className="grid w-full grid-cols-[min-content_auto] items-center gap-3 px-3 text-body1 text-primary">
        {props.address && (
          <>
            <Icon svg={MapPin} size="xsmall" color="brandColor" />
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                props.address
              )}`}
            >
              {props.address}
            </a>
          </>
        )}
        {props.email && (
          <>
            <Icon svg={Mail} size="xsmall" color="brandColor" />
            {props.email}
          </>
        )}
        {props.phone && (
          <>
            <Icon svg={Phone} size="xsmall" color="brandColor" />
            <a href={`tel:${props.phone}`}>{props.phone}</a>
          </>
        )}
      </div>

      <div className="flex w-full flex-col gap-4">
        <IfFlag equals={false} flag="unitedDerm">
          <Button
            style="primary"
            fullWidth
            onClick={() => props.onButtonClick("primary")}
            disabled={props.firstButtonDisabled}
          >
            {props.primary}
          </Button>
        </IfFlag>
        {props.secondary && (
          <Button
            style={unitedDermFlag ? "primary" : "secondary"}
            fullWidth
            onClick={() => props.onButtonClick("secondary")}
          >
            {props.secondary}
          </Button>
        )}
      </div>

      {(props.facebook || props.twitter || props.instagram) && (
        <div className="flex justify-evenly gap-8">
          {props.facebook && (
            <IconButton svg={Facebook} size="small" style="brandIcon" />
          )}
          {props.twitter && (
            <IconButton svg={Twitter} size="small" style="brandIcon" />
          )}
          {props.instagram && (
            <IconButton svg={Instagram} size="small" style="brandIcon" />
          )}
        </div>
      )}
    </div>
  );
};

type CardProps =
  | (StandardProps & { variant?: "standard" })
  | (ContactProps & { variant: "contactUs" });

/**
 * A large card component featuring a single item, with configurable elements.
 *
 * Available in multiple variants, where `"standard"` (title + description) is
 * the default.
 */
const CampaignCard = (props: CardProps) => {
  if (props.variant === "contactUs") {
    return <ContactUsCard {...props} />;
  } else {
    return <StandardCard {...props} />;
  }
};

export default CampaignCard;
