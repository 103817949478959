import cx from "classnames";
import { FC, ReactNode, SVGProps } from "react";

import { Button } from "atoms/button/Button";
import { IconButton } from "atoms/icon-button/IconButton";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Close } from "atoms/icon/icons/close.svg";
import { Modal } from "atoms/modal/Modal";
import { TextButton } from "atoms/text-button/TextButton";

export type ActionModalSize = "xsmall" | "small" | "medium";
export type ActionModalIconColor = "primaryText" | "brandColor" | "red";

type BaseActionModalProps = {
  open?: boolean;
  headerIconSvg?: FC<SVGProps<SVGSVGElement>>;
  headerIconColor?: ActionModalIconColor;
  onClose: () => void;
  onFirstButtonClick: () => void;
  firstButtonLabel: string;
  onSecondButtonClick?: undefined;
  secondButtonLabel?: undefined;
  title: string;
  description: ReactNode;
  size: ActionModalSize;
};

type WithSecondButtonProps = {
  onSecondButtonClick: () => void;
  secondButtonLabel: string;
} & Omit<BaseActionModalProps, "secondButtonLabel" | "onSecondButtonClick">;

type ActionModalProps = BaseActionModalProps | WithSecondButtonProps;

export const ActionModal = ({
  open,
  onClose,
  onFirstButtonClick,
  firstButtonLabel,
  onSecondButtonClick,
  secondButtonLabel,
  headerIconSvg,
  headerIconColor,
  title,
  description,
  size,
}: ActionModalProps) => {
  const sizes: { [key: string]: string } = {
    xsmall: "min-h-[180px]",
    smallIcon: "min-h-[360px]",
    smallNoIcon: "min-h-[340px]",
    medium: "min-h-[540px]",
  };
  const isMedium = size === "medium";

  const getSizeClass = (size: string) => {
    if (size === "small" && headerIconSvg) {
      return sizes.smallIcon;
    } else if (size === "small" && !headerIconSvg) {
      return sizes.smallNoIcon;
    }
    return sizes[size];
  };

  return (
    <Modal
      size={getSizeClass(size)}
      open={open}
      onClose={onClose}
      footer={
        <div
          className={cx(
            "flex w-full flex-col items-center gap-2.5 px-4",
            secondButtonLabel ? "pt-2 pb-2.5" : "py-4"
          )}
        >
          <Button fullWidth onClick={onFirstButtonClick}>
            {firstButtonLabel}
          </Button>
          {secondButtonLabel && (
            <div className="pr-3">
              <TextButton onClick={onSecondButtonClick}>
                {secondButtonLabel}
              </TextButton>
            </div>
          )}
        </div>
      }
    >
      <>
        <div className="ml-3.5 mt-3.5">
          <IconButton
            svg={Close}
            size="small"
            style="iconOnly"
            onClick={onClose}
          />
        </div>
        <div className="mx-4">
          {headerIconSvg && (
            <div
              className={cx(
                "flex items-center justify-center",
                isMedium ? "mb-10" : "mb-6"
              )}
            >
              <Icon
                svg={headerIconSvg}
                size={isMedium ? "xlarge" : "medium"}
                color={headerIconColor || "primaryText"}
              />
            </div>
          )}
          <div
            className={cx(
              "primary-text text-center font-serif",
              !headerIconSvg && "mt-3",
              size === "medium" ? "mb-8 text-h1" : "mb-6 text-h2"
            )}
          >
            {title}
          </div>

          <div className="text-center font-sans text-body1 text-secondary">
            {description}
          </div>
        </div>
      </>
    </Modal>
  );
};
