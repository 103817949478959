import { useState } from "react";

interface ExpectProps {
  /** Specify the title for the current expectation*/
  title: string;

  /** Specify the body for the current expectation*/
  body: string;
}

export const WhatToExpect = ({ title, body }: ExpectProps) => {
  const [expanded, setExpanded] = useState(false);

  const truncate = body.length > 200;
  const truncated = body.slice(0, 200).concat("...");

  const expander = (
    <button onClick={() => setExpanded(!expanded)} className="text-brand-color">
      {expanded ? "Read less" : "Read more"}
    </button>
  );

  return (
    <div className="leading-1 mx-4 mb-3 flex flex-col rounded border border-white bg-white py-6 px-6">
      <div className="text-center text-sub2 uppercase">{title}</div>
      <div className="pt-3 text-body1 text-secondary">
        {truncate && !expanded ? truncated : body}
        {truncate && expander}
      </div>
    </div>
  );
};
