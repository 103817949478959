import { Models } from "api/types";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as RewardIcon } from "atoms/icon/icons/rewards_nav.svg";

export interface RewardUnlockBannerProps {
  rewardCount: number;
  rewards?: Models<"RewardSummaryItemModel">[] | null;
}

export const RewardUnlockBanner = ({
  rewardCount,
  rewards,
}: RewardUnlockBannerProps) => {
  if (rewardCount === 0) {
    return null;
  }

  return (
    <div className="flex flex-row items-center rounded bg-two p-4">
      <Icon svg={RewardIcon} color="brandColor" />
      <div className="flex flex-col justify-around gap-1">
        <div className="px-2 text-left text-bold2 text-primary">
          {rewardCount > 1
            ? `This purchase unlocks ${rewardCount} new rewards!`
            : "This purchase unlocks a new reward!"}
        </div>
        {rewards?.map((reward) => {
          return (
            <div
              key={reward.title}
              className="px-2 text-left text-body2 text-secondary"
            >
              {reward.title}
            </div>
          );
        })}
      </div>
    </div>
  );
};
