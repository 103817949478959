import { Capacitor } from "@capacitor/core";
import { isMobile, isSafari } from "react-device-detect";

import { Button } from "atoms/button/Button";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as RepeatMDLogo } from "atoms/icon/icons/asset_Repeat_app_icon_small_ol.svg";

interface OpenMobileAppBannerProps {
  onClick?: () => void;
}

export const OpenMobileAppBanner = (props: OpenMobileAppBannerProps) => {
  if (!isMobile || isSafari || Capacitor.isNativePlatform()) {
    return null;
  }

  return (
    <div className="flex items-center justify-between border-b-[0.75px] border-light-grey px-3 py-2 text-left">
      <div className="flex items-center gap-2">
        <Icon svg={RepeatMDLogo} />
        <div className="text-body2">Open in the Patient Rewards app</div>
      </div>
      <div>
        <Button onClick={props.onClick} size="xsmall">
          Open
        </Button>
      </div>
    </div>
  );
};
