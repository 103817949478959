import {
  Elements,
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe, PaymentRequest } from "@stripe/stripe-js";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { get, post } from "api";
import { Button } from "atoms/button/Button";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Affirm } from "atoms/icon/icons/affirm.svg";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right.svg";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { TextField } from "atoms/text-field/TextField";
import TopNav from "molecules/navbars/TopNav";
import { OrderSummary } from "organisms/order-summary/OrderSummary";
import { ModalSelectPaymentMethodList } from "organisms/selection-modals/modal-select-payment-method-list/ModalSelectPaymentMethodList";
import { queryClient } from "pages/Root";
import { formatCurrency } from "toolbox/Money";
import { formatPhoneNumber } from "toolbox/PhoneNumber";
import { waitObj } from "toolbox/Promise";

import PaymentMethodButton from "./PaymentMethodButton";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY || "");

const load = async () => {
  const userInfo = queryClient.fetchQuery(["/user"], () => get("/user"), {
    staleTime: 1000 * 60 * 2,
  });
  const platform = queryClient.fetchQuery(["platform"], () => get("/platform"));
  const cartDetails = queryClient.fetchQuery(["orders/current/details"], () =>
    get("/orders/current/details")
  );
  const paymentMethods = queryClient.fetchQuery(["/payment-methods"], () =>
    get("/payment-methods", {})
  );

  return await waitObj({
    userInfo,
    platform,
    cartDetails,
    paymentMethods: paymentMethods.then((p) => p.items),
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const checkoutSelectPaymentLoader: LoaderFunction = async () => {
  const data = await load();

  if (data.cartDetails.estimatedTotal <= 0) {
    return redirect(
      `/cart/checkout/success?shopDisplayOrderId=${data.cartDetails.shopDisplayOrderId}`
    );
  }

  return json<LoaderData>(data);
};

const isValidEmail = (email: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

const CheckoutSelectPaymentMethodPageInternal = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userInfo, platform, cartDetails, paymentMethods } =
    useLoaderData() as LoaderData;
  const defaultPaymentMethod = paymentMethods?.find(
    (pm) => pm.isDefault == true
  );
  const paramSelectPaymentMethod = paymentMethods?.find(
    (pm) => pm.paymentMethodId == searchParams.get("selectedPaymentMethodId")
  );
  const affirmCostPerMonth = Math.round(cartDetails.estimatedTotal / 24);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paramSelectPaymentMethod ? paramSelectPaymentMethod : defaultPaymentMethod
  );
  const [paymentModal, openPaymentModal] = useState(false);
  const [emailAddress, setEmailAddress] = useState(userInfo.email);
  const [invalidEmail, setInvalidEmail] = useState<boolean>();

  const containsMembership =
    cartDetails.shopDisplayOrderLineItems?.find(
      (item) => item.shopDisplayMembershipId != null
    ) != null;

  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: platform.name + " Checkout",
          amount: cartDetails.estimatedTotal,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
      });
      // Check if ApplePay or Google Pay are available
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [cartDetails.estimatedTotal, platform.name, stripe]);

  const placeOrder = useMutation({
    mutationFn: () =>
      post("/orders/{shopDisplayOrderId}/transactions", {
        shopDisplayOrderId: cartDetails.shopDisplayOrderId,
        paymentProcessorPaymentMethodId:
          selectedPaymentMethod?.paymentMethodDetails
            .paymentProcessorPaymentMethodId,
        orderEmail: emailAddress,
      }),
    onSuccess: ({ orderTransactionId }) => {
      navigate(
        `/cart/checkout/success?shopDisplayOrderId=${cartDetails?.shopDisplayOrderId}&orderTransactionId=${orderTransactionId}`
      );
    },
  });

  const setupWalletPayment = async () => {
    const transactionDetails = post(
      "/orders/{shopDisplayOrderId}/transactions",
      {
        shopDisplayOrderId: cartDetails.shopDisplayOrderId,
        orderEmail: emailAddress,
      }
    );

    return waitObj({ transactionDetails });
  };

  paymentRequest?.on("paymentmethod", async (ev) => {
    if (!stripe) {
      return;
    }
    const transactionDetails = (await setupWalletPayment()).transactionDetails;
    const clientSecret = transactionDetails.clientSecret || "";
    const { paymentIntent, error: confirmError } =
      await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );
    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      ev.complete("fail");
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete("success");
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
        } else {
          // The payment has succeeded.
          navigate(
            `/cart/checkout/success?shopDisplayOrderId=${cartDetails?.shopDisplayOrderId}&orderTransactionId=${transactionDetails.orderTransactionId}`
          );
        }
      } else {
        navigate(
          `/cart/checkout/success?shopDisplayOrderId=${cartDetails?.shopDisplayOrderId}&orderTransactionId=${transactionDetails.orderTransactionId}`
        );
      }
    }
  });

  const paymentMethodItems = paymentMethods?.map((paymentMethod) => ({
    id: paymentMethod.paymentMethodId,
    name:
      "***" + paymentMethod.paymentMethodDetails.displayNumber?.split(" ")[3],
    cardType: paymentMethod.paymentMethodDetails.cardBrand,
  }));
  paymentMethodItems?.push({
    id: "addnewcard",
    name: "Add a new card",
    cardType: "Unknown",
  });

  return (
    <>
      <TopNav
        startIconSVG={ChevronLeft}
        onStartIconClick={() => {
          navigate(-1);
        }}
        showHeaderItems={false}
      >
        <div className="flex max-h-[32px] items-center">
          <div className="flex w-full items-center justify-center pr-6 text-sub2 text-primary">
            CHECKOUT
          </div>
        </div>
      </TopNav>
      <div className="flex flex-col gap-2 p-5">
        <div className="text-bold1 text-primary">Contact Info</div>
        <TextField
          type="text"
          label="Email Address"
          name="email"
          invalid={invalidEmail}
          value={emailAddress || ""}
          onChange={(value) => {
            setEmailAddress(value);
          }}
          onBlur={(value) => {
            setInvalidEmail(!isValidEmail(value));
          }}
        />
        <TextField
          type="tel"
          label="Phone Number"
          name="phone"
          disabled
          value={formatPhoneNumber(userInfo.phoneNumber)}
        />
      </div>
      <hr className="mt-2 bg-light-grey" />
      <div className="flex flex-col gap-2 p-5">
        <div className="text-bold1 text-primary">Payment Method</div>
        <PaymentMethodButton
          id="default"
          text={
            selectedPaymentMethod
              ? "***" +
                  selectedPaymentMethod.paymentMethodDetails.displayNumber?.split(
                    " "
                  )[3] || "Card"
              : "Add credit/debit card"
          }
          cardType={selectedPaymentMethod?.paymentMethodDetails.cardBrand}
          selected={false}
          onSelect={() => openPaymentModal(true)}
        />
        {!containsMembership && platform.platformSettings?.affirmEnabled && (
          <div
            className="flex cursor-pointer items-center justify-between rounded border-[1px] border-solid border-dark-grey px-4"
            onClick={() => {
              navigate("/cart/checkout/element?type=affirm");
            }}
          >
            <div className="inline-flex items-center gap-3 text-body2 text-primary">
              As low as {formatCurrency(affirmCostPerMonth, 2)}/month with
              <div className="pt-5">
                <Icon svg={Affirm} size="large" />
              </div>
            </div>

            <div className="flex flex-row items-center">
              <div className="ml-2">
                <Icon svg={ChevronRight} color="brandColor" size="xsmall" />
              </div>
            </div>
          </div>
        )}
        {!containsMembership && paymentRequest && (
          <PaymentRequestButtonElement
            options={{
              paymentRequest,
              style: {
                paymentRequestButton: {
                  type: "buy",
                  height: "52px",
                },
              },
            }}
          />
        )}
      </div>
      <hr className="mt-2 bg-light-grey" />
      <div className="p-5">
        {cartDetails && (
          <OrderSummary
            cart={cartDetails}
            displayProducts={true}
            feePercentage={
              platform?.platformSettings?.patientFeePercentage || 0
            }
          />
        )}
        <div className="sticky bottom-0 w-full bg-white pb-[calc(20px_+_env(safe-area-inset-bottom))] empty:hidden">
          <RepeatMDFooter showShopTerms />
          <Button
            fullWidth
            disabled={
              selectedPaymentMethod == null || invalidEmail || !emailAddress
            }
            onClick={() => placeOrder.mutate()}
          >
            Place order
          </Button>
        </div>
      </div>
      {paymentModal && (
        <ModalSelectPaymentMethodList
          open={paymentModal}
          onClose={() => openPaymentModal(false)}
          title="SELECT A PAYMENT METHOD"
          currentId={selectedPaymentMethod?.paymentMethodId}
          onSelect={(paymentMethodId) => {
            setSelectedPaymentMethod(
              paymentMethods?.find(
                (paymentMethod) =>
                  paymentMethod.paymentMethodId == paymentMethodId
              )
            );
          }}
          onEdit={(paymentMethodId) => {
            if (paymentMethodId == "addnewcard") {
              navigate("/cart/checkout/paymentmethod/new?cartFlow=true");
            } else {
              navigate("/cart/checkout/paymentmethod/" + paymentMethodId);
            }
          }}
          items={paymentMethodItems}
        />
      )}
    </>
  );
};

const CheckoutSelectPaymentMethodPage = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutSelectPaymentMethodPageInternal />
    </Elements>
  );
};

export default CheckoutSelectPaymentMethodPage;
