import { Preferences } from "@capacitor/preferences";
import { useContext } from "react";
import {
  json,
  Link,
  LoaderFunction,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { Models } from "api/types";
import { IconButton } from "atoms/icon-button/IconButton";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { useAppState } from "contexts/AppContext";
import { useChangeIcon } from "hooks/useChangeIcon";
import { PlatformLocationsList } from "organisms/platform-locations-list/PlatformLocationsList";
import { queryClient, ThemeContext } from "pages/Root";
import Services from "services/Services";

const load = async () => {
  const { value: platformDomainTokensStringified } = await Preferences.get({
    key: "platformDomainTokens",
  });
  const platformDomainTokens: Models<"GetMultiPlatformUserPlatformResult">[] =
    JSON.parse(platformDomainTokensStringified || "[]");

  return {
    platformDomainTokens,
  };
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const loader: LoaderFunction = async () => {
  return json<LoaderData>(await load());
};

export const SwitchMedspaPage = () => {
  const { platformDomainTokens } = useLoaderData() as LoaderData;

  const appState = useAppState();
  const navigate = useNavigate();
  const { resetTheme } = useContext(ThemeContext);
  const changeIcon = useChangeIcon(platformDomainTokens);

  const handlePlatformClick = (id: string) => {
    const platform = platformDomainTokens.find(
      (platformDomainToken) => platformDomainToken.platformId === id
    );
    appState.setApiUrl(
      appState.apiUrl
        ? platform?.platformDomain
          ? "https://" + platform?.platformDomain
          : ""
        : ""
    );
    appState.setAppIcons(
      platformDomainTokens.map(
        (platform) => `launcher_${platform.platformSubdomain}`
      ) || []
    );
    appState.setPlatformId(platform?.platformId || "");
    Services.auth.setAuthToken(platform?.token || "");
    queryClient.clear();
    changeIcon(platform?.platformSubdomain || "");
    resetTheme();
    navigate("/home");
  };

  return (
    <>
      <div className="sticky top-0 bg-white pt-[calc(20px_+_env(safe-area-inset-top))] pl-3 shadow-[0px_2px_4px_rgba(0,0,0,0.05)]">
        <span className="flex items-center justify-between">
          <Link to="..">
            <IconButton size="small" style="iconOnly" svg={ChevronLeft} />
          </Link>

          <div className="text-sub2 uppercase">Select your location</div>
          <div className="invisible">
            <IconButton size="small" style="iconOnly" svg={ChevronLeft} />
          </div>
        </span>
      </div>

      <div className="bg-one px-4 pt-4">
        <PlatformLocationsList
          platforms={platformDomainTokens.map((platformDomainToken) => ({
            id: platformDomainToken.platformId,
            imageUrl: platformDomainToken.platformImageUrl,
            name: platformDomainToken.platformName,
            isBlocked: platformDomainToken.isBlocked,
          }))}
          onItemClick={handlePlatformClick}
        />

        <RepeatMDFooter />
      </div>
    </>
  );
};
