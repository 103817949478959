import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { FC, SVGProps } from "react";
import {
  json,
  LoaderFunction,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get, post } from "api";
import { GetResult, Models } from "api/types";
import { Button } from "atoms/button/Button";
import { IconButton } from "atoms/icon-button/IconButton";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as Left } from "atoms/icon/icons/chevron_left.svg";
import { ReactComponent as Offers } from "atoms/icon/icons/offers_nav.svg";
import { ReactComponent as QrCode } from "atoms/icon/icons/qr_code.svg";
import { ReactComponent as Shop } from "atoms/icon/icons/shop_nav.svg";
import { BadgeTag } from "atoms/tag/BadgeTag";
import TopNav from "molecules/navbars/TopNav";
import CampaignCard from "organisms/campaign-card/CampaignCard";
import ProductCarousel from "organisms/product-carousel/ProductCarousel";
import { ReferAFriend } from "organisms/refer-a-friend/ReferAFriend";
import { queryClient } from "pages/Root";
import { notFalsy, notNull } from "toolbox/Guards";
import { waitObj } from "toolbox/Promise";
import { FormattedString } from "utils/FormattedStrings";

/**
 * An information panel similar to a ProductCard, but which covers the entire
 * screen and allows for richer description formatting
 */
const InfoOverlay = (props: {
  header: string;
  imageSrc?: string | null;
  title: string;
  description: string;
  disclaimer?: string;
  primary?: string;
  showNavbar: boolean;
  isInStoreRedeemable?: boolean | null;
  expiry?: dayjs.Dayjs | null;
  onClose(): void;
  onButtonClick?(): void;
}) => {
  return (
    <div className="absolute top-0 left-0 right-0 z-10 -m-px flex min-h-full flex-col bg-white pb-[safe-area-inset-bottom] sm:border">
      {props.showNavbar && (
        <div className="sticky top-0 z-20 flex items-center justify-between bg-white pt-[env(safe-area-inset-top)]">
          <IconButton svg={Left} style="iconOnly" onClick={props.onClose} />
          <div className="absolute left-1/2 -translate-x-1/2 text-center text-sub2 uppercase">
            {props.header}
          </div>
        </div>
      )}
      {props.imageSrc && (
        <div className="relative">
          {props.expiry && (
            <div className="absolute top-3 left-3">
              <BadgeTag color="alert">{`Expires ${props.expiry.fromNow()}`}</BadgeTag>
            </div>
          )}
          <img
            className="aspect-video w-full object-cover"
            src={props.imageSrc}
          />
        </div>
      )}
      <div className="flex flex-auto flex-col gap-6 p-6 pb-[calc(10px_+_env(safe-area-inset-bottom))] text-primary">
        <h1 className="font-serif text-h1">{props.title}</h1>
        <FormattedString>{props.description}</FormattedString>
        <p className="text-body2 italic text-secondary">{props.disclaimer}</p>
      </div>
      <div className="sticky bottom-0 w-full bg-white p-4 pb-[env(safe-area-inset-bottom)] empty:hidden">
        {!props.isInStoreRedeemable && props.primary && (
          <Button fullWidth onClick={props.onButtonClick}>
            {props.primary}
          </Button>
        )}
        {props.isInStoreRedeemable && (
          <Button
            fullWidth
            size="large"
            onClick={props.onButtonClick}
            data-testid="button-redeem-offer"
          >
            <Icon svg={QrCode} size="xsmall" color="white" />
            <span className="ml-1">Scan in office to claim offer</span>
          </Button>
        )}
      </div>
    </div>
  );
};

const PromotionInfo = (props: {
  widgetId?: string | null;
  promotionId: string;
  showNavbar: boolean;
}) => {
  const navigate = useNavigate();
  const { data: widget } = useQuery({
    queryKey: ["/widgets", { page: "DiscoverPage" }],
    queryFn: () => get("/widgets", { page: "DiscoverPage" }),
    select: (data) => data.find((w) => w.id === props.widgetId),
  });
  const { data: promotion } = useQuery({
    queryKey: ["/promotions"],
    queryFn: () => get("/promotions"),
    staleTime: 1000 * 60 * 2,
    select: (data) => data.find((p) => p.id === props.promotionId),
  });
  const redeem = useMutation({
    mutationFn: () =>
      post("/promotions/{promotionId}/redeem", {
        promotionId: props.promotionId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["/promotions"]);
      navigate(`/account/offers/${props.promotionId}?claimed=1`);
    },
  });

  const { data: authClaims } = useQuery({
    queryKey: ["/auth/claims"],
    queryFn: () => get("/auth/claims"),
  });

  const isAdminImpersonation = !!authClaims?.claims.adminUserId;

  if (!promotion) return null;

  const expiry = promotion.expiresOn ? dayjs(promotion.expiresOn) : null;

  return (
    <InfoOverlay
      header="Learn More!"
      imageSrc={promotion.imageUrl}
      title={promotion.title}
      description={promotion.description}
      disclaimer={promotion.disclaimer || undefined}
      primary={widget?.primaryText || "Claim Offer Now"}
      showNavbar={props.showNavbar}
      isInStoreRedeemable={
        promotion.isInStoreRedeemable && !isAdminImpersonation
      }
      expiry={expiry}
      onButtonClick={() => {
        if (promotion.isInStoreRedeemable && !isAdminImpersonation) {
          navigate(`/scan-redeem-offer-in-location`, {
            state: {
              promotionId: promotion.id,
            },
          });
        } else {
          if (widget?.primaryUrl) open(widget.primaryUrl, "_blank");
          redeem.mutate();
        }
      }}
      onClose={() => navigate("")}
    />
  );
};

const CustomWidgetInfo = (props: { widgetId: string; showNavbar: boolean }) => {
  const navigate = useNavigate();
  const { data: widget } = useQuery({
    queryKey: ["/widgets", { page: "DiscoverPage" }],
    queryFn: () => get("/widgets", { page: "DiscoverPage" }),
    select: (data) => data.find((w) => w.id === props.widgetId),
  });

  const imageSrc = widget?.widgetData[0].imageUrl;
  const title = widget?.widgetData[0].title;
  const description = widget?.widgetData[0].description;
  const primary = widget?.primaryUrl ? widget.primaryText : undefined;

  if (!title || !description) return null;

  return (
    <InfoOverlay
      header="Learn More"
      imageSrc={imageSrc}
      title={title}
      description={description}
      primary={primary ?? undefined}
      showNavbar={props.showNavbar}
      onButtonClick={() => {
        if (widget.primaryUrl) open(widget.primaryUrl, "_blank");
      }}
      onClose={() => navigate("")}
    />
  );
};

const RepeatOfferInfo = (props: { showNavbar: boolean }) => {
  const navigate = useNavigate();
  const { data } = useQuery({
    queryKey: ["/offers"],
    queryFn: () => get("/offers"),
  });

  if (!data) return null;

  return (
    <InfoOverlay
      header="Learn More"
      imageSrc={data.imageUrl}
      title={data.title}
      description={data.description}
      primary="Browse Shop"
      showNavbar={props.showNavbar}
      onButtonClick={() => navigate("/shop")}
      onClose={() => navigate("")}
    />
  );
};

const ICONS: Record<Models<"WidgetIcon">, FC<SVGProps<SVGSVGElement>>> = {
  Shop,
  Offers,
};

type WidgetProps = {
  widget: Models<"WidgetModel">;
  marketplaceEnabled?: boolean;
};

const PromotionCard = (props: WidgetProps & { promotionId: string }) => {
  const navigate = useNavigate();
  const { data } = useQuery({
    queryKey: ["/promotions"],
    queryFn: () => get("/promotions"),
    select: (data) => data.find((p) => p.id === props.promotionId),
    staleTime: 1000 * 60 * 2,
  });
  const redeem = useMutation({
    mutationFn: () =>
      post("/promotions/{promotionId}/redeem", {
        promotionId: props.promotionId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["/promotions"]);
      navigate(`/account/offers/${props.promotionId}?claimed=1`);
    },
  });

  const { data: authClaims } = useQuery({
    queryKey: ["/auth/claims"],
    queryFn: () => get("/auth/claims"),
  });

  const isAdminImpersonation = !!authClaims?.claims.adminUserId;

  if (!data || data.redeemedOn) return null;

  const tag = data.expiresOn
    ? { alert: `Expires ${dayjs(data.expiresOn).fromNow()}` }
    : null;

  return (
    <CampaignCard
      imageSrc={data.imageUrl}
      tag={tag}
      svg={props.widget.icon && ICONS[props.widget.icon]}
      buttonSvg={
        data.isInStoreRedeemable && !isAdminImpersonation ? QrCode : null
      }
      title={props.widget.title || data.title}
      description={props.widget.description || data.shortDescription}
      primary={
        data.isInStoreRedeemable && !isAdminImpersonation
          ? " Scan in office to claim offer"
          : props.widget.primaryText || "Claim Offer Now"
      }
      secondary="Learn More"
      onButtonClick={(btn) => {
        if (btn === "primary") {
          if (!data.isInStoreRedeemable || isAdminImpersonation) {
            if (props.widget.primaryUrl)
              open(props.widget.primaryUrl, "_blank");
            redeem.mutate();
          } else {
            navigate(`/scan-redeem-offer-in-location`, {
              state: {
                promotionId: data.id,
              },
            });
          }
        } else if (btn === "secondary") {
          navigate(`?widget=${props.widget.id}&promotion=${data.id}`);
        }
      }}
    />
  );
};

const ListingCard = (props: WidgetProps & { listingId: string }) => {
  const navigate = useNavigate();
  const { data, isSuccess } = useQuery({
    queryKey: ["/listings", props.listingId],
    queryFn: () => get("/listings/{id}", { id: props.listingId }),
    staleTime: 1000 * 60,
  });

  if (!isSuccess || !data) return null;

  return (
    <CampaignCard
      imageSrc={data.imageUrl}
      svg={props.widget.icon && ICONS[props.widget.icon]}
      title={props.widget.title || data.title}
      description={props.widget.description || data.description}
      primary={props.widget.primaryText || "Learn More"}
      onButtonClick={() => navigate("/pdp/package/" + data.id)}
    />
  );
};

const MembershipCard = (props: WidgetProps & { membershipId: string }) => {
  const navigate = useNavigate();
  const { data, isSuccess } = useQuery({
    queryKey: ["/memberships", props.membershipId],
    queryFn: () =>
      get("/memberships/{membershipId}", { membershipId: props.membershipId }),
  });

  if (!isSuccess || !data) return null;

  return (
    <CampaignCard
      imageSrc={data.imageUrl}
      svg={props.widget.icon && ICONS[props.widget.icon]}
      title={props.widget.title || data.name}
      description={props.widget.description || data.description || ""}
      primary={props.widget.primaryText || "See All Benefits"}
      onButtonClick={() => navigate("/pdp/membership/" + data.id)}
    />
  );
};

const GenericCard = (props: {
  widget: Models<"WidgetModel">;
  showNavbar: boolean;
}) => {
  const navigate = useNavigate();
  const title = props.widget.title;
  const description = props.widget.description;
  const imageUrl = props.widget.widgetData[0]?.imageUrl;

  const primaryText = props.widget.primaryText ?? "Learn More";
  const primaryUrl = props.widget.primaryUrl;

  const secondaryText = primaryText && primaryUrl ? "Learn More" : undefined;

  if (!title || !description) return null;

  let search = `?widget=${props.widget.id}`;
  if (!props.showNavbar) {
    search = search + "&isMobileApp=true";
  }

  return (
    <CampaignCard
      svg={props.widget.icon && ICONS[props.widget.icon]}
      title={title}
      description={description}
      imageSrc={imageUrl}
      primary={primaryText}
      secondary={secondaryText}
      onButtonClick={(btn) => {
        if (btn === "primary" && !primaryUrl) {
          navigate(search);
        } else if (btn === "primary" && primaryUrl) {
          // If primaryUrl includes a phone number, navigate in the same tab.
          // If not, open it in a new tab.
          if (primaryUrl.startsWith("tel:")) {
            window.location.href = primaryUrl;
          } else {
            open(primaryUrl, "_blank");
          }
        } else if (btn === "secondary") {
          navigate(search);
        }
      }}
    />
  );
};

const DiscoverCard = (props: {
  widget: Models<"WidgetModel">;
  showNavbar: boolean;
  marketplaceEnabled?: boolean;
}) => {
  const data = props.widget.widgetData[0];

  if (data?.promotionId) {
    return (
      <PromotionCard widget={props.widget} promotionId={data.promotionId} />
    );
  } else if (data?.packageListingId && props.marketplaceEnabled) {
    return (
      <ListingCard widget={props.widget} listingId={data.packageListingId} />
    );
  } else if (data?.membershipId && props.marketplaceEnabled) {
    return (
      <MembershipCard widget={props.widget} membershipId={data.membershipId} />
    );
  } else {
    return <GenericCard widget={props.widget} showNavbar={props.showNavbar} />;
  }
};

const DiscoverCarousel = (props: WidgetProps) => {
  const promotions = useQueries({
    queries: props.widget.widgetData.map((d) => ({
      enabled: !!d.promotionId,
      notifyOnChangeProps: ["data" as const],
      queryKey: ["/promotions"],
      queryFn: () => get("/promotions"),
      select: (data: GetResult<"/promotions">) => {
        const promotion = data.find((p) => p.id === d.promotionId);
        if (!promotion || promotion.redeemedOn) return;
        return {
          to: `?widget=${props.widget.id}&promotion=${promotion.id}`,
          tag: "Promotion",
          title: promotion.title,
          imageSrc: promotion.imageUrl ?? "",
          primary: "View Offer",
          alert:
            promotion.expiresOn &&
            `Expires ${dayjs(promotion.expiresOn).fromNow()}`,
        };
      },
      staleTime: 1000 * 60 * 2,
    })),
  });

  const listings = useQueries({
    queries: props.widget.widgetData.map((d) => ({
      enabled: !!d.packageListingId,
      queryKey: ["/listings", d.packageListingId],
      queryFn: () => get("/listings/{id}", { id: d.packageListingId! }),
      select: (data: GetResult<"/listings/{id}">) => ({
        to: "/pdp/package/" + data.id,
        tag: data.type,
        title: data.title,
        imageSrc: data.imageUrl ?? "",
        price: data.baseCost,
        memberPrice: data.membershipCost,
      }),
      staleTime: 1000 * 60,
    })),
  });

  const memberships = useQueries({
    queries: props.widget.widgetData.map((d) => ({
      enabled: !!d.membershipId,
      queryKey: ["/memberships", d.membershipId],
      queryFn: () =>
        get("/memberships/{membershipId}", { membershipId: d.membershipId! }),
      select: (data: GetResult<"/memberships/{membershipId}">) => ({
        to: "/pdp/membership/" + data.id,
        tag: "Membership",
        title: data.name,
        imageSrc: data.imageUrl ?? "",
        price: data.monthlyCost,
      }),
    })),
  });

  const title = props.widget.title;
  if (!title || !(promotions.length + listings.length)) return null;

  return (
    <ProductCarousel
      svg={props.widget.icon && ICONS[props.widget.icon]}
      title={title}
      description={props.widget.description}
      cards={props.widget.widgetData
        .map((d, i) => {
          if (d.promotionId) return promotions[i].data;
          if (d.packageListingId && props.marketplaceEnabled)
            return listings[i].data;
          if (d.membershipId && props.marketplaceEnabled)
            return memberships[i].data;
        })
        .filter(notNull)}
    />
  );
};

const VacationCard = (props: {
  widget: Models<"WidgetModel">;
  showNavbar: boolean;
}) => {
  const navigate = useNavigate();
  const { data, isSuccess } = useQuery(["/offers"], () => get("/offers"));

  if (!isSuccess || !data) return null;

  let search = `?repeatOffer=${data.id}`;
  if (!props.showNavbar) {
    search = search + "&isMobileApp=true";
  }

  return (
    <CampaignCard
      imageSrc={data.imageUrl}
      subtitle={props.widget.subtitle || "Win a Vacation!"}
      title={props.widget.title || data.title}
      description={props.widget.description || data.shortDescription}
      primary={props.widget.primaryText || "Learn More"}
      onButtonClick={() => navigate(search)}
    />
  );
};

const TopSellingCarousel = (props: WidgetProps) => {
  const { data: listings } = useQuery({
    queryKey: ["/listings"],
    queryFn: () => get("/listings"),
    staleTime: 1000 * 60,
    select: (data: GetResult<"/listings">) =>
      data
        .sort((a, b) => b.sellVolume - a.sellVolume)
        .map((listing) => {
          if (!listing.imageUrl || !listing.title || !listing.baseCost) {
            return null;
          }
          return {
            to: "/pdp/package/" + listing.id,
            tag: listing.type,
            title: listing.title,
            imageSrc: listing.imageUrl ?? "",
            price: listing.baseCost,
            memberPrice: listing.membershipCost,
          };
        })
        .filter(notFalsy)
        .slice(0, 5),
  });

  const title = props.widget.title;
  if (!title || !listings || !listings.length) return null;

  return (
    <ProductCarousel
      svg={props.widget.icon && ICONS[props.widget.icon]}
      title={title}
      description={props.widget.description}
      cards={listings}
    />
  );
};

const load = async (request: Request) => {
  /* When displaying the page in a mobile webview, we let the app handle displaying its own navbar */
  const showNavbar =
    new URL(request.url).searchParams.get("isMobileApp") !== "true";

  const query = new URL(request.url).searchParams;
  const platform = queryClient.fetchQuery(["/platform"], () =>
    get("/platform")
  );
  const layout = queryClient.fetchQuery({
    queryKey: ["/widgets", { page: "DiscoverPage" }],
    queryFn: () => get("/widgets", { page: "DiscoverPage" }),
  });

  return waitObj({
    layout,
    repeatOfferId: query.get("repeatOffer"),
    widgetId: query.get("widget"),
    promotionId: query.get("promotion"),
    platform,
    showNavbar,
  });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const discoverLoader: LoaderFunction = async ({ request }) => {
  return json(await load(request));
};

const DiscoverPage = () => {
  const { layout, promotionId, repeatOfferId, widgetId, showNavbar, platform } =
    useLoaderData() as LoaderData;

  if (promotionId) {
    return (
      <PromotionInfo
        widgetId={widgetId}
        promotionId={promotionId}
        showNavbar={showNavbar}
      />
    );
  } else if (widgetId) {
    return <CustomWidgetInfo widgetId={widgetId} showNavbar={showNavbar} />;
  } else if (repeatOfferId) {
    return <RepeatOfferInfo showNavbar={showNavbar} />;
  }

  return (
    <>
      {showNavbar && (
        <TopNav>
          <h1 className="text-xl">Discover</h1>
        </TopNav>
      )}
      <div className="flex flex-col [&>:nth-child(even)]:bg-two">
        {layout.map((widget) => {
          if (
            widget.type === "RepeatMDOffer" &&
            platform.platformSettings?.repeatMDMarketingEnabled === false
          ) {
            return null;
          }

          switch (widget.type) {
            case "CampaignCard":
              return (
                <DiscoverCard
                  key={widget.id}
                  widget={widget}
                  showNavbar={showNavbar}
                  marketplaceEnabled={
                    platform.platformSettings?.marketplaceEnabled
                  }
                />
              );
            case "DiscoverCarousel":
              return (
                <DiscoverCarousel
                  key={widget.id}
                  widget={widget}
                  marketplaceEnabled={
                    platform.platformSettings?.marketplaceEnabled
                  }
                />
              );
            case "ReferAFriend":
              return <ReferAFriend key={widget.id} />;
            case "RepeatMDOffer":
              return (
                <VacationCard
                  key={widget.id}
                  widget={widget}
                  showNavbar={showNavbar}
                />
              );
            case "ExternalLink":
              return (
                <DiscoverCard
                  key={widget.id}
                  widget={widget}
                  showNavbar={showNavbar}
                  marketplaceEnabled={
                    platform.platformSettings?.marketplaceEnabled
                  }
                />
              );
            case "TopSellingCarousel":
              if (!platform.platformSettings?.marketplaceEnabled) return null;
              return <TopSellingCarousel key={widget.id} widget={widget} />;
            default:
              return null;
          }
        })}
      </div>
    </>
  );
};

export default DiscoverPage;
