import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { datadogRum } from "@datadog/browser-rum-slim";
import { useMutation } from "@tanstack/react-query";
import cx from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import {
  ActionFunction,
  Form,
  json,
  Link,
  redirect,
  useActionData,
  useLocation,
  useNavigation,
} from "react-router-dom";

import { get, post } from "api";
import { Button } from "atoms/button/Button";
import { IconButton } from "atoms/icon-button/IconButton";
import { ReactComponent as ChevronLeft } from "atoms/icon/icons/chevron_left.svg";
import { TextButton } from "atoms/text-button/TextButton";
import { getFeatureFlag } from "services/Flags";
import Services from "services/Services";
import { readFormData } from "toolbox/ReadFormData";

type ActionResult = {
  error?: string;
  phoneNumber?: string;
};

type SignInForm = {
  phoneNumber: string;
  verificationCode?: string;
  newUser?: boolean | string;
  signupReferralCode?: string;
};

export const signInAction: ActionFunction = async ({ request }) => {
  const { phoneNumber, verificationCode, newUser, signupReferralCode } =
    await readFormData<SignInForm>(request);

  // Track the user session as early as possible, even if there are login errors
  datadogRum.setUser({ name: phoneNumber });

  if (Capacitor.isNativePlatform()) {
    try {
      const multiPlatformVerify = await post("/multi-platform/auth/verify", {
        phoneNumber,
        verificationCode,
      });

      Preferences.set({
        key: "multiPlatformVerifyToken",
        value: multiPlatformVerify?.token || "",
      });

      Preferences.set({
        key: "platformDomainTokens",
        value: JSON.stringify(multiPlatformVerify?.platformDomainTokens),
      });

      localStorage.setItem(
        "iconNames",
        JSON.stringify(
          multiPlatformVerify?.platformDomainTokens?.map(
            (platform) => `launcher_${platform.platformSubdomain}`
          ) || []
        )
      );
      if (!multiPlatformVerify?.token)
        return json({ error: "Failed to verify", phoneNumber });

      Preferences.set({
        key: "phoneNumber",
        value: phoneNumber,
      });

      if (newUser == "true" || newUser == true) {
        const redirectUrl = signupReferralCode
          ? `/signin/signup?referralCode=${signupReferralCode}`
          : "/signin/signup";

        return redirect(redirectUrl);
      }

      if (multiPlatformVerify.platformDomainTokens?.length === 1) {
        const platformDomainToken = multiPlatformVerify.platformDomainTokens[0];
        Services.auth.setAuthToken(platformDomainToken?.token || "");
        localStorage.setItem("platformId", platformDomainToken?.platformId);

        return redirect("/home?fromSignIn=true");
      }

      if (
        multiPlatformVerify.platformDomainTokens?.length &&
        multiPlatformVerify.platformDomainTokens.length > 1
      ) {
        if (localStorage.getItem("platformId")) {
          const id = localStorage.getItem("platformId");
          const platformDomainToken =
            multiPlatformVerify.platformDomainTokens.find(
              (pdt) => pdt.platformId === id
            );

          if (!platformDomainToken) {
            return redirect("/signin/platform-selector");
          }

          localStorage.setItem(
            "baseApiUrl",
            platformDomainToken.platformDomain
              ? "https://" + platformDomainToken.platformDomain
              : ""
          );
          Services.auth.setAuthToken(platformDomainToken.token || "");
          return redirect("/home");
        } else {
          return redirect("/signin/platform-selector");
        }
      }
    } catch (err) {
      return json({ error: "Failed to verify", phoneNumber });
    }
  }

  try {
    const verify = await post("/auth/verify", {
      phoneNumber,
      verificationCode,
      signupReferralCode,
    });

    if (!verify.token) return json({ error: "Failed to verify", phoneNumber });

    Services.auth.setAuthToken(verify.token);

    // TODO: Ideally this wouldn't need to be a separate request
    const user = await get("/user");
    const completeProfileFlag = await getFeatureFlag(
      "incomplete-profile-redirect-v-2"
    );
    const redirectUrl = signupReferralCode
      ? `/signin/signup?referralCode=${signupReferralCode}`
      : "/signin/signup";

    if (
      (newUser === "true" && !user.completedProfile && verify.token) ||
      (completeProfileFlag && !user.completedProfile)
    ) {
      return redirect(redirectUrl);
    } else {
      return redirect("/home?fromSignIn=true");
    }
  } catch (err) {
    return json({ error: "Failed to verify", phoneNumber });
  }
};

export type VerificationCodeProps = {
  value: string;
  valueLength: number;
  onChange: (value: string) => void;
  error: boolean;
};

const isDigit = new RegExp(/^\d+$/);

const VerificationCode = ({
  value,
  valueLength,
  onChange,
  error,
}: VerificationCodeProps) => {
  const valueItems = useMemo(() => {
    const valueArray = value.split("");
    const items: Array<string> = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (isDigit.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [value, valueLength]);

  const inputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const target = e.target;
    let targetValue = target.value;
    const isTargetValueDigit = isDigit.test(targetValue);

    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : " ";

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      const nextElementSibling =
        target.nextElementSibling as HTMLInputElement | null;

      if (nextElementSibling) {
        nextElementSibling.focus();
      }
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);

      target.blur();
    }

    const newValue =
      value.substring(0, idx) + targetValue + value.substring(idx + 1);

    onChange(newValue);

    if (!isTargetValueDigit) {
      return;
    }

    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const targetValue = target.value;

    target.setSelectionRange(0, targetValue.length);

    if (e.key !== "Backspace" || target.value !== "") {
      return;
    }

    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;
    target.setSelectionRange(0, target.value.length);
  };

  return (
    <div className="flex items-center justify-center gap-3">
      {valueItems.map((digit, idx) => (
        <input
          className={cx(
            "w-9 rounded-[5px] border px-2 py-2 text-center",
            !error ? "border-light-grey " : "border-red"
          )}
          key={idx}
          type={"text"}
          inputMode="numeric"
          pattern="\d{1}"
          autoComplete="one-time-code"
          maxLength={valueLength}
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
          autoFocus={idx == 0 ? true : false}
        />
      ))}
    </div>
  );
};

export const VerificationCodePage = () => {
  const navigation = useNavigation();
  const data = useActionData() as ActionResult | undefined;
  const [otp, setOtp] = useState("");
  const { state } = useLocation();
  const [disabedButton, setDisabedButton] = useState<boolean>(true);
  const [disabledRensendButton, setDisabledResendButton] =
    useState<boolean>(false);
  const [verificationError, setVerificationError] = useState<string>("");

  useEffect(() => {
    if (state) {
      window.localStorage.setItem("state", JSON.stringify(state));
    }
    if (data?.error) {
      setVerificationError(data.error);
      setDisabedButton(true);
    }
  }, [data, state]);

  const onChange = (value: string) => {
    if (value.replace(/\s/g, "").length == 6) {
      setVerificationError("");
      setDisabedButton(false);
    } else {
      setDisabedButton(true);
    }
    setOtp(value);
  };

  const resendSmsCodeMutation = useMutation({
    mutationFn: (phoneNumber: string) => post("/auth/login", { phoneNumber }),
    onSuccess: () => {
      setTimeout(() => {
        setDisabledResendButton(false);
      }, 5000);
    },
  });

  const stateInfo = window.localStorage.getItem("state")
    ? JSON.parse(window.localStorage.getItem("state") || "{}")
    : state;

  return (
    <div>
      <div className="pt-[calc(40px_+_env(safe-area-inset-top))] pl-3">
        <span className="flex items-center">
          <Link to="..">
            <IconButton size="small" style="iconOnly" svg={ChevronLeft} />
          </Link>
        </span>
      </div>
      <Form method="post">
        <div className="flex flex-col items-center gap-4 p-4 pt-5">
          <div className="font-serif text-h2 text-primary">
            Enter verification code
          </div>
          <div className="w-[75%] text-center text-body2 text-secondary">
            Verification code should have been sent to your phone number ***
            {stateInfo.phone.slice(-3)}
          </div>
          <fieldset
            disabled={navigation.state === "submitting"}
            className="flex w-full flex-col gap-5 pb-5"
          >
            <input type="hidden" name="phoneNumber" value={stateInfo.phone} />
            <input type="hidden" name="verificationCode" value={otp} />
            <input type="hidden" name="newUser" value={stateInfo.newUser} />
            <input
              type="hidden"
              name="signupReferralCode"
              value={stateInfo.signupReferralCode || ""}
            />
            <VerificationCode
              value={otp}
              valueLength={6}
              onChange={onChange}
              error={!!verificationError}
            />
            {data?.error && (
              <div className="text-center text-body3 text-red">
                {verificationError}
              </div>
            )}
            <div className="flex flex-col items-center">
              <TextButton
                disabled={disabledRensendButton}
                onClick={() => {
                  setDisabledResendButton(true);
                  resendSmsCodeMutation.mutate(stateInfo.phone);
                }}
              >
                Resend code?
              </TextButton>
            </div>
            <Button fullWidth submit disabled={disabedButton}>
              Confirm
            </Button>
          </fieldset>
        </div>
      </Form>
    </div>
  );
};
