import React from "react";

interface Props {
  /** Specify the color option */
  color?: "primary" | "secondary" | "alert" | "disabled";

  /** Specify the Tag size */
  size?: "large" | "medium" | "small";

  /** Displays a short descriptive keyword in a pill container */
  children: string;

  /** Specify the testid for current component */
  "data-testid"?: string;
}

export const DescriptiveTag: React.FC<Props> = ({
  color = "primary",
  size = "medium",
  children,
  "data-testid": dataTestId,
}) => {
  const sizes = {
    small: "text-chip uppercase py-1 px-1.5",
    medium: "text-bold3 py-1 px-2",
    large: "text-bold1 px-4 py-1.5",
  };

  const colors = {
    primary: "bg-brand-color text-white",
    secondary: "bg-brand-color/10 text-brand-color",
    alert: "text-white bg-text-primary",
    disabled: "bg-background-two text-dark-grey",
  };

  return (
    <div
      className={`flex w-fit items-center gap-1.5 rounded font-sans ${sizes[size]} ${colors[color]}`}
      data-testid={dataTestId}
    >
      {color === "alert" && (
        <div className="h-2.5 w-2.5 rounded-full bg-red">
          <div className="h-full w-full animate-ping rounded-full bg-inherit" />
        </div>
      )}
      {children}
    </div>
  );
};
