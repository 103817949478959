import React, { useEffect, useState } from "react";

import { AffirmBanner } from "atoms/affirm/affirmBanner/AffirmBanner";
import { Banner } from "atoms/banner/Banner";
import { AffirmBannerLarge } from "molecules/affirm-banner/AffirmBannerLarge";

export interface BannersContainerProps {
  children: React.ReactNode;
  to?: string;
}

const allowedTypes = [Banner, AffirmBanner, AffirmBannerLarge];

export const BannersContainer = ({ children }: BannersContainerProps) => {
  const [currentChild, setCurrentChild] = useState(0);

  const filteredChildren = React.useMemo(() => {
    return Array.isArray(children)
      ? children.filter(
          (child) =>
            React.isValidElement(child) &&
            allowedTypes.some((type) => child.type === type)
        )
      : [children];
  }, [children]);

  useEffect(() => {
    if (React.Children.count(filteredChildren) > 1) {
      const interval = setInterval(() => {
        setCurrentChild(
          (prevChild) =>
            (prevChild + 1) % React.Children.count(filteredChildren)
        );
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [filteredChildren]);

  const renderChildren = React.Children.map(
    filteredChildren,
    (child, index) => {
      const isActive = index === currentChild;

      return (
        <div
          className={`banner-wrapper overflow-hidden opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none ${
            isActive ? "active opacity-100" : "opacity-0"
          }`}
          style={{
            gridRowStart: 1,
            gridColumnStart: 1,
            pointerEvents: isActive ? "auto" : "none",
          }}
        >
          {child}
        </div>
      );
    }
  );

  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
      {renderChildren}
    </div>
  );
};
