import {
  json,
  LoaderFunction,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get } from "api";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/ic_chevron_right_small_ol.svg";
import { RepeatMDFooter } from "atoms/repeatmd-footer/RepeatMDFooter";
import { queryClient } from "pages/Root";
import { notNull } from "toolbox/Guards";
import { waitObj } from "toolbox/Promise";

import { ShopListHeader } from "./ShopListHeader/ShopListHeader";

const load = async () => {
  const shopData = queryClient.fetchQuery(["/shop"], () => get("/shop"));
  const listings = queryClient.fetchQuery(
    ["/listings"],
    () => get("/listings"),
    { staleTime: 1000 * 60 }
  );
  const theme = queryClient.fetchQuery(["/platform/theme"], () =>
    get("/platform/theme")
  );

  return waitObj({ shopData, listings, theme });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const shopBrowseByConcernsLoader: LoaderFunction = async () => {
  return json<LoaderData>(await load());
};

const ShopBrowseByConcernPage = () => {
  const navigate = useNavigate();
  const { shopData, listings, theme } = useLoaderData() as LoaderData;
  const headerImage = theme.resources?.find(
    (resource) => resource.key === "browse-concern-header"
  )?.url;
  const concerns = shopData.concerns?.map((concern) => {
    let count = 0;
    listings.forEach((listing) => {
      if (listing.concernIds.indexOf(concern.id) > -1) {
        count++;
      }
    });
    if (count > 0) {
      return { id: concern.id, name: concern.name, count };
    }
    return null;
  });
  return (
    <div className="flex h-full flex-col bg-two">
      <ShopListHeader
        bgGradient
        title="Browse by concern"
        subtitle="Select from popular concerns to find the best treatment for you"
        backgroundImageUrl={headerImage}
      />
      <div className="m-4 flex flex-col divide-y divide-solid divide-background-two overflow-clip rounded-lg bg-white shadow-subtle">
        {concerns &&
          concerns.filter(notNull).map((concern) => (
            <div
              key={concern && concern.id}
              className="flex h-14 w-full cursor-pointer flex-row items-center"
              onClick={() => navigate(`/shop/concern/${concern.id}`)}
            >
              <div className="flex w-full px-4 py-4 text-bold2 text-primary">
                {concern && concern.name}
              </div>
              <div className="flex w-full justify-end py-4 pl-4 text-body2 text-secondary">
                {concern && concern.count}{" "}
                {concern && concern.count > 1 ? "treatments" : "treatment"}
              </div>
              <div className="px-2">
                <Icon svg={ChevronRight} size="xsmall" color="brandColor" />
              </div>
            </div>
          ))}
      </div>
      <RepeatMDFooter />
    </div>
  );
};

export default ShopBrowseByConcernPage;
