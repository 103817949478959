import { LoaderFunction, Outlet, redirect } from "react-router-dom";

import { get } from "api";
import { queryClient } from "pages/Root";

import LoadingLayout from "./LoadingLayout";

/**
 * Layout route for anonymous users (pre-login)
 */
const AnonymousLayout = () => {
  return (
    <>
      <LoadingLayout />

      <div className="flex flex-auto">
        <div className="w-full">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export const AnonymousLoader: LoaderFunction = async ({ request }) => {
  const successfulPaymentParam = new URL(request.url).searchParams.get(
    "successfulPayment"
  );
  return await queryClient
    .fetchQuery(["/user"], () => get("/user"))
    .then(({ completedProfile }) => {
      if (!successfulPaymentParam && completedProfile) {
        return redirect("/home");
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
};

export default AnonymousLayout;
