import dayjs from "dayjs";

import { RewardStatus } from "api/types";
import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronRight } from "atoms/icon/icons/chevron_right.svg";
import { DescriptiveTag } from "atoms/tag/DescriptiveTag";

export interface RedeemedRewardProps {
  id: string;
  title?: string | null;
  earnedRewardStatus: RewardStatus.Redeemed | RewardStatus.CashedIn;
  redeemedDateTime?: string | null;
  onClick?: () => void;
  disclaimer?: string | null;
}

export const RedeemedReward = (props: RedeemedRewardProps) => {
  return (
    <div
      className="flex cursor-pointer items-center justify-between bg-white px-4 pt-4 pb-3"
      onClick={props.onClick}
      data-testid="div-earned-reward"
    >
      <div>
        <div className="pb-2">
          <DescriptiveTag color="secondary" size="small">
            {props.earnedRewardStatus === RewardStatus.Redeemed
              ? "redeemed"
              : "banked"}
          </DescriptiveTag>
        </div>

        <div className="pb-1 pr-4 font-sans">
          <div className="text-bold1 text-primary">{props.title}</div>
          {props.disclaimer && (
            <div className="pt-0.5 text-body3 italic leading-[18px] text-secondary line-clamp-3">
              {props.disclaimer}
            </div>
          )}
        </div>

        <div className="flex items-center gap-0.5 font-sans text-body2 text-secondary">
          {dayjs(props.redeemedDateTime).format("ddd, MM/DD/YYYY - hh:mma")}
        </div>
      </div>
      <div>
        <Icon svg={ChevronRight} size="xsmall" color="brandColor" />
      </div>
    </div>
  );
};
