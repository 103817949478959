import { useMutation } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { post } from "api";
import { QRScanner } from "organisms/qr-scanner/QRScanner";

const redeem = debounce(
  ({ scannedCode, rewardId }) =>
    post("/rewards/redeem-in-store", {
      scannedCode: scannedCode,
      rewardId: rewardId,
    }),
  500,
  { leading: true, trailing: false }
);

const ScanRedeemRewardInLocationPage = () => {
  const [scannedQRCode, setScannedQRCode] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [errorMessage, setErrorMessage] = useState(
    "Something went wrong - please contact support if this issue persists"
  );

  const mutation = useMutation({
    mutationFn: redeem,
    onSuccess: () => {
      setScannedQRCode("");
      navigate("/rewards/" + state.rewardId + "?comingFromScan=true");
    },
    onError: (err: any) => {
      if (err.response.status == 400) {
        setErrorMessage("Unrecognized QR code - please try again");
      }
    },
  });

  const handleShowErrorMessage = useCallback(() => {
    if (mutation.isError) {
      setShowErrorMessage(true);
      return setTimeout(() => {
        setShowErrorMessage(false);
        setScannedQRCode("");
      }, 2000);
    }
  }, [mutation.isError]);

  useEffect(() => {
    const timeoutId = handleShowErrorMessage();
    return () => clearTimeout(timeoutId);
  }, [handleShowErrorMessage]);

  const handleScanResult = useCallback(() => {
    if (
      scannedQRCode &&
      !showErrorMessage &&
      !mutation.isLoading &&
      !mutation.isSuccess
    ) {
      mutation.mutate({
        scannedCode: scannedQRCode,
        rewardId: state.rewardId,
      });
    }
  }, [scannedQRCode, showErrorMessage, mutation, state.rewardId]);

  useEffect(() => {
    handleScanResult();
  }, [handleScanResult]);

  return (
    <QRScanner
      errorMessage={showErrorMessage ? errorMessage : undefined}
      onClose={() => navigate(-1)}
      onResult={setScannedQRCode}
    />
  );
};

export default ScanRedeemRewardInLocationPage;
