import { Button } from "atoms/button/Button";

type EmptyStateProps = {
  title: string;
  body: string;
  buttonText: string;
  onClick: () => void;
};

export const EmptyState = (props: EmptyStateProps) => {
  return (
    <div className="flex flex-col items-center gap-5 px-10 py-12">
      <div className="text-bold1">{props.title}</div>
      <div className="text-body1 text-secondary">{props.body}</div>
      <Button onClick={props.onClick}>{props.buttonText}</Button>
    </div>
  );
};
