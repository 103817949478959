import { DescriptiveTag } from "atoms/tag/DescriptiveTag";

type KeyBenefitsProps = {
  keyBenefits: Array<string>;
};

export const KeyBenefits = (props: KeyBenefitsProps) => {
  if (props.keyBenefits.length == 0) {
    return null;
  }
  return (
    <div className="flex flex-col items-center gap-6 bg-one py-6 px-4">
      <div className="text-sub1 uppercase text-brand-color">
        HELPS WITH THESE CONCERNS
      </div>
      <div className="flex flex-wrap justify-center gap-2">
        {props.keyBenefits.slice(0, 4).map((key) => (
          <div key={key}>
            <DescriptiveTag color="secondary" size="large">
              {key}
            </DescriptiveTag>
          </div>
        ))}
      </div>
    </div>
  );
};
