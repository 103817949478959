import { useState } from "react";

import { Models } from "api/types";
import { ModalSelectorList } from "organisms/selection-modals/modal-selector-list/ModalSelectorList";
import { getProductTitleQuantity } from "services/HelperService";

interface TreatmentSelectorModalProps {
  // todo: change to Treatment type
  treatments: {
    id: string;
    name: string;
    description: string;
    quantity: number | null | undefined;
    packageUnitType: Models<"PackageUnitTypeModel"> | null | undefined;
  }[];
  onSubmit: (ids: string[]) => void;
  onNext?: () => void;
  onCancel: () => void;
  onBack: () => void;
  currentStep: number;
  stepCount: number;
  singleItemRedemption?: boolean;
}

export const TreatmentSelectorModal = ({
  treatments,
  onSubmit,
  onNext,
  onCancel,
  onBack,
  currentStep,
  stepCount,
  singleItemRedemption,
}: TreatmentSelectorModalProps) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  let title = "SELECT YOUR TREATMENT";
  if (stepCount > 1) {
    title += ` (${currentStep}/${stepCount})`;
  }
  let firstButtonLabel = "";
  let secondButtonLabel = "";
  let onFirstButtonClick = () => {
    // init variable
  };
  let onSecondButtonClick = () => {
    // init variable
  };
  let firstButtonDisabled = false;

  if (currentStep === 1 && stepCount === 1) {
    firstButtonLabel = "Select";
    onFirstButtonClick = () => onSubmit(selectedIds);
    firstButtonDisabled = selectedIds.length === 0;
    if (!singleItemRedemption) {
      secondButtonLabel = "Cancel";
      onSecondButtonClick = onCancel;
    }
  }
  if (currentStep === 1 && stepCount > 1 && onNext) {
    firstButtonLabel = "Next";
    onFirstButtonClick = onNext;
    firstButtonDisabled = selectedIds.length === 0;
    secondButtonLabel = "Cancel";
    onSecondButtonClick = onCancel;
  }
  if (currentStep > 1 && currentStep < stepCount && onNext) {
    firstButtonLabel = "Next";
    onFirstButtonClick = onNext;
    firstButtonDisabled = selectedIds.length < currentStep;
    secondButtonLabel = "Back";
    onSecondButtonClick = onBack;
  }
  if (stepCount > 1 && currentStep === stepCount) {
    firstButtonLabel = "Select";
    onFirstButtonClick = () => onSubmit(selectedIds);
    firstButtonDisabled = selectedIds.length < currentStep;
    secondButtonLabel = "Back";
    onSecondButtonClick = onBack;
  }

  return (
    <ModalSelectorList
      size="fullscreen"
      onClose={onCancel}
      open={true}
      title={title}
      type="products"
      items={treatments.map((item) => ({
        id: item.id,
        title: item.name,
        subtitle: getProductTitleQuantity(
          item.quantity,
          item.packageUnitType,
          true
        ),
      }))}
      checkedItems={selectedIds}
      onSelect={(itemId) =>
        setSelectedIds((ids) =>
          Object.assign([], ids, { [currentStep - 1]: itemId })
        )
      }
      firstButtonLabel={firstButtonLabel}
      onFirstButtonClick={onFirstButtonClick}
      firstButtonDisabled={firstButtonDisabled}
      secondButtonLabel={secondButtonLabel}
      onSecondButtonClick={onSecondButtonClick}
    />
  );
};
