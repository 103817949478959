import cx from "classnames";

interface ImagePlaceholderProps {
  /** Additional classes */
  className?: string | null;

  /** The url of the image */
  url?: string | null;

  /** The image title */
  title?: string | null;

  /** Specify the image size */
  size?: "sm" | "lg";

  /** Specify the testid for current component */
  "data-testid"?: string;
}

export const ImagePlaceholder = ({
  className,
  url,
  title,
  size = "lg",
  "data-testid": dataTestId,
}: ImagePlaceholderProps) => {
  const sizes = {
    sm: "h-[56px] w-[56px]",
    lg: "h-[120px] w-[120px]",
  };

  const styles = {
    sm: "bg-light-grey p-[4px]",
    lg: "bg-one  p-2.5",
  };

  return url ? (
    <img
      src={url}
      alt={title || ""}
      className={cx(sizes[size], styles[size], "object-contain", className)}
      data-testid={dataTestId}
    />
  ) : (
    <div>
      <div
        className={cx(
          "flex items-center justify-center bg-one text-2xl font-semibold leading-6 text-brand-color",
          sizes[size],
          styles[size],
          className
        )}
      >
        {title?.replace(/^the\s+/i, "")?.charAt(0)}
      </div>
    </div>
  );
};
