import { ContextType, createContext, useState } from "react";

type CartItem = {
  shopDisplayId: string;
  shopDisplayPackageId: string;
  packagePriceId: string;
};

export const CartContext = createContext({
  cart: [] as CartItem[],
  addItem: (item: CartItem): void => undefined,
  removeItem: (itemId: string): void => undefined,
});

export const useCartState = (): ContextType<typeof CartContext> => {
  const [cart, setCart] = useState<CartItem[]>([]);
  return {
    cart,
    addItem: (item) => {
      setCart([...cart, item]);
    },
    removeItem: (itemId) => {
      setCart(cart.filter((cartItem) => cartItem.shopDisplayId !== itemId));
    },
  };
};
