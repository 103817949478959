import cx from "classnames";
import { FC, SVGProps } from "react";

interface IconProps {
  /** The actual svg component to render (imports from component/icon/icons) */
  svg: FC<SVGProps<SVGSVGElement>>;

  /** Specify the fill color of the icon */
  color?:
    | "primaryText"
    | "secondaryText"
    | "darkGrey"
    | "white"
    | "brandColor"
    | "currentColor"
    | "successGreen"
    | "red"
    | "lightGrey";

  className?: string;

  /** Specify the stroke size of the icon */
  size?:
    | "x3small"
    | "x2small"
    | "xsmall"
    | "small"
    | "medium"
    | "large"
    | "xlarge";
}

/**
 * Atomic component that wraps an icon svg
 */
export const Icon = ({
  svg: Svg,
  color = "primaryText",
  size = "medium",
  className = "",
}: IconProps) => {
  const sizes = {
    x3small: "h-4 w-4",
    x2small: "h-5 w-5",
    xsmall: "h-6 w-6",
    small: "h-7 w-7",
    medium: "h-8 w-8",
    large: "h-11 w-11",
    xlarge: "h-12 w-12",
  };

  const colors = {
    brandColor: "fill-brand-color",
    currentColor: "fill-current",
    darkGrey: "fill-dark-grey",
    primaryText: "fill-[theme(textColor.primary)]",
    secondaryText: "fill-[theme(textColor.secondary)]",
    white: "fill-white",
    successGreen: "fill-success-green",
    red: "fill-red",
    lightGrey: "fill-light-grey",
  };

  return (
    <div className={cx(sizes[size], colors[color], className)}>
      <Svg />
    </div>
  );
};
