import { useQuery } from "@tanstack/react-query";

import { get } from "api";
import { RewardStatus } from "api/types";
import { ReactComponent as AccountNav } from "atoms/icon/icons/account_nav.svg";
import { ReactComponent as DiscoverNav } from "atoms/icon/icons/discover_nav.svg";
import { ReactComponent as HomeNav } from "atoms/icon/icons/home_nav.svg";
import { ReactComponent as AccountPlus1Nav } from "atoms/icon/icons/ic_account+1_nav_ol.svg";
import { ReactComponent as DiscoverPlus1Nav } from "atoms/icon/icons/ic_discover+1_nav_ol.svg";
import { ReactComponent as RewardsPlus1Nav } from "atoms/icon/icons/rewards+1_nav.svg";
import { ReactComponent as RewardsNav } from "atoms/icon/icons/rewards_nav.svg";
import { ReactComponent as ShopNav } from "atoms/icon/icons/shop_nav.svg";

import { Item } from "./item/Item";

/** App navigation menu. */
export const Footer = () => {
  const { data: rewards } = useQuery({
    queryKey: ["/rewards"],
    queryFn: () => get("/rewards", {}),
  });

  const { data: platform } = useQuery({
    queryKey: ["/platform"],
    queryFn: () => get("/platform"),
  });

  const promotions = useQuery({
    queryKey: ["/promotions"],
    queryFn: () => get("/promotions"),
    staleTime: 1000 * 60 * 2,
  });

  const hasAvailableRewards = rewards?.some(
    (r) => r.status === RewardStatus.Available
  );
  const hasAvailableOffers = promotions?.data?.some(
    (p) => p.expiresOn != null && p.redeemedOn == null
  );
  const hasRedeemedOffers = promotions?.data?.some((p) => p.redeemedOn != null);

  return (
    <div className="flex w-full items-center justify-between bg-white px-[10px] pt-[7px] pb-[calc(9px_+_env(safe-area-inset-bottom))] shadow-footerNavigation">
      <Item svg={HomeNav} to="/home" label="Home" />
      <Item
        svg={hasAvailableOffers ? DiscoverPlus1Nav : DiscoverNav}
        to="/discover"
        label="Discover"
      />
      {platform?.platformSettings?.marketplaceEnabled && (
        <Item svg={ShopNav} to="/shop" label="Shop" />
      )}
      <Item
        svg={hasAvailableRewards ? RewardsPlus1Nav : RewardsNav}
        to="/rewards"
        label="Rewards"
      />
      <Item
        svg={hasRedeemedOffers ? AccountPlus1Nav : AccountNav}
        to="/account"
        label="Account"
      />
    </div>
  );
};
