import { useMutation } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  json,
  LoaderFunction,
  useLoaderData,
  useNavigate,
} from "react-router-dom";

import { get, post } from "api";
import { RewardStatus } from "api/types";
import { QRScanner } from "organisms/qr-scanner/QRScanner";
import { queryClient } from "pages/Root";
import { waitObj } from "toolbox/Promise";

const load = async () => {
  const rewards = queryClient.fetchQuery(["/rewards"], () =>
    get("/rewards", {})
  );
  return waitObj({ rewards });
};

type LoaderData = Awaited<ReturnType<typeof load>>;
export const ScanCheckInPageLoader: LoaderFunction = async () => {
  return json<LoaderData>(await load());
};

const checkin = debounce(
  (scannedCode: string) => post("/visit/checkin", { scannedCode }),
  500,
  { leading: true, trailing: false }
);

const ScanCheckInPage = () => {
  const [scannedQRCode, setScannedQRCode] = useState("");
  const { rewards } = useLoaderData() as LoaderData;
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Something went wrong - please contact support if this issue persists"
  );
  const futureRewards = rewards.filter(
    (r) => r.status === RewardStatus.NotAvailable
  );
  const nextReward = futureRewards.length
    ? futureRewards.reduce(
        (acc, curr) =>
          acc.minimumVisits ?? 0 <= (curr.minimumVisits ?? 0) ? acc : curr,
        futureRewards[0]
      )
    : undefined;

  const rewardProgress = nextReward?.unlockProgressPercent || 0;
  const navigate = useNavigate();
  const queryString = new URLSearchParams({
    rewardProgress: `${rewardProgress}`,
    comingFromScan: "true",
  }).toString();

  const mutation = useMutation({
    mutationFn: checkin,
    onSuccess: () => {
      setScannedQRCode("");
      navigate(`/cart/checkout/reward?${queryString}`);
    },
    onError: (err: any) => {
      if (err.response.status == 400) {
        setErrorMessage("Unrecognized QR code - please try again");
      }
    },
  });

  const handleShowErrorMessage = useCallback(() => {
    if (mutation.isError) {
      setShowErrorMessage(true);
      return setTimeout(() => {
        setShowErrorMessage(false);
        setScannedQRCode("");
      }, 2000);
    }
  }, [mutation.isError]);

  useEffect(() => {
    const timeoutId = handleShowErrorMessage();
    return () => clearTimeout(timeoutId);
  }, [handleShowErrorMessage]);

  const handleScanResult = useCallback(() => {
    if (
      scannedQRCode &&
      !showErrorMessage &&
      !mutation.isLoading &&
      !mutation.isSuccess
    ) {
      mutation.mutate(scannedQRCode);
    }
  }, [scannedQRCode, showErrorMessage, mutation]);

  useEffect(() => {
    handleScanResult();
  }, [handleScanResult]);

  return (
    <QRScanner
      errorMessage={showErrorMessage ? errorMessage : undefined}
      onClose={() => navigate(-1)}
      onResult={setScannedQRCode}
    />
  );
};

export default ScanCheckInPage;
