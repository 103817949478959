import { useNavigate } from "react-router-dom";

import { Button } from "../button/Button";
import { ReactComponent as QrCode } from "../icon/icons/qr_code.svg";

const ScanInButton = () => {
  const navigate = useNavigate();
  const urlToNavigate = "/scan-checkin-page";
  const handleClick = () => navigate(urlToNavigate);

  return (
    <Button startIconSVG={QrCode} onClick={handleClick} size="xlarge">
      Check in for rewards
    </Button>
  );
};

export default ScanInButton;
