import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Models } from "api/types";

import {
  ListingSortOptions,
  ListingTypeOptions,
} from "./FilterAndSortListings";

const DEFAULT_SEARCH_PARAMS = {
  areas: "",
  concerns: "",
  sort: "featured",
  type: "",
} as const;

export const useListingFilterAndSort = (
  listings: Models<"ListingModel">[],
  treatmentType: ListingTypeOptions | null
) => {
  const stringifyArray = (strings: string[] | null) => {
    return strings?.join(",") ?? "";
  };

  const joinStrings = (strings: string | null) => {
    return strings === "" ? [] : strings?.split(",") ?? [];
  };

  const [searchParams, setSearchParams] = useSearchParams(
    DEFAULT_SEARCH_PARAMS
  );

  const [type, setType] = useState<ListingTypeOptions | null>(
    searchParams.get("type") == ""
      ? treatmentType
      : (searchParams.get("type") as ListingTypeOptions | null)
  );
  const [concerns, setConcerns] = useState<string[]>(
    joinStrings(searchParams.get("concerns"))
  );
  const [areas, setAreas] = useState<string[]>(
    joinStrings(searchParams.get("areas"))
  );
  const [sort, sortBy] = useState<ListingSortOptions>(
    searchParams.get("sort") as ListingSortOptions
  );
  const [checkpoints, setCheckpoints] = useState({
    type,
    concerns,
    areas,
    sort,
  });

  const typeCount = listings
    .flatMap((listing) => listing.type)
    .reduce((acc, concernId) => {
      return { ...acc, [concernId]: (acc[concernId] ?? 0) + 1 };
    }, {} as Record<string, number>);

  const filteredListings = listings
    .filter((listing) => (type == null ? true : listing.type == type))
    .filter((listing) =>
      concerns.every((concern) => listing.concernIds.includes(concern))
    )
    .filter((listing) => areas.every((area) => listing.areaIds.includes(area)))
    .sort((a, b) => {
      switch (sort) {
        case "featured": {
          return 0;
        }
        case "priceLowToHigh": {
          return a.baseCost - b.baseCost;
        }
        case "priceHighToLow": {
          return b.baseCost - a.baseCost;
        }
        case "recentlyAdded": {
          return new Date(b.created).valueOf() - new Date(a.created).valueOf();
        }
        case "bestsellers":
        default: {
          return b.sellVolume - a.sellVolume;
        }
      }
    });

  const concernsCount = filteredListings
    .flatMap((listing) => listing.concernIds)
    .reduce((acc, concernId) => {
      return { ...acc, [concernId]: (acc[concernId] ?? 0) + 1 };
    }, {} as Record<string, number>);

  const areasCount = filteredListings
    .flatMap((listing) => listing.areaIds)
    .reduce((acc, areaId) => {
      return { ...acc, [areaId]: (acc[areaId] ?? 0) + 1 };
    }, {} as Record<string, number>);

  return {
    filteredListings,
    type,
    setType,
    concerns,
    setConcerns: (concernId: string) => {
      let newConcerns;
      if (concerns.includes(concernId)) {
        newConcerns = concerns.filter((concern) => concern !== concernId);
      } else {
        newConcerns = [...concerns, concernId];
      }
      setConcerns(newConcerns);
    },
    areas,
    setAreas: (areaId: string) => {
      let newAreas;
      if (areas.includes(areaId)) {
        newAreas = areas.filter((area) => area !== areaId);
      } else {
        newAreas = [...areas, areaId];
      }
      setAreas(newAreas);
    },
    sort,
    sortBy,
    setSearchParams: () =>
      setSearchParams({
        areas: stringifyArray(areas),
        concerns: stringifyArray(concerns),
        sort: sort,
        type: type ?? "",
      }),
    typeCount,
    concernsCount,
    areasCount,
    saveCheckpoints: () => setCheckpoints({ type, concerns, areas, sort }),
    restore: () => {
      setType(checkpoints.type);
      setConcerns(checkpoints.concerns);
      setAreas(checkpoints.areas);
      sortBy(checkpoints.sort);
    },
    clearConcerns: () => {
      setConcerns([]);
      setSearchParams({
        areas: searchParams.get("areas") ?? "",
        sort: searchParams.get("sort") ?? "",
        type: searchParams.get("type") ?? "",
      });
    },
    clearAreas: () => {
      setAreas([]);
      setSearchParams({
        concerns: searchParams.get("concerns") ?? "",
        sort: searchParams.get("sort") ?? "",
        type: searchParams.get("type") ?? "",
      });
    },
  };
};
