import cx from "classnames";
import { useEffect, useState } from "react";

type PromoCodeBoxProps = {
  isUsed: boolean;
  code: string;
  fullWidth?: boolean;
};

export const PromoCodeBox = ({
  isUsed,
  code,
  fullWidth,
}: PromoCodeBoxProps) => {
  const [showCopiedToClipboard, setShowCopiedToClipboard] = useState(false);

  useEffect(() => {
    if (showCopiedToClipboard) {
      const timeout = setTimeout(() => {
        setShowCopiedToClipboard(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [showCopiedToClipboard]);

  return (
    <div className={fullWidth ? "w-full" : ""}>
      <div
        className={cx(
          "mx-4 flex items-center justify-center rounded py-[18px] text-center",
          isUsed ? "bg-two" : "cursor-pointer bg-light-grey"
        )}
        onClick={() => {
          if (!isUsed) {
            if (navigator.clipboard) {
              navigator.clipboard
                // Be aware clipboard.writeText works only over https
                .writeText(code)
                .then(() => setShowCopiedToClipboard(true))
                .catch((err) =>
                  console.log("Error copying promo code to clipboard: ", err)
                );
            } else {
              // For local app running on http
              const tempInput = document.createElement("input");
              tempInput.value = code;
              document.body.appendChild(tempInput);
              tempInput.select();
              document.execCommand("copy");
              document.body.removeChild(tempInput);
              setShowCopiedToClipboard(true);
            }
          }
        }}
      >
        <div
          className={cx(
            fullWidth ? "mr-4" : "mr-6",
            "font-medium",
            isUsed ? "text-dark-grey" : "text-primary"
          )}
        >
          Promo code: {code}
        </div>
        <div
          className={cx(
            "text-body1",
            isUsed ? "text-secondary" : "text-brand-color"
          )}
        >
          {isUsed ? "Used" : "Copy"}
        </div>
      </div>
      <div
        className={cx(
          "absolute top-[100px] left-2 right-2 z-10 rounded bg-black py-4 pl-6 text-body2 text-white",
          !showCopiedToClipboard && "hidden"
        )}
      >
        Copied to clipboard
      </div>
    </div>
  );
};
