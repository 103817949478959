import cx from "classnames";
import { useEffect, useState } from "react";
import { Form } from "react-router-dom";

import { Models } from "api/types";
import { Button } from "atoms/button/Button";
import { Checkbox } from "atoms/checkbox/Checkbox";
import { IconButton } from "atoms/icon-button/IconButton";
import { ReactComponent as Close } from "atoms/icon/icons/close.svg";
import { Modal as SimpleModal } from "atoms/modal/Modal";
import { TextButton } from "atoms/text-button/TextButton";
import { TextField } from "atoms/text-field/TextField";

interface AddressModalProps {
  address?: Models<"UserAddressModel">;
  open: boolean;
  onClose: () => void;
  onSave: (
    address:
      | Models<"CreateUserAddressRequest">
      | Models<"UpdateUserAddressRequest">
  ) => void;
  onDelete?: (id: string) => void;
}

export const AddressModal = ({
  address,
  open,
  onClose,
  onSave,
  onDelete,
}: AddressModalProps) => {
  const [formData, setFormData] = useState<
    Omit<Models<"UserAddressModel">, "userId">
  >({
    id: address?.id || "",
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    phoneNumber: "",
    isDefault: false,
  });
  const [errors, setErrors] = useState({
    phoneNumber: "",
  });

  useEffect(() => {
    setFormData({
      id: address?.id || "",
      firstName: address?.firstName || "",
      lastName: address?.lastName || "",
      address1: address?.address1 || "",
      address2: address?.address2 || "",
      city: address?.city || "",
      state: address?.state || "",
      postalCode: address?.postalCode || "",
      phoneNumber: address?.phoneNumber || "",
      isDefault: !!address?.isDefault,
    });
  }, [address]);

  const formatPhoneNumber = (value: string) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  return (
    <SimpleModal
      size="fullscreen"
      open={open}
      onClose={onClose}
      header={
        <div className="flex items-center justify-between border-b-[1px] border-solid border-light-grey py-3">
          <IconButton
            svg={Close}
            size="small"
            style="iconOnly"
            onClick={onClose}
          />

          <div className="text-sub2 text-primary">ADD NEW ADDRESS</div>

          <div className="invisible">
            <IconButton
              svg={Close}
              size="small"
              style="iconOnly"
              onClick={onClose}
            />
          </div>
        </div>
      }
      footer={
        <div className="flex w-full flex-col items-center gap-4 border-t-[1px] border-solid border-light-grey p-4">
          <Button
            fullWidth
            onClick={() =>
              onSave(
                !formData.id
                  ? formData
                  : Object.entries(formData).reduce(
                      (acc: Models<"UpdateUserAddressRequest">, [k, v]) => {
                        if (
                          k !== "id" &&
                          v !== address?.[k as keyof Models<"UserAddressModel">]
                        ) {
                          acc[
                            k as keyof Omit<
                              Models<"UpdateUserAddressRequest">,
                              "id"
                            >
                          ] = v as any;
                        }
                        return acc;
                      },
                      formData
                    )
              )
            }
            disabled={
              formData.phoneNumber.length < 14 ||
              Object.keys(formData).some(
                (k) =>
                  !(
                    k === "address2" ||
                    k === "id" ||
                    k === "isDefault" ||
                    formData[k as keyof AddressModalProps["address"]]
                  )
              )
            }
          >
            Save address
          </Button>
          <div className={cx(!(address && onDelete) && "invisible")}>
            <TextButton onClick={() => address?.id && onDelete?.(address.id)}>
              <div className="text-red">Delete address</div>
            </TextButton>
          </div>
        </div>
      }
    >
      <Form className="flex flex-col gap-4 p-6">
        <TextField
          label="First name"
          value={formData.firstName}
          onChange={(value) =>
            setFormData({
              ...formData,
              firstName: value.replace(/[^a-zA-Z\s]/g, ""),
            })
          }
        />
        <TextField
          label="Last name"
          value={formData.lastName}
          onChange={(value) =>
            setFormData({
              ...formData,
              lastName: value.replace(/[^a-zA-Z\s]/g, ""),
            })
          }
        />

        <TextField
          label="Address line 1"
          value={formData.address1}
          onChange={(value) =>
            setFormData({
              ...formData,
              address1: value.replace(/[^a-zA-Z0-9\s]/g, ""),
            })
          }
        />
        <TextField
          label="Address line 2"
          value={formData.address2 || ""}
          onChange={(value) =>
            setFormData({
              ...formData,
              address2: value.replace(/[^a-zA-Z0-9\s]/g, ""),
            })
          }
        />

        <TextField
          label="City"
          value={formData.city}
          onChange={(value) =>
            setFormData({
              ...formData,
              city: value.replace(/[^a-zA-Z\s]/g, ""),
            })
          }
        />

        <div className="flex gap-4">
          <div className="flex-[1.3]">
            <TextField
              maxLength={2}
              label="State"
              value={formData.state}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  state: value.replace(/[^a-zA-Z]/g, ""),
                })
              }
              placeholder="2 letter code"
            />
          </div>
          <div className="flex-[2_2_0%]">
            <TextField
              inputMode="numeric"
              maxLength={5}
              label="Zip code"
              value={formData.postalCode}
              onChange={(value) =>
                setFormData({
                  ...formData,
                  postalCode: value.replace(/[^0-9]/g, ""),
                })
              }
            />
          </div>
        </div>

        <TextField
          type="tel"
          label="Phone number"
          value={formData.phoneNumber}
          placeholder="(123) 456-7890"
          invalid={!!errors["phoneNumber"]}
          supportingText={errors["phoneNumber"]}
          onChange={(value) => {
            const formattedPhoneNumber = formatPhoneNumber(value);
            setFormData({ ...formData, phoneNumber: formattedPhoneNumber });
          }}
        />

        <Checkbox
          label="Use as default address"
          checked={formData.isDefault}
          onChange={(checked: boolean) =>
            setFormData({ ...formData, isDefault: checked })
          }
        />
      </Form>
    </SimpleModal>
  );
};
