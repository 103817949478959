import cx from "classnames";
import { useState } from "react";

import { Icon } from "atoms/icon/Icon";
import { ReactComponent as ChevronDownSmall } from "atoms/icon/icons/chevron_down_small.svg";
import { ReactComponent as ChevronUpSmall } from "atoms/icon/icons/chevron_up_small.svg";
import {
  FutureReward,
  FutureRewardProps,
} from "molecules/containers/reward/future-reward/FutureReward";

export interface UpcomingRewardListProps {
  rewards: FutureRewardProps[];
}

export const UpcomingRewardList = ({ rewards }: UpcomingRewardListProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded((expanded) => !expanded);
  };

  const visibleRewards = expanded ? rewards : rewards.slice(0, 3);

  if (!rewards.length) return null;

  return (
    <div>
      <div className="pb-6 text-center font-sans text-sub2 text-secondary">
        UPCOMING REWARDS
      </div>
      <div className="divide-y divide-solid divide-background-two overflow-clip rounded-lg shadow-subtle">
        {visibleRewards.map((reward) => (
          <FutureReward key={reward.id} {...reward} />
        ))}
        <div
          className={cx(
            "flex cursor-pointer justify-between bg-white px-4 py-3",
            rewards.length < 4 && "hidden"
          )}
          onClick={handleClick}
        >
          <div className="text-bold1 text-brand-color">
            {expanded ? "Hide rewards" : "See more rewards"}
          </div>
          <Icon
            color="brandColor"
            size="xsmall"
            svg={expanded ? ChevronUpSmall : ChevronDownSmall}
          />
        </div>
      </div>
    </div>
  );
};
