import { useNavigate } from "react-router-dom";

import { ActionModal } from "molecules/modal-window/action-modal/ActionModal";

interface PromoCodeErrorModalProps {
  onClose: () => void;
  error: string | undefined | null;
}

export const PromoCodeErrorModal = ({
  onClose,
  error,
}: PromoCodeErrorModalProps) => {
  const navigate = useNavigate();

  return (
    <ActionModal
      size="small"
      open={true}
      onClose={onClose}
      onFirstButtonClick={onClose}
      firstButtonLabel="Okay"
      title="Invalid Promotion"
      description={error}
    />
  );
};
